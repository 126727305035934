import { runInAction } from "mobx";
import { observer, Observer } from "mobx-react-lite";
import { useState } from "react";

import { Checkbox, Dialog, PrimaryButton, Stack, Text } from "@bps/fluent-ui";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import { MedicationSummary } from "../MedicationSummary.tsx";
import { useQuickAccessContext } from "../quickAccess/context/QuickAccessContext.tsx";
import { ReactionListDialog } from "../reactions/ReactionListDialog.tsx";
import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";
import { PrescribingFormulationsFilter } from "./PrescribingFormulationsFilter.tsx";
import { PrescribingFormulationsList } from "./PrescribingFormulationsList.tsx";
import { PrescribingHighlightStack } from "./PrescribingHighlightStack.tsx";
import { PrescribingMedicationDialogButtons } from "./PrescribingMedicationDialogButtons.tsx";
import { PrescribingPatientHeader } from "./PrescribingPatientHeader.tsx";
import { PrescribingProductsList } from "./PrescribingProductsList.tsx";
import { PrescribingProductsSearch } from "./PrescribingProductsSearch.tsx";
import { ReactionWarnings } from "./PrescribingReactionWarnings.tsx";
import { PrescribingWizardPage } from "./PrescribingWizardPage.tsx";

export const PrescribingMedicationDialog: React.FC = observer(() => {
  const { setMedicationDialogVisibleType, medicationDialogVisibleWithType } =
    useMedicationsContext();

  const helper = usePrescribingWizardContext();

  const {
    warnings,
    products,
    formulations,
    selectedProduct,
    selectedFormulation,
    restorePrescribingWizardHelper
  } = helper;

  const [isListDialogVisible, setIsListDialogVisible] = useState(false);

  const { clinicalRecord } = usePatientRecordScreenContext();

  const { setQuickAccessPrescribingRx, quickAccessPrescribingRx } =
    useQuickAccessContext();

  const hasPatientReactions = clinicalRecord?.hasReactionsRecorded;

  const hasUpdatedReactions = () => {
    if (clinicalRecord.clinicalData && clinicalRecord.clinicalData.reaction) {
      const { createLog, updateLog } = clinicalRecord.clinicalData.reaction;

      const openEncounterId = clinicalRecord.openEncounter?.id;

      return (
        openEncounterId &&
        (createLog?.createdEncounterId === openEncounterId ||
          updateLog?.updatedEncounterId === openEncounterId)
      );
    }
    return false;
  };

  const hasCheckedReactions =
    clinicalRecord.clinicalData?.reactionsChecked?.reactionsCheckedAndUpdated
      .value || hasUpdatedReactions();

  const hasPrescribingWarnings = !!warnings?.length;

  const renderHeader = () => {
    return (
      <Stack tokens={{ childrenGap: 8 }}>
        <PrescribingPatientHeader clinicalRecord={clinicalRecord} />
        {hasPatientReactions &&
          hasCheckedReactions &&
          (!!formulations?.length ? (
            <PrescribingFormulationsFilter />
          ) : (
            <PrescribingProductsSearch />
          ))}
      </Stack>
    );
  };

  const renderReactionPrompt = () => {
    return (
      <PrescribingHighlightStack
        horizontalAlign="center"
        verticalAlign="center"
      >
        <Text>To proceed with prescribing you must record reactions.</Text>
        <PrimaryButton
          text="Record reactions"
          onClick={() => setIsListDialogVisible(true)}
          styles={{ root: { marginTop: 10 } }}
        />
      </PrescribingHighlightStack>
    );
  };

  const renderReactionsCheck = () => {
    return (
      <PrescribingHighlightStack
        horizontalAlign="center"
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
      >
        <Text>
          To proceed with prescribing you must confirm you have checked the
          patient's reactions.
        </Text>
        <Checkbox
          automationAttribute="prescribing-reactions-checkbox"
          label="Reactions checked"
          onChange={async (ev, checked) => {
            if (checked) {
              runInAction(() => {
                helper.isReactionsCheckedAndUpdated = true;
              });
              await clinicalRecord.saveClinicalData({
                reactionsChecked: {
                  eTag: clinicalRecord.clinicalData?.reactionsChecked?.eTag,
                  reactionsCheckedAndUpdated: { value: true }
                }
              });
            }
          }}
        />
      </PrescribingHighlightStack>
    );
  };

  const renderLeftPanel = () => {
    if (!hasPatientReactions) {
      return renderReactionPrompt();
    } else if (!hasCheckedReactions) {
      return renderReactionsCheck();
    } else if (!!formulations?.length) {
      return <PrescribingFormulationsList />;
    } else if (!!products?.length) {
      return <PrescribingProductsList />;
    } else {
      return (
        <Stack
          grow
          verticalFill
          verticalAlign="center"
          horizontalAlign="center"
        >
          <Text>Search for an ingredient or product</Text>
        </Stack>
      );
    }
  };

  const renderRightPanel = () => {
    if (!hasPatientReactions || !hasCheckedReactions) {
      return null;
    } else if (hasPrescribingWarnings) {
      return (
        <PrescribingHighlightStack>
          <ReactionWarnings />
        </PrescribingHighlightStack>
      );
    } else if (selectedProduct && selectedFormulation) {
      return (
        <PrescribingHighlightStack>
          <MedicationSummary summary={selectedFormulation} />
        </PrescribingHighlightStack>
      );
    } else {
      return (
        <PrescribingHighlightStack
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Text>No formulation selected</Text>
        </PrescribingHighlightStack>
      );
    }
  };

  const title = quickAccessPrescribingRx
    ? "Prescribe Rx"
    : `${capitalizeSentence(medicationDialogVisibleWithType ?? "")} Rx`;

  const onClose = () => {
    setMedicationDialogVisibleType(undefined);
    setQuickAccessPrescribingRx(false);
    restorePrescribingWizardHelper();
  };

  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={onClose}
        dialogContentProps={{ title, showCloseButton: true }}
        maxWidth="90vw"
        minWidth="90vw"
      >
        <Observer>
          {() => (
            <PrescribingWizardPage>
              <Stack
                styles={{ root: { width: "50%", marginRight: 16 } }}
                tokens={{ childrenGap: 8 }}
              >
                {renderHeader()}
                {renderLeftPanel()}
              </Stack>

              <Stack styles={{ root: { width: "50%" } }} verticalFill>
                {renderRightPanel()}
              </Stack>
            </PrescribingWizardPage>
          )}
        </Observer>
        <PrescribingMedicationDialogButtons onClose={onClose} />
      </Dialog>

      {isListDialogVisible && (
        <ReactionListDialog
          clinicalRecord={clinicalRecord}
          onDismiss={() => {
            setIsListDialogVisible(false);
          }}
        />
      )}
    </>
  );
});

import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { Card, OptionsSelect, Stack } from "@bps/fluent-ui";

import { RecentPatientsList } from "./components/RecentPatientsList.tsx";

export type TakeValue = "20" | "40";

const RecentPatientsScreen: React.FC = observer(() => {
  const [take, setTake] = useState<TakeValue>("20");

  return (
    <Stack
      grow
      styles={{
        root: {
          height: "100%",
          maxWidth: 1500,
          margin: "0 auto"
        }
      }}
    >
      <Card
        styles={{
          subComponentStyles: {
            tile: {
              content: {
                padding: "16px",
                position: "relative"
              },
              root: {
                flexGrow: 1
              }
            }
          }
        }}
        headingLevel="section-heading"
        heading="My recent patients"
        iconName="TemporaryUser"
        button={
          <OptionsSelect
            styles={{ root: { width: 180 } }}
            hideClearButton
            hideSearchOption
            onChangeSelectedKeys={(value: TakeValue) => setTake(value)}
            selectedKeys={take}
            options={[
              { key: "20", text: "20 patients" },
              { key: "40", text: "40 patients" }
            ]}
          />
        }
      >
        <Stack styles={{ root: { height: "100%" } }}>
          <RecentPatientsList take={take} />
        </Stack>
      </Card>
    </Stack>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default RecentPatientsScreen;

import React, { ReactNode } from "react";

import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

import { ClinicalTasksTableLabels } from "../clinical-task/types/clinical-task-table-labels.enum.ts";
import { ClinicalActivityListFilter } from "./ClinicalActivityList.tsx";

export interface ClinicalActivityManagementFilterProps {
  children: ReactNode;
}

export interface ClinicalActivityManagementFilterValues
  extends ClinicalActivityListFilter {
  patientSearch?: string;
  createdByIds: string[];
  descriptions?: string[];
}

export const ClinicalActivityManagementFilterBase: React.FC<
  ClinicalActivityManagementFilterProps
> = ({ children }) => {
  const root = useStores();

  return (
    <FilterBar<ClinicalActivityManagementFilterValues>
      initialValues={{
        activityStatuses: [
          ClinicalActivityStatusText.Today,
          ClinicalActivityStatusText.Overdue,
          ClinicalActivityStatusText.Upcoming
        ],
        createdByIds: []
      }}
      items={[
        {
          type: "searchBox",
          name: "patientSearch",
          stickItem: true,
          props: {
            placeholder: ClinicalTasksTableLabels.SearchPatient,
            styles: {
              root: {
                flexGrow: 1
              }
            }
          }
        },
        {
          type: "optionsSelect",
          name: "activityStatuses",
          props: {
            id: "noShow-filter-appointmentDateRange",
            placeholder: "Status",
            options: [
              {
                key: ClinicalActivityStatusText.Upcoming,
                text: ClinicalActivityStatusText.Upcoming
              },
              {
                key: ClinicalActivityStatusText.Overdue,
                text: ClinicalActivityStatusText.Overdue
              },
              {
                key: ClinicalActivityStatusText.Today,
                text: ClinicalActivityStatusText.Today
              },
              {
                key: ClinicalActivityStatusText.Completed,
                text: ClinicalActivityStatusText.Completed
              }
            ],
            multiSelect: true,
            calloutWidth: 200,
            hideSearchOption: true
          }
        },
        {
          type: "optionsSelect",
          name: "descriptions",
          props: {
            id: "clinical-task-status-dropdown",
            placeholder: "Description",
            options: root.clinical.ref.clinicalActivityDescriptions.values
              .filter(x => x.task)
              .map(x => {
                return { key: x.code, text: x.text };
              }),
            multiSelect: true,
            hideSearchOption: true,
            calloutWidth: 256
          }
        },
        {
          type: "optionsSelect",
          name: "activityPriorities",
          props: {
            id: "noShow-filter-appointmentDateRange",
            placeholder: "Priority",
            options: root.clinical.ref.priorities.keyTextValues,
            multiSelect: true,
            calloutWidth: 200,
            hideSearchOption: true
          }
        },
        {
          type: "dateTimeFramePicker",
          name: "dueDateStartDate",
          props: {
            id: "filter-datetimeframepicker",
            startDateName: "dueDateStartDate",
            endDateName: "dueDateEndDate",
            placeholder: "Due",
            incrementName: "dueIncrement",
            incrementProps: {
              incrementTitle: "Consult count",
              suffix: "consults"
            }
          }
        },
        {
          type: "bookableUsersSelect",
          name: "createdByIds",
          props: {
            id: "clinical-task-filter-providers",
            usersFilter: {
              statusCodes: [UserStatus.Active, UserStatus.Inactive],
              businessRoleClasses: ["PROVIDER"]
            },
            filterByStatus: true,
            showInactiveBadge: true,
            multiSelect: true,
            placeholder: ClinicalTasksTableLabels.CreatedBy,
            calloutWidth: 350
          }
        }
      ]}
    >
      {() => children}
    </FilterBar>
  );
};

export const ClinicalActivityManagementFilter = withFetch(
  x => [
    x.clinical.ref.priorities.load(),
    x.clinical.ref.clinicalActivityDescriptions.load()
  ],
  ClinicalActivityManagementFilterBase
);

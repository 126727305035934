import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { DefaultButton, PrimaryButton, Spinner, Stack } from "@bps/fluent-ui";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";

interface PrescribingMedicationDialogButtonsProps {
  onClose: () => void;
}
export const PrescribingMedicationDialogButtons: FunctionComponent<PrescribingMedicationDialogButtonsProps> =
  observer(({ onClose }) => {
    const helper = usePrescribingWizardContext();
    const { handleSubmitMedication } = helper;

    const { setDoseFormDialogVisibleWithViewType } = useMedicationsContext();

    return (
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: 8 }}
        styles={{ root: { paddingTop: 24 } }}
      >
        {helper.warningsLoading && <Spinner />}
        <PrimaryButton
          disabled={
            !helper.selectedFormulation ||
            helper.warningsLoading ||
            !!helper.warnings?.length
          }
          text="Add dose"
          onClick={async () => {
            await handleSubmitMedication();
            if (!helper.warnings.length) {
              setDoseFormDialogVisibleWithViewType("edit");
            }
          }}
        />
        <DefaultButton text="Close" onClick={onClose} />
      </Stack>
    );
  });

import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import {
  FontIcon,
  FontSizes,
  IColumn,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AddressType,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PeopleTabs } from "@libs/routing/routes.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import {
  ContactCategoryText,
  ContactStatusText,
  ContactTypeText
} from "@ui-components/RefText.tsx";

import { ContactCommunicationColumn } from "./ContactCommunicationColumn.tsx";
import { ContactListProps } from "./ContactList.types.ts";
import { ContactListActionMenu } from "./ContactListActionMenu.tsx";
import { ContactListNotFound } from "./ContactListNotFound.tsx";
import { ContactNameColumn } from "./ContactNameColumn.tsx";
import { useContactListSearch } from "./hooks/useContactListSearch.ts";

export const ContactList: React.FC<ContactListProps> = observer(
  ({ filter, loading, activeTab }) => {
    const { core, practice } = useStores();
    const {
      ui: { showEditContact, showContactDetails }
    } = practice;

    const onShowEdit = (cardId: string, id: string) => {
      showEditContact(cardId, id);
    };

    const renderName = (contact: Contact) => (
      <ContactNameColumn contact={contact} />
    );

    const renderNameHeader = (headerName: string, tootlTipText: string) => {
      return (
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <span>{headerName}</span>
          {activeTab !== PeopleTabs.Organisations && (
            <TooltipHost content={tootlTipText}>
              <FontIcon
                iconName="Info"
                styles={{
                  root: {
                    paddingTop: 2,
                    fontSize: FontSizes.size12,
                    cursor: "pointer"
                  }
                }}
              />
            </TooltipHost>
          )}
        </Stack>
      );
    };

    const renderEmail = (contact: Contact): ReactNode => {
      const email = contact.communications.filter(
        x => x.type === CommunicationType.Email
      );
      if (email.length === 0) return "";
      if (email.length === 1) return email[0].value;

      return (
        <Navigate onClick={() => showContactDetails(contact.id)}>
          Multiple emails
        </Navigate>
      );
    };

    const renderAddress = (contact: Contact): ReactNode => {
      // look for physical address in record or in location
      const filterAddress = contact.addresses.filter(
        x => x.type === AddressType.Physical || x.type === AddressType.Both
      );
      if (filterAddress.length === 0) return "";
      if (filterAddress.length === 1) return addressText(filterAddress[0]);

      return (
        <Navigate onClick={() => showContactDetails(contact.id)}>
          Multiple addresses
        </Navigate>
      );
    };

    const canOpenClinical = core.hasPermissions([
      Permission.EncounterRead,
      Permission.ClinicalRead
    ]);

    const canOpenPatient = core.hasPermissions([
      Permission.PatientRead,
      Permission.PatientWrite,
      Permission.PatientRead,
      Permission.PatientWrite
    ]);

    const canOpenIndividualOrg = core.hasPermissions([
      Permission.ContactRead,
      Permission.ContactWrite,
      Permission.PatientRead,
      Permission.PatientWrite
    ]);

    const displayActionsColumn =
      canOpenClinical || canOpenPatient || canOpenIndividualOrg;

    const actionsColumn: IColumn | undefined = {
      name: "Actions",
      onRender: (contact: Contact) => (
        <ContactListActionMenu
          contact={contact}
          onShowEdit={onShowEdit}
          canOpenClinical={canOpenClinical}
        />
      ),
      className: RESET_CELLS_PADDING_CLASSNAME,
      key: "actions",
      minWidth: 50,
      maxWidth: 50
    };

    const defaultColumns: IColumn[] = [
      {
        name: "Name",
        onRenderHeader: () =>
          renderNameHeader("Name", "Last name, (First name) Preferred name"),
        onRender: renderName,
        key: nameof<Contact>("name"),
        minWidth: 100,
        maxWidth: 200
      },
      {
        name: "Date of birth",
        onRender: (contact: Contact) => contact.birthDate?.toDayDefaultFormat(),
        fieldName: nameof("birthDate"),
        key: nameof<Contact>("birthDate"),
        minWidth: 80,
        maxWidth: 100
      },
      {
        name: "Preferred Phone",
        onRender: (contact: Contact) => (
          <ContactCommunicationColumn contact={contact} />
        ),
        key: nameof<Contact>("communications"),
        minWidth: 80,
        maxWidth: 150
      },
      {
        name: "Email",
        onRender: renderEmail,
        key: nameof<Contact>("email"),
        minWidth: 80,
        maxWidth: 150
      },
      {
        name: "Physical Address",
        onRender: renderAddress,
        key: nameof<Contact>("addresses"),
        minWidth: 80,
        maxWidth: 250
      },
      {
        name: "Category",
        onRender: (contact: Contact) => (
          <ContactCategoryText code={contact.categories} />
        ),
        key: nameof<Contact>("categories"),
        minWidth: 100,
        maxWidth: 300
      },
      {
        name: "Type",
        onRender: (contact: Contact) => <ContactTypeText code={contact.type} />,
        fieldName: nameof("type"),
        key: nameof<Contact>("type"),
        minWidth: 80,
        maxWidth: 100
      },
      {
        name: "Status",
        fieldName: nameof("status"),
        onRender: (contact: Contact) => (
          <ContactStatusText code={contact.status} />
        ),
        key: nameof<Contact>("status"),
        minWidth: 80,
        maxWidth: 100
      }
    ];

    const getColumns = () => {
      const isPatientTab = activeTab === PeopleTabs.Patients;

      return defaultColumns.filter(x => {
        if (isPatientTab) {
          return x.key !== nameof<Contact>("categories");
        } else {
          return (
            x.key !== nameof<Contact>("type") &&
            x.key !== nameof<Contact>("birthDate")
          );
        }
      });
    };

    const search = useContactListSearch(filter);

    return (
      <div style={{ position: "relative", flexGrow: 1, margin: 16 }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always}>
          <div data-people-loading={loading}>
            <InfiniteScrollList<Contact>
              setKey="id"
              stickyHeader
              onRenderNoResults={() => <ContactListNotFound filter={filter} />}
              getItems={search}
              columns={
                displayActionsColumn
                  ? [actionsColumn, ...getColumns()]
                  : getColumns()
              }
            />
          </div>
        </ScrollablePane>
      </div>
    );
  }
);

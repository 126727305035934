import { action, observable } from "mobx";

import { Selection } from "@bps/fluent-ui";
import { DraftItemsListItem } from "@modules/billing/screens/billing-history/components/BillingList.types.ts";

export class DraftItemsListHelper {
  @observable selectedInvoices: DraftItemsListItem[] = [];
  @observable disableBulkDelete = true;

  @action
  updateFromSelection = () => {
    const selectedItems = this.selection
      .getSelection()
      .filter(item => item) as DraftItemsListItem[];

    const canBeDelete = selectedItems.length !== 0;

    this.disableBulkDelete = !canBeDelete;
    this.selectedInvoices = selectedItems.filter(item => item);
  };

  selection = new Selection({ onSelectionChanged: this.updateFromSelection });

  @observable
  isInvoiceNewModalVisible = false;
  @action
  setIsInvoiceNewModalVisible = (value: boolean) => {
    this.isInvoiceNewModalVisible = value;
  };
}

import { ReactNode } from "react";

import { DateTime } from "@bps/utils";
import {
  BillingStatuses,
  TransactionDto,
  TransactionItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AdjustmentName,
  TransactionAdjustedBarBase
} from "@modules/billing/screens/shared-components/TransactionAdjustedBarBase.tsx";
import { isBillingRefKeyCodeAccessor } from "@stores/billing/BillingRef.ts";
import { Transaction } from "@stores/billing/models/Transaction.ts";
import { refTextFor } from "@ui-components/RefText.tsx";

interface TransactionAdjustedBarProps {
  transaction: Transaction<
    TransactionDto<TransactionItemDto>,
    TransactionItemDto
  >;
  name: string;
}

export const TransactionAdjustedBar: React.FC<TransactionAdjustedBarProps> = ({
  transaction,
  name
}) => {
  if (
    !transaction.changeLog?.updatedBy ||
    transaction.status === BillingStatuses.current
  ) {
    return null;
  }

  let adjustmentName: string = "";
  if (transaction.status === BillingStatuses.cancelled) {
    adjustmentName = AdjustmentName.cancelledBy;
  } else if (transaction.status === BillingStatuses.adjusted) {
    adjustmentName = AdjustmentName.adjustedBy;
  }

  const updatedDate = DateTime.fromISO(
    transaction.changeLog.updatedDate
  )?.toDayDefaultFormat();

  let reason: string | ReactNode = transaction.adjustmentReason;

  if (
    transaction.adjustmentReasonText &&
    isBillingRefKeyCodeAccessor(transaction.adjustmentReasonText)
  ) {
    const key = transaction.adjustmentReasonText.key;
    const code = transaction.adjustmentReasonText.code;
    reason = refTextFor(x => x.billing.ref[key])({
      code
    });
  }

  return (
    <TransactionAdjustedBarBase
      userName={transaction.changeLog.updatedBy}
      transactionName={name}
      adjustmentName={adjustmentName}
      reason={reason}
      updatedDate={updatedDate}
    />
  );
};

import { reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";

import {
  Dialog,
  IconButton,
  IOverflowSetItemProps,
  Link,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import {
  DocumentTabStatus,
  DocumentWriterTab,
  PatientTab,
  TemplateWriterTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import { ReasonForVisitContext } from "@modules/clinical/screens/context/ReasonForVisitContext.ts";
import { ReasonForVisitHelper } from "@modules/clinical/screens/context/ReasonForVisitHelper.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { clinicalRecordMapKey } from "@stores/clinical/utils/clinical.utils.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ErrorAlert } from "@ui-components/Alert.tsx";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { whenPermission } from "@ui-components/withPerm.tsx";

import { ReasonForVisitModal } from "../patient-record/components/clinical-form/ReasonForVisitModal.tsx";
import { ClinicalRecordsBar } from "./components/ClinicalRecordsBar.tsx";
import { DocumentPill } from "./components/DocumentPill.tsx";
import { PatientPill } from "./components/PatientPill.tsx";
import { PatientPillOverflowButton } from "./components/PatientPillOverFlowButton.tsx";
import { TemplatePill } from "./components/TemplatePill.tsx";

interface WarningProps {
  header: string;
  body: string;
}

const toGenericWarningProps = ({ count }: { count: number }): WarningProps => ({
  header: count > 1 ? `${count} items in progress` : "Items in progress",
  body:
    count > 1
      ? "Finalise or discard the items to close"
      : "Finalise or discard the item to close"
});

const QuickLinksComponent: React.FC = observer(() => {
  const { clinical, practice, userExperience, correspondence, core, document } =
    useStores();

  const [warning, setWarning] = useState<WarningProps | undefined>(undefined);

  const openedConsults = clinical.ui.openedRecordIds
    .filter(x => x.encounterId)
    .map(x => {
      return clinical.multipleEncountersRecordsMap.get(
        clinicalRecordMapKey(x.patientId, x.encounterId)
      );
    })
    .filter(isDefined);

  const openedDocumentLinks = correspondence.openedDocumentTabs
    .map(x => correspondence.correspondenceMap.get(x.documentId))
    .filter(isDefined);

  const openedTemplateLinks = document.openedTemplateTabs
    .map(x => document.templateMap.get(x.documentId))
    .filter(isDefined);

  const activeTemplateTab = document.activeTemplateTab;

  const patientPillItems: IOverflowSetItemProps[] =
    clinical.ui.openedRecordIds.map(x => {
      const key = clinicalRecordMapKey(x.patientId, x.encounterId);
      const record = clinical.multipleEncountersRecordsMap.get(key);

      return {
        key: `${key}${x.encounterId === undefined ? "-view" : ""}`,
        patientId: x.patientId,
        type: "patient",
        active:
          clinical.activeRecord === key &&
          (x.encounterId
            ? !clinical.activeRecordIsView
            : clinical.activeRecordIsView),
        encounterId: x.encounterId,
        record
      };
    });

  const documentPillItems: IOverflowSetItemProps[] =
    correspondence.openedDocumentTabs.map(docTabMap => {
      return {
        key: docTabMap.documentId,
        type: "document",
        active:
          correspondence.activeDocumentTab?.documentId === docTabMap.documentId,
        patientId: docTabMap.patientId,
        encounterId: docTabMap.encounterId,
        documentTabStatus: docTabMap.documentTabStatus
      };
    });

  const templatePillItems: IOverflowSetItemProps[] =
    document.openedTemplateTabs.map(tempTabMap => {
      return {
        key: tempTabMap.documentId,
        type: "template",
        active: activeTemplateTab?.documentId === tempTabMap.documentId,
        documentTabStatus: tempTabMap.documentTabStatus,
        newTemplateId: tempTabMap.newDocumentId
      };
    });

  const pillItems = patientPillItems
    .concat(documentPillItems)
    .concat(templatePillItems);

  useEffect(() => {
    const loadPatientPills = async () => {
      const recordsIdsUserStorage = await userExperience.getUserStorage(
        UserStorageKeys.OpenPatientTabs
      );

      const persistedRecordsIds = recordsIdsUserStorage?.jsonData as
        | PatientTab[]
        | undefined;

      const filteredRecordIds = persistedRecordsIds?.filter(
        x =>
          !clinical.ui.openedRecordIds.some(
            y =>
              y.patientId === x.patientId &&
              (y.encounterId === undefined || y.encounterId === x.encounterId)
          )
      );

      let updatedOpenedRecords = Array.from(
        new Set([...clinical.ui.openedRecordIds, ...(filteredRecordIds ?? [])])
      );

      updatedOpenedRecords =
        userExperience.getUpdatedOpenedRecords(updatedOpenedRecords);

      const documentsIdsUserStorage = await userExperience.getUserStorage(
        UserStorageKeys.OpenDocuments
      );

      const persistedDocumentsIds =
        documentsIdsUserStorage !== undefined
          ? (documentsIdsUserStorage.jsonData as DocumentWriterTab[])
          : undefined;

      const templateIdsUserStorage = await userExperience.getUserStorage(
        UserStorageKeys.OpenTemplates
      );

      const persistedTemplateIds =
        templateIdsUserStorage !== undefined
          ? (templateIdsUserStorage.jsonData as TemplateWriterTab[])
          : undefined;

      if (filteredRecordIds || UserStorageKeys.OpenDocuments) {
        runInAction(() => {
          // merge any patient tab with tabs from user storage
          clinical.ui.openedRecordIds.replace(updatedOpenedRecords);
          correspondence.openedDocumentTabs.replace(
            Array.from(
              new Set([
                ...correspondence.openedDocumentTabs,
                ...(persistedDocumentsIds ?? [])
              ])
            )
          );
          document.openedTemplateTabs.replace(
            Array.from(
              new Set([
                ...document.openedTemplateTabs,
                ...(persistedTemplateIds ?? [])
              ])
            )
          );
        });
      }
    };

    loadPatientPills();
  }, [
    clinical,
    clinical.ui.openedRecordIds,
    core.user,
    correspondence.openedDocumentTabs,
    userExperience,
    document.openedTemplateTabs
  ]);

  const handleDocumentClick = (
    id: string,
    patientId: string,
    encounterId: string
  ) => {
    if (correspondence.activeDocumentTab?.documentId !== id)
      correspondence.activeDocumentTab = {
        documentId: id,
        patientId,
        encounterId
      };
  };

  const handleTemplateClick = (documentId: string) => {
    if (activeTemplateTab?.documentId !== documentId)
      document.setActiveTemplateTab({
        documentId
      });
  };

  useEffect(() => {
    /**
     * Every time the opend record id list changes, the patient and record details
     * are queried. Note that the store caches the records and it won't result
     * in repetitive API calls.
     */
    const openedRecordsReactionDisposer = reaction(
      () => clinical.ui.openedRecordIds.map(x => x.patientId),
      ids => {
        practice.getContactsById(ids);
      },
      {
        fireImmediately: true
      }
    );

    openedRecordsReactionDisposer();
  }, [clinical.ui.openedRecordIds, practice]);

  useEffect(() => {
    if (
      !userExperience.userStorageMap.get(UserStorageKeys.QuickLinksOnBottom)
    ) {
      userExperience.getUserStorage(UserStorageKeys.QuickLinksOnBottom);
    }
  }, [userExperience]);

  const renderStyledErrorAlert = (error: Error) => (
    <ErrorAlert
      error={error}
      styles={{ root: { height: 32, width: 200, button: { height: 24 } } }}
    />
  );

  const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    return (
      <>
        {item.type === "patient" ? (
          <ContactFetcher
            contactId={item.patientId}
            fallback={<Spinner />}
            renderError={renderStyledErrorAlert}
          >
            {(patient: Contact) => {
              return (
                <PatientPill
                  active={item.active}
                  record={item.record}
                  patient={patient}
                  encounterId={item.encounterId}
                />
              );
            }}
          </ContactFetcher>
        ) : (
          <>
            {item.type === "document" ? (
              <DataFetcher<(ClinicalDocument | undefined) | undefined>
                fetch={async ({ correspondence }) => {
                  if (
                    (item.documentTabStatus === DocumentTabStatus.New ||
                      !item.documentTabStatus) &&
                    !correspondence.correspondenceMap.get(item.key)
                  ) {
                    // If document is new and not in correspondence map the screen has been refreshed and we cannot show the document so remove from the openDocumentTabs array
                    await correspondence.closeDocument(item.key);
                    return undefined;
                  }

                  const doc =
                    await correspondence.getCorrespondenceByDocumentId(
                      item.patientId,
                      item.key
                    );

                  return doc;
                }}
              >
                {(doc: ClinicalDocument) => {
                  if (doc) {
                    return (
                      <ContactFetcher
                        contactId={doc.patientId}
                        fallback={<Spinner />}
                        renderError={renderStyledErrorAlert}
                      >
                        {(patient: Contact) => {
                          return (
                            <DocumentPill
                              onClose={async () =>
                                await correspondence.closeDocument(item.key)
                              }
                              onClick={() =>
                                handleDocumentClick(
                                  item.key,
                                  item.patientId,
                                  item.encounterId
                                )
                              }
                              active={item.active}
                              clinDocument={doc}
                              patient={patient}
                            />
                          );
                        }}
                      </ContactFetcher>
                    );
                  }
                  return undefined;
                }}
              </DataFetcher>
            ) : (
              <>
                {item.type === "template" ? (
                  <DataFetcher<Template | undefined>
                    fetch={async ({ document }) => {
                      let key: string = item.key;
                      if (
                        item.documentTabStatus === DocumentTabStatus.New &&
                        !document.templateMap.get(item.key)
                      ) {
                        // If template is new and not in template map the screen has been refreshed and we cannot show the template so remove from the openDocumentTabs array
                        await document.closeTemplate(item.key);
                        return undefined;
                      }
                      if (
                        item.documentTabStatus === DocumentTabStatus.Edit &&
                        item.newDocumentId
                      ) {
                        document.updateTemplateTab(
                          item.key,
                          item.newDocumentId,
                          false
                        );
                        key = item.newDocumentId;
                      }

                      return document
                        .getTemplateByDocumentId(key)
                        .catch(catchNotFoundError);
                    }}
                  >
                    {(temp: Template) => {
                      if (temp) {
                        return (
                          <TemplatePill
                            onClose={() => document.closeTemplate(temp.id)}
                            onClick={() => handleTemplateClick(temp.id)}
                            active={item.active}
                            template={temp}
                          />
                        );
                      }
                      return undefined;
                    }}
                  </DataFetcher>
                ) : null}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const handleCloseWarning = () => setWarning(undefined);

  const handleCloseAll = async (event: React.MouseEvent<any>) => {
    event.preventDefault();
    await Promise.all(
      clinical.ui.openedRecordIds.map(x =>
        clinical.getRecord(x.patientId, { encounterId: x.encounterId })
      )
    );

    await clinical.closeAllRecords();
    await correspondence.closeAllDocuments();

    openedTemplateLinks.forEach(link => {
      document.closeTemplate(link.id);
    });

    const encountersLeftOpenCount = clinical.ui.openedRecordIds.length;
    const documentsLeftOpenCount = openedDocumentLinks.length;
    const templatesLeftOpenCount = openedTemplateLinks.length;

    if (
      encountersLeftOpenCount ||
      documentsLeftOpenCount ||
      templatesLeftOpenCount
    ) {
      setWarning(
        toGenericWarningProps({
          count:
            encountersLeftOpenCount +
            documentsLeftOpenCount +
            templatesLeftOpenCount
        })
      );
      return;
    }
  };

  const onPositionClicked = async () => {
    const quickLinksStorageSetting = await userExperience.getUserStorage(
      UserStorageKeys.QuickLinksOnBottom
    );

    if (quickLinksStorageSetting) {
      const valueAsBool = quickLinksStorageSetting.jsonData as boolean;

      await userExperience.updateUserStorage(
        UserStorageKeys.QuickLinksOnBottom,
        {
          id: quickLinksStorageSetting.id,
          key: UserStorageKeys.QuickLinksOnBottom,
          userId: core.userId,
          jsonData: !valueAsBool,
          eTag: quickLinksStorageSetting.eTag
        }
      );
      return;
    }

    await userExperience.addUserStorage(UserStorageKeys.QuickLinksOnBottom, {
      key: UserStorageKeys.QuickLinksOnBottom,
      userId: core.userId,
      jsonData: false
    });
  };

  const onRenderOverflowButton = (
    overflowItems: IOverflowSetItemProps[] | undefined
  ): JSX.Element => {
    return (
      <PatientPillOverflowButton
        items={overflowItems ?? []}
        onDocumentClick={handleDocumentClick}
      />
    );
  };

  const saveInProgress = openedConsults.some(x => x.saving);

  return (
    <>
      {pillItems.length > 0 && (
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 16 }}
          styles={(_, theme) => ({
            root: {
              padding: "0 16px",
              minHeight: 40,
              borderBottom: `1px solid ${theme.appointmentStatusesColors.completedSecondary}`
            }
          })}
        >
          <ClinicalRecordsBar
            items={pillItems}
            onRenderItem={onRenderItem}
            onRenderOverflow={onRenderOverflowButton}
          />

          <Stack horizontal>
            {saveInProgress ? (
              <Spinner />
            ) : (
              <Link styles={{ root: { width: 70 } }} onClick={handleCloseAll}>
                Close all
              </Link>
            )}

            <TooltipHost
              content={
                userExperience.quickLinksOnBottom ? "Move up" : "Move down"
              }
            >
              <IconButton
                onClick={onPositionClicked}
                iconProps={{
                  iconName: userExperience.quickLinksOnBottom
                    ? "header"
                    : "footer"
                }}
              />
            </TooltipHost>
          </Stack>

          {warning && (
            <Dialog
              hidden={false}
              onDismiss={handleCloseWarning}
              dialogContentProps={{
                title: warning.header,
                showCloseButton: true
              }}
              maxWidth={600}
            >
              <MessageBar messageBarType={MessageBarType.warning}>
                {warning.body}
              </MessageBar>
            </Dialog>
          )}
        </Stack>
      )}
    </>
  );
});

const QuickLinksWithPermission = whenPermission(
  Permission.ClinicalRead,
  QuickLinksComponent
);

const QuickLinks: FunctionComponent = () => {
  const root = useStores();

  return (
    <ReasonForVisitContext.Provider value={new ReasonForVisitHelper(root)}>
      <QuickLinksWithPermission />
      <ReasonForVisitModal
        title="Add a reason for consult to end consult"
        submitButtonProps={{
          text: "End consult",
          iconProps: undefined
        }}
      />
    </ReasonForVisitContext.Provider>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default QuickLinks;

import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { GroupedList, IGroup, useTheme } from "@bps/fluent-ui";
import { HL7HeaderDataPreview } from "@modules/inbox/screens/shared-components/HL7HeaderDataPreview.tsx";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { getInboxScreenStylesSet } from "../../inbox/InboxScreen.styles.ts";
import { UploadDocForm } from "../../shared-components/upload-doc-form/UploadDocForm.tsx";
import { UploadDocFormLabels } from "../../shared-components/upload-doc-form/UploadDocForm.types.ts";
import { UserInboxActionForm } from "./user-inbox-form/UserInboxActionForm.tsx";

export interface InboxUploadActionGroupProps {
  document: InboxDocument;
  onClose: () => void;
}

export const InboxUploadActionGroup: React.FC<InboxUploadActionGroupProps> =
  observer(({ document, onClose }) => {
    const theme = useTheme();
    const { groupedListHeaderStyles } = getInboxScreenStylesSet(theme);

    const { isFormActive, setActiveForm, inboxDocument } =
      useContext(InboxScreenContext);

    const groups = (): IGroup[] => {
      if (
        inboxDocument?.headerDocumentDetailId &&
        inboxDocument?.headerEntityId
      ) {
        return [
          {
            key: "messageHeader",
            name: "Message header",
            startIndex: 0,
            count: 1,
            isCollapsed: false
          },
          {
            key: "details",
            name: "Details",
            startIndex: 1,
            count: 1,
            isCollapsed: !isFormActive
          },
          {
            key: "actions",
            name: "Actions",
            startIndex: 2,
            count: 1,
            isCollapsed: isFormActive
          }
        ];
      }

      return [
        {
          key: "details",
          name: "Details",
          startIndex: 0,
          count: 1,
          isCollapsed: !isFormActive
        },
        {
          key: "actions",
          name: "Actions",
          startIndex: 1,
          count: 1,
          isCollapsed: isFormActive
        }
      ];
    };

    const items = [
      <UploadDocForm
        key="upload-doc-form"
        document={document}
        isDisabled={true}
        onSubmitSucceeded={() => {
          setActiveForm(false);
        }}
        removeDocAfterSubmit={true}
        submitButtonProps={{
          text: UploadDocFormLabels.Save,
          iconProps: undefined
        }}
        confirmOnNewAssignee
      />,
      <UserInboxActionForm
        key="inbox-action-form"
        document={document}
        onClose={onClose}
      />
    ];
    if (
      inboxDocument?.headerDocumentDetailId &&
      inboxDocument?.headerEntityId
    ) {
      items.unshift(<HL7HeaderDataPreview key="messageHeader" />);
    }

    return (
      <GroupedList
        groups={groups()}
        items={items}
        styles={{
          root: {
            selectors: groupedListHeaderStyles
          }
        }}
        groupProps={{
          onRenderHeader: (props, defaultRender) => {
            if (!props || !defaultRender) {
              return null;
            }
            return defaultRender!({
              ...props,
              styles: { headerCount: { display: "none" } },
              onGroupHeaderClick: () => props.onToggleCollapse!(props.group!)
            });
          }
        }}
        onRenderCell={(_depth, item: React.ReactNode) => item}
      />
    );
  });

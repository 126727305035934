import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";

import { DateTime } from "@bps/utils";
import { ClinicalActivityDialog } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalActivityDialog.tsx";
import { ClinicalActivityFormModel } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalActivityFormModel.ts";
import { ClinicalNotificationCompleteDialog } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalNotificationCompleteDialog.tsx";
import { ClinicalNotificationDeleteDialog } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalNotificationDeleteDialog.tsx";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { ActivitiesClinicalList } from "./ActivitiesClinicalList.tsx";

export const ActivitiesClinical: FC = observer(() => {
  const { clinical, core } = useStores();

  const {
    selectedClinicalActivities,
    setSelectedClinicalActivities,
    setPatientClinicalActivites,
    patientClinicalActivities,
    setActivityDialogVisible,
    activityDialogVisible
  } = useContext(InboxScreenContext);

  useEffect(() => {
    if (selectedClinicalActivities.length > 0) {
      const clinicalActivity = selectedClinicalActivities[0];

      const fetchActivities = async () => {
        const activities = await clinical.getPatientClinicalActivities(
          clinicalActivity.patientId
        );
        setPatientClinicalActivites(activities);
      };

      fetchActivities();
    }
  }, [clinical, setPatientClinicalActivites, selectedClinicalActivities]);

  const { initialValues, onSubmit } = new ClinicalActivityFormModel(
    clinical,
    core,
    selectedClinicalActivities[0]
  );

  const handleDeleteConfirmed = async (reasonForDeleteComment: string) => {
    const promises = selectedClinicalActivities.map(activity => {
      const item = {
        ...activity.dto,
        isDeleted: true,
        deletedComment: reasonForDeleteComment
      };
      return clinical.updateClinicalActivity(activity.patientId, [item]);
    });
    await Promise.all(promises);
    setActivityDialogVisible({
      ...activityDialogVisible,
      delete: false
    });
    setSelectedClinicalActivities([]);
  };

  const incompleteSelectedClinicalActivities =
    selectedClinicalActivities.filter(x => x && !x.isCompleted);

  const handleCompleteConfirmed = async (notes: string) => {
    const promises = incompleteSelectedClinicalActivities.map(activity => {
      const item = {
        ...activity.dto,
        completionNotes: notes,
        activityStatus: ClinicalActivityStatus.Completed,
        completedBy: core.userId,
        completedDate: DateTime.now().toISO()
      };
      return clinical.updateClinicalActivity(activity.patientId, [item]);
    });
    await Promise.all(promises);
    setActivityDialogVisible({
      ...activityDialogVisible,
      complete: false
    });
    setSelectedClinicalActivities([]);
  };

  return (
    <>
      <ActivitiesClinicalList setSelected={setSelectedClinicalActivities} />
      <ClinicalActivityDialog
        title="Edit clinical task"
        hideActivityTypeOption
        clinicalActivity={selectedClinicalActivities[0]}
        clinicalActivities={patientClinicalActivities}
        hidden={!activityDialogVisible.edit}
        onDismiss={() => {
          setActivityDialogVisible({
            ...activityDialogVisible,
            edit: false
          });
        }}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      <ClinicalNotificationDeleteDialog
        title={{
          single: "Delete task?",
          multiple: "Delete tasks?"
        }}
        subText={{
          single: "Delete following task:",
          multiple: "Delete following tasks:"
        }}
        hidden={!activityDialogVisible.delete}
        selectedCount={incompleteSelectedClinicalActivities.length}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => {
          setActivityDialogVisible({
            ...activityDialogVisible,
            delete: false
          });
        }}
      />
      <ClinicalNotificationCompleteDialog
        itemName="task"
        hidden={!activityDialogVisible.complete}
        selectedCount={incompleteSelectedClinicalActivities.length}
        onConfirm={handleCompleteConfirmed}
        onCancel={() => {
          setActivityDialogVisible({
            ...activityDialogVisible,
            complete: false
          });
        }}
      />
    </>
  );
});

import { FormApi } from "final-form";

import { FontSizes, FontWeights, ILabelStyles } from "@bps/fluent-ui";
import {
  CareGiverDto,
  CareRecipientDto,
  ClinicalDataType,
  EncounterClinicalDataDto,
  SocialHistoryConfirmedClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import {
  toBoolObservationFromString,
  toStringFromBoolObservation
} from "../family-social-history.utils.ts";
import { SocialFormValidator } from "../validators/SocialFormValidator.tsx";
import { SocialFormContent } from "./SocialFormContent.tsx";
import { SocialFormValues } from "./SocialFormValues.ts";

interface SocialFormProps {
  clinicalRecord: ClinicalRecord;
}

export const emptyCareGiver: CareGiverDto[] = [{ id: "", careGiverTypes: [] }];

const validator = new SocialFormValidator();

export const sectionHeader: Partial<ILabelStyles> = {
  root: { fontSize: FontSizes.large, fontWeight: FontWeights.regular }
};

const socialFormConfirmed = (
  openEncounter: Encounter | undefined,
  socialHistoryConfirmed: SocialHistoryConfirmedClinicalDataDto | undefined
) => {
  return (
    openEncounter &&
    socialHistoryConfirmed?.createLog?.createdEncounterId === openEncounter?.id
  );
};

const SocialFormComponent: React.FunctionComponent<SocialFormProps> = ({
  clinicalRecord
}) => {
  const { stashedClinicalData, openEncounter, id, saveClinicalData, patient } =
    clinicalRecord;

  const { clinical, notification } = useStores();

  const hasBeenReset =
    !!clinicalRecord.stashedClinicalData?.haveBeenResetForms.get(
      ClinicalDataType.Social
    );

  const { isViewOnly } = usePatientRecordScreenContext();

  const currentSocial = hasBeenReset
    ? clinicalRecord.clinicalData?.social
    : stashedClinicalData?.social;

  const currentSocialHistoryConfirmed =
    clinicalRecord.clinicalData?.socialHistoryConfirmed;

  const currentPatientDemographicUpdate = hasBeenReset
    ? clinicalRecord.clinicalData?.patientDemographicUpdate
    : stashedClinicalData?.patientDemographicUpdate;

  const currentPhysicalActivity = hasBeenReset
    ? clinicalRecord.clinicalData?.physicalActivity
    : stashedClinicalData?.physicalActivity;

  const onCancel = () => {
    clinical.ui.closePatientRecordContentForm(id, ClinicalDataType.Social);
  };

  const onReset = () => {
    onCancel();
    clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
      ClinicalDataType.Social,
      true
    );
  };

  const getSourcePatientDemographicValues = () => {
    const poaRelationships = patient?.relationships?.filter(
      r => r.relationship === RelationshipType.PowerOfAttorney
    );

    const definedPoaRelationships = poaRelationships?.filter(
      r => r.hasRelationship === true
    );

    const isEnduringPowerOfAttorney: boolean | undefined =
      poaRelationships && poaRelationships.length > 0
        ? !!(definedPoaRelationships && definedPoaRelationships.length > 0)
        : undefined;

    const powerOfAttorneys = definedPoaRelationships?.map(r => {
      return { id: r.relatedContactId! };
    });

    const carerRelationships = patient?.relationships?.filter(
      r => r.relationship === RelationshipType.Carer
    );

    const definedCarerRelationships = carerRelationships?.filter(
      r => r.hasRelationship === true
    );

    const hasACareGiver: boolean | undefined =
      carerRelationships && carerRelationships.length > 0
        ? !!(definedCarerRelationships && definedCarerRelationships.length > 0)
        : undefined;

    const careGivers = definedCarerRelationships?.map(r => {
      return {
        id: r.relatedContactId!,
        careGiverTypes: r.metadata && r.metadata["careType"]?.split(",")
      } as CareGiverDto;
    });

    const careRecipientRelationships = patient?.relationships?.filter(
      r => r.relationship === RelationshipType.CarerOf
    );

    const definedcareRecipientRelationships =
      careRecipientRelationships?.filter(r => r.hasRelationship === true);

    const isACarer: boolean | undefined =
      careRecipientRelationships && careRecipientRelationships.length > 0
        ? !!(
            definedcareRecipientRelationships &&
            definedcareRecipientRelationships.length > 0
          )
        : undefined;

    const careRecipients = definedcareRecipientRelationships?.map(r => {
      return {
        id: r.relatedContactId!,
        careRecipientTypes: r.metadata && r.metadata["careType"]?.split(","),
        assigned: !!r.relatedContactId
      } as CareRecipientDto;
    });

    return {
      relationshipStatus: patient?.relationshipStatus,
      isEnduringPowerOfAttorney,
      powerOfAttorneys,
      hasACareGiver,
      isACarer,
      careGivers,
      careRecipients
    };
  };

  const sourcePatientDemographicValues = getSourcePatientDemographicValues();

  const getInitialValues = (): SocialFormValues => {
    let hasACareGiver: string | undefined;
    if (
      currentPatientDemographicUpdate?.hasACareGiver &&
      currentPatientDemographicUpdate.hasACareGiver.observed !==
        sourcePatientDemographicValues.hasACareGiver
    ) {
      hasACareGiver = toStringFromBoolObservation(
        currentPatientDemographicUpdate.hasACareGiver.observed
      );
    } else {
      hasACareGiver = toStringFromBoolObservation(
        sourcePatientDemographicValues.hasACareGiver
      );
    }

    let isACarer: string | undefined;
    if (
      currentPatientDemographicUpdate?.isACarer &&
      currentPatientDemographicUpdate.isACarer.observed !==
        sourcePatientDemographicValues.isACarer
    ) {
      isACarer = toStringFromBoolObservation(
        currentPatientDemographicUpdate?.isACarer?.observed
      );
    } else {
      isACarer = toStringFromBoolObservation(
        sourcePatientDemographicValues?.isACarer
      );
    }

    return {
      social: {
        sexuality: currentSocial?.sexuality,
        isPregnant: toStringFromBoolObservation(
          currentSocial?.isPregnant?.observed
        ),
        isBreastfeeding: toStringFromBoolObservation(
          currentSocial?.isBreastfeeding?.observed
        ),
        isAdvancedCareDirective: toStringFromBoolObservation(
          currentSocial?.isAdvancedCareDirective?.observed
        ),
        accommodation: currentSocial?.accommodation,
        livesWith: currentSocial?.livesWith,
        safetyInHome: currentSocial?.safetyInHome
      },
      physicalActivity: {
        isEliteAthlete: toStringFromBoolObservation(
          currentPhysicalActivity?.isEliteAthlete?.observed
        )
      },
      patientDemographic: {
        relationshipStatus:
          currentPatientDemographicUpdate?.relationshipStatus ??
          sourcePatientDemographicValues?.relationshipStatus,
        isEnduringPowerOfAttorney:
          currentPatientDemographicUpdate &&
          currentPatientDemographicUpdate.isEnduringPowerOfAttorney
            ?.observed !==
            sourcePatientDemographicValues.isEnduringPowerOfAttorney
            ? toStringFromBoolObservation(
                currentPatientDemographicUpdate?.isEnduringPowerOfAttorney
                  ?.observed
              )
            : toStringFromBoolObservation(
                sourcePatientDemographicValues?.isEnduringPowerOfAttorney
              ),
        powerOfAttorneys:
          currentPatientDemographicUpdate?.powerOfAttorneys ??
          sourcePatientDemographicValues.powerOfAttorneys,
        hasACareGiver,
        careGivers:
          currentPatientDemographicUpdate?.careGivers ??
          sourcePatientDemographicValues.careGivers,
        isACarer,
        careRecipients:
          currentPatientDemographicUpdate?.careRecipients ??
          sourcePatientDemographicValues.careRecipients
      },
      socialConfirmed: {
        confirmed: socialFormConfirmed(
          openEncounter,
          currentSocialHistoryConfirmed
        )
      }
    };
  };

  const submitData = async (values: SocialFormValues) => {
    const clinicalData = getSocialStashedClinicalData(values);
    await saveClinicalData(clinicalData);
  };

  const getSocialStashedClinicalData = (values: SocialFormValues) => {
    const socialETag = stashedClinicalData?.originalDto?.social?.eTag;
    const confirmedETag =
      stashedClinicalData?.originalDto?.socialHistoryConfirmed?.eTag;

    const pduETag =
      stashedClinicalData?.originalDto?.patientDemographicUpdate?.eTag;

    const physicalActivityETag =
      stashedClinicalData?.originalDto?.physicalActivity?.eTag;

    const clinicalData: EncounterClinicalDataDto = {};

    clinicalData.social = {
      eTag: socialETag,
      sexuality: values.social.sexuality,
      isPregnant: toBoolObservationFromString(values.social.isPregnant),
      isBreastfeeding: toBoolObservationFromString(
        values.social.isBreastfeeding
      ),
      isAdvancedCareDirective: toBoolObservationFromString(
        values.social.isAdvancedCareDirective
      ),
      accommodation: values.social.accommodation,
      livesWith: values.social.livesWith,
      safetyInHome: values.social.safetyInHome
    };

    clinicalData.physicalActivity = {
      ...currentPhysicalActivity,
      eTag: physicalActivityETag,
      isEliteAthlete: toBoolObservationFromString(
        values.physicalActivity?.isEliteAthlete
      )
    };

    clinicalData.patientDemographicUpdate = {
      ...currentPatientDemographicUpdate,
      eTag: pduETag,
      relationshipStatus:
        sourcePatientDemographicValues.relationshipStatus !==
        values.patientDemographic.relationshipStatus
          ? values.patientDemographic.relationshipStatus
          : undefined,
      isEnduringPowerOfAttorney:
        toStringFromBoolObservation(
          sourcePatientDemographicValues.isEnduringPowerOfAttorney
        ) !== values.patientDemographic.isEnduringPowerOfAttorney
          ? toBoolObservationFromString(
              values.patientDemographic.isEnduringPowerOfAttorney
            )
          : undefined,
      powerOfAttorneys:
        sourcePatientDemographicValues.powerOfAttorneys !==
        values.patientDemographic.powerOfAttorneys
          ? values.patientDemographic.powerOfAttorneys
          : undefined,
      hasACareGiver:
        toStringFromBoolObservation(
          sourcePatientDemographicValues.hasACareGiver
        ) !== values.patientDemographic.hasACareGiver
          ? toBoolObservationFromString(values.patientDemographic.hasACareGiver)
          : undefined,
      careGivers:
        sourcePatientDemographicValues.careGivers !==
        values.patientDemographic.careGivers
          ? values.patientDemographic.careGivers
          : undefined,
      isACarer:
        toStringFromBoolObservation(sourcePatientDemographicValues.isACarer) !==
        values.patientDemographic.isACarer
          ? toBoolObservationFromString(values.patientDemographic.isACarer)
          : undefined,
      careRecipients:
        sourcePatientDemographicValues.careRecipients !==
        values.patientDemographic.careRecipients
          ? values.patientDemographic.careRecipients
          : undefined
    };

    clinicalData.socialHistoryConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };

    return clinicalData;
  };

  const onSubmitSucceeded = (
    values: SocialFormValues,
    form: FormApi<SocialFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.Social
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onCancel();
    }
  };

  const getValues = (stashedData?: StashedEncounterClinicalData) => {
    const dirty = !!stashedData?.dirtySocialForm && !hasBeenReset;

    const isNewSocial =
      !clinicalRecord.clinicalData?.social?.createLog &&
      !clinicalRecord.clinicalData?.patientDemographicUpdate?.createLog &&
      currentPhysicalActivity?.isEliteAthlete === undefined; // only one field from this clinicalData on form

    const socialFormAlreadyConfirmed =
      socialFormConfirmed(
        openEncounter,
        stashedData?.originalDto?.socialHistoryConfirmed
      ) ?? false;

    return { dirty, socialFormAlreadyConfirmed, isNewSocial };
  };

  const getButtonText = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, socialFormAlreadyConfirmed, isNewSocial } =
      getValues(stashedData);

    const submissionButtonText =
      isNewSocial ||
      socialFormAlreadyConfirmed ||
      (!socialFormAlreadyConfirmed && dirty)
        ? "Save"
        : "Confirm";

    return submissionButtonText;
  };

  const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, socialFormAlreadyConfirmed, isNewSocial } =
      getValues(stashedData);

    return (isNewSocial || socialFormAlreadyConfirmed) && !dirty;
  };

  return (
    <ClinicalSubmissionForm<SocialFormValues>
      formName="social"
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={getInitialValues()}
      readOnly={isViewOnly}
      validate={validator.validate}
      disableRoutePrompt
      heading="Social history"
      onCancel={onReset}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtySocialForm && !hasBeenReset
      }
      disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
      submitButtonTextCondition={stashedData => getButtonText(stashedData)}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.socialHistoryConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.socialHistoryConfirmed
      )}
      hideSubmit={isViewOnly}
    >
      <>
        <StashedClinicalDataFormSpy<SocialFormValues>
          clinicalRecord={clinicalRecord}
          getData={getSocialStashedClinicalData}
          areasToObserve={{
            social: [ClinicalDataType.Social],
            patientDemographicUpdate: [ClinicalDataType.Social],
            physicalActivity: [ClinicalDataType.Social]
          }}
          withHasBeenResetFormCheck={ClinicalDataType.Social}
        />
        <SocialFormContent patient={patient} />
      </>
    </ClinicalSubmissionForm>
  );
};

export const SocialForm = withFetch(
  x => [
    x.clinical.ref.accommodations.load(),
    x.clinical.ref.livesWith.load(),
    x.clinical.ref.safetyInHome.load(),
    x.clinical.ref.sexuality.load(),
    x.clinical.ref.relationshipStatuses.load(),
    x.clinical.ref.careTypes.load()
  ],
  SocialFormComponent
);

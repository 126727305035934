import React, { useContext } from "react";

import { Link, Stack } from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

export const RebookAppointmentLink: React.FC<{}> = () => {
  const { calendarEvent } = useContext(BookingCalendarEventContext);

  const { contactId } = calendarEvent;

  const { booking } = useStores();
  return (
    <Stack verticalAlign="end" styles={{ root: { paddingBottom: 6 } }}>
      <Link
        onClick={() => {
          booking.ui.showCalendarEventDialog({
            type: CalendarEventType.Appointment,

            secondColumnContent: SecondColumnContent.nextAvailable,
            initialValues: {
              startDate:
                calendarEvent?.startDateTime &&
                calendarEvent?.startDateTime.plus({ weeks: 1 }).toJSDate(),
              startTime: calendarEvent.startTime.toTimeInputFormat(),
              appointmentTypeId: calendarEvent?.appointmentTypeId,
              patientId: contactId,
              episodeOfCareId:
                calendarEvent?.reason?.episodeOfCareId ?? undefined,
              providerId: calendarEvent?.userId
            }
          });
        }}
      >
        Rebook in a week
      </Link>
    </Stack>
  );
};

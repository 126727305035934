import { FC, useContext, useState } from "react";

import {
  Heading,
  MoreLessToggle,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { FeeActiveLabel } from "../../labels.ts";
import {
  FEE_FORM_CHANGES_BADGE_WIDTH,
  feeFormChangesBadgeStyles,
  feeFormChangesFeeStyles,
  feeFormChangesOngoingToggleStyles,
  formDateInputStyles
} from "../../SchedulesScreen.styles.ts";
import { feeFormNameOf } from "../ScheduleForm.types.tsx";

const DEFAULT_NUM_ROWS_SHOWN = 2;

export const FeeFormChangesFields: FC = () => {
  const { palette } = useTheme();

  const { editFee: fee } = useContext(ScheduleScreenContext);

  const allExpiredInstances = Array.from(fee?.lockedInstances || []).reverse();
  const isToggleRequired =
    (allExpiredInstances.length || 0) > DEFAULT_NUM_ROWS_SHOWN;

  const [showAll, setShowAll] = useState<boolean>(!isToggleRequired);
  const instancesShown = showAll
    ? allExpiredInstances
    : allExpiredInstances.slice(0, DEFAULT_NUM_ROWS_SHOWN);

  return (
    <Stack tokens={{ childrenGap: 8 }} styles={{ root: { maxHeight: 296 } }}>
      <Stack horizontal tokens={{ childrenGap: 12 }}>
        <div style={{ width: FEE_FORM_CHANGES_BADGE_WIDTH }} />
        <Heading labelPaddings styles={{ root: { width: 150 } }}>
          Change on
        </Heading>
        <Heading labelPaddings styles={{ root: { width: 102 } }}>
          Active
        </Heading>
        <Heading labelPaddings styles={feeFormChangesFeeStyles}>
          Fee
        </Heading>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="start">
        <TextBadge
          badgeColor={TextBadgeColor.yellow}
          badgeSize={TextBadgeSize.small}
          styles={feeFormChangesBadgeStyles}
        >
          Future
        </TextBadge>
        <DatePickerField
          name={feeFormNameOf("feeNextEffectiveDate")}
          styles={formDateInputStyles}
        />
        <ToggleField
          onText="Yes"
          offText="No"
          inlineLabel
          name={feeFormNameOf("feeNextIsActive")}
          styles={feeFormChangesOngoingToggleStyles}
        />
        <Stack styles={feeFormChangesFeeStyles}>
          <FieldSpy name={feeFormNameOf("feeNextIsActive")}>
            {(meta, feeNextIsActive: boolean) => (
              <SpinNumberInputField
                name={feeFormNameOf("feeNextFee")}
                prefix="$"
                step={0.01}
                precision={2}
                disabled={!feeNextIsActive}
              />
            )}
          </FieldSpy>
        </Stack>
      </Stack>
      <FieldCondition when={feeFormNameOf("feeShowCurrent")} is={true}>
        <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="start">
          <TextBadge
            badgeColor={TextBadgeColor.blue}
            badgeSize={TextBadgeSize.small}
            styles={feeFormChangesBadgeStyles}
          >
            Current
          </TextBadge>
          <DatePickerField
            name={feeFormNameOf("feeEffectiveDate")}
            styles={formDateInputStyles}
          />
          <ToggleField
            onText="Yes"
            offText="No"
            inlineLabel
            name={feeFormNameOf("feeIsActive")}
            styles={feeFormChangesOngoingToggleStyles}
          />
          <Stack styles={feeFormChangesFeeStyles}>
            <FieldSpy name={feeFormNameOf("feeIsActive")}>
              {(meta, feeIsActive: boolean) => (
                <SpinNumberInputField
                  name={feeFormNameOf("feeFee")}
                  prefix="$"
                  step={0.01}
                  precision={2}
                  disabled={!feeIsActive}
                />
              )}
            </FieldSpy>
          </Stack>
        </Stack>
      </FieldCondition>

      {instancesShown.map(instance => (
        <Stack
          key={instance.id}
          horizontal
          tokens={{ childrenGap: 12 }}
          verticalAlign="start"
        >
          <TextBadge
            badgeColor={TextBadgeColor.lightGrey}
            badgeSize={TextBadgeSize.small}
            styles={feeFormChangesBadgeStyles}
          >
            Historic
          </TextBadge>
          <Stack.Item
            styles={{
              root: {
                width: 150,
                padding: "4px 8px",
                fontStyle: instance.isActive ? undefined : "italic"
              }
            }}
          >
            {DateTime.fromISO(instance.effectiveDate)?.toFormat(
              DATE_FORMATS.DAY_DEFAULT_FORMAT
            )}
          </Stack.Item>
          <Stack.Item
            styles={{ root: { width: 130, padding: "4px 8px 4px 37px" } }}
          >
            {instance.isActive ? "Yes" : "No"}
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                fontStyle: instance.isActive ? undefined : "italic",
                padding: "2px 0px"
              }
            }}
          >
            {instance.isActive
              ? currencyFormat(instance.fee || 0, { currency: "" })
              : FeeActiveLabel.inactive}
          </Stack.Item>
        </Stack>
      ))}
      {isToggleRequired && (
        <MoreLessToggle
          value={!showAll}
          onChanged={() => setShowAll(!showAll)}
          styles={{
            root: {
              border: `1px solid ${palette.neutralLighter}`,
              padding: 8,
              textAlign: "center",
              cursor: "pointer",
              alignSelf: "stretch",
              borderBottom: `1px solid ${palette.neutralLighter}`,
              "&:hover": {
                backgroundColor: palette.neutralLighter
              }
            },
            content: { justifyContent: "center" }
          }}
          linkProps={{
            moreStateText: `Show more (${
              allExpiredInstances.length - DEFAULT_NUM_ROWS_SHOWN
            })`,
            lessStateText: "Show less"
          }}
        />
      )}
    </Stack>
  );
};

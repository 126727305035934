import { observer } from "mobx-react-lite";

import {
  ConstrainMode,
  DetailsListLayoutMode,
  DtoDetailsRow,
  IDetailsRowProps,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { compareDatesPredicate, DateTime } from "@bps/utils";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { PrescriptionListContextMenu } from "./PrescriptionListContexMenu.tsx";

interface PrescriptionsListProps {
  clinicalRecord: ClinicalRecord;
}

export interface PrescriptionItem {
  prescriptionDate?: string;
  prescribedBy?: string;
  productName: string;
  doseDetails: string;
  quantity?: string;
  repeats?: string;
  scriptId?: number;
  scriptDetails?: string;
  bottomBorder?: boolean;
  medications?: MedicationClinicalDataItemDto[];
  showContext?: boolean;
}
export const PrescriptionsList: React.FC<PrescriptionsListProps> = observer(
  ({ clinicalRecord }) => {
    const { core, clinical } = useStores();
    const theme = useTheme();

    const helper = useMedicationsContext();

    const getScriptDetailsText = (
      medication: MedicationClinicalDataItemDto
    ) => {
      let details: string = "";
      if (medication.rxType) {
        const typeText = clinical.ref.rxTypes.get(medication.rxType)?.text;
        if (typeText) {
          details = `${details}${typeText}`;
        }
      }

      if (medication.regulation49) {
        details = `${details}, reg49`;
      }

      if (medication.brandSubstitute) {
        details = `${details}, substitute`;
      }
      return details;
    };

    const prescriptionsData =
      clinicalRecord.clinicalData?.prescriptions?.prescriptions ?? [];

    const getPrescriptions = async () => {
      const sortedPrescriptions = prescriptionsData.sort((a, b) => {
        if (b.createLog?.createdDateTime && a.createLog?.createdDateTime) {
          return compareDatesPredicate(
            DateTime.fromISO(a.createLog.createdDateTime),
            DateTime.fromISO(b.createLog.createdDateTime),
            true
          );
        } else return 0;
      });

      const userIds = sortedPrescriptions.map(x => x.createLog?.createdById!);
      const users: User[] = await core.getUsersByIds(userIds);

      const prescriptionItems: PrescriptionItem[] = [];
      sortedPrescriptions.forEach(prescription => {
        const sortedMeds = prescription.medications.sort((a, b) =>
          a.productName.localeCompare(b.productName)
        );
        sortedMeds.forEach((medication, index) => {
          // first medication for a prescription has extra information
          const prescriptionItem: PrescriptionItem = {
            productName: medication.productName,
            doseDetails: helper.getDoseDetails(medication),
            quantity: medication.quantity?.toString() ?? "-",
            repeats: medication.repeats?.toString() ?? "-",
            scriptDetails: getScriptDetailsText(medication)
          };

          if (index === 0) {
            prescriptionItem.showContext = true;
            if (prescription.createLog?.createdDateTime) {
              prescriptionItem.prescriptionDate = DateTime.fromISO(
                prescription.createLog?.createdDateTime
              ).toDayDefaultFormat();
            }

            if (prescription.createLog?.createdById) {
              const user = users.find(
                x => x.id === prescription.createLog?.createdById
              );
              prescriptionItem.prescribedBy = user?.fullName;
            }

            if (prescription.scriptId !== undefined) {
              prescriptionItem.scriptId = prescription.scriptId;
            }

            prescriptionItem.medications = prescription.medications;
          }

          if (index === prescription.medications.length - 1) {
            prescriptionItem.bottomBorder = true;
          }

          prescriptionItems.push(prescriptionItem);
        });
      });

      return prescriptionItems;
    };

    const getPrescriptionTableColumns = () => {
      return [
        {
          fieldName: "contextMenu",
          key: "contextMenu",
          minWidth: 32,
          maxWidth: 32,
          name: "",
          onRender: (prescription: PrescriptionItem) => {
            if (prescription.showContext) {
              return (
                <PrescriptionListContextMenu prescription={prescription} />
              );
            } else {
              return undefined;
            }
          }
        },
        {
          fieldName: "Prescribed",
          key: "Prescribed",
          minWidth: 100,
          maxWidth: 100,
          name: "Prescribed",
          onRender: (prescription: PrescriptionItem) => {
            if (prescription.prescriptionDate && prescription.prescribedBy) {
              return (
                <Stack tokens={{ childrenGap: 8 }}>
                  <Text variant="medium">{prescription.prescriptionDate}</Text>
                  <Text variant="small">{`by ${prescription.prescribedBy}`}</Text>
                </Stack>
              );
            } else {
              return null;
            }
          }
        },
        {
          fieldName: "productName",
          key: "productName",
          minWidth: 80,
          maxWidth: 230,
          name: "Name",

          onRender: (prescription: PrescriptionItem) => (
            <Text
              variant="medium"
              styles={{
                root: { color: theme.palette.themePrimary, whiteSpace: "wrap" }
              }}
            >
              {prescription.productName}
            </Text>
          )
        },
        {
          fieldName: "dose",
          key: "dose",
          minWidth: 80,
          maxWidth: 480,
          name: "Dose details",
          onRender: (prescription: PrescriptionItem) => (
            <Text
              styles={{
                root: { whiteSpace: "wrap" }
              }}
              variant="medium"
            >
              {prescription.doseDetails}
            </Text>
          )
        },
        {
          fieldName: "quantity",
          key: "quantity",
          minWidth: 64,
          maxWidth: 64,
          name: "Qty",

          onRender: (prescription: PrescriptionItem) => (
            <Text variant="medium">{prescription.quantity}</Text>
          )
        },
        {
          fieldName: "repeats",
          key: "repeats",
          minWidth: 40,
          maxWidth: 40,
          name: "Rpts",

          onRender: (prescription: PrescriptionItem) => (
            <Text variant="medium">{prescription.repeats}</Text>
          )
        },
        {
          fieldName: "Script",
          key: "type",
          minWidth: 80,
          maxWidth: 80,
          name: "Script",

          onRender: (prescription: PrescriptionItem) => {
            return (
              <Stack tokens={{ childrenGap: 8 }}>
                {prescription.scriptId !== undefined && (
                  <Text variant="medium">{`ID: ${prescription.scriptId}`}</Text>
                )}
                {prescription.scriptDetails && (
                  <Text variant="small">{prescription.scriptDetails}</Text>
                )}
              </Stack>
            );
          }
        }
      ];
    };
    return (
      <DataFetcher<PrescriptionItem[]>
        fetch={getPrescriptions}
        fallback={<Spinner styles={{ root: { padding: "16px 0px" } }} />}
        refetchId={prescriptionsData.length}
      >
        {prescriptionItems => {
          return (
            <ShimmeredDetailsList
              compact={true}
              items={prescriptionItems}
              setKey="set"
              onRenderRow={(props: IDetailsRowProps) => {
                const item = props.item;
                const styles = {
                  cell: {
                    alignItems: "baseline",
                    borderBottom: item.bottomBorder
                      ? `1px ${theme.palette.neutralLight} solid`
                      : undefined
                  }
                };
                return <DtoDetailsRow {...props} styles={styles} />;
              }}
              layoutMode={DetailsListLayoutMode.justified}
              constrainMode={ConstrainMode.horizontalConstrained}
              columns={getPrescriptionTableColumns()}
            />
          );
        }}
      </DataFetcher>
    );
  }
);

import { Observer, observer } from "mobx-react-lite";
import { FunctionComponent, ReactNode, useRef } from "react";

import {
  DetailsRow,
  DirectionalHint,
  FontIcon,
  FontSizes,
  FontWeights,
  getColumnClampLinesSelector,
  IColumn,
  IDetailsHeaderProps,
  IDetailsRowProps,
  noWrap,
  PersonaSize,
  RESET_CELLS_PADDING_CLASSNAME,
  Selection,
  Shimmer,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalTaskStatus } from "@shared-types/clinical/clinical-task-status.enum.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import {
  ClinicalTaskPriorityText,
  ClinicalTaskTypeText
} from "@ui-components/RefText.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";
import { ClinicalTaskActionMenu } from "./ClinicalTaskActionMenu.tsx";
import { ClinicalTaskTableBaseProps } from "./ClinicalTaskTableWrapper.tsx";
import { ClinicalTasksTableLabels } from "./types/clinical-task-table-labels.enum.ts";

export interface ChildrenProps {
  selection: React.MutableRefObject<
    Selection<
      ClinicalTask & {
        key: string;
      }
    >
  >;
  columns: IColumn[];
  renderRow: (props: IDetailsRowProps) => JSX.Element | null;
  renderDetailsHeader: (
    props: IDetailsHeaderProps,
    defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
  ) => JSX.Element;
}

export interface ClinicalTaskTableProps
  extends Omit<ClinicalTaskTableBaseProps, "removeHeader"> {
  showPtColumn?: boolean;
  isMultiline?: boolean;
  showCreatedByColumn?: boolean;
  isViewOnly?: boolean;
  verticalView?: boolean;
  children: (props: ChildrenProps) => ReactNode;
}

export const ClinicalTaskTableBase: FunctionComponent<ClinicalTaskTableProps> =
  observer((props: ClinicalTaskTableProps) => {
    const theme = useTheme();
    const { core } = useStores();
    const {
      compact,
      showLockIcon,
      showPtColumn,
      isMultiline,
      showCreatedByColumn,
      verticalView,
      setSelected,
      children
    } = props;

    const isItemSelectable = (item: ClinicalTask) => {
      const isAllowedToChange =
        !item.isLocked ||
        item.lockedBy === core.userId ||
        core.hasPermissions([Permission.ClinTaskUnlock]);
      return isAllowedToChange && item.isCompleted !== true;
    };

    const selection = useRef(
      new Selection({
        canSelectItem: (item: ClinicalTask & { key: string }) =>
          isItemSelectable(item),
        onSelectionChanged: async () => {
          const selectedTasks =
            selection.current.getSelection() as ClinicalTask[];
          if (setSelected) {
            setSelected(selectedTasks);
          }
        }
      })
    );

    const toDueDateString = (dueDate: string | undefined) => {
      if (!dueDate) return "";

      return DateTime.fromISO(dueDate).toDayDefaultFormat();
    };

    const checkConfidentailAccess = (
      item: ClinicalTask,
      renderColumn: JSX.Element
    ) => {
      return core.hasAccessToSecGroup(item.secGroupId) && renderColumn;
    };

    const renderStatusBadgeAndIcon = (item: ClinicalTask) => {
      const taskStatus = item.taskStatus;
      let badgeColor = TextBadgeColor.lightGrey;
      if (taskStatus === ClinicalTaskStatus.Upcoming) {
        badgeColor = TextBadgeColor.blue;
      }
      if (taskStatus === ClinicalTaskStatus.Today) {
        badgeColor = TextBadgeColor.yellow;
      }
      if (taskStatus === ClinicalTaskStatus.Overdue) {
        badgeColor = TextBadgeColor.red;
      }

      return (
        <Observer>
          {() => (
            <Stack horizontal tokens={{ childrenGap: verticalView ? 0 : 8 }}>
              <TextBadge
                badgeColor={badgeColor}
                badgeSize={
                  verticalView ? TextBadgeSize.small : TextBadgeSize.medium
                }
                hasBorder={taskStatus === ClinicalTaskStatus.Today}
                styles={{
                  root: {
                    fontSize: FontSizes.size12,
                    width: verticalView ? 80 : 100,
                    paddingTop: compact ? 5 : undefined,
                    paddingBottom: compact ? 5 : undefined
                  }
                }}
              >
                {taskStatus}
              </TextBadge>
              {item.isCompleted && (
                <TooltipHost
                  styles={{ root: { alignSelf: "center" } }}
                  calloutProps={{
                    directionalHint: DirectionalHint.bottomLeftEdge
                  }}
                  content={
                    <DataFetcher<string | undefined>
                      fetch={async ({ core }) => {
                        let user: User | undefined;
                        if (item.completedBy) {
                          user = await core.getUser(item.completedBy);
                        } else {
                          if (item.changeLog?.updatedBy) {
                            user = await core.getUserByUserName(
                              item.changeLog?.updatedBy
                            );
                          }
                        }
                        return user?.fullName;
                      }}
                    >
                      {name => (
                        <Stack
                          tokens={{ childrenGap: 8 }}
                          styles={{ root: { margin: "10px 12px" } }}
                        >
                          <Stack>
                            <Text bold>Completed</Text>
                            <Text>{name}</Text>
                            <Text>{item.completedDate}</Text>
                          </Stack>
                          {item.completionNotes && (
                            <Stack>
                              <Text bold>Notes</Text>
                              <Text>{item.completionNotes}</Text>
                            </Stack>
                          )}
                        </Stack>
                      )}
                    </DataFetcher>
                  }
                >
                  <FontIcon
                    styles={{
                      root: {
                        fontSize: FontSizes.size16,
                        color: theme.palette.themePrimary
                      }
                    }}
                    iconName={item.completionNotes ? "CannedChat" : "Info"}
                  />
                </TooltipHost>
              )}
              {showLockIcon && item.isLocked && !item.isCompleted && (
                <TooltipHost
                  styles={{ root: { alignSelf: "center" } }}
                  calloutProps={{
                    directionalHint: DirectionalHint.bottomLeftEdge
                  }}
                  content={
                    <DataFetcher<User>
                      fetch={async ({ core }) =>
                        await core.getUser(item.lockedBy!)
                      }
                      fallback={<Shimmer width={120} />}
                      renderError={() => <Text>Unknown provider</Text>}
                    >
                      {(user: User) => (
                        <Stack
                          tokens={{ childrenGap: 10 }}
                          styles={{ root: { margin: "20px 24px" } }}
                        >
                          <Stack.Item
                            styles={{ root: { fontSize: FontSizes.size20 } }}
                          >
                            Locked task
                          </Stack.Item>
                          <Stack.Item
                            styles={{ root: { fontSize: FontSizes.size14 } }}
                          >
                            This is locked to {user.fullName}
                          </Stack.Item>
                        </Stack>
                      )}
                    </DataFetcher>
                  }
                >
                  <FontIcon
                    styles={{ root: { fontSize: FontSizes.size16 } }}
                    iconName="LockSolid"
                  />
                </TooltipHost>
              )}
            </Stack>
          )}
        </Observer>
      );
    };

    const renderVisitsNumber = (item: ClinicalTask) => {
      const { remainingVisits = 0 } = item;
      const visitsText = remainingVisits === 1 ? "consult" : "consults";

      return (
        <Observer>
          {() => <Text>{`${remainingVisits} ${visitsText}`}</Text>}
        </Observer>
      );
    };

    const compactDescriptionWidth = verticalView ? 100 : undefined;
    // Columns are shown in the Summary and Tasks tabs
    const compactStatusWidth = verticalView ? 80 : 100;
    const columns: IColumn[] = [
      {
        key: ClinicalTasksTableLabels.Status,
        isRowHeader: true,
        minWidth: verticalView ? 60 : 100,
        maxWidth: compact ? compactStatusWidth : 130,
        name: ClinicalTasksTableLabels.Status,
        isMultiline: isMultiline ?? true,
        onRender: renderStatusBadgeAndIcon
      },
      {
        key: ClinicalTasksTableLabels.Description,
        minWidth: 100,
        maxWidth: compact ? compactDescriptionWidth : 323,
        name: ClinicalTasksTableLabels.Description,
        isMultiline: isMultiline ?? true,
        onRender: (item: ClinicalTask) => {
          const providerName = core.getUserByPrivateSecGroupId(item.secGroupId)
            ?.fullName;

          return core.hasAccessToSecGroup(item.secGroupId) ? (
            <Observer>
              {() => (
                <Stack horizontal>
                  <ClinicalTaskTypeText
                    code={item.taskType}
                    styles={{
                      root: {
                        ...noWrap,
                        minHeight: 18,
                        "& .clampTwoLines": compact
                          ? getColumnClampLinesSelector(2)
                          : {}
                      }
                    }}
                  />

                  <ConfidentialToolTipFontIcon
                    isShowConfidentialIcon={item.secGroupId !== undefined}
                  />
                </Stack>
              )}
            </Observer>
          ) : (
            <Text nowrap>{`Confidential (by ${providerName} )`}</Text>
          );
        }
      }
    ];

    if (compact) {
      // Shows in Summary horizontal tab only
      !verticalView &&
        columns.push({
          key: ClinicalTasksTableLabels.Due,
          minWidth: 80,
          maxWidth: 80,
          name: ClinicalTasksTableLabels.Due,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) => (
            <Observer>
              {() => (
                <>
                  {item.dueDate
                    ? toDueDateString(item.dueDate)
                    : renderVisitsNumber(item)}
                </>
              )}
            </Observer>
          )
        });
    } else {
      // Shows in Tasks tab only

      columns.push(
        {
          key: ClinicalTasksTableLabels.Claim,
          minWidth: 70,
          maxWidth: 120,
          name: ClinicalTasksTableLabels.Claim,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) =>
            checkConfidentailAccess(
              item,
              <Observer>
                {() => (
                  <TooltipHost content={item.claimNumber}>
                    {item.claimNumber}
                  </TooltipHost>
                )}
              </Observer>
            )
        },
        {
          key: ClinicalTasksTableLabels.DueDate,
          minWidth: 80,
          maxWidth: 80,
          name: ClinicalTasksTableLabels.DueDate,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) =>
            checkConfidentailAccess(
              item,
              <Observer>
                {() => {
                  return item.dueDate ? (
                    <Text>{toDueDateString(item.dueDate)}</Text>
                  ) : null;
                }}
              </Observer>
            )
        },
        {
          key: ClinicalTasksTableLabels.DueIn,
          minWidth: 80,
          maxWidth: 80,
          name: ClinicalTasksTableLabels.DueIn,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) =>
            checkConfidentailAccess(item, renderVisitsNumber(item))
        },
        {
          key: ClinicalTasksTableLabels.Priority,
          minWidth: 68,
          maxWidth: 88,
          name: ClinicalTasksTableLabels.Priority,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) =>
            checkConfidentailAccess(
              item,
              <Observer>
                {() => <ClinicalTaskPriorityText code={item.priority} />}
              </Observer>
            )
        },
        {
          key: ClinicalTasksTableLabels.Comment,
          minWidth: 200,
          maxWidth: 547,
          name: ClinicalTasksTableLabels.Comment,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) =>
            checkConfidentailAccess(
              item,
              <Observer>
                {() => (
                  <TooltipHost content={item.comment}>
                    {item.comment}
                  </TooltipHost>
                )}
              </Observer>
            )
        }
      );

      const getCreatedByText = (username?: string) => {
        let result;

        if (username === User.System) {
          result = <TooltipHost content={username}>{username}</TooltipHost>;
        } else if (username) {
          result = (
            <UserFetcher username={username}>
              {user => {
                return (
                  <TooltipHost content={user.fullName}>
                    {user.fullName}
                  </TooltipHost>
                );
              }}
            </UserFetcher>
          );
        }

        return result;
      };

      if (showCreatedByColumn) {
        columns.splice(6, 0, {
          key: ClinicalTasksTableLabels.CreatedBy,
          minWidth: 100,
          maxWidth: 160,
          name: ClinicalTasksTableLabels.CreatedBy,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) =>
            checkConfidentailAccess(
              item,
              <Observer>
                {() => <>{getCreatedByText(item.changeLog?.createdBy)}</>}
              </Observer>
            )
        });
      }

      if (showPtColumn) {
        columns.splice(0, 0, {
          key: ClinicalTasksTableLabels.Actions,
          name: ClinicalTasksTableLabels.Actions,
          minWidth: 50,
          maxWidth: 50,
          className: RESET_CELLS_PADDING_CLASSNAME,
          onRender: (record: ClinicalTask) => (
            <ClinicalTaskActionMenu item={record} />
          )
        });
        columns.splice(1, 0, {
          key: ClinicalTasksTableLabels.Patient,
          minWidth: 100,
          maxWidth: 150,
          name: ClinicalTasksTableLabels.Patient,
          isMultiline: isMultiline ?? true,
          onRender: (item: ClinicalTask) => (
            <Observer>
              {() => (
                <ContactFetcher
                  fallback={<Spinner />}
                  contactId={item.patientId}
                >
                  {(patient: Contact) => {
                    return checkConfidentailAccess(
                      item,
                      <TooltipHost content={patient.name}>
                        <Persona
                          size={PersonaSize.size24}
                          id={patient.id}
                          text={patient.name}
                          imageUrl={patient.profilePictureUrl}
                          imageInitials={patient.initials}
                          onRenderPrimaryText={() => (
                            <Text>{`${patient.firstName} ${patient.lastName}`}</Text>
                          )}
                        />
                      </TooltipHost>
                    );
                  }}
                </ContactFetcher>
              )}
            </Observer>
          )
        });
      }
    }

    const renderRow = (props: IDetailsRowProps): JSX.Element | null => {
      const item = props.item as ClinicalTask;
      if (compact && item.isCompleted) return null;

      const renderRowProps = { ...props };

      const disableRow =
        !isItemSelectable(item) || !core.hasAccessToSecGroup(item.secGroupId);

      if (disableRow) {
        renderRowProps.onRenderCheck = () => (
          <ConfidentialToolTipFontIcon isShowConfidentialIcon summaryStyling />
        );
      }

      return (
        <DetailsRow
          {...renderRowProps}
          styles={{
            root: {
              fontSize: FontSizes.size14,
              minHeight: compact ? 30 : undefined,
              height: compact ? 40 : undefined,
              lineHeight: compact ? 15 : undefined,
              fontWeight: FontWeights.regular,
              "&.is-selected:hover": {
                backgroundColor: theme.semanticColors.listItemBackgroundChecked
              }
            },
            fields: {
              display: "inline-flex",
              alignItems: "center"
            },
            checkCell: {
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: 48
            },
            isMultiline: {
              ...noWrap
            }
          }}
          disabled={disableRow}
        />
      );
    };

    const renderDetailsHeader = (
      props: IDetailsHeaderProps,
      defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
    ) => {
      return defaultRender!({
        ...props!,
        styles: {
          root: {
            height: compact ? 28 : undefined,
            lineHeight: compact ? 19 : undefined,
            selectors: {
              "div.ms-DetailsHeader-check .ms-Check": {
                display: "none"
              }
            }
          }
        }
      });
    };

    return (
      <>{children({ columns, selection, renderRow, renderDetailsHeader })}</>
    );
  });

export const ClinicalTaskTable = withFetch(
  x => [
    x.clinical.ref.clinicalTaskTypes.load(),
    x.clinical.ref.clinicalTaskPriorities.load()
  ],
  ClinicalTaskTableBase
);

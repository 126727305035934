import { FormApi } from "final-form";

import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  TreatmentDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { getTreatmentData } from "../../SOTAP/SOTAP.utils.ts";
import { TreatmentAndEducationCardValidator } from "../../SOTAP/validators/TreatmentAndEducationCardValidator.tsx";
import { TreatmentFormValues } from "./TreatmentForm.types.ts";
import { TreatmentFormContent } from "./TreatmentFormContent.tsx";

interface TreatmentFormProps {
  clinicalRecord: ClinicalRecord;
}

const TreatmentFormBase: React.FunctionComponent<TreatmentFormProps> = ({
  clinicalRecord
}) => {
  const hasBeenReset =
    !!clinicalRecord.stashedClinicalData?.haveBeenResetForms.get(
      ClinicalDataType.TreatmentAndEducation
    );

  const treatmentPlan = hasBeenReset
    ? clinicalRecord.clinicalData?.patientTreatmentPlan
    : clinicalRecord.stashedClinicalData?.patientTreatmentPlan;

  const { clinical, core, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const onDismiss = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.TreatmentAndEducation
    );
  };

  const onReset = () => {
    onDismiss();
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "treatmentAndEducation"
    ]);
  };

  const getInitialValueTreatmentPlanData = () => {
    if (core.hasPermissions(Permission.MultiProviderClaimsAllowed)) {
      return treatmentPlan?.treatmentPlans?.find(
        d => d.businessRoleCode === clinicalRecord.openEncounter?.businessRole
      );
    } else {
      return treatmentPlan?.treatmentPlans &&
        treatmentPlan?.treatmentPlans.length > 0
        ? treatmentPlan?.treatmentPlans[0]
        : undefined;
    }
  };

  const getInitialValues = (): TreatmentFormValues => {
    let initialValues: TreatmentFormValues;

    const treatmentPlanData = getInitialValueTreatmentPlanData();

    const treatmentDate =
      getClinicalDataLastUpdatedDate(treatmentPlan)?.toDayDefaultFormat();

    const planTreatments = getTreatmentData(
      treatmentPlanData?.treatments,
      false
    );

    const treatmentAndEduValues = hasBeenReset
      ? clinicalRecord?.clinicalData?.treatmentAndEducation
      : clinicalRecord.stashedClinicalData?.treatmentAndEducation;

    const analysisAndPlanValues = hasBeenReset
      ? clinicalRecord?.clinicalData?.analysisAndPlan
      : clinicalRecord.stashedClinicalData?.analysisAndPlan;

    const treatments = getTreatmentData(
      treatmentAndEduValues?.treatments,
      true
    );

    const treatmentsBase = treatments.map(x => x.treatment);

    const primaryMeridians = treatmentAndEduValues?.primaryMeridians
      ? treatmentAndEduValues?.primaryMeridians.map(primaryMeridian => {
          return {
            ...primaryMeridian,
            check: true
          };
        })
      : undefined;

    const extraordinaryMeridians = treatmentAndEduValues?.extraordinaryMeridians
      ? treatmentAndEduValues?.extraordinaryMeridians.map(
          extraordinaryMeridians => {
            return {
              ...extraordinaryMeridians,
              check: true
            };
          }
        )
      : undefined;

    if (clinicalRecord.isFollowOnEncounter) {
      initialValues = {
        treatments:
          (treatmentAndEduValues?.treatments &&
            treatmentAndEduValues?.treatments.length > 0) ||
          treatmentAndEduValues?.treatmentsCleared
            ? treatments
            : planTreatments,
        treatmentsBase:
          (treatmentAndEduValues?.treatments &&
            treatmentAndEduValues?.treatments.length > 0) ||
          treatmentAndEduValues?.treatmentsCleared
            ? treatmentsBase
            : planTreatments.map(x => x.treatment),
        treatmentsCleared: treatmentAndEduValues?.treatmentsCleared,
        otherTreatments: treatmentAndEduValues?.treatmentsCleared
          ? treatmentAndEduValues?.otherTreatments
          : treatmentAndEduValues?.otherTreatments ??
            treatmentPlanData?.otherTreatments,
        hasOtherTreatments: treatmentAndEduValues?.treatmentsCleared
          ? false
          : (treatmentAndEduValues?.otherTreatments ??
              treatmentPlanData?.otherTreatments) !== undefined,
        educationComment: treatmentAndEduValues?.treatmentsCleared
          ? treatmentAndEduValues?.educationComment
          : treatmentAndEduValues?.educationComment ??
            treatmentPlanData?.educationComment,
        otherComment: treatmentAndEduValues?.treatmentsCleared
          ? treatmentAndEduValues?.otherComment
          : treatmentAndEduValues?.otherComment ??
            treatmentPlanData?.otherEducationComment,
        educationOptions:
          (treatmentAndEduValues?.educationOptions &&
            treatmentAndEduValues?.educationOptions.length > 0) ||
          treatmentAndEduValues?.treatmentsCleared
            ? treatmentAndEduValues?.educationOptions
            : treatmentPlanData?.educationOptions,
        treatmentDate,
        analysis: analysisAndPlanValues?.analysis,
        rxAnalysis: analysisAndPlanValues?.rxAnalysis,
        primaryMeridians,
        extraordinaryMeridians
      };
    } else {
      initialValues = {
        treatments,
        treatmentsBase,
        hasOtherTreatments:
          treatmentAndEduValues?.otherTreatments !== undefined,
        otherTreatments: treatmentAndEduValues?.otherTreatments,
        educationOptions: treatmentAndEduValues?.educationOptions,
        educationComment: treatmentAndEduValues?.educationComment,
        otherComment: treatmentAndEduValues?.otherComment,
        analysis: analysisAndPlanValues?.analysis,
        rxAnalysis: analysisAndPlanValues?.rxAnalysis,
        primaryMeridians,
        extraordinaryMeridians
      };
    }

    return initialValues;
  };

  const convertFormValuesToData = (values: TreatmentFormValues) => {
    const data: EncounterClinicalDataDto = {};

    const treatmentETag =
      clinicalRecord.stashedClinicalData?.originalDto?.treatmentAndEducation
        ?.eTag;

    const analysisETag =
      clinicalRecord.stashedClinicalData?.originalDto?.analysisAndPlan?.eTag;

    const treatmentValues: TreatmentDataItemDto[] | undefined =
      values.treatments
        ?.filter(x => !!x.treatment)
        .map(x => {
          return {
            treatment: x.treatment,
            comment: x.comment
          };
        });

    const primaryMeridians = values.primaryMeridians?.filter(x => x.check);
    const extraordinaryMeridians = values.extraordinaryMeridians?.filter(
      x => x.check
    );
    data.treatmentAndEducation = {
      eTag: treatmentETag,
      treatments:
        treatmentValues !== undefined && treatmentValues.length > 0
          ? treatmentValues
          : undefined,
      otherTreatments: values.otherTreatments,
      educationOptions: values.educationOptions,
      educationComment: values.educationComment,
      otherComment: values.otherComment,
      primaryMeridians:
        primaryMeridians !== undefined && primaryMeridians.length > 0
          ? primaryMeridians
          : undefined,
      extraordinaryMeridians:
        extraordinaryMeridians !== undefined &&
        extraordinaryMeridians.length > 0
          ? extraordinaryMeridians
          : undefined,
      treatmentsCleared: values.treatmentsCleared
    };

    data.analysisAndPlan = {
      eTag: analysisETag,
      analysis: values.analysis,
      rxAnalysis: values.rxAnalysis
    };

    return data;
  };

  const onSubmit = async (values: TreatmentFormValues) => {
    const data = convertFormValuesToData(values);

    await clinicalRecord.saveClinicalData(data);
  };

  const treatmentAndEducationValidator =
    new TreatmentAndEducationCardValidator();

  const onSubmitSucceeded = (
    values: TreatmentFormValues,
    form: FormApi<TreatmentFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.TreatmentAndEducation
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onReset();
    }
  };

  return (
    <ClinicalSubmissionForm<TreatmentFormValues>
      formName="treatment"
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      noGap
      initialValues={getInitialValues()}
      readOnly={isViewOnlyOrDischarged}
      disableRoutePrompt
      validate={treatmentAndEducationValidator.validate}
      heading="Management"
      onCancel={onReset}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyTreatmentForm
      }
      disableButtonCondition={stashedData => !stashedData?.dirtyTreatmentForm}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.treatmentAndEducation
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.treatmentAndEducation
      )}
    >
      <>
        <StashedClinicalDataFormSpy<TreatmentFormValues>
          clinicalRecord={clinicalRecord}
          getData={convertFormValuesToData}
          areasToObserve={{
            treatmentAndEducation: [ClinicalDataType.TreatmentAndEducation],
            analysisAndPlan: [ClinicalDataType.AnalysisAndPlan]
          }}
        />
        <TreatmentFormContent />
      </>
    </ClinicalSubmissionForm>
  );
};

export const TreatmentForm = withFetch(
  x => [
    x.clinical.ref.treatmentOptions.load(),
    x.clinical.ref.educationOptions.load(),
    x.clinical.ref.rxAnalysisOptions.load(),
    x.clinical.ref.meridians.load(),
    x.clinical.ref.acupoints.load()
  ],
  TreatmentFormBase
);

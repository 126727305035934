import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  FontSizes,
  IColumn,
  Link,
  SelectionMode,
  Text
} from "@bps/fluent-ui";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";

import { useSyncFusionAutofillContext } from "./SyncFusionAutofillContext.ts";

export const SyncFusionAutofillList: FunctionComponent = observer(() => {
  const {
    handlePreviewClick,
    autofills,
    autofillContext,
    handleAutofillShortcutOnClick
  } = useSyncFusionAutofillContext();

  const columns: IColumn[] = [
    {
      fieldName: "Shortcut",
      key: "shortcut",
      name: "Shortcut",
      minWidth: 60,
      maxWidth: 60,
      onRender: (autofill: AutofillDto) => (
        <Link
          styles={{
            root: {
              fontSize: FontSizes.size14
            }
          }}
          onClick={() =>
            handleAutofillShortcutOnClick(autofill, autofillContext)
          }
        >
          {autofill.shortcut}
        </Link>
      ),
      isResizable: false
    },
    {
      fieldName: "Name",
      key: "Name",
      minWidth: 198,
      name: "Name",
      onRender: (autofill: AutofillDto) => {
        return <Text>{autofill.name}</Text>;
      }
    },
    {
      fieldName: "Preview",
      key: "Preview",
      minWidth: 52,
      maxWidth: 52,
      name: "",
      onRender: (autofill: AutofillDto) => {
        return (
          <Link
            styles={{
              root: {
                fontSize: FontSizes.size14
              }
            }}
            onClick={() => handlePreviewClick(autofill)}
          >
            Preview
          </Link>
        );
      }
    }
  ];

  return (
    <DetailsList
      layoutMode={DetailsListLayoutMode.fixedColumns}
      items={autofills}
      columns={columns}
      selectionMode={SelectionMode.none}
      styles={{
        root: {
          overflow: "auto",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 240px)"
        }
      }}
    />
  );
});

import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  NoDataTile,
  Spinner,
  SplitButtonProps,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { Schedule } from "@stores/billing/models/Schedule.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ScheduleCard } from "./ScheduleCard.tsx";

const SchedulesList: React.FC = observer(() => {
  const { core } = useStores();
  const {
    schedules,
    openScheduleView,
    openFeeDialog,
    openEditScheduleDialog,
    openNewScheduleDialog,
    loadSchedules
  } = useContext(ScheduleScreenContext);

  const getScheduleListTileButtons = (schedule: Schedule): SplitButtonProps => {
    // default settings
    const buttons: SplitButtonProps = {
      ...dataAttribute(
        DataAttributes.Element,
        `schedule-tile-button-${schedule.id}`
      ),
      text: "View & edit fees",
      onClick: () => openScheduleView(schedule),
      splitButtonMenuProps: {
        ...dataAttribute(
          DataAttributes.Element,
          `schedule-tile-button-split-${schedule.id}`
        )
      },
      items: []
    };

    if (!core.hasPermissions(Permission.ServiceWrite)) {
      // if user cannot edit fees, use "View fees" button
      buttons.text = "View fees";
    } else {
      if (!schedule.totalFees && !schedule.isInactive) {
        //if schedule is active but has no fees, use with "Add fees" button
        buttons.text = schedule.benefitSchedule
          ? "Add non-standard fee"
          : "Add fee";
        buttons.onClick = () => openFeeDialog(schedule);
      } else {
        // otherwise add "Add fees" button to split
        buttons.items.push({
          ...dataAttribute(
            DataAttributes.Element,
            `add-new-fees-option-${schedule.id}`
          ),
          key: "new-fee",
          text: schedule.benefitSchedule ? "New non-standard fee" : "New fee",
          onClick: () => openFeeDialog(schedule)
        });
      }

      if (
        core.hasPermissions(Permission.BookingScheduleWrite) &&
        !schedule.benefitSchedule
      ) {
        // if user can edit schedules, add "Edit schedule" button to split
        buttons.items.push({
          ...dataAttribute(
            DataAttributes.Element,
            `edit-schedule-option-${schedule.id}`
          ),
          key: "edit-schedule",
          text: "Edit schedule",
          onClick: () => openEditScheduleDialog(schedule)
        });
      }
    }

    return buttons;
  };

  return (
    <DataFetcher fetch={() => loadSchedules()} fallback={<Spinner />}>
      {() => (
        <Stack
          {...dataAttribute(DataAttributes.Element, "schedules-list")}
          tokens={{ childrenGap: 16 }}
          grow
        >
          <Stack horizontal horizontalAlign="end">
            <When permission={Permission.BookingScheduleWrite}>
              <ActionButton
                {...dataAttribute(DataAttributes.Element, "add-schedule-btn")}
                iconProps={{ iconName: "Add" }}
                allowDisabledFocus
                onClick={openNewScheduleDialog}
              >
                New schedule
              </ActionButton>
            </When>
          </Stack>
          {schedules && schedules.length > 0 ? (
            <Stack tokens={{ childrenGap: 41 }}>
              {schedules.map((schedule: Schedule) => (
                <ScheduleCard
                  key={schedule.id}
                  schedule={schedule}
                  buttonProps={getScheduleListTileButtons(schedule)}
                />
              ))}
            </Stack>
          ) : (
            <NoDataTile
              textProps={{ text: "No schedules" }}
              linkProps={{
                text: "Create a new schedule",
                onClick: openNewScheduleDialog
              }}
              styles={{ root: { height: 223 } }}
            />
          )}
        </Stack>
      )}
    </DataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default SchedulesList;

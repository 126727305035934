import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  ActionButton,
  ButtonsGroupOption,
  DefaultButton,
  IPersonaProps,
  Link,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DocumentStagingInfo } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  DocumentEntityType,
  ReportTypeKeys
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { VisibilityAndConfidentialityField } from "@modules/clinical/screens/document-writer/components/VisibilityAndConfidentialityField.tsx";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { CorrespondenceFileUploaderField } from "../clinical-form/CorrespondenceFileUploaderField.tsx";
import { InvestigationReportFreeTextField } from "../clinical-form/InvestigationReportFreeTextField.tsx";
import { styles } from "./AddInvestigationDialog.styles.ts";
import {
  InvestigationFormValues,
  Labels
} from "./AddInvestigationDialog.types.ts";
import { AddXrayParametersDialog } from "./AddXrayParametersDialog.tsx";
import {
  InboxPickerFormValues,
  SelectInboxDocumentDialog
} from "./SelectInboxDocumentDialog.tsx";
import { XRayParametersTable } from "./XRayParametersTable.tsx";

interface AddEditInvestigationDialogFieldsProps {
  stagingPath: DocumentStagingInfo | undefined;
  editInvestigation?: ClinicalDocument;
}

const getTextFromItem = (persona: IPersonaProps) => persona.text as string;

export const AddEditInvestigationDialogFieldsBase: React.FC<AddEditInvestigationDialogFieldsProps> =
  observer(({ stagingPath, editInvestigation }) => {
    const form = useForm();
    const nameOf = nameOfFactory<InvestigationFormValues>();

    const { values } = useFormState<InvestigationFormValues>({
      subscription: { values: true }
    });

    const { inbox, core } = useStores();
    const { clinicalRecord } = usePatientRecordScreenContext();
    const [reportTextFocused, setReportTextFocused] = useState(false);
    const [previewDocument, setPreviewDocument] = useState<
      Document | undefined
    >(undefined);

    const [selectFromInbox, setSelectFromInbox] = useState<boolean>(false);

    const [hideXrayParametersDialog, setHideXrayParametersDialog] =
      useState<boolean>(true);

    const reportTypeDisabled = editInvestigation?.reportType !== undefined;
    const isXrayParameterExist = !!values.xrayParameters?.region;
    const reportTypeOptions:
      | ButtonsGroupOption<string>[]
      | { key: string; text: string; disabled: boolean }[] = [];
    inbox.ref.reportTypes.keyTextValues.forEach(x => {
      if (x.key !== ReportTypeKeys.unchecked)
        reportTypeOptions.push({
          key: x.key,
          text: x.text,
          disabled: reportTypeDisabled
        });
    });

    const renderInboxDocumentPicker = () => {
      const onSubmit = (values: InboxPickerFormValues) => {
        const inboxDocument = inbox.inboxDocumentsMap.get(values.documentId);
        if (inboxDocument) {
          form.batch(() => {
            form.change(nameOf("subject"), inboxDocument.name);
            form.change(nameOf("from"), inboxDocument.fromContactId);
            form.change(nameOf("reportDate"), inboxDocument.documentDate);
            form.change(nameOf("inboxDocument"), inboxDocument);
          });
        }
        setSelectFromInbox(false);
      };

      return (
        <DataFetcher
          fetch={({ inbox }) =>
            inbox.getInboxDocuments({ patientId: clinicalRecord.id })
          }
        >
          {documents => {
            return (
              <SelectInboxDocumentDialog
                dialogName="Select inbox document dialog"
                documents={documents.results}
                dialogProps={{
                  onDismiss: () => setSelectFromInbox(false),
                  title: Labels.InboxAddDialog,
                  minWidth: 437
                }}
                buttonsProps={{
                  submitButtonProps: {
                    text: "Select",
                    iconProps: { hidden: true }
                  }
                }}
                onSubmit={onSubmit}
              />
            );
          }}
        </DataFetcher>
      );
    };

    const showFreeTextField =
      values.files.length === 0 && !values.inboxDocument && !editInvestigation;

    const showFileUploaderField = !reportTextFocused && !values.inboxDocument;
    const showInboxButton =
      values.files.length === 0 && !reportTextFocused && !values.inboxDocument;

    let checkedBy: string | undefined;

    if (editInvestigation) {
      checkedBy = editInvestigation.checkedBy;
    } else {
      checkedBy = core.userId;
    }

    return (
      <>
        {!editInvestigation && (
          <Stack tokens={{ childrenGap: 8 }}>
            {showInboxButton && (
              <DefaultButton
                text={Labels.InboxAdd}
                iconProps={{ iconName: "Add" }}
                styles={styles.inboxButton}
                onClick={() => setSelectFromInbox(true)}
              />
            )}
            {values.inboxDocument && (
              <Fieldset
                horizontal
                verticalAlign="center"
                frame
                tokens={{ childrenGap: 8 }}
              >
                <Text styles={styles.fileName}>
                  {values.inboxDocument.name}
                  {values.inboxDocument.docExtension
                    ? `.${values.inboxDocument.docExtension}`
                    : ""}
                </Text>
                <Link
                  styles={styles.previewLink}
                  onClick={() => {
                    const inboxDocument = values.inboxDocument!;
                    const document: Document = {
                      entityId: inboxDocument!.id,
                      documentId: inboxDocument.documentDetailId,
                      entityType: DocumentEntityType.DocumentProcessing,
                      name: inboxDocument.name,
                      extension: inboxDocument.docExtension ?? "",
                      downloadUri: inboxDocument.fileUri,
                      previewUri: inboxDocument.blobSasUri
                    };
                    setPreviewDocument(document);
                  }}
                >
                  Preview
                </Link>
                <DeleteButton onClick={() => form.reset()} />
              </Fieldset>
            )}
            {showFileUploaderField && (
              <CorrespondenceFileUploaderField
                stagingPath={stagingPath}
                hideDate={true}
                hideSubject={true}
                maxFiles={1}
                setExternalFields={files => {
                  const [file] = files;
                  file &&
                    form.batch(() => {
                      form.change(nameOf("subject"), file?.subject);
                    });
                }}
              />
            )}
          </Stack>
        )}

        <Fieldset styles={styles.fieldset}>
          {/* 2 scenarios when report text field got display
            - the form is in creation stage and the inboxDocument field is not uploaded with any files
            - the form is in edit stage and the document is reportText document type
          */}
          {(showFreeTextField ||
            (editInvestigation && values.isReportTextDocument)) && (
            <InvestigationReportFreeTextField
              reportTextFocused={reportTextFocused}
              setReportTextFocused={setReportTextFocused}
              isEdit={!!editInvestigation}
            />
          )}
          {((!showFileUploaderField && !values.inboxDocument) ||
            (editInvestigation && values.isReportTextDocument)) &&
            !isXrayParameterExist && (
              <ActionButton
                iconProps={{ iconName: "Add" }}
                title="Add x-ray parameters"
                onClick={() => {
                  setHideXrayParametersDialog(false);
                }}
              >
                Add x-ray parameters
              </ActionButton>
            )}

          {values.xrayParameters && isXrayParameterExist && (
            <XRayParametersTable
              xrayParameters={values.xrayParameters}
              openXrayParameterDialog={() => setHideXrayParametersDialog(false)}
            />
          )}
        </Fieldset>

        <Fieldset frame styles={styles.fieldset}>
          <TextInputField
            name={nameOf("subject")}
            placeholder={Labels.Subject}
            label={Labels.Subject}
            required
          />
          <Stack horizontal>
            <ContactPickerField
              name={nameOf("from")}
              getTextFromItem={getTextFromItem}
              iconName="Search"
              placeholder="Search"
              label="From"
              fieldItemStyle={{ root: { flexGrow: 1, flexBasis: 0 } }}
              filter={{
                types: [ContactType.Organisation, ContactType.Individual]
              }}
            />
            <DatePickerField
              label={Labels.Date}
              name={nameOf("reportDate")}
              styles={{ root: { marginLeft: "8px" } }}
              maxDate={DateTime.jsDateNow()}
            />
          </Stack>
          <VisibilityAndConfidentialityField
            name={nameOf("visibility")}
            isEdit={editInvestigation !== undefined}
          />
          <Fieldset horizontal>
            <ButtonsGroupSingleChoiceField
              disabled={reportTypeDisabled}
              label={Labels.ReportType}
              name={nameOf("reportType")}
              options={reportTypeOptions}
            />
            <DropdownField
              name={nameOf("outcome")}
              label={Labels.TaskOutcome}
              options={inbox.ref.outcomes.keyTextValues}
              fieldItemStyles={{ root: { width: "100%" } }}
              placeholder={Labels.Outcome}
            />
          </Fieldset>

          <RecordUpdateCheckedLog
            checkedBy={checkedBy}
            checkedDate={editInvestigation?.dateChecked}
          />
          <TextInputField
            name={nameOf("link")}
            prefix="https://"
            label={Labels.Link}
            maxLength={2000}
          />
          <TextInputField
            name={nameOf("extraInfo")}
            label={Labels.ExtraInfo}
            maxLength={2000}
          />
        </Fieldset>

        {selectFromInbox && renderInboxDocumentPicker()}
        {previewDocument && (
          <DocumentViewerDialog
            getDocument={() => previewDocument}
            closeDocumentViewer={() => setPreviewDocument(undefined)}
          />
        )}
        {!hideXrayParametersDialog && (
          <AddXrayParametersDialog
            onDismiss={() => {
              setHideXrayParametersDialog(true);
            }}
            initialValues={values.xrayParameters}
          />
        )}
      </>
    );
  });

export const AddEditInvestigationDialogFields = withFetch(
  x => [
    x.inbox.ref.reportTypes.load(),
    x.inbox.ref.outcomes.load(),
    x.clinical.ref.imagingRegions.load(),
    x.clinical.ref.sidesOfBody.load()
  ],
  AddEditInvestigationDialogFieldsBase
);

import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useField } from "react-final-form";

import {
  FontIcon,
  FontSizes,
  Stack,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ClaimPickerField } from "@modules/acc/screens/shared-components/claim-picker/ClaimPickerField.tsx";
import { ClaimStatusBadge } from "@modules/acc/screens/shared-components/claim-status-badge/ClaimStatusBadge.tsx";
import { PatientCalendarEventPickerField } from "@modules/billing/screens/invoice/components/PatientCalendarEventPickerField.tsx";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";
import { ClearCalendarEventOnClaimChangeSpy } from "./ClearCalendarEventOnClaimChangeSpy.tsx";
import { ClearFieldsOnPatientChangeSpy } from "./ClearFieldsOnPatientChangeSpy.tsx";
import { SetClaimOnCalendarEventChangeSpy } from "./SetClaimOnCalendarEventChangeSpy.tsx";

export const InvoiceFormClaimFieldsBase: React.FC = observer(() => {
  const { acc } = useStores();
  const theme = useTheme();
  const { isFromCalendarEvent } = useContext(InvoiceFormContext);

  const {
    input: { value: patientId }
  } = useField(invoiceFormNameOf("patientId"), {
    subscription: { value: true }
  });

  const {
    input: { value: claimId }
  } = useField(invoiceFormNameOf("claimId"), { subscription: { value: true } });

  const onRenderClaimOption = (claim: Claim) => {
    const diagnosisDescription =
      claim.primaryDiagnosis?.terminology?.text || "";

    const providerCode =
      claim.providerContactClass?.accPracticeContractTypeCode;

    return (
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontal styles={{ root: { width: 120, flexShrink: 0 } }}>
          {claim.isOnHold && (
            <TooltipHost content="Claim on hold, ACC32 treatment extension required.">
              <FontIcon
                iconName="Clock"
                styles={{
                  root: {
                    fontSize: FontSizes.size16,
                    marginRight: 4
                  }
                }}
              />
            </TooltipHost>
          )}
          <span>{claim.claimNumber || "Not lodged"}</span>
          {providerCode && <span>{` | ${providerCode}`}</span>}
        </Stack>
        <Stack.Item
          styles={{
            root: {
              fontSize: 12,
              color: theme.palette.neutralSecondary,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden"
            }
          }}
          grow
          shrink
        >
          {diagnosisDescription}
        </Stack.Item>
        <Stack.Item>
          {claim.claimStatus && (
            <ClaimStatusBadge
              claimStatus={claim.claimStatus}
              badgeSize={TextBadgeSize.small}
              styles={{
                badge: { width: 90, fontSize: 10 },
                icon: { fontSize: 14 }
              }}
            />
          )}
        </Stack.Item>
      </Stack>
    );
  };

  const claim = claimId ? acc.claimsMap.get(claimId) : undefined;

  return (
    <>
      {patientId && (
        <Stack
          styles={{
            root: {
              display: "grid",
              columnGap: "8px",
              gridAutoFlow: "column",
              gridTemplateColumns: "1fr 1fr"
            }
          }}
        >
          <ClaimPickerField
            name="claimId"
            patientId={patientId}
            label="Claim number"
            onRenderOption={onRenderClaimOption}
            disabled={!patientId || isFromCalendarEvent}
            calloutWidth={500}
          />
          <PatientCalendarEventPickerField
            name="calendarEventId"
            options={{ claim, patientId }}
            label="Appointment"
            disabled={isFromCalendarEvent}
          />
        </Stack>
      )}
      <ClearCalendarEventOnClaimChangeSpy />
      <SetClaimOnCalendarEventChangeSpy />
      <ClearFieldsOnPatientChangeSpy />
    </>
  );
});

export const InvoiceFormClaimFields = withFetch(
  root => [root.practice.ref.accProviderContractTypes.load()],
  InvoiceFormClaimFieldsBase
);

import { FormApi } from "final-form";
import { FunctionComponent, useRef } from "react";

import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import {
  BodyExaminationContext,
  useBodyExaminationContext
} from "../../full-body-clinical-data/examination/context/BodyExaminationContext.ts";
import { BodyExaminationHelper } from "../../full-body-clinical-data/examination/context/BodyExaminationHelper.ts";
import { DermatomesAndMyotomesFields } from "./DermatomesAndMyotomesFields.tsx";
import { DermatomesMyotomesAndReflexesFormValues } from "./DermatomesAndMyotomesForm.types.ts";
import { DMRFormValidator } from "./validator/DMRFormValidator.ts";

interface DermatomesAndMyotomesFormProps {
  clinicalRecord: ClinicalRecord;
}

const DermatomesAndMyotomesFormBase: FunctionComponent<
  DermatomesAndMyotomesFormProps
> = ({ clinicalRecord }) => {
  const { isViewOnly } = usePatientRecordScreenContext();

  const { clinical, notification } = useStores();
  const {
    dmrInitialValues,
    dmrSubmitData,
    dmrOnCancel,
    getDMRStashedClinicalData
  } = useBodyExaminationContext();

  const validator = new DMRFormValidator();

  const onSubmitSucceeded = (
    values: DermatomesMyotomesAndReflexesFormValues,
    form: FormApi<DermatomesMyotomesAndReflexesFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.DermatomesAndMyotomes
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      dmrOnCancel();
    }
  };

  return (
    <ClinicalSubmissionForm<DermatomesMyotomesAndReflexesFormValues>
      formName="dermatomes-and-myotomes"
      onSubmit={dmrSubmitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={dmrInitialValues}
      readOnly={isViewOnly}
      validate={validator.validate}
      disableRoutePrompt
      heading="Dermatomes, myotomes, & reflexes"
      onCancel={() => {
        dmrOnCancel();
      }}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyAreas?.get(
          "dermatomesAndMyotomes"
        )
      }
      disableButtonCondition={stashedData =>
        !stashedData?.dirtyAreas?.get("dermatomesAndMyotomes")
      }
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.dermatomesAndMyotomesConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.dermatomesAndMyotomesConfirmed
      )}
    >
      <>
        <DermatomesAndMyotomesFields />
        <StashedClinicalDataFormSpy<DermatomesMyotomesAndReflexesFormValues>
          clinicalRecord={clinicalRecord}
          getData={getDMRStashedClinicalData}
          areasToObserve={{
            dermatomesAndMyotomes: [ClinicalDataType.DermatomesAndMyotomes]
          }}
        />
      </>
    </ClinicalSubmissionForm>
  );
};
export const DermatomeAndMyotomeFormWithContext: FunctionComponent<
  DermatomesAndMyotomesFormProps
> = ({ clinicalRecord }) => {
  const { clinical } = useStores();
  const helper = useRef(
    new BodyExaminationHelper(clinical, clinicalRecord)
  ).current;
  return (
    <BodyExaminationContext.Provider value={helper}>
      <DermatomesAndMyotomesFormBase clinicalRecord={clinicalRecord} />
    </BodyExaminationContext.Provider>
  );
};

export const DermatomesAndMyotomesForm = withFetch(
  x => [
    x.clinical.ref.nerves.load(),
    x.clinical.ref.dermatomeEstimations.load(),
    x.clinical.ref.reflexNerves.load()
  ],
  DermatomeAndMyotomeFormWithContext
);

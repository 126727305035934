import { action, computed, observable } from "mobx";

import { IGroup } from "@bps/fluent-ui";
import {
  compareDatesPredicate,
  DateTime,
  newGuid,
  upsertItem
} from "@bps/utils";
import {
  AddDocumentDto,
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  DocumentContentType,
  DocumentCreateOptions,
  DocumentEnum,
  DocumentExtensionType,
  DocumentMetadataItem,
  DocumentSource,
  EncounterClinicalDataDto,
  MedicationClinicalDataItemDto,
  PrescriptionClinicalDataItemDto,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  TemplateArgs,
  TemplateNameDescription,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { getDoseFullInstructions } from "../utils.ts";

export interface ContextualMenuItem {
  event?: MouseEvent;
  medicationId?: string;
}

type MedicationType = "prescribe" | "record";
type DoseDialogType = "edit" | "view";

export class MedicationsHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root: RootStore
  ) {}

  private get notification() {
    return this.root.notification;
  }

  private get clinical() {
    return this.root.clinical;
  }

  @observable
  public selectedMedicationId: string | undefined;

  @observable
  public isPrintDialogVisible: boolean = false;

  @action
  setPrintDialogVisible = (state: boolean) => {
    this.isPrintDialogVisible = state;
  };

  @observable lastPrintedDocument: ClinicalDocument | undefined;
  @action setLastPrintedDocument = (document: ClinicalDocument) => {
    this.lastPrintedDocument = document;
  };

  @observable lastPrintedDocumentId: string | undefined;
  @action setPrintedDocumentId = (documentId: string) => {
    this.lastPrintedDocumentId = documentId;
  };

  @observable
  selectedCurrentMeds: MedicationClinicalDataItemDto[] = [];

  @action
  setSelectedCurrentMeds = (meds: MedicationClinicalDataItemDto[]) => {
    this.selectedCurrentMeds = meds;
  };

  @computed
  get selectedMedication() {
    return this.clinicalRecord.clinicalData?.medication?.medications.find(
      m => m.id === this.selectedMedicationId
    );
  }

  @observable
  public medicationDialogVisibleWithType: MedicationType | undefined =
    undefined;

  @observable
  public isDeleteDialogVisible: boolean = false;

  @observable
  public doseFormDialogVisibleWithViewType: DoseDialogType | undefined =
    undefined;

  @action
  public setDeleteDialogVisible = (value: boolean) => {
    this.isDeleteDialogVisible = value;
    if (!value) {
      this.selectedMedicationId = undefined;
    }
  };

  @action
  public setMedicationDialogVisibleType = (
    value: MedicationType | undefined
  ) => {
    this.medicationDialogVisibleWithType = value;
  };

  @action
  public setDoseFormDialogVisibleWithViewType = (
    value: DoseDialogType | undefined
  ) => {
    this.doseFormDialogVisibleWithViewType = value;
    if (!value) {
      this.selectedMedicationId = undefined;
    }
  };

  public onDeleteMedication = async (
    id: string,
    reasonForDelete: string,
    reasonForDeleteComment?: string
  ) => {
    try {
      let medications = [
        ...(this.clinicalRecord.clinicalData?.medication?.medications || [])
      ];
      const medication = medications.find(x => x.id === id);
      if (medication) {
        medication.isDeleted = true;
        medication.reasonForDelete = reasonForDelete;
        medication.deletedComment = reasonForDeleteComment;
        medications = upsertItem({
          array: medications,
          item: medication,
          predicate: x => x.id === id
        });

        const eTag = this.clinicalRecord.clinicalData?.medication?.eTag;
        await this.clinicalRecord.saveClinicalData({
          medication: { medications, eTag }
        });

        this.notification.success("Clinical records has bee updated.");
      }
      this.setDeleteDialogVisible(false);
    } catch (e) {
      this.notification.error(e);
    }
  };

  // Return filtered out isCeased and isDeleted
  public getActiveMedicationData = () => {
    const medications =
      this.clinicalRecord.clinicalData?.medication?.medications.filter(
        x => !x.isCeased && !x.isDeleted
      ) ?? [];

    const sortedMedications = Array.from(medications).sort((a, b) =>
      a.productName.localeCompare(b.productName)
    );

    const groups: IGroup[] = sortedMedications.map((item, index) => ({
      key: item.id ?? index.toString(),
      name: item.productName,
      count: 1,
      isCollapsed: true,
      startIndex: index,
      level: 1
    }));

    return { groups, sortedMedications };
  };

  public getPrescriptionData = () => {
    const prescriptions =
      this.clinicalRecord.clinicalData?.prescriptions?.prescriptions ?? [];

    const sortedPrescriptions = prescriptions.sort((a, b) => {
      if (b.createLog?.createdDateTime && a.createLog?.createdDateTime) {
        return compareDatesPredicate(
          DateTime.fromISO(a.createLog.createdDateTime),
          DateTime.fromISO(b.createLog.createdDateTime),
          true
        );
      } else return 0;
    });

    const groups: IGroup[] = sortedPrescriptions.map((item, index) => ({
      key: item.scriptId?.toString() ?? index.toString(),
      name: `${item.createLog?.createdDateTime} ${item.scriptId}`,
      count: 1,
      isCollapsed: true,
      startIndex: index,
      level: 1
    }));

    return { groups, prescriptions };
  };

  public createPrescription = async (
    selectedMeds: MedicationClinicalDataItemDto[]
  ) => {
    const prescriptions =
      this.clinicalRecord?.clinicalData?.prescriptions?.prescriptions ?? [];

    const eTag = this.clinicalRecord.clinicalData?.prescriptions?.eTag;

    const documentId = newGuid();

    const newPrescription: PrescriptionClinicalDataItemDto = {
      medications: selectedMeds ?? [],
      documentId
    };

    const medications = this.clinicalRecord?.clinicalData?.medication
      ?.medications
      ? Array.from(this.clinicalRecord?.clinicalData?.medication?.medications)
      : [];

    selectedMeds.forEach(x => {
      const med = medications?.find(med => med.id === x.id);
      const isoNow = DateTime.now().toISO();
      if (med) {
        if (med.firstRx) {
          med.lastRx = isoNow;
        } else {
          med.firstRx = isoNow;
          med.lastRx = isoNow;
        }
      }
    });

    const encounterClinicalData: EncounterClinicalDataDto = {
      prescriptions: {
        eTag,
        prescriptions: [...prescriptions, newPrescription]
      },
      medication: {
        eTag: this.clinicalRecord.clinicalData?.medication?.eTag,
        medications: medications ?? []
      }
    };

    await this.clinicalRecord.saveClinicalData(encounterClinicalData);
    // Print the document
    await this.printPerscription(selectedMeds, documentId);
  };

  printPerscription = async (
    selectedMeds: MedicationClinicalDataItemDto[],
    documentId: string
  ) => {
    const encounterId = this.clinicalRecord.openEncounter?.id;
    const patientId = this.clinicalRecord.patient?.id;
    const userId = this.root.core?.user?.id;

    if (
      encounterId &&
      userId &&
      patientId &&
      selectedMeds &&
      selectedMeds.length > 0
    ) {
      const templateArgs: TemplateArgs = {
        name: TemplateNameDescription.prescription
      };

      const templates = await this.root.document.getTemplates(templateArgs);

      const prescriptionTemplate = templates.find(
        x => x.name === TemplateNameDescription.prescription
      );

      const context: { [id: string]: string } = {};

      context["PatientId"] = patientId;
      context["UserId"] = userId;
      context["PrescriptionDocumentId"] = documentId;

      if (this.clinicalRecord.openEncounter) {
        context["EncounterId"] = encounterId;
      }

      const parameters: { [id: string]: string } = {};

      const renderOptions: TemplateRenderOptions = {
        context,
        contentType: DocumentContentType.Sfdt,
        skipMerge: false,
        parameters
      };

      if (prescriptionTemplate) {
        const renderedDocument = await this.root.document.renderTemplate(
          prescriptionTemplate.id,
          renderOptions
        );

        const metadata: DocumentMetadataItem[] = [
          {
            key: DocumentEnum.Date,
            value: DateTime.now().toISODate()
          },
          {
            key: DocumentEnum.Extension,
            value: DocumentExtensionType.Docx
          },
          {
            key: DocumentEnum.TemplateId,
            value: prescriptionTemplate.id
          },
          {
            key: DocumentEnum.EncounterId,
            value: encounterId
          },
          {
            key: DocumentEnum.ContentType,
            value: DocumentContentType.Sfdt
          }
        ];

        const dto: AddDocumentDto = {
          id: documentId,
          patientId,
          type: CorrespondenceType.Perscription,
          direction: CorrespondenceDirection.Out,
          status: CorrespondenceStatus.Done,
          content: renderedDocument.content ?? "",
          store: StoreType.Prescriptions,
          metadata
        };

        const options: DocumentCreateOptions = {
          source: DocumentSource.Content,
          documents: [dto]
        };

        await this.root.correspondence.addDocuments(encounterId, options);

        this.setPrintedDocumentId(documentId);

        const document = await this.getPerscriptionDocument();
        if (document) {
          this.setPrintDialogVisible(true);
          this.setLastPrintedDocument(document);
        }
      }
    }
  };

  getDoseDetails = (record: MedicationClinicalDataItemDto): string => {
    const {
      prn,
      complexInstructions,
      food,
      dose,
      doseUnit,
      frequency,
      route,
      duration,
      durationUnit,
      otherInstructions
    } = record;

    const complexInstructionsText = complexInstructions
      ? complexInstructions
      : "";

    const foodText = food ? this.clinical.ref.dosingFood.get(food)?.text : "";

    const frequencyText = frequency
      ? this.clinical.ref.dosingFrequencies.get(frequency)?.text
      : "";

    const routeText = route
      ? this.clinical.ref.dosingRoutes.get(route)?.text
      : "";

    const durationUnitText = durationUnit
      ? this.clinical.ref.dosingDurationPeriods.get(durationUnit)?.text
      : "";

    const otherInstructionsText = otherInstructions
      ? this.clinical.ref.dosingOtherInstructions.get(otherInstructions)?.text
      : "";

    const doseFullInstructions = getDoseFullInstructions({
      dose,
      doseUnit,
      frequencyText,
      prn,
      routeText,
      complexInstructionsText,
      foodText,
      otherInstructionsText,
      duration,
      durationUnitText
    });

    return doseFullInstructions;
  };

  getPerscriptionDocument = async () => {
    const documentId = this.lastPrintedDocumentId;
    if (documentId) {
      return await this.root.correspondence.getInvestigationByDocumentId(
        this.clinicalRecord.id,
        documentId
      );
    }
    return undefined;
  };

  ceaseMedication = async (medication: MedicationClinicalDataItemDto) => {
    const medications =
      this.clinicalRecord.clinicalData?.medication?.medications ?? [];

    const updatedMedication: MedicationClinicalDataItemDto = {
      ...medication,
      isCeased: true
    };

    const updatedMedications = upsertItem({
      array: medications,
      item: updatedMedication,
      predicate: x => x.id === updatedMedication.id
    });

    const encounterClinicalData: EncounterClinicalDataDto = {
      medication: {
        eTag: this.clinicalRecord.clinicalData?.medication?.eTag,
        medications: updatedMedications
      }
    };

    await this.clinicalRecord.saveClinicalData(encounterClinicalData);
  };

  deleteCurrentMedication = async (
    medication: MedicationClinicalDataItemDto
  ) => {
    let medications =
      this.clinicalRecord.clinicalData?.medication?.medications ?? [];

    if (medication) {
      const updatedMedication: MedicationClinicalDataItemDto = {
        ...medication,
        isDeleted: true,
        reasonForDelete: "OTH" // TEMP as modal will be adapted more later
      };

      medications = upsertItem({
        array: medications,
        item: updatedMedication,
        predicate: x => x.id === updatedMedication.id
      });

      const encounterClinicalData: EncounterClinicalDataDto = {
        medication: {
          eTag: this.clinicalRecord.clinicalData?.medication?.eTag,
          medications: medications ?? []
        }
      };

      await this.clinicalRecord.saveClinicalData(encounterClinicalData);
    }

    this.setDeleteDialogVisible(false);
    this.setSelectedCurrentMeds([]);
  };
}

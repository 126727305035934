import {
  FontWeights,
  mergeFuncStyles,
  mergeStyles,
  mergeStyleSets,
  noWrap
} from "@bps/fluent-ui";

import { InvalidItemsIndexes } from "../AllocationList/AllocationListBase.types.tsx";

export const getDetailsListStyles = (
  invalidItemsIndexes?: InvalidItemsIndexes
) =>
  mergeFuncStyles(({ theme }) => ({
    root: { paddingBottom: 6 },
    contentWrapper: {
      borderBottom: "1px solid",
      // set error border color if one of the items is invalid
      borderBottomColor: invalidItemsIndexes?.length
        ? theme.semanticColors.errorText
        : theme.palette.neutralLighter,
      selectors: {
        "& .ms-List-cell ": {
          minHeight: 18
        }
      }
    }
  }));

export const getFooterRowStyles = (invalidItemsIndexes?: InvalidItemsIndexes) =>
  mergeFuncStyles(({ theme }) => ({
    root: {
      borderBottomColor: invalidItemsIndexes?.length
        ? theme.semanticColors.errorText
        : theme.palette.neutralLighter
    },
    cell: {
      lineHeight: 19,
      padding: "8px 0px",
      alignItems: "start"
    }
  }));

export const totalsStyles = mergeStyleSets({
  root: {
    flex: 1,
    display: "grid",
    gridTemplateColumns: "max-content auto",
    alignItems: "baseline",
    justifyContent: "flex-end",
    textAlign: "right"
  }
});

export const totalLabelStyles = mergeStyleSets({
  root: {
    padding: 8,
    minWidth: 134, // approx width of longest label
    fontWeight: FontWeights.semibold
  }
});

export const automationElementStyles = mergeStyles({
  flexGrow: 1,
  ...noWrap
});

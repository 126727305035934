import { FieldValidator } from "final-form";

import { SideRailMenuItem } from "@bps/fluent-ui";
import {
  compareDatesOrUndefinedPredicate,
  compareDatesPredicate,
  DateTime,
  isDefined,
  newGuid
} from "@bps/utils";
import {
  AddClaimAdjustmentDocumentDto,
  AddClaimAdjustmentDto,
  ClaimAdjustmentDocumentDto,
  ClaimAdjustmentDocumentStatuses,
  ClaimAdjustmentReasons,
  ClaimAdjustmentRequestArray,
  ClaimAdjustmentRequests,
  ClaimAdjustmentRequestType,
  ClaimAdjustmentStatus,
  ClaimDiagnosisDto,
  ClaimStatuses,
  DiagnosisAdditionRequestDto,
  DiagnosisChangeRequestDto,
  SideChangeRequestDto,
  TreatmentRequestDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { PatientTreatmentPlanDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ChangeSideValuesType } from "@shared-types/acc/change-side-values.type.ts";
import { ClaimAdjustmentDocumentUploadFormValue } from "@shared-types/acc/claim-adjustment-document-upload-value.types.ts";
import { ClaimAdjustmentDocumentFormValue } from "@shared-types/acc/claim-adjustment-document-value.type.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { ClaimDiagnosisChangeFormValues } from "@shared-types/acc/claim-diagnosis-change-values.type.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { ProviderDetailsFormValues } from "@shared-types/acc/provider-details-values.type.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import {
  isDiagnosisAdditionRequest,
  isTreatmentsRequest
} from "@stores/acc/utils/acc.utils.ts";
import { ContractType } from "@stores/practice/models/Provider.ts";
import { capitaliseAddress } from "@stores/practice/utils/practice.utils.ts";

import {
  getEmptyDiagnosis,
  getOldDiagnosisKey,
  isDiagnosisChangeNotEmpty,
  isDiagnosisNotEmpty,
  mapToChangeSideItemFormValue,
  mapToClaimDiagnosisDto,
  mapToClaimDiagnosisFormValues,
  mapToDiagnosisChangeFormValues,
  mapToDiagnosisChangesDto,
  mapToSideChangesDto
} from "../../utils.ts";
import { ClaimAdjustmentModalValues } from "../types/claim-adjustment-modal-values.interface.ts";
import {
  ClaimAdjustmentSideRailLabels,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";
import { ClaimAdjustmentResponseModalFormValues } from "./ClaimAdjustmentResponseModalForm.types.ts";

export const getClaimAdjustmentModalValues = (
  claimAdjustment?: ClaimAdjustment
): ClaimAdjustmentModalValues => {
  const claimAdjustmentReasons: ClaimAdjustmentReasons[] = [];

  claimAdjustment?.hasDiagnosisAddition &&
    claimAdjustmentReasons.push(ClaimAdjustmentReasons.hasDiagnosisAddition);
  claimAdjustment?.hasSideChange &&
    claimAdjustmentReasons.push(ClaimAdjustmentReasons.hasSideChange);
  claimAdjustment?.hasTreatmentDetails &&
    claimAdjustmentReasons.push(ClaimAdjustmentReasons.hasTreatmentDetails);
  claimAdjustment?.hasDiagnosisChange &&
    claimAdjustmentReasons.push(ClaimAdjustmentReasons.hasDiagnosisChange);

  return {
    claimAdjustmentReasons,
    isVoluntarySubmission: claimAdjustment?.isVoluntarySubmission || false,
    verballyApproved: claimAdjustment?.verballyApproved,
    responseDate: claimAdjustment?.responseDate?.toJSDate(),
    purchaseOrderNumber: claimAdjustment?.purchaseOrderNumber
  };
};

export const getEmptyDiagnosisAdditionRequestDto =
  (): DiagnosisAdditionRequestDto => ({
    claimAdjustmentRequestTypeCode:
      ClaimAdjustmentRequestType.DiagnosisAddition,
    requestApproved: false,
    diagnosisAdditions: []
  });

export const getEmptyDiagnosisChangeRequestDto =
  (): DiagnosisChangeRequestDto => ({
    claimAdjustmentRequestTypeCode: ClaimAdjustmentRequestType.DiagnosisChange,
    requestApproved: false,
    diagnosisChanges: []
  });

export const getEmptySideChangeRequestDto = (): SideChangeRequestDto => ({
  claimAdjustmentRequestTypeCode: ClaimAdjustmentRequestType.SideChange,
  requestApproved: false,
  sideChanges: []
});

export const getEmptyTreatmentRequestDto = (): TreatmentRequestDto => ({
  claimAdjustmentRequestTypeCode: ClaimAdjustmentRequestType.TreatmentRequest,
  initialAssessmentsNeeded: 0,
  requestApproved: false
});

export const getClaimAdjustmentModalDto = (
  claimAdjustmentValues: ClaimAdjustmentModalValues,
  originalClaimAdjustmentRequests: ClaimAdjustmentRequestArray
) => {
  const { claimAdjustmentReasons, ...rest } = claimAdjustmentValues;
  const claimAdjustmentRequests: ClaimAdjustmentRequestArray = [];

  if (
    claimAdjustmentReasons?.includes(
      ClaimAdjustmentReasons.hasDiagnosisAddition
    )
  ) {
    const additionsRequest = originalClaimAdjustmentRequests.find(
      r =>
        r.claimAdjustmentRequestTypeCode ===
        ClaimAdjustmentRequestType.DiagnosisAddition
    );
    claimAdjustmentRequests.push(
      additionsRequest ?? getEmptyDiagnosisAdditionRequestDto()
    );
  }

  if (claimAdjustmentReasons?.includes(ClaimAdjustmentReasons.hasSideChange)) {
    const sideChangeRequest = originalClaimAdjustmentRequests.find(
      r =>
        r.claimAdjustmentRequestTypeCode ===
        ClaimAdjustmentRequestType.SideChange
    );
    claimAdjustmentRequests.push(
      sideChangeRequest ?? getEmptySideChangeRequestDto()
    );
  }

  if (
    claimAdjustmentReasons?.includes(ClaimAdjustmentReasons.hasDiagnosisChange)
  ) {
    const diagnosisChangeRequest = originalClaimAdjustmentRequests.find(
      r =>
        r.claimAdjustmentRequestTypeCode ===
        ClaimAdjustmentRequestType.DiagnosisChange
    );
    claimAdjustmentRequests.push(
      diagnosisChangeRequest ?? getEmptyDiagnosisChangeRequestDto()
    );
  }

  if (
    claimAdjustmentReasons?.includes(ClaimAdjustmentReasons.hasTreatmentDetails)
  ) {
    const treatmentRequest = originalClaimAdjustmentRequests.find(
      r =>
        r.claimAdjustmentRequestTypeCode ===
        ClaimAdjustmentRequestType.TreatmentRequest
    );
    claimAdjustmentRequests.push(
      treatmentRequest ?? getEmptyTreatmentRequestDto()
    );
  }

  const responseDate: string | undefined = DateTime.jsDateToISODate(
    claimAdjustmentValues.responseDate
  );

  return {
    ...rest,
    responseDate,
    claimAdjustmentRequests
  };
};

export const getClaimAdjustmentDocumentDto = (
  document: ClaimAdjustmentDocumentFormValue
): AddClaimAdjustmentDocumentDto => {
  const { id, ...rest } = document;
  return rest;
};

export const getClaimAdjustmentDocumentDtoByUpload = (
  document: ClaimAdjustmentDocumentUploadFormValue
): AddClaimAdjustmentDocumentDto => {
  const { id, file, name, blobName, ...rest } = document;
  return {
    ...rest,
    stagingId: blobName,
    status: rest.status || ClaimAdjustmentDocumentStatuses.Staged
  };
};

export const getClaimAdjustmentDocumentDtos = (
  documents: ClaimAdjustmentDocumentFormValue[],
  uploadDocuments?: ClaimAdjustmentDocumentUploadFormValue[],
  claimAdjustmentDocuments?: ClaimAdjustmentDocumentDto[]
) => {
  const documentDtos = documents.map(x => getClaimAdjustmentDocumentDto(x));
  if (uploadDocuments) {
    documentDtos.push(
      ...uploadDocuments.map(document =>
        getClaimAdjustmentDocumentDtoByUpload(document)
      )
    );
  }

  const claimAdjustmentFormPdf = claimAdjustmentDocuments?.find(
    x => x.isDocumentAcc32Form
  );
  if (claimAdjustmentFormPdf) {
    documentDtos.push(claimAdjustmentFormPdf);
  }

  return documentDtos;
};

export const getAddClaimAdjustmentDto = (options: {
  claimAdjustmentFormValues: ClaimAdjustmentFormValues;
  currentDiagnoses: ClaimDiagnosisDto[];
  claimAdjustment: ClaimAdjustment;
}) => {
  const { claimAdjustmentFormValues, currentDiagnoses, claimAdjustment } =
    options;

  const {
    documents,
    sideChanges,
    diagnosisChanges,
    handSplintingCost,
    patientDateOfBirth,
    patientAddress,
    diagnosisAdditions,
    mostRecentSurgeryDate,
    uploadDocuments,
    firstAdditionalTreatmentRequested,
    initialAssessmentsNeeded,
    followUpTreatmentsNeeded,
    treatmentsRequired,
    treatmentStartDate,
    expectedTreatmentCompletionDate,
    nailSimpleRemovalTreatmentsNeeded,
    nailRemovalResectionTreatmentsNeeded,
    abscessHematomaTreatmentsNeeded,
    ...rest
  } = claimAdjustmentFormValues;

  const { approvedVisits, handSplintingCostApproved } = claimAdjustment;

  const treatmentRequest: TreatmentRequestDto | undefined =
    claimAdjustment.hasTreatmentDetails
      ? {
          ...getEmptyTreatmentRequestDto(),
          treatmentsApproved: approvedVisits,
          handSplintingCostApproved,
          initialAssessmentsNeeded,
          firstAdditionalTreatmentRequested,
          followUpTreatmentsNeeded,
          nailSimpleRemovalTreatmentsNeeded,
          nailRemovalResectionTreatmentsNeeded,
          abscessHematomaTreatmentsNeeded,
          treatmentsRequested: treatmentsRequired,
          handSplintingCost: handSplintingCost
            ? Number(handSplintingCost)
            : undefined,
          treatmentStartDate: DateTime.jsDateToISODate(treatmentStartDate)
        }
      : undefined;

  if (
    treatmentRequest &&
    claimAdjustmentFormValues.providerContractType ===
      ContractType.PhysiotherapistSpecifiedTreatment
  ) {
    treatmentRequest.handSplintingCost = undefined;
    treatmentRequest.initialAssessmentsNeeded = undefined;
    treatmentRequest.handSplintingCostApproved = undefined;
  }

  return {
    ...rest,
    patientDateOfBirth: DateTime.jsDateToISODate(patientDateOfBirth),
    patientAddress: patientAddress && capitaliseAddress(patientAddress),
    mostRecentSurgeryDate: DateTime.jsDateToISODate(mostRecentSurgeryDate),
    claimAdjustmentRequests: mapToClaimAdjustmentRequestsDto({
      additions: diagnosisAdditions,
      diagnosisChanges,
      sideChanges,
      treatmentRequest,
      currentDiagnoses
    }),
    expectedTreatmentCompletionDate: DateTime.jsDateToISODate(
      expectedTreatmentCompletionDate
    ),
    claimAdjustmentDocuments: getClaimAdjustmentDocumentDtos(
      documents,
      uploadDocuments,
      claimAdjustment.documents
    )
  } as AddClaimAdjustmentDto;
};

const getDiagnosisAdditionsFormValues = (claimAdjustment?: ClaimAdjustment) => {
  if (claimAdjustment?.hasDiagnosisAddition)
    return {
      diagnosisAdditions: claimAdjustment?.diagnosisAdditions.length
        ? claimAdjustment?.diagnosisAdditions.map(mapToClaimDiagnosisFormValues)
        : [getEmptyDiagnosis()]
    };
  else return { diagnosisAdditions: [] };
};

const getChangeDiagnosesFormValues = (claimAdjustment?: ClaimAdjustment) => {
  if (claimAdjustment?.hasDiagnosisChange)
    return {
      diagnosisChanges: !!claimAdjustment?.diagnosisChanges?.length
        ? claimAdjustment?.diagnosisChanges.map(mapToDiagnosisChangeFormValues)
        : [getEmptyDiagnosis()]
    };
  else return { diagnosisChanges: [] };
};

const getTreatmentDetailsFormValues = (claimAdjustment: ClaimAdjustment) => {
  const treatmentsDetails = {
    treatmentStartDate: claimAdjustment.treatmentStartDate?.toJSDate(),
    followUpTreatmentsNeeded: claimAdjustment.followUpTreatmentsNeeded,
    treatmentsRequired: claimAdjustment.treatmentsRequired,
    handSplintingCost: claimAdjustment.handSplintingCost?.toString() ?? "0",
    initialAssessmentsNeeded: claimAdjustment.initialAssessmentsNeeded,
    firstAdditionalTreatmentRequested:
      claimAdjustment.firstAdditionalTreatmentRequested,
    nailRemovalResectionTreatmentsNeeded:
      claimAdjustment.nailRemovalResectionTreatmentsNeeded,
    abscessHematomaTreatmentsNeeded:
      claimAdjustment.abscessHematomaTreatmentsNeeded,
    nailSimpleRemovalTreatmentsNeeded:
      claimAdjustment.nailSimpleRemovalTreatmentsNeeded
  };

  return treatmentsDetails;
};

export const getCurrentStatusAndPrognosisFormValues = (
  claimAdjustment?: ClaimAdjustment
) => ({
  originalAccidentEvent: claimAdjustment?.originalAccidentEvent,
  currentConditionOrDiagnosis: claimAdjustment?.currentConditionOrDiagnosis,
  causeOfCurrentCondition: claimAdjustment?.causeOfCurrentCondition,
  causeOfNotResolvedCondition: claimAdjustment?.causeOfNotResolvedCondition,
  signAndSymptom: claimAdjustment?.signAndSymptom,
  proposedTreatment: claimAdjustment?.proposedTreatment,
  expectedTreatmentCompletionDate:
    claimAdjustment?.expectedTreatmentCompletionDate?.toJSDate(),
  managementPlan: claimAdjustment?.managementPlan
});

export const getClaimAdjustmentDocumentFormValues = (
  documents: ClaimAdjustmentDocumentDto[]
) => ({
  documents: documents
    .filter(x => !x.isDocumentAcc32Form)
    .map(x => ({
      id: newGuid(),
      ...x
    }))
});

export const getClaimAdjustmentFormValues = (
  claimAdjustment: ClaimAdjustment,
  claim: Claim
): ClaimAdjustmentFormValues => {
  const patientDetailsValues = {
    claimNumber: claimAdjustment?.claimNumber,
    accRelatedSurgery: claimAdjustment?.accRelatedSurgery,
    mostRecentSurgeryDate: claimAdjustment?.mostRecentSurgeryDate?.toJSDate(),
    patientFirstName: claimAdjustment?.patientFirstName,
    patientLastName: claimAdjustment?.patientLastName,
    patientDateOfBirth: claimAdjustment?.patientDateOfBirth?.toJSDate(),
    patientAddress: claimAdjustment?.patientAddress,
    patientNhiNumber: claim.dto.patientNhiNumber
  };

  const providerDetailsValues = {
    providerFirstName: claimAdjustment?.providerFirstName,
    providerLastName: claimAdjustment?.providerLastName,
    providerContactNumber: claimAdjustment?.providerContactNumber,
    hpiCpn: claimAdjustment?.hpiCpn,
    hpiOrganisationNumber: claimAdjustment?.hpiOrganisationNumber,
    providerContractType: claimAdjustment?.providerContractType,
    providerId: claimAdjustment?.providerId
  };

  const defaultFormValues = {
    id: claimAdjustment?.id
  };

  const treatmentDetails = getTreatmentDetailsFormValues(claimAdjustment);

  const currentStatusAndPrognosisFormValues =
    getCurrentStatusAndPrognosisFormValues(claimAdjustment);

  const declarationSectionValues = {
    providerDeclarationConfirmed: claimAdjustment?.providerDeclarationConfirmed,
    patientDeclarationConfirmed: claimAdjustment?.patientDeclarationConfirmed
  };

  const diagnosisAdditionsValues =
    getDiagnosisAdditionsFormValues(claimAdjustment);

  const changeDiagnosesFormValues =
    getChangeDiagnosesFormValues(claimAdjustment);

  const changeSideFormValues = getChangeSideFormValues(
    claimAdjustment,
    claim.currentDiagnoses
  );

  const documentsFormValues = getClaimAdjustmentDocumentFormValues(
    claimAdjustment.documents || []
  );

  return {
    ...treatmentDetails,
    ...defaultFormValues,
    ...patientDetailsValues,
    ...providerDetailsValues,
    ...changeDiagnosesFormValues,
    ...currentStatusAndPrognosisFormValues,
    ...diagnosisAdditionsValues,
    ...declarationSectionValues,
    ...changeSideFormValues,
    ...documentsFormValues,
    ...defaultFormValues
  };
};

const getChangeSideFormValues = (
  claimAdjustment: ClaimAdjustment,
  currentDiagnoses: ClaimDiagnosisDto[]
): ChangeSideValuesType => {
  const changeSideFormValues: ChangeSideValuesType = {
    sideChanges: []
  };

  if (claimAdjustment?.hasSideChange) {
    if (claimAdjustment?.sideChanges?.length) {
      changeSideFormValues.sideChanges = claimAdjustment.sideChanges.map(
        mapToChangeSideItemFormValue
      );
    } else {
      const sideChange = getNewClaimAdjustmentSideChange(currentDiagnoses);
      changeSideFormValues.sideChanges.push(sideChange);
    }
  }
  return changeSideFormValues;
};

export const getNewClaimAdjustmentSideChange = (
  currentDiagnoses: ClaimDiagnosisDto[]
) => {
  const sideChange: ClaimDiagnosisChangeFormValues = { id: newGuid() };
  // auto-selects diagnosis when there is only 1
  if (currentDiagnoses?.length === 1) {
    sideChange.oldDiagnosisKey = getOldDiagnosisKey(currentDiagnoses[0]);
    sideChange.diagnosisCode = currentDiagnoses[0].terminology?.code;
  }
  return sideChange;
};

//checks if the date provided is the earliest in the collection
export const isDateEarliest = (date: DateTime, dates: DateTime[]) => {
  const sortedInDateOrder = dates.sort(compareDatesPredicate);
  if (date.equals(sortedInDateOrder[0])) {
    return true;
  }
  return false;
};

export const isDateGapGreaterThanAYear = (date1: DateTime, date2: DateTime) => {
  const sortedInDateOrder = [date1, date2].sort(compareDatesPredicate);

  const oldestDate = sortedInDateOrder[0];
  const newestDate = sortedInDateOrder[1];

  return oldestDate.plus({ years: 1 }) < newestDate;
};

export const sortClaimAdjustments = (claimAdjustments: ClaimAdjustment[]) => {
  return Array.from(claimAdjustments).sort((a, b) =>
    compareDatesOrUndefinedPredicate(b.createdDate, a.createdDate)
  );
};
export const isClaimAdjustmentAllowed = (claimStatus: string) => {
  return (
    claimStatus === ClaimStatuses.Accepted ||
    claimStatus === ClaimStatuses.Accredited ||
    claimStatus === ClaimStatuses.Discharged
  );
};

export const checkIfProviderCanSubmit =
  (): FieldValidator<string> => (value, parent: ProviderDetailsFormValues) =>
    value && parent.providerContractType === ContractType.Orthotist
      ? "Provider cannot submit an ACC32."
      : undefined;

export const isClaimAdjustmentResponseDisplayable = (
  purchaseOrderNumber: string,
  claimAdjustmentStatus: string
) => {
  return (
    purchaseOrderNumber &&
    (claimAdjustmentStatus === ClaimAdjustmentStatus.Accepted ||
      claimAdjustmentStatus === ClaimAdjustmentStatus.Declined ||
      claimAdjustmentStatus === ClaimAdjustmentStatus.Partial)
  );
};

export const isClaimAdjustmentResponseRecordable = (
  claimAdjustmentStatus: string
) => {
  return (
    claimAdjustmentStatus === ClaimAdjustmentStatus.Accepted ||
    claimAdjustmentStatus === ClaimAdjustmentStatus.Declined ||
    claimAdjustmentStatus === ClaimAdjustmentStatus.Partial ||
    claimAdjustmentStatus === ClaimAdjustmentStatus.Pending
  );
};

export const mapToClaimAdjustmentRequestsDto = (args: {
  additions: ClaimDiagnosisFormValues[];
  diagnosisChanges: ClaimDiagnosisChangeFormValues[];
  sideChanges: ClaimDiagnosisChangeFormValues[];
  currentDiagnoses: ClaimDiagnosisDto[];
  treatmentRequest?: TreatmentRequestDto;
}): ClaimAdjustmentRequests[] => {
  const additionRequest = getEmptyDiagnosisAdditionRequestDto();
  additionRequest.diagnosisAdditions = args.additions
    .filter(isDiagnosisNotEmpty)
    .map(mapToClaimDiagnosisDto);

  const diagnosisChangeRequest = getEmptyDiagnosisChangeRequestDto();
  diagnosisChangeRequest.diagnosisChanges =
    args.diagnosisChanges
      ?.map(x => mapToDiagnosisChangesDto(x, args.currentDiagnoses))
      ?.filter(isDiagnosisChangeNotEmpty) ?? [];

  const sideChangeRequest = getEmptySideChangeRequestDto();
  sideChangeRequest.sideChanges = args.sideChanges
    .map(x => mapToSideChangesDto(x, args.currentDiagnoses))
    .filter(
      sideChange =>
        isDiagnosisNotEmpty(sideChange.oldDiagnosis) || sideChange.newSide
    );

  const requests: ClaimAdjustmentRequests[] = [];
  if (
    additionRequest.diagnosisAdditions.length > 0 ||
    args.additions.length === 1
  )
    requests.push(additionRequest);

  if (
    diagnosisChangeRequest.diagnosisChanges.length > 0 ||
    args.diagnosisChanges.length === 1
  )
    requests.push(diagnosisChangeRequest);

  if (sideChangeRequest.sideChanges.length > 0 || args.sideChanges.length === 1)
    requests.push(sideChangeRequest);

  if (
    args.treatmentRequest &&
    [
      args.treatmentRequest.firstAdditionalTreatmentRequested,
      args.treatmentRequest.followUpTreatmentsNeeded,
      args.treatmentRequest.handSplintingCost,
      args.treatmentRequest.initialAssessmentsNeeded,
      args.treatmentRequest.treatmentStartDate,
      args.treatmentRequest.treatmentsApproved,
      args.treatmentRequest.treatmentsRequested
    ].filter(isDefined).length > 0
  )
    requests.push(args.treatmentRequest);

  return requests;
};

export const getGoalsTextList = (
  treatmentPlan: PatientTreatmentPlanDataItemDto
) => {
  const goals = treatmentPlan.goals;

  let text = "";
  if (!!goals?.length) {
    goals.forEach(goal => {
      if (goal.goal) {
        text += `*${goal.goal}\n`;
      }
    });
  }
  return text;
};

export const calculateResponseStatus = (
  claimAdjustmentRequests: ClaimAdjustmentRequests[],
  isDeclined: boolean
) => {
  if (claimAdjustmentRequests.length === 0) {
    return undefined;
  }

  if (isDeclined) {
    return ClaimAdjustmentStatus.Declined;
  }
  if (claimAdjustmentRequests.every(x => x.requestApproved)) {
    return ClaimAdjustmentStatus.Accepted;
  }

  if (claimAdjustmentRequests.some(x => x.requestApproved)) {
    return ClaimAdjustmentStatus.Partial;
  }

  const treatmentRequest: TreatmentRequestDto | undefined =
    claimAdjustmentRequests.find(isTreatmentsRequest);

  const diagnosisAdditionRequest = claimAdjustmentRequests.find(
    isDiagnosisAdditionRequest
  ) as DiagnosisAdditionRequestDto | undefined;

  if (treatmentRequest) {
    const isSplittingApprovedGreaterThanCost =
      treatmentRequest.handSplintingCostApproved &&
      treatmentRequest.handSplintingCostApproved <
        treatmentRequest.handSplintingCost!;

    const isTreatmentsApprovedGreaterThanRequested =
      treatmentRequest.treatmentsApproved &&
      treatmentRequest.treatmentsApproved <
        (treatmentRequest.treatmentsRequested ??
          treatmentRequest.followUpTreatmentsNeeded ??
          0);

    if (
      isSplittingApprovedGreaterThanCost ||
      isTreatmentsApprovedGreaterThanRequested ||
      treatmentRequest.initialAssessmentsApproved
    ) {
      return ClaimAdjustmentStatus.Partial;
    }
  }

  if (diagnosisAdditionRequest) {
    if (
      diagnosisAdditionRequest.diagnosisAdditions.some(x => x.requestApproved)
    ) {
      return ClaimAdjustmentStatus.Partial;
    }
    if (
      diagnosisAdditionRequest.diagnosisAdditions.every(x => x.requestApproved)
    ) {
      return ClaimAdjustmentStatus.Accepted;
    }
  }

  return ClaimAdjustmentStatus.Pending;
};

export const calculateTreatmentsRequestApproved = (
  requests: Array<
    | { request?: boolean; approved?: boolean }
    | { request?: number; approved?: number }
  >
) =>
  requests.every(x => {
    if (!x.request) {
      return true;
    }

    if (typeof x.request === "boolean") {
      return x.request && x.approved;
    } else {
      return x.request && x.approved && Number(x.approved) >= Number(x.request);
    }
  });

export const getClaimAdjustmentMenuItems = (
  showStatusAndPrognosis: boolean,
  patientLabel: string,
  claimAdjustment: ClaimAdjustment
): SideRailMenuItem[] => {
  const claimAdjustmentMenuItems = [
    {
      text: ClaimAdjustmentSideRailLabels.overview,
      id: ClaimsAdjustmentCardIds.overview
    },
    {
      text: `${patientLabel} ${ClaimAdjustmentSideRailLabels.patientDetails}`,
      id: ClaimsAdjustmentCardIds.patientDetails
    },
    {
      text: ClaimAdjustmentSideRailLabels.providerDetails,
      id: ClaimsAdjustmentCardIds.providerDetails
    }
  ];

  if (claimAdjustment?.hasTreatmentDetails) {
    claimAdjustmentMenuItems.push({
      text: ClaimAdjustmentSideRailLabels.treatmentDetails,
      id: ClaimsAdjustmentCardIds.treatmentDetails
    });
  }

  if (claimAdjustment?.hasDiagnosisAddition) {
    claimAdjustmentMenuItems.push({
      text: ClaimAdjustmentSideRailLabels.addADiagnosis,
      id: ClaimsAdjustmentCardIds.addDiagnosis
    });
  }

  if (claimAdjustment?.hasSideChange) {
    claimAdjustmentMenuItems.push({
      text: ClaimAdjustmentSideRailLabels.changeSide,
      id: ClaimsAdjustmentCardIds.changeSide
    });
  }

  if (claimAdjustment?.hasDiagnosisChange) {
    claimAdjustmentMenuItems.push({
      text: ClaimAdjustmentSideRailLabels.changeDiagnosis,
      id: ClaimsAdjustmentCardIds.changeDiagnosis
    });
  }

  if (showStatusAndPrognosis) {
    claimAdjustmentMenuItems.push({
      text: ClaimAdjustmentSideRailLabels.currentStatusAndPrognosis,
      id: ClaimsAdjustmentCardIds.currentStatusAndPrognosis
    });
  }

  claimAdjustmentMenuItems.push({
    text: ClaimAdjustmentSideRailLabels.documentation,
    id: ClaimsAdjustmentCardIds.documentation
  });

  //splice is used here to keep patientProviderDeclarations section at the bottom as per design
  claimAdjustmentMenuItems.splice(claimAdjustmentMenuItems.length, 0, {
    text: ClaimAdjustmentSideRailLabels.patientProviderDeclarations,
    id: ClaimsAdjustmentCardIds.patientProviderDeclarations
  });

  return claimAdjustmentMenuItems;
};

export const getClaimAdjustmentResponseInitialValues = (
  claimAdjustment: ClaimAdjustment
): ClaimAdjustmentResponseModalFormValues => {
  const {
    sideChanges,
    responseDate,
    responseNotes,
    approvedVisits,
    diagnosisChanges,
    purchaseOrderNumber,
    initialAssessmentsApproved,
    handSplintingCostApproved,
    diagnosisAdditions,
    status,
    nailRemovalResectionTreatmentsNeededApproved,
    nailSimpleRemovalTreatmentsNeededApproved,
    abscessHematomaTreatmentsNeededApproved
  } = claimAdjustment;

  return {
    purchaseOrderNumber,
    responseDate: responseDate?.toJSDate(),
    approvedVisits,
    responseNotes,
    handSplintingCostApproved,
    nailRemovalResectionTreatmentsNeededApproved,
    nailSimpleRemovalTreatmentsNeededApproved,
    abscessHematomaTreatmentsNeededApproved,
    initialAssessmentsApproved: !!initialAssessmentsApproved,
    diagnosisAdditions,
    diagnosisKeys: mapAndFilter(diagnosisAdditions, diagnosis => {
      if (diagnosis.requestApproved && diagnosis.terminology) {
        return diagnosis.terminology.diagnosisKey;
      }
      return "";
    }),
    diagnosisChangesKeys: mapAndFilter(diagnosisChanges, diagnosisChange => {
      if (
        diagnosisChange.requestApproved &&
        diagnosisChange.newDiagnosis.terminology
      ) {
        return diagnosisChange.newDiagnosis.terminology.diagnosisKey;
      }
      return "";
    }),
    sideChangesKeys: mapAndFilter(sideChanges, sideChange => {
      if (sideChange.requestApproved && sideChange.oldDiagnosis.terminology) {
        return sideChange.oldDiagnosis.terminology?.diagnosisKey;
      }
      return "";
    }),
    diagnosisChanges,
    sideChanges,
    isDeclined: status?.code === ClaimAdjustmentStatus.Declined
  };
};

/**
 *
 * @param arr to map through
 * @param condition to map through arr
 * @returns array of strings without empty entries
 *
 * it is a simplified version of
 * arr
 *  .map(x => {
      if (x.type) {
        return x.type
      }
        return "";
      })
 .filter(entry => entry),
 */
export const mapAndFilter = (arr: any[], condition: (i: any) => string) =>
  arr.map(condition).filter(key => key);

import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { FC } from "react";

import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PeopleTabs, routes } from "@libs/routing/routes.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { ContactsFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RouterStore } from "@stores/router/RouterStore.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";
import {
  hasValidBirthdayData,
  hasValidNameData,
  hasValidPhoneNumberData
} from "@ui-components/pickers/contact-picker/contact-picker.utils.ts";

import {
  MIN_SEARCH_CHAR_DATE_OF_BIRTH,
  MIN_SEARCH_CHAR_PATIENT_NAME,
  MIN_SEARCH_CHAR_PHONE_NUMBER
} from "../../../utils.ts";

enum ContactsFilterKeys {
  categories = "categories",
  statuses = "statuses",
  search = "search",
  types = "types"
}

export const defaultPeopleScreenFilter: ContactsFilter = {
  categories: [],
  search: "",
  types: [ContactType.Patient],
  statuses: [ContactStatus.Active]
};

export const getFilterFromLocation = (routing: RouterStore): ContactsFilter => {
  const filter: ContactsFilter = { ...defaultPeopleScreenFilter };
  const tab = routing.match(routes.addressBook.basePath)?.params.tab;

  if (tab === PeopleTabs.Individuals) {
    filter.types = [ContactType.Individual];
  } else if (tab === PeopleTabs.Organisations) {
    filter.types = [ContactType.Organisation];
  }

  if (routing.location.search) {
    const parsedFilter: ContactsFilter = parse(routing.location.search);

    // convert in array is one only selected, but not search filter
    Object.entries(parsedFilter).forEach(([key, value]) => {
      if (key !== ContactsFilterKeys.search && value && !Array.isArray(value)) {
        filter[key as string] = Array(value);
      } else {
        filter[key as string] = value;
      }
    });
  }

  return filter;
};

export const setLocationFromFilter = (
  field: keyof ContactsFilter,
  value: string | string[] | undefined,
  routing: RouterStore
) => {
  if (field === "search") {
    if (value && !Array.isArray(value)) {
      const isValidName = hasValidNameData(value);
      const isValidBirthdayDate = hasValidBirthdayData(value);
      const isValidPhoneNumber = hasValidPhoneNumberData(value);
      if (
        isValidName &&
        !isValidPhoneNumber &&
        value.length >= MIN_SEARCH_CHAR_PATIENT_NAME
      ) {
        routing.replaceQueryStringParam(field, value);
      } else if (
        isValidPhoneNumber &&
        value.length >= MIN_SEARCH_CHAR_PHONE_NUMBER
      ) {
        routing.replaceQueryStringParam(field, value);
      } else if (
        isValidBirthdayDate &&
        value.length >= MIN_SEARCH_CHAR_DATE_OF_BIRTH
      ) {
        routing.replaceQueryStringParam(field, value);
      }
    } else {
      routing.replaceQueryStringParam(field, value);
    }
  } else {
    routing.replaceQueryStringParam(field, value);
  }
};

export const ContactListFilters: FC<
  Pick<FilterBarProps<ContactsFilter>, "children"> & { activeTab: string }
> = observer(({ activeTab, ...props }) => {
  const { routing, practice } = useStores();
  const filterQuery: ContactsFilter = getFilterFromLocation(routing);

  const initialState = {
    search: filterQuery?.search,
    types: filterQuery?.types ?? [],
    categories: filterQuery?.categories ?? [],
    statuses: filterQuery?.statuses ?? []
  };

  const isPatientTab = activeTab === PeopleTabs.Patients;
  const isNonPatient = activeTab === PeopleTabs.Individuals;
  const isOrg = activeTab === PeopleTabs.Organisations;

  const items: Item<ContactsFilter>[] = [
    {
      type: "searchBox",
      name: nameof<ContactsFilter>("search"),
      stickItem: true,
      props: {
        removeSpecialCharacters: false,
        autoFocus: true,
        placeholder: "Search name",
        styles: { root: { maxWidth: 280, minWidth: 280 } }
      }
    },
    {
      type: "optionsSelect",
      name: nameof<ContactsFilter>("types"),
      props: {
        id: "contacts-filter-types",
        multiSelect: true,
        placeholder: "Show all types",
        options: isPatientTab
          ? practice.ref.contactTypes.keyTextValues.filter(
              x => x.key !== ContactType.Organisation
            )
          : practice.ref.contactTypes.keyTextValues,
        hideSearchOption: true,
        calloutWidth: 170,
        listHeight: "initial"
      }
    },
    {
      type: "optionsSelect",
      name: nameof<ContactsFilter>("categories"),
      props: {
        id: "contacts-filter-categories",
        multiSelect: true,
        placeholder: "Show all categories",
        options: practice.ref.contactCategories.keyTextValues,
        calloutWidth: 170,
        listHeight: "300px",
        styles: {
          root: {
            display: isPatientTab ? "none" : undefined
          }
        }
      }
    },
    {
      type: "optionsSelect",
      name: nameof<ContactsFilter>("statuses"),
      props: {
        id: "contacts-filter-statuses",
        multiSelect: true,
        placeholder: "Show all statuses",
        options: isPatientTab
          ? practice.ref.contactStatuses.keyTextValues
          : practice.ref.contactStatuses.keyTextValues.filter(
              x =>
                x.key !== ContactStatus.Deceased &&
                x.key !== ContactStatus.Merged
            ),
        hideSearchOption: true,
        calloutWidth: 170,
        listHeight: "initial"
      }
    }
  ];

  return (
    <FilterBar<ContactsFilter>
      {...props}
      onChange={({ field, value }) =>
        setLocationFromFilter(field, value, routing)
      }
      items={items.filter(x => {
        if (isNonPatient || isOrg) {
          return x.name !== nameof<ContactsFilter>("types");
        }
        return items;
      })}
      initialValues={initialState}
    />
  );
});

import React, { useEffect, useState } from "react";

import {
  IColumn,
  PivotItem,
  PivotTabs,
  Stack,
  useScrollToViewById,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalDataTypeLabel } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import {
  generateMeasurementColumns,
  LATEST_DATE_COLUMN_ID,
  reverseDateColumns
} from "./generateMeasurementColumns.tsx";
import { generateObservationColumns } from "./generateObservationColumns.tsx";
import { ObservationGraphView } from "./ObservationGraphView.tsx";
import { ObservationMatrixPivotBar } from "./ObservationMatrixPivotBar.tsx";
import { ObservationsHeaderWrapper } from "./ObservationsHeaderWrapper.tsx";
import {
  generateMeasurementRows,
  getMeasurementTypeLabel,
  groupMeasurementsByDateAndType,
  groupObservationsByDateAndType
} from "./utils.ts";

interface ObservationsProps {
  clinicalRecord: ClinicalRecord;
}

export interface MeasurementRow {
  key: string;
  type: string;
  [key: string]: string;
}

export enum ObservationPivotName {
  Matrix = "Matrix",
  DashBoard = "Dashboard",
  NewObservations = "NewObs",
  All = "All"
}

export const ObservationsExpandedTable: React.FC<ObservationsProps> = ({
  clinicalRecord
}) => {
  const { clinical, userExperience, core } = useStores();
  const theme = useTheme();
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [selectedKey, setSelectedKey] = useState<string>(
    ObservationPivotName.Matrix
  );

  const scroll = useScrollToViewById({ behavior: "smooth" }, 1);

  useEffect(() => {
    scroll(LATEST_DATE_COLUMN_ID);
  }, [scroll, userExperience.reverseObservationMatrixDates]);

  const onSwitchDateOrder = async () => {
    const reverseDatesSetting = await userExperience.getUserStorage(
      UserStorageKeys.ReverseObservationMatrixDates
    );

    if (reverseDatesSetting) {
      const valueAsBool = reverseDatesSetting.jsonData as boolean;

      return await userExperience.updateUserStorage(
        UserStorageKeys.ReverseObservationMatrixDates,
        {
          id: reverseDatesSetting.id,
          key: UserStorageKeys.ReverseObservationMatrixDates,
          userId: core.userId,
          jsonData: !valueAsBool,
          eTag: reverseDatesSetting.eTag
        }
      );
    }

    return await userExperience.addUserStorage(
      UserStorageKeys.ReverseObservationMatrixDates,
      {
        key: UserStorageKeys.ReverseObservationMatrixDates,
        userId: core.userId,
        jsonData: false
      }
    );
  };

  const handlePivotItemClick = (item?: any) => {
    if (item?.props.itemKey) {
      setSelectedKey(item.props.itemKey);
    }
  };

  const fetchMeasurements = async () => {
    const allMeasurementsResult = await clinicalRecord.loadMeasurementData();
    const measurements = allMeasurementsResult.results;
    const groupedByDateAndType = groupMeasurementsByDateAndType(
      measurements,
      getMeasurementTypeLabel
    );

    const dates = Object.keys(groupedByDateAndType);

    const uniqueTypes = new Set(
      measurements.map(m => getMeasurementTypeLabel(m.type))
    );

    const sortedRows = generateMeasurementRows(
      uniqueTypes,
      dates,
      groupedByDateAndType
    );

    const rowsWithLabels = sortedRows.map(row => ({
      ...row,
      typeLabel: getMeasurementTypeLabel(row.type)
    }));

    const columns = generateMeasurementColumns({
      dates,
      groupedByDateAndType,
      theme
    });

    setColumns(columns);
    return rowsWithLabels;
  };

  const fetchObservations = async () => {
    const allObservationResults = await clinicalRecord.loadObservationData();
    const observations = allObservationResults.results;
    const groupedByDateAndType = groupObservationsByDateAndType(observations);

    const dates = Object.keys(groupedByDateAndType);

    const uniqueTypes = new Set(
      observations.map(m => getMeasurementTypeLabel(m.type))
    );

    const sortedRows = generateMeasurementRows(
      uniqueTypes,
      dates,
      groupedByDateAndType
    );

    const rowsWithLabels = sortedRows.map(row => ({
      ...row,
      typeLabel: getMeasurementTypeLabel(row.type)
    }));

    const columns = generateObservationColumns({
      dates,
      groupedByDateAndType,
      theme
    });

    setColumns(columns);
    return rowsWithLabels;
  };

  return (
    <ObservationsHeaderWrapper
      heading={ClinicalDataTypeLabel.Observations}
      onClose={clinical.ui.tabs.currentPatientRecordTab!.hideActive}
      pivotTab={
        <PivotTabs selectedKey={selectedKey} onLinkClick={handlePivotItemClick}>
          <PivotItem
            headerText={ObservationPivotName.Matrix}
            itemKey={ObservationPivotName.Matrix}
          />
          <PivotItem
            headerText={ObservationPivotName.DashBoard}
            itemKey={ObservationPivotName.DashBoard}
          />
          {core.hasPermissions(Permission.NewObservationEnitity) && (
            <PivotItem
              headerText={ObservationPivotName.NewObservations}
              itemKey={ObservationPivotName.NewObservations}
            />
          )}
        </PivotTabs>
      }
    >
      {(selectedKey === ObservationPivotName.Matrix ||
        selectedKey === ObservationPivotName.All) && (
        <DataFetcher
          fetch={() => {
            userExperience.getUserStorage(
              UserStorageKeys.ReverseObservationMatrixDates
            );
            return fetchMeasurements();
          }}
          noExceptionsHandlers
        >
          {(rows, loading, error) => {
            return loading || error || rows?.length ? (
              <Stack>
                <ObservationMatrixPivotBar
                  onSwitch={async () => {
                    await onSwitchDateOrder();
                  }}
                  onPivotClick={handlePivotItemClick}
                  selectedKey={selectedKey}
                />
                <ShimmeredDetailsList
                  errorMessage={error?.message}
                  enableShimmer={loading}
                  columns={
                    userExperience.reverseObservationMatrixDates
                      ? reverseDateColumns(columns)
                      : columns
                  }
                  items={rows || []}
                />
              </Stack>
            ) : null;
          }}
        </DataFetcher>
      )}

      {selectedKey === ObservationPivotName.DashBoard && (
        <ObservationGraphView clinicalRecord={clinicalRecord} />
      )}
      {selectedKey === ObservationPivotName.NewObservations && (
        <DataFetcher fetch={fetchObservations} noExceptionsHandlers>
          {(rows, loading, error) =>
            loading || error || rows?.length ? (
              <ShimmeredDetailsList
                errorMessage={error?.message}
                enableShimmer={loading}
                columns={columns}
                items={rows || []}
              />
            ) : null
          }
        </DataFetcher>
      )}
    </ObservationsHeaderWrapper>
  );
};

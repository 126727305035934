import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Heading } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ConditionContext } from "../context/ConditionContext.ts";
import {
  conditionModalFormNameOf,
  ConditionModalFormValues
} from "./Condition.types.ts";
import { ConditionModalFallback } from "./ConditionModalFallback.tsx";
import { ConditionModalFormFields } from "./ConditionModalFormFields.tsx";
import { ConditionModalFormValidator } from "./ConditionModalFormValidator.ts";

interface ConditionModalBaseProps {
  disabled?: boolean;
  onDismiss?: () => void;
  onSubmit?: (values: ConditionModalFormValues) => void;
}

const ConditionModalBase: React.FC<ConditionModalBaseProps> = observer(
  ({ disabled, onSubmit, onDismiss }) => {
    const { core, booking } = useStores();

    const validator = new ConditionModalFormValidator(core);

    const {
      hiddenConditionModal,
      setHiddenConditionModal,
      patientId,
      acc,
      practice,
      submitConditionModal,
      handleSubmitSucceeded,
      initialValues,
      initialCondition
    } = useContext(ConditionContext);

    if (hiddenConditionModal) return null;

    const extraPromptConditionOnSubmit = (
      form: FormApi<ConditionModalFormValues>
    ) => {
      if (initialCondition) {
        const privateState = form.getFieldState(
          conditionModalFormNameOf("private")
        );

        const referralNumberState = form.getFieldState(
          conditionModalFormNameOf("referralNumber")
        );

        return !!privateState?.dirty || !!referralNumberState?.dirty;
      }

      return false;
    };

    const getDialogContentProps = (form: FormApi<ConditionModalFormValues>) => {
      if (initialCondition) {
        const privateState = form.getFieldState(
          conditionModalFormNameOf("private")
        );

        const referralNumberFormValue = form.getState().values.referralNumber;
        const referralNumberChanged =
          referralNumberFormValue !== initialValues.referralNumber;

        if (!!privateState?.dirty) {
          return {
            dialogContentProps: {
              title: "Change condition type",
              subText: "Are you sure you want to change the condition type?"
            },
            confirmButtonProps: { text: "Change condition" }
          };
        } else if (referralNumberChanged) {
          return {
            dialogContentProps: {
              title: `Confirm changing claim to ${referralNumberFormValue}`,
              subText: `
                  Changing "${
                    initialValues.referralNumber ?? ""
                  }" to "${referralNumberFormValue}" will impact billing and claiming, we recommend this is ONLY done under ACC direction`
            },
            confirmButtonProps: { text: "Change claim number" }
          };
        }
      }

      return undefined;
    };

    const patientName: string | undefined =
      patientId && practice.contactsMap.get(patientId)?.name;

    const getData = async ({ practice }: RootStore) => {
      const defaultInsurer = await practice.getDefaultInsurers();
      const claimEpisodesOfCare = initialCondition?.episodeOfCareId
        ? await acc.getClaimEpisodesOfCare({
            episodesOfCare: [initialCondition?.episodeOfCareId],
            includeInactive: true
          })
        : undefined;
      return {
        defaultInsurerId: defaultInsurer.defaultNzPublicInsurerId,
        claimEpisodesOfCare
      };
    };

    return (
      <DataFetcher fetch={getData} noExceptionsHandlers>
        {(data, loading, error) => (
          <SubmissionFormDialog
            dialogName="Condition setup"
            loadingData={loading}
            dataLoadingError={error?.message}
            initialValues={initialValues}
            Fallback={ConditionModalFallback}
            onSubmitSucceeded={values => {
              handleSubmitSucceeded(values);
              booking.ui.setSecondColumnContent(undefined);
              onSubmit && onSubmit(values);
            }}
            onSubmit={submitConditionModal}
            buttonsProps={form => ({
              hideButtonsSeparator: true,
              disableSubmitOnFormInvalid: true,
              submitButtonProps: {
                text: "Save",
                iconProps: { iconName: undefined }
              },
              promptOnSubmit: true,
              disableSubmitOnPristine: initialValues.private === undefined,
              extraPromptConditionOnSubmit,
              submitConfirmProps: {
                styles: {
                  main: {
                    ".ms-Dialog-subText": { marginBottom: 40 }
                  }
                },
                ...getDialogContentProps(form)
              }
            })}
            validate={validator.validate}
            dialogProps={{
              minWidth: 480,
              maxWidth: 480,
              onDismiss: () => {
                setHiddenConditionModal(true);
                onDismiss && onDismiss();
              },
              dialogContentProps: {
                title: (
                  <Heading variant="modal-heading">
                    Condition setup {patientName && `for ${patientName}`}
                  </Heading>
                )
              }
            }}
          >
            {() => (
              <ConditionModalFormFields
                defaultNzPublicInsurerId={data?.defaultInsurerId}
                claimEpisodesOfCare={data?.claimEpisodesOfCare}
                disabled={disabled}
              />
            )}
          </SubmissionFormDialog>
        )}
      </DataFetcher>
    );
  }
);

export const ConditionModal = withFetch(
  x => [x.practice.ref.sexes.load(), x.acc.ref.claimStatuses.load()],
  ConditionModalBase
);

import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FieldItem,
  FontSizes,
  PrimaryButton,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { BillingHeading } from "@modules/billing/screens/shared-components/BillingHeading.tsx";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { EmailConfirmationModal } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { OptedOutTooltip } from "../../shared-components/OptedOutTooltip.tsx";
import {
  InvoiceFormButtons,
  invoiceFormNameOf,
  InvoiceFormSubmitButtonClicked
} from "./InvoiceForm.types.tsx";

export const InvoiceFormHeader: FunctionComponent = observer(() => {
  const form = useForm<InvoiceFormValues>();
  const {
    adjustInvoice,
    isAdjustMode,
    isInvoiceEmailDialogVisible,
    onClose,
    setSubmitButtonClicked,
    setIsConfirmationDialogVisible,
    setInvoiceEmailDialogVisible,
    setInvoiceEmail
  } = useContext(InvoiceFormContext);

  const { initialValues, values } = useFormState<InvoiceFormValues>({
    subscription: { initialValues: true, values: true }
  });

  const root = useStores();

  const onSubmitForm = (): void => {
    if (isAdjustMode && !form.getState().invalid) {
      // only if valid form
      setIsConfirmationDialogVisible(true);
    } else {
      form.submit();
    }
  };

  const disableSubmitOnPristine =
    isAdjustMode || initialValues.invoiceItems?.length === 0;

  const handlePayInvoice = () => {
    setSubmitButtonClicked(InvoiceFormSubmitButtonClicked.PayNow);
    onSubmitForm();
  };

  const handleOpenPdf = () => {
    setSubmitButtonClicked(InvoiceFormSubmitButtonClicked.OpenPdf);
    onSubmitForm();
  };

  const isNewInvoiceRoute = !!root.routing.match(routes.accounts.invoices.new);

  const getSubmitButtonProps = () => {
    if (
      isNewInvoiceRoute &&
      root.core.hasPermissions(Permission.SaveDraftAllowed)
    ) {
      return {
        ...dataAttribute(DataAttributes.Element, "submit-invoice"),
        width: 50,
        primary: false,
        text: "Save",
        onClick: () => {
          setSubmitButtonClicked(InvoiceFormSubmitButtonClicked.Save);
        },
        iconProps: { iconName: "WaitlistConfirmMirrored" }
      };
    } else {
      return {
        ...dataAttribute(DataAttributes.Element, "submit-invoice"),
        width: 50,
        primary: false,
        text: "Save"
      };
    }
  };

  const getExtraButtonsBetween = (
    form: FormApi<InvoiceFormValues>,
    isDisabled?: boolean
  ): JSX.Element => {
    const { accountContactId, patientId } = form.getState().values;
    const contactId = accountContactId || patientId;

    return (
      <>
        <ContactFetcher
          contactId={contactId}
          options={{ includeContactPreferences: true }}
        >
          {contact => (
            <OptedOutTooltip contact={contact}>
              <DefaultButton
                id="send-invoice-email"
                text="Send email"
                disabled={!contact || contact.billingOptedOut || isDisabled}
                onClick={() => setInvoiceEmailDialogVisible(true)}
                iconProps={{ iconName: "Mail" }}
              />
            </OptedOutTooltip>
          )}
        </ContactFetcher>

        <DefaultButton
          id="get-invoice-pdf"
          text="PDF"
          disabled={isDisabled}
          onClick={handleOpenPdf}
          iconProps={{ iconName: "Pdf" }}
        />
      </>
    );
  };

  const getExtraButtonsBefore = (
    form: FormApi<InvoiceFormValues>,
    isDisabled?: boolean
  ): JSX.Element => {
    return (
      <When permission={Permission.PaymentCreate}>
        <PrimaryButton
          id="pay-invoice"
          text={InvoiceFormButtons.pay}
          onClick={handlePayInvoice}
          disabled={isDisabled}
          iconProps={{ iconName: "Money" }}
        />
      </When>
    );
  };

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { width: "100%" } }}
      >
        <BillingHeading
          breadcrumbProps={{ transactionNumber: values.invoiceNumber }}
          amount={isAdjustMode ? Number(values.total) : undefined}
          transaction={adjustInvoice}
          dateOverride={
            <FieldItem
              styles={{ root: { fontSize: FontSizes.size14 } }}
              name={invoiceFormNameOf("invoiceDate")}
            >
              {DateTime.fromJSDate(values.invoiceDate).toDayDefaultFormat()}
            </FieldItem>
          }
          buttons={
            <FormSubmitButtons
              cancelButtonProps={{
                ...dataAttribute(DataAttributes.Element, "cancel-invoice")
              }}
              disableSubmitOnPristine={disableSubmitOnPristine}
              extraActionsBetween={getExtraButtonsBetween}
              extraActionsBefore={getExtraButtonsBefore}
              onCancel={() => onClose()}
              onSubmit={() => {
                onSubmitForm();
              }}
              styles={{ root: { paddingTop: 0, margin: 0, borderTop: "none" } }}
              submitButtonProps={getSubmitButtonProps()}
            />
          }
        />
      </Stack>

      {isInvoiceEmailDialogVisible && values.accountContactId && (
        <EmailConfirmationModal
          accountContactId={values.accountContactId}
          onDismiss={() => {
            setInvoiceEmailDialogVisible(false);
          }}
          onSubmit={({ email }) => {
            setSubmitButtonClicked(InvoiceFormSubmitButtonClicked.SendEmail);
            setInvoiceEmail(email);
            onSubmitForm();
          }}
          commType={OutboundCommType.Invoice}
        />
      )}
    </>
  );
});

import { FunctionComponent, useState } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { CancelCalendarEventDialog } from "../../../cancel-calendar-event-dialog/CancelCalendarEventDialog.tsx";

interface CancelCalendarEventComponentProps {
  showCancelEvent: () => void;
  disabled?: boolean;
}

export const CancelCalendarEventComponent: FunctionComponent<
  CancelCalendarEventComponentProps
> = ({ showCancelEvent, disabled }) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const { booking } = useStores();

  const handleCancelCalendarEvent = () => {
    showCancelEvent();
    setIsClosing(true);
  };

  const calendarEvent = booking.ui.cancelCalendarEventId
    ? booking.calendarEventsMap.get(booking.ui.cancelCalendarEventId!)
    : undefined;

  return (
    <>
      <DefaultButton
        iconProps={{ iconName: undefined }}
        onClick={handleCancelCalendarEvent}
        text="Cancel appointment"
        disabled={disabled}
      />
      {isClosing && calendarEvent && (
        <CancelCalendarEventDialog setIsClosing={setIsClosing} />
      )}
    </>
  );
};

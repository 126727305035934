import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FontIcon,
  Heading,
  ICalloutProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { CalendarEventPriority } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { getNextAppointmentStatusCode } from "@modules/booking/screens/booking-calendar/components/shared-components/appointment-status/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AppointmentStatus } from "../../shared-components/appointment-status/AppointmentStatus.tsx";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

export const BookingEventCalloutNextStatusInput: React.FC<ICalloutProps> =
  observer(({ onDismiss }) => {
    const root = useStores();
    const { core } = root;

    const {
      calendarEvent,
      changeAppointmentStatus,
      isStatusUpdating,
      startPatientMatchWorkflow
    } = useContext(BookingCalendarEventContext);

    const canWriteCalenderEvent = core.hasPermissions(
      Permission.CalendarEventWrite
    );

    const theme = useTheme();

    const nextAppointmentStatusCode = getNextAppointmentStatusCode(
      calendarEvent.appointmentStatus
    );

    const urgent = calendarEvent.priority === CalendarEventPriority.Urgent;

    if (!nextAppointmentStatusCode) return null;

    return (
      <Stack>
        <Stack>
          <Heading labelPaddings>Next status</Heading>
        </Stack>
        <Stack>
          <DefaultButton
            disabled={isStatusUpdating.get() || !canWriteCalenderEvent}
            styles={{
              root: {
                borderColor: theme.palette.neutralSecondary
              }
            }}
            onClick={async () => {
              await changeAppointmentStatus(nextAppointmentStatusCode);
              if (onDismiss) onDismiss();
              if (calendarEvent.canShowPatientMatchReview)
                startPatientMatchWorkflow();
            }}
          >
            <Stack horizontal verticalAlign="center">
              <AppointmentStatus
                {...dataAttribute(
                  DataAttributes.Element,
                  "booking-event-callout-appointment-status"
                )}
                urgent={urgent}
                verticalAlign="center"
                styles={{ root: { paddingRight: 8 } }}
                code={nextAppointmentStatusCode}
                showIndicator
              />
              <FontIcon
                styles={{
                  root: {
                    color: theme.palette.neutralSecondary,
                    fontSize: 12
                  }
                }}
                iconName="ChevronRight"
              />
            </Stack>
          </DefaultButton>
        </Stack>
      </Stack>
    );
  });

import { FormApi } from "final-form";
import { FunctionComponent } from "react";

import { Spinner } from "@bps/fluent-ui";
import {
  AccClinicalTab,
  ClinicalDataType,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClaimForm } from "@modules/acc/screens/claim/components/ClaimFormLazy.tsx";
import { onSubmitAccForm } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimHelper } from "@modules/acc/screens/claim/context/ClaimHelper.ts";
import {
  ClaimScreenBaseProps,
  ClaimScreenDataFetcherProps
} from "@modules/acc/screens/claim/types/claim.types.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../StashedClinicalDataFormSpy.tsx";
import { ClinicalFormValuesSpy } from "../clinical-record-forms-values-spy/ClinicalRecordFormsValuesSpy.tsx";
import { ClinicalClaimDataFetcher } from "./ClinicalClaimDataFetcher.tsx";
import { ClinicalClaimFormHeader } from "./ClinicalClaimFormHeader.tsx";

interface ClinicalClaimScreenBaseProps extends ClaimScreenBaseProps {}

interface ClinicalClaimScreenDataFetcherProps
  extends ClaimScreenDataFetcherProps {
  claimId: string;
}

const ClinicalClaimScreenBase: FunctionComponent<
  ClinicalClaimScreenBaseProps
> = ({ claim }) => {
  const root = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();

  const claimHelper = new ClaimHelper(root, claim, {
    onSubmit: onSubmitAccForm,
    clinicalRecord
  });

  const originalInitialValues = claimHelper.getInitialFormValues();
  const persistedValues =
    clinicalRecord.clinicalRecordFormsValuesStash.persistedValues[
      AccClinicalTab.Injury
    ];

  // Excluded some PersistedValues to prioritise StashedEncounterClinicalDate
  const {
    accidentDate,
    causeOfAccident,
    primaryDiagnosis,
    claimDiagnosis,
    hasAdditionalDiagnoses,
    workType,
    ...restPersistedValues
  } = (persistedValues ?? {}) as ClaimFormValues;

  const mergedValues: ClaimFormValues = {
    ...originalInitialValues,
    ...restPersistedValues
  };

  const persistedDiagnosisWithIdsMapped = mergedValues?.claimDiagnosis?.length
    ? originalInitialValues.claimDiagnosis?.map(
        (diagnosis: ClaimDiagnosisFormValues, index) => ({
          ...diagnosis,
          id: mergedValues.claimDiagnosis?.[index].id || diagnosis.id
        })
      )
    : originalInitialValues.claimDiagnosis;

  const restartForm = (
    values: ClaimFormValues,
    form: FormApi<ClaimFormValues>
  ) => {
    form.restart(values);
    root.clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
      type: AccClinicalTab.Injury
    });
  };

  return (
    <ClaimContext.Provider value={claimHelper}>
      <DataFetcher
        fetch={() =>
          claimHelper.getStashedClinicalClaimInitialValues(mergedValues)
        }
        fallback={<Spinner />}
      >
        {stashedValues => {
          return (
            <ClaimForm
              initialValues={{
                ...mergedValues,
                ...stashedValues
              }}
              header={<ClinicalClaimFormHeader />}
              onSubmitSucceeded={restartForm}
            >
              {() => (
                <>
                  <ClinicalFormValuesSpy
                    clinicalRecord={clinicalRecord}
                    originalInitialValues={{
                      ...originalInitialValues,
                      claimDiagnosis: persistedDiagnosisWithIdsMapped
                    }}
                    tab={AccClinicalTab.Injury}
                  />

                  <StashedClinicalDataFormSpy<ClaimFormValues>
                    clinicalRecord={clinicalRecord}
                    getData={claimHelper.getStashedClinicalData}
                    areasToObserve={{
                      currentInjury: [PermanentClinicalTab.SOTAP],
                      diagnoses: [PermanentClinicalTab.SOTAP],
                      workHistory: [
                        ClinicalDataType.WorkHistory,
                        PermanentClinicalTab.SOTAP
                      ],
                      patientDemographicUpdate: [
                        ClinicalDataType.WorkHistory,
                        PermanentClinicalTab.SOTAP
                      ]
                    }}
                  />
                </>
              )}
            </ClaimForm>
          );
        }}
      </DataFetcher>
    </ClaimContext.Provider>
  );
};

const ClinicalClaimEditScreenDataFetcher: React.FC<
  ClinicalClaimScreenDataFetcherProps
> = props => {
  return (
    <ClinicalClaimDataFetcher
      fetchProvider
      fetchPatient
      claimId={props.claimId}
    >
      {claim => <ClinicalClaimScreenBase {...props} claim={claim} />}
    </ClinicalClaimDataFetcher>
  );
};

const ClinicalClaimEditScreen = withFetch(
  x => [
    x.acc.ref.claimStatuses.load(),
    x.practice.ref.accProviderTypes.load(),
    x.acc.ref.occupations.load(),
    x.clinical.ref.occupations.load()
  ],
  ClinicalClaimEditScreenDataFetcher
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClinicalClaimEditScreen;

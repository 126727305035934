import { action, computed, observable, runInAction } from "mobx";

import { DateTime, newGuid } from "@bps/utils";
import { ClaimAdjustmentDocumentTypes } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  CorrespondenceVisibility,
  DocumentContentType,
  DocumentDto,
  DocumentEnum,
  DocumentExtensionType,
  DocumentMetadataItem,
  DocumentTabStatus,
  DocumentWriterTab,
  EpisodeOfCareDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  TemplateRenderDto,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { splitClaimKeyString } from "@modules/acc/screens/shared-components/claim-picker/utils.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { CorrespondenceStore } from "@stores/clinical/CorrespondenceStore.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { DocumentStore } from "@stores/documents/DocumentStore.ts";
import { Template } from "@stores/documents/models/Template.ts";

import { getSideOfBodyText } from "../../../../utils/clinical.utils.ts";
import { DocumentMergeFieldsFormValues } from "../../../document-writer/components/merge-fields/DocumentWriterMergeFormDialog.types.ts";
import { TemplateOptionKeys } from "../../../patient-record/types/template-option-keys.interface.ts";
import { AuthorTypes } from "../TemplatePickerFilter.tsx";
import {
  TemplateListItem,
  TemplatePivotItemKeys
} from "../TemplatePivot.types.ts";

export interface TemplateFilter {
  documentTypes?: string[];
  documentStatus?: string[];
  documentAuthor?: string;
  searchText?: string;
}

export enum TemplateName {
  MedicalCertificate = "Medical Certificate",
  OutcomeMeasure = "Outcome Measure",
  Referral = "Referral Letter",
  Discharge = "Discharge Letter",
  Blank = "Blank Document"
}

export class TemplatePickerFormModel {
  constructor(
    private stores: {
      document: DocumentStore;
      correspondence: CorrespondenceStore;
      clinical: ClinicalStore; // To be removed once all templates are migrated
      core: CoreStore;
    }
  ) {
    this.document = stores.document;
    this.correspondence = stores.correspondence;
    this.clinical = stores.clinical;
    this.core = stores.core;
  }

  @observable
  private _selectedTemplate: Template | undefined;

  get selectedTemplate() {
    return this._selectedTemplate;
  }

  set selectedTemplate(value: Template | undefined) {
    runInAction(() => {
      this._selectedTemplate = value;
    });
  }

  @observable
  favourites: string[];

  @observable
  selectedKey: string;

  @action
  setFavourites(favourites: string[]) {
    this.favourites = favourites;
  }

  @action
  setSelectedKey(selectedKey: string) {
    this.selectedKey = selectedKey;
  }

  setUpInitialState(favourites: string[]) {
    this.setFavourites(favourites);

    this.setSelectedKey(
      favourites.length
        ? TemplatePivotItemKeys.Favourites
        : TemplatePivotItemKeys.All
    );
  }

  // TODO: Temporary until templates have a document type
  @computed
  get documentType(): CorrespondenceType {
    let documentType = CorrespondenceType.Letter;

    if (this._selectedTemplate?.name === TemplateName.OutcomeMeasure) {
      documentType = CorrespondenceType.Report;
    } else if (
      this._selectedTemplate?.name === TemplateName.MedicalCertificate
    ) {
      documentType = CorrespondenceType.MedicalCertificate;
    } else if (this._selectedTemplate?.name === TemplateName.Referral) {
      documentType = CorrespondenceType.Referral;
    } else if (this._selectedTemplate?.name === TemplateName.Discharge) {
      documentType = CorrespondenceType.DischargeLetter;
    }
    return documentType;
  }

  document: DocumentStore;
  correspondence: CorrespondenceStore;
  clinical: ClinicalStore; // To be removed once all templates are migrated
  core: CoreStore;

  static getItemsByFilter(
    items: TemplateListItem[],
    filter: TemplateFilter,
    userName: string | undefined
  ) {
    let filteredItems = items;

    if (filter.searchText && filter.searchText.length > 0) {
      filteredItems = filteredItems.filter(x =>
        x.details.toLowerCase().includes(filter.searchText!.toLowerCase())
      );
    }

    if (filter.documentStatus) {
      filteredItems = filteredItems.filter(
        x =>
          x.documentStatus && filter.documentStatus?.includes(x.documentStatus)
      );
    }

    if (filter.documentTypes && filter.documentTypes.length > 0) {
      filteredItems = filteredItems.filter(
        x => x.documentType && filter.documentTypes?.includes(x.documentType)
      );
    }

    if (filter.documentAuthor) {
      if (filter.documentAuthor === AuthorTypes.Me) {
        filteredItems = filteredItems.filter(
          x => x.documentAuthor === userName
        );
      } else if (filter.documentAuthor === AuthorTypes.System) {
        filteredItems = filteredItems.filter(x => !x.documentAuthor);
      } else {
        filteredItems = filteredItems.filter(
          x => x.documentAuthor && x.documentAuthor !== userName
        );
      }
    }

    return filteredItems;
  }

  async renderTemplateContent(
    props: {
      patientId: string;
      userId: string;
      practiceOrgUnitId?: string;
      toContactId?: string;
      encounterId?: string;
      claimId?: string;
      dischargeBusinessCode?: string;
    },
    mergeFieldValues: DocumentMergeFieldsFormValues // TODO: To be removed when all old documents are migrated
  ) {
    const nameOf = nameOfFactory<TemplateOptionKeys>();

    let renderedDocument: TemplateRenderDto | undefined;

    if (this._selectedTemplate) {
      const context: { [id: string]: string } = {};
      context[nameOf("PatientId")] = props.patientId;
      context[nameOf("UserId")] = props.userId;

      if (props.encounterId) {
        context[nameOf("EncounterId")] = props.encounterId;
      }

      if (props.claimId) {
        context[nameOf("ClaimId")] = props.claimId;
      }

      if (props.practiceOrgUnitId) {
        context[nameOf("PracticeOrgUnitId")] = props.practiceOrgUnitId;
      }

      if (props.toContactId) {
        context[nameOf("ContactId")] = props.toContactId;
      }

      if (props.dischargeBusinessCode) {
        context[nameOf("DischargeBusinessCode")] = props.dischargeBusinessCode;
      }

      if (mergeFieldValues.episodeOfCareId) {
        context[nameOf("EpisodeOfCareId")] = mergeFieldValues.episodeOfCareId;
      }

      if (mergeFieldValues.visits && mergeFieldValues.visits.length > 0) {
        context[nameOf("RelatedEncounterIds")] =
          mergeFieldValues.visits?.toString();
      }

      if (
        mergeFieldValues.clinicalTools &&
        mergeFieldValues.clinicalTools.length > 0
      ) {
        context[nameOf("ClinicalTools")] =
          mergeFieldValues.clinicalTools.toString();
      }

      const parameters: { [id: string]: string } = {};

      if (mergeFieldValues.fromDate) {
        parameters[nameOf("StartDate")] = DateTime.fromJSDate(
          mergeFieldValues.fromDate
        ).toDayDefaultFormat();
      }

      if (mergeFieldValues.toDate) {
        parameters[nameOf("EndDate")] = DateTime.fromJSDate(
          mergeFieldValues.toDate
        ).toDayDefaultFormat();
      }

      let practiceLetterhead: Template | undefined;
      if (mergeFieldValues.usePracticeLetterhead) {
        practiceLetterhead = await this.document.getDefaultPracticeLetterhead();
      }

      const renderOptions: TemplateRenderOptions = {
        context,
        contentType: DocumentContentType.Sfdt,
        parameters,
        letterheadId: mergeFieldValues.usePracticeLetterhead
          ? practiceLetterhead?.id
          : undefined
      };

      renderedDocument = await this.document.renderTemplate(
        this._selectedTemplate.id,
        renderOptions
      );
    }

    return renderedDocument;
  }

  async generateDocumentDto(props: {
    patientId: string;
    userId: string;
    templateType: CorrespondenceType | ClaimAdjustmentDocumentTypes; // TODO: Could be removed one templates have document type
    toContactId?: string;
    renderedContent?: TemplateRenderDto;
    encounterId?: string;
    claimId?: string;
    documentTitle?: string;
    providerId?: string;
    showOnTimeline?: boolean;
    confidential?: boolean;
  }) {
    const metadata: DocumentMetadataItem[] = [
      {
        key: DocumentEnum.Date,
        value: DateTime.now().toISO()
      },
      {
        key: DocumentEnum.Extension,
        value: DocumentExtensionType.Docx
      },
      {
        key: DocumentEnum.Name,
        value: this._selectedTemplate?.name ?? props.documentTitle
      }
    ];

    if (this._selectedTemplate) {
      metadata.push({
        key: DocumentEnum.TemplateId,
        value: this._selectedTemplate.id
      });
    }

    if (props.encounterId) {
      metadata.push({
        key: DocumentEnum.EncounterId,
        value: props.encounterId
      });
    }

    if (props.renderedContent?.contextData) {
      metadata.push({
        key: DocumentEnum.ContextData,
        value: props.renderedContent.contextData
      });
    }

    if (props.showOnTimeline) {
      metadata.push({
        key: DocumentEnum.ShowOnTimeline,
        value: true.toString()
      });
    }

    const dto: DocumentDto = {
      id: newGuid(),
      patientId: props.patientId,
      to: props.toContactId,
      from: props.providerId ?? props.userId,
      type: props.templateType,
      direction: CorrespondenceDirection.Out,
      status: CorrespondenceStatus.Draft,
      content: props.renderedContent?.content ?? "",
      showOnTimeline: props.showOnTimeline,
      secGroupId: props.confidential
        ? this.core.user?.privateSecGroupId
        : undefined,
      metadata,
      eTag: ""
    };

    this.correspondence.mergeCorrespondence(dto);

    return dto;
  }

  get isCustomTemplate() {
    return this.selectedTemplate?.isCustom;
  }

  getDischargeOptions = (
    episodeOfCareAndRolesMap: {
      episodeOfCare: EpisodeOfCareDto;
      role: string | undefined;
    }[]
  ) => {
    return episodeOfCareAndRolesMap.map(dischargeCondition => {
      const primaryDiagnosis = dischargeCondition.episodeOfCare.diagnoses?.find(
        diagnose => diagnose.isPrimaryDiagnosis
      );

      const diagnosisCode =
        primaryDiagnosis?.diagnosisCode?.originalText ?? "Unknown";
      let sideOfBodyText = "";
      if (primaryDiagnosis?.diagnosisSide) {
        sideOfBodyText = ` - ${getSideOfBodyText(
          primaryDiagnosis.diagnosisSide
        )}`;
      }

      // Fetch the full name
      let roleText = "";
      if (dischargeCondition.role) {
        const role = this.core.catalogBusinessRoles.find(
          x => x.code === dischargeCondition.role
        );
        if (role) {
          roleText = role.text;
        }
      }

      const prefix = `${diagnosisCode}${sideOfBodyText}`;

      return {
        key: dischargeCondition.role
          ? `${dischargeCondition.episodeOfCare.id}::${dischargeCondition.role}`
          : dischargeCondition.episodeOfCare.id,
        text: roleText ? `${prefix}::${roleText}` : prefix
      };
    });
  };

  createDocument = async (
    templateType: CorrespondenceType,
    values: DocumentMergeFieldsFormValues,
    options: {
      toContactId?: string;
      encounterId?: string;
      onSuccess: (documentTab: DocumentWriterTab | undefined) => void;
      onDismiss: () => void;
    }
  ) => {
    const { toContactId, encounterId, onSuccess, onDismiss } = options;

    const { patientId: patientIdValue } = values;
    if (!patientIdValue) {
      throw new Error("No patient selected");
    }

    const userId = this.core.userId;

    const claimId = splitClaimKeyString(values.claimId).keyId;

    const providerId = values.providerId;
    const documentTitle = values.documentTitle;
    const episodeOfCareId = values.episodeOfCareId;

    const documentMergeFieldsFormValues = { ...values };

    const showOnTimeline =
      documentMergeFieldsFormValues.visibility ===
      CorrespondenceVisibility.DisplayOnTimeline;

    const confidential =
      documentMergeFieldsFormValues.visibility ===
      CorrespondenceVisibility.Confidential;

    let renderedContent: TemplateRenderDto | undefined;

    // Discharge Form compensation.
    if (episodeOfCareId && episodeOfCareId.includes("::")) {
      const split = episodeOfCareId.split("::");
      documentMergeFieldsFormValues.episodeOfCareId = split[0];
      documentMergeFieldsFormValues.dischargeBusinessCode = split[1];
    }

    const dischargeBusinessRoleCode = values.dischargeBusinessCode;
    const practiceOrgUnitId = this.core.location.parentOrgUnit?.id ?? "";
    if (this.selectedTemplate) {
      renderedContent = await this.renderTemplateContent(
        {
          patientId: patientIdValue,
          userId,
          toContactId,
          encounterId,
          claimId,
          dischargeBusinessCode: dischargeBusinessRoleCode,
          practiceOrgUnitId
        },
        documentMergeFieldsFormValues
      );
    } else {
      if (values?.usePracticeLetterhead) {
        const practiceLetterheadTemplate =
          await this.document.getDefaultPracticeLetterhead();

        if (practiceLetterheadTemplate) {
          const context: { [id: string]: string } = {
            PatientId: patientIdValue,
            UserId: userId,
            practiceOrgUnitId
          };

          renderedContent = await this.document.renderTemplate(
            practiceLetterheadTemplate.id,
            {
              contentType: DocumentContentType.Sfdt,
              skipMerge: false,
              context
            }
          );
        }
      }
    }

    const dto = await this.generateDocumentDto({
      patientId: patientIdValue,
      userId,
      templateType,
      toContactId,
      renderedContent,
      encounterId,
      providerId,
      documentTitle,
      showOnTimeline,
      confidential
    });

    onSuccess({
      documentId: dto.id,
      patientId: dto.patientId,
      encounterId,
      documentTabStatus: DocumentTabStatus.New
    });
    onDismiss();
  };
}

import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DateTime } from "@bps/utils";
import { BillingStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import {
  AdjustmentName,
  TransactionAdjustedBarBase
} from "@modules/billing/screens/shared-components/TransactionAdjustedBarBase.tsx";
import {
  InvoiceAdjustmentReasonText,
  InvoiceCancelReasonText
} from "@ui-components/RefText.tsx";

export const InvoiceViewInfoBar: React.FC = observer(() => {
  const { isStatusCancelledOrAdjusted, invoice } =
    useContext(InvoiceViewContext);

  if (!invoice.changeLog || !isStatusCancelledOrAdjusted) {
    return null;
  }

  let actionText: string = "";
  if (invoice.status === BillingStatuses.cancelled) {
    actionText = AdjustmentName.cancelledBy;
  } else if (invoice.status === BillingStatuses.adjusted) {
    actionText = AdjustmentName.adjustedBy;
  }

  const updatedDate = DateTime.fromISO(
    invoice.changeLog.updatedDate
  )?.toDayDefaultFormat();

  const reasonText =
    invoice.status === BillingStatuses.cancelled ? (
      <InvoiceCancelReasonText code={invoice.adjustmentReason} />
    ) : (
      <InvoiceAdjustmentReasonText code={invoice.adjustmentReason} />
    );

  return (
    <TransactionAdjustedBarBase
      userName={invoice.changeLog.createdBy}
      transactionName="invoice"
      adjustmentName={actionText}
      reason={reasonText}
      updatedDate={updatedDate}
    />
  );
});

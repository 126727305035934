import { FormApi } from "final-form";
import { FunctionComponent } from "react";

import { CenteredLargeSpinner } from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { BodyExaminationValidator } from "../validators/BodyExaminationValidator.tsx";
import { BodyExaminationFields } from "./BodyExaminationFields.tsx";
import {
  BodyExaminationFormProps,
  BodyExaminationFormValues
} from "./BodyExaminationForm.types.ts";
import {
  BodyExaminationContext,
  useBodyExaminationContext
} from "./context/BodyExaminationContext.ts";
import { BodyExaminationHelper } from "./context/BodyExaminationHelper.ts";

const validator = new BodyExaminationValidator();

const BodyExaminationFormBase: FunctionComponent<BodyExaminationFormProps> = ({
  clinicalRecord
}) => {
  const { isViewOnly } = usePatientRecordScreenContext();
  const { clinical, notification } = useStores();

  const {
    setShowDiagramEditorArea,
    initialValues,
    submitData,
    onCancel,
    getStashedClinicalData
  } = useBodyExaminationContext();

  const onSubmitSucceeded = (
    values: BodyExaminationFormValues,
    form: FormApi<BodyExaminationFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.BodyExam
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onCancel();
    }
  };

  return (
    <ClinicalSubmissionForm<BodyExaminationFormValues>
      formName="clinical-data-body-examination"
      autoFocus={false}
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues}
      readOnly={isViewOnly}
      validate={values => {
        return validator.validate(values);
      }}
      disableRoutePrompt
      heading="Body"
      onCancel={() => {
        setShowDiagramEditorArea(false);
        onCancel();
      }}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyAreas?.get("bodyArea")
      }
      disableButtonCondition={stashedData =>
        !stashedData?.dirtyAreas?.get("bodyArea")
      }
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.bodyArea
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.bodyArea
      )}
    >
      <>
        <BodyExaminationFields />
        <StashedClinicalDataFormSpy<BodyExaminationFormValues>
          clinicalRecord={clinicalRecord}
          getData={getStashedClinicalData}
          areasToObserve={{
            bodyArea: [ClinicalDataType.BodyExam]
          }}
        />
      </>
    </ClinicalSubmissionForm>
  );
};

const BodyExaminationFormWithContext: FunctionComponent<
  BodyExaminationFormProps
> = ({ clinicalRecord }) => {
  const { clinical } = useStores();
  return (
    <DataFetcher<BodyExaminationHelper>
      fetch={async () => new BodyExaminationHelper(clinical, clinicalRecord)}
      fallback={<CenteredLargeSpinner />}
    >
      {bodyExaminationHelper => (
        <BodyExaminationContext.Provider value={bodyExaminationHelper}>
          <BodyExaminationFormBase clinicalRecord={clinicalRecord} />
        </BodyExaminationContext.Provider>
      )}
    </DataFetcher>
  );
};

export const BodyExaminationForm = withFetch(
  x => [
    x.clinical.ref.bodyAreas.load(),
    x.clinical.ref.motionTypes.load(),
    x.clinical.ref.injuryAreaMotionTypeGroups.load()
  ],
  BodyExaminationFormWithContext
);

import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import {
  DetailsList,
  FontIcon,
  Heading,
  HideStack,
  IDragDropEvents,
  mergeStyles,
  Selection,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { PatientSummary } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { patientSummaryIcons } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { PatientSummaryText } from "@ui-components/RefText.tsx";

import {
  UserClinicalViewFormIcons,
  UserClinicalViewItemsColumns,
  UserLabels
} from "../../labels.ts";
import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import { UserClinicalFormLabel } from "./UserClinicalFormLabel.tsx";
import {
  IUserClinicalViewItem,
  UserClinicalViewFormValues
} from "./UserClinicalViewForm.types.ts";

interface PatientSummarySettingsFormProps {
  initialValues: Partial<UserClinicalViewFormValues>;
  onSubmit: (values: UserClinicalViewFormValues) => void;
}

export const PatientSummarySettingsForm: React.FC<PatientSummarySettingsFormProps> =
  observer(({ initialValues, onSubmit }) => {
    const { notification } = useStores();
    const theme = useTheme();
    const { formFields, formFooter } = getUserStylesSet(theme);
    const dragEnterClass = mergeStyles({
      backgroundColor: theme.palette.neutralLight
    });

    const draggedItem = useRef<IUserClinicalViewItem | undefined>(undefined);
    const draggedIndex = useRef<number>(-1);
    const selection = useRef<Selection>(new Selection());

    const insertBeforeItem = (
      item: IUserClinicalViewItem,
      form: FormApi<UserClinicalViewFormValues>
    ): void => {
      const draggedItems = selection.current.isIndexSelected(
        draggedIndex.current
      )
        ? (selection.current.getSelection() as IUserClinicalViewItem[])
        : [draggedItem.current!];

      const formStateItems = form.getState()!.values.patientSummaryPage;
      const insertIndex = formStateItems.indexOf(item);
      if (draggedIndex.current !== insertIndex) {
        //Check for if the dragged item is not dropped at the same position
        const items = formStateItems.filter(
          itm => draggedItems.indexOf(itm) === -1
        );
        items.splice(insertIndex, 0, ...draggedItems);
        let counter = 0;
        items.forEach(i => {
          counter = counter + 1;
          i.position = counter;
        });

        form.change("patientSummaryPage", items);
      }
    };

    const getDragDropEvents = (
      form: FormApi<UserClinicalViewFormValues>
    ): IDragDropEvents => {
      return {
        canDrop: () => {
          return true;
        },
        canDrag: () => {
          return true;
        },
        onDragEnter: () => {
          // return string is the css classes that will be added to the entering element.
          return dragEnterClass;
        },
        onDragLeave: () => {
          return;
        },
        onDrop: (item?: any) => {
          if (draggedItem.current) {
            insertBeforeItem(item, form);
          }
        },
        onDragStart: (item?: any, itemIndex?: number) => {
          draggedItem.current = item;
          draggedIndex.current = itemIndex!;
        },
        onDragEnd: () => {
          draggedItem.current = undefined;
          draggedIndex.current = -1;
        }
      };
    };

    const clinicalViewIconStyles = {
      root: {
        height: 24,
        width: 24,
        fontSize: "16px",
        paddingTop: "2px",
        color: theme.palette.themeDarkAlt
      }
    };

    return (
      <SubmissionForm
        formName="patient-summary-settings"
        styles={{ main: { borderRadius: 4 } }}
        initialValues={initialValues}
        onSubmitSucceeded={() => {
          notification.success(
            "Clinical settings Patient Summary has been updated"
          );
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {prop => (
          <Fieldset
            styles={{
              root: formFields
            }}
          >
            <Stack>
              <UserClinicalFormLabel
                label={UserLabels.cardArrangement}
                description={UserLabels.patientSummaryPageInfo}
              />

              <DetailsList
                items={prop.form.getState()?.values.patientSummaryPage || []}
                columns={[
                  {
                    name: UserClinicalViewItemsColumns.icon,
                    key: "icon",
                    minWidth: 20,
                    maxWidth: 20,
                    onRender: () => (
                      <FontIcon
                        iconName={
                          UserClinicalViewFormIcons.gripperBarHorizontal
                        }
                        styles={{ root: { fontSize: 16 } }}
                      />
                    )
                  },
                  {
                    name: UserClinicalViewItemsColumns.name,
                    key: "name",
                    minWidth: 400,
                    onRender: item => (
                      <Stack horizontal>
                        <FontIcon
                          className="clinicalViewIcon"
                          styles={clinicalViewIconStyles}
                          iconName={
                            patientSummaryIcons.find(x => x.key === item.key)
                              ?.iconName
                          }
                        />
                        <PatientSummaryText code={item.key} />
                      </Stack>
                    )
                  },
                  {
                    name: UserClinicalViewItemsColumns.checked,
                    key: "checked",
                    minWidth: 50,
                    maxWidth: 50,
                    onRender: (item, index) => (
                      <>
                        <CheckboxField
                          name={`patientSummaryPage[${index}].checked`}
                        />
                        <HideStack when={true}>
                          <TextInputField
                            name={`patientSummaryPage[${index}].position`}
                          />
                        </HideStack>
                      </>
                    )
                  }
                ]}
                dragDropEvents={getDragDropEvents(prop.form)}
                isHeaderVisible={false}
                styles={{ root: { paddingBottom: 20, marginBottom: 20 } }}
              />
            </Stack>
            <Stack>
              <Stack.Item>
                <Stack horizontal>
                  <FontIcon
                    className="clinicalViewIcon"
                    styles={clinicalViewIconStyles}
                    iconName={
                      patientSummaryIcons.find(
                        x => x.key === PatientSummary.NotesFromPreviousEncounter
                      )?.iconName
                    }
                  />
                  <Heading variant="section-sub-heading">
                    {UserLabels.notesFromPreEncounter}
                  </Heading>
                </Stack>
                <Text>{UserLabels.notesFromPreEncounterInfo}</Text>
              </Stack.Item>
              <Stack.Item>
                <CheckboxField
                  label="Only ever show my own last notes"
                  name={nameof("onlyShowMyNotes")}
                  styles={{
                    root: {
                      width: 300,
                      marginTop: 25
                    }
                  }}
                />
              </Stack.Item>
            </Stack>
          </Fieldset>
        )}
      </SubmissionForm>
    );
  });

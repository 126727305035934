import { useContext, useEffect, useRef } from "react";
import { Field, useField, useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IDropdown,
  IExtendedBasePicker,
  IFocusZone,
  ITag,
  ITextField,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { EncounterType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { LocationsSelectField } from "@modules/practice/screens/shared-components/location/LocationsSelectField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ConsultDetailDialogValues } from "./ConsultDetailDialog.tsx";
import { nameOf } from "./EncounterFormFields.tsx";
import { EncounterFormContext } from "./models/EncounterFormContext.ts";
import { ReasonForVisitPickerField } from "./ReasonForVisitPickerField.tsx";
import { getTimePickerProps } from "./utils.ts";

/**
 * Enum for setting which field gets focussed when the dialog is opened.
 */
export enum AutoFocusElement {
  Date = 1,
  Time = 2,
  Via = 3,
  Location = 4,
  Reason = 5
}

export const encounterTypes = [
  {
    key: EncounterType.Consultation,
    linkText: "in person consult",
    text: "In person consult"
  },
  {
    key: EncounterType.PhoneConsultation,
    linkText: "phone consult",
    text: "Phone"
  },
  {
    key: EncounterType.VideoConsultation,
    linkText: "video consult",
    text: "Video"
  }
];

export const RFV_CD_TYPE_CODE_OTHER = "0.Other";

export const ConsultDetailDialogFields: React.FC = () => {
  const {
    consultDetailDialogVisibleWithFocus,
    clinicalRecord,
    isEnding,
    isFinalising,
    isRecordUpdate
  } = useContext(EncounterFormContext);

  const theme = useTheme();
  const { clinical, core, booking } = useStores();
  const { input: startDateInput } = useField(nameOf("startDate"));
  const form = useForm<ConsultDetailDialogValues>();
  const userId = core.userId;
  const fetchUsersOrgUnits = async () => {
    return await booking.getUserAvailability(userId);
  };

  const reasonForVisits =
    clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits;

  const timePickerProps = getTimePickerProps(startDateInput.value);

  const getLabelText = (
    label: string,
    minWidth?: number,
    required?: boolean
  ) => {
    return (
      <Heading
        labelPaddings
        styles={{
          root: {
            minWidth,
            "::after": required && {
              content: "'*'",
              color: theme.palette.redDark,
              verticalAlign: "middle",
              paddingLeft: 4
            }
          }
        }}
      >
        {label}
      </Heading>
    );
  };

  const datePickerField = useRef<ITextField>(null);
  const timePickerField = useRef<ITextField>(null);
  const viaChoiceField = useRef<IFocusZone>(null);
  const locationField = useRef<IDropdown>(null);
  const reasonPickerField = useRef<IExtendedBasePicker<ITag>>();

  // This focusses the correct field when the dialog is opened by clicking a navigation link in the today's notes header.
  useEffect(() => {
    switch (consultDetailDialogVisibleWithFocus) {
      case AutoFocusElement.Date:
        datePickerField.current?.focus();
        break;
      case AutoFocusElement.Time:
        timePickerField.current?.focus();
        break;
      case AutoFocusElement.Via:
        viaChoiceField.current?.focus();
        break;
      case AutoFocusElement.Location:
        locationField.current?.focus();
        break;
      case AutoFocusElement.Reason:
        reasonPickerField.current?.focus();
        break;
    }
  }, [consultDetailDialogVisibleWithFocus]);

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <When permission={Permission.ConfidentialDataAllowed}>
        <YesNoToggleField
          {...dataAttribute(DataAttributes.Element, "confidential")}
          label="Confidential"
          inlineLabel
          name={nameOf("confidential")}
          styles={{
            root: {
              marginBottom: 0
            },
            label: {
              display: "flex",
              marginRight: 4
            }
          }}
        />
      </When>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Stack horizontal>
          {getLabelText("Date", 84)}
          <DatePickerField
            textFieldComponentRef={datePickerField}
            name={nameOf("startDate")}
            maxDate={DateTime.jsDateNow()}
            styles={{
              root: { marginLeft: 4 },
              field: { minWidth: 100 }
            }}
          />
        </Stack>
        <Stack horizontal styles={{ root: { marginLeft: 8 } }}>
          {getLabelText("Time")}
          <TimePickerField
            componentRef={timePickerField}
            suggestionInterval={15}
            max={timePickerProps.maxTime}
            hideSuggestions={timePickerProps.isStartDateAfterToday}
            name={nameOf("startTime")}
            styles={{
              subComponentStyles: {
                textField: { root: { width: 100, marginLeft: 6 } }
              }
            }}
          />
        </Stack>
      </Stack>

      {!isRecordUpdate && (
        <>
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
            {getLabelText("Via", 84)}
            <ButtonsGroupSingleChoiceField
              componentRef={viaChoiceField}
              name={nameOf("type")}
              options={encounterTypes}
              className="test"
              fieldItemStyles={{
                root: {
                  class: {
                    display: "flex"
                  }
                }
              }}
              styles={{
                label: { root: { marginRight: 8 } }
              }}
            />
          </Stack>
          <FieldCondition
            when={nameOf("type")}
            is={(val: string) => {
              return val === EncounterType.Consultation;
            }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 4 }}
            >
              {getLabelText("At", 84)}
              <DropdownField
                componentRef={locationField}
                name={nameOf("location")}
                options={clinical.ref.encounterLocations.keyTextValues}
                placeholder="--Select Location--"
                styles={{
                  root: {
                    minWidth: core.hasMultiLocationOrgUnit ? 146 : 364
                  }
                }}
              />
              {core.hasMultiLocationOrgUnit && (
                <Stack horizontal tokens={{ childrenGap: 4 }} grow>
                  {getLabelText("Location", 0, true)}
                  <DataFetcher fetch={() => fetchUsersOrgUnits()}>
                    {userOrgUnits => (
                      <LocationsSelectField
                        styles={{ root: { width: "100%", maxWidth: 290 } }}
                        showIcon={false}
                        hideSearchOption
                        name={nameOf("practiceLocationId")}
                        hideClearButton={true}
                        showAllSelected={false}
                        showDefaultLocationBadge
                        placeholder="Select location"
                        fieldItemStyle={{ root: { flexGrow: 1 } }}
                        onFilter={options =>
                          options.filter(
                            option =>
                              (userOrgUnits &&
                                userOrgUnits.availableOrgUnitIds.includes(
                                  option.key
                                )) ||
                              option.data?.isDefault
                          )
                        }
                      />
                    )}
                  </DataFetcher>
                </Stack>
              )}
            </Stack>
          </FieldCondition>
          <FieldSpy
            name={nameOf("type")}
            onChange={() => {
              form.change(nameOf("location"), undefined);
            }}
          />
        </>
      )}

      <Fieldset
        verticalAlign="center"
        styles={{
          root: { minWidth: 600 }
        }}
      >
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
          {getLabelText("Reason", 84, isEnding || isFinalising)}
          <ReasonForVisitPickerField
            validateOnInitialize={isEnding || isFinalising}
            componentRef={refObject => {
              if (refObject) {
                reasonPickerField.current = refObject;
              }
            }}
            hideLabel
            codedFields={reasonForVisits?.map(p => {
              return {
                code: `${p.code}.${p.originalText}`,
                text: p.originalText
              };
            })}
            name={nameOf("reasonForVisit")}
          />
        </Stack>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
          {getLabelText("", 84)}
          <Field name={nameOf("reasonForVisit")}>
            {({ input }) =>
              input.value.includes(RFV_CD_TYPE_CODE_OTHER) && (
                <TextInputField
                  required
                  name={nameOf("otherText")}
                  placeholder="Insert other reason for consult"
                  maxLength={100}
                />
              )
            }
          </Field>
        </Stack>
      </Fieldset>
    </Stack>
  );
};

import { FormApi } from "final-form";
import { Observer } from "mobx-react-lite";
import { ReactNode } from "react";

import {
  Heading,
  Stack,
  ToolTipButton,
  useResizeElementObserver
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import { LastUpdatedText } from "@ui-components/last-updated/LastUpdatedText.tsx";

import { ClinicalFormHeaderButtons } from "./ClinicalFormHeaderButtons.tsx";

interface ClinicalFormResponsiveHeaderProps {
  heading?: string;
  onCancel?: (form: FormApi<any, Partial<any>>) => void;
  hideSubmit?: boolean;
  clinicalToolAfterSubmit?: (value: boolean) => void;
  canSubmit?: (form: FormApi<any, Partial<any>>) => Promise<boolean>;
  extraPromptConditionOnCancel?: (form: FormApi<any, Partial<any>>) => boolean;
  lastUpdatedDate?: DateTime;
  lastUpdatedUserId?: string;
  buttonText?: string;
  disableButtonCondition?: (
    stashedData: StashedEncounterClinicalData
  ) => boolean;
  submitButtonTextCondition?: (
    stashedData?: StashedEncounterClinicalData
  ) => string;
  promptOnCancel?: boolean;
  confidentialShield?: ReactNode;
  onClose?: () => void;
  saveButtons?: ReactNode;
  saveAndCloseOnClick?: (form: FormApi<any, Partial<any>>) => void;
  additionalDisableCheck?: (values: Partial<any>) => boolean;
}

export const ClinicalFormResponsiveHeader: React.FC<
  ClinicalFormResponsiveHeaderProps
> = props => {
  const { setElement, resizeObserverEntry, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  return (
    <div
      ref={r => {
        if (r && !element) {
          setElement(r);
        }
      }}
      style={{ width: "100%" }}
    >
      <Stack
        styles={{ root: { width: "100%", padding: "16px 0" } }}
        tokens={{ childrenGap: 4 }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          <Stack horizontal wrap styles={{ root: { paddingRight: 8 } }}>
            <Heading
              styles={{ root: { marginBottom: 0 } }}
              variant="section-heading"
            >
              {props.heading}
            </Heading>
            <Stack
              horizontal
              styles={{ root: { margin: 0 } }}
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <Stack>{props.confidentialShield}</Stack>
            </Stack>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
            {props.saveButtons}
            {props.onClose ? (
              <ToolTipButton
                toolTipContent="Close"
                buttonProps={{
                  text: "Close",
                  onClick: props.onClose
                }}
              />
            ) : (
              <>
                {props.saveAndCloseOnClick && (
                  <Observer>
                    {() => (
                      <ClinicalFormHeaderButtons
                        disableButtonCondition={props.disableButtonCondition}
                        submitButtonTextCondition={
                          props.submitButtonTextCondition
                        }
                        panelWidth={panelWidth}
                        onCancel={props.onCancel}
                        clinicalToolAfterSubmit={props.clinicalToolAfterSubmit}
                        canSubmit={props.canSubmit}
                        extraPromptConditionOnCancel={
                          props.extraPromptConditionOnCancel
                        }
                        saveAndCloseOnClick={props.saveAndCloseOnClick!}
                        additionalDisableCheck={props.additionalDisableCheck}
                        hideSubmit={props.hideSubmit}
                      />
                    )}
                  </Observer>
                )}
              </>
            )}
          </Stack>
        </Stack>
        {props.lastUpdatedDate && (
          <LastUpdatedText
            asLastRecorded
            lastUpdatedDate={props.lastUpdatedDate}
            lastUpdatedUserId={props.lastUpdatedUserId}
            textStyles={{
              root: {
                flexGrow: 1,
                fontSize: 14
              }
            }}
          />
        )}
      </Stack>
    </div>
  );
};

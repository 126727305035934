import { observer } from "mobx-react-lite";

import { PrimaryButton } from "@bps/fluent-ui";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

interface AppointmentInformationEditButtonProps {
  editAppointment: () => void;
  noAppointments: boolean;
  apptItems: CalendarEvent[];
  selectionCount: number | undefined;
}

export const AppointmentInformationEditButton: React.FC<AppointmentInformationEditButtonProps> =
  observer(({ editAppointment, noAppointments, apptItems, selectionCount }) => {
    return (
      <PrimaryButton
        onClick={editAppointment}
        text="Edit"
        disabled={noAppointments || apptItems.length < 1 || !selectionCount}
      />
    );
  });

import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  PrimaryButton,
  Stack,
  ToolTipButton
} from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ObservationsResponsiveHeaderProps {
  heading?: string;
  onClose: () => void;
  pivotTab?: ReactNode;
}

enum ObservationsTestElements {
  AddButton = "observations-add-button"
}

export const ObservationsResponsiveHeader: React.FC<ObservationsResponsiveHeaderProps> =
  observer(({ heading, onClose, pivotTab }) => {
    const { clinical } = useStores();
    const handleOnGraphClick = () => {
      clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.GeneralExamination
      });
    };

    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontal verticalAlign="center">
          <Heading variant="section-heading">{heading}</Heading>
          <Stack
            horizontal
            styles={{
              root: { marginTop: 10, paddingBottom: 8, paddingLeft: 8 }
            }}
          >
            {pivotTab}
          </Stack>
        </Stack>

        <Stack horizontal verticalAlign="center">
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: "8" }}
          >
            <PrimaryButton
              {...dataAttribute(
                DataAttributes.Element,
                ObservationsTestElements.AddButton
              )}
              text="Add"
              iconProps={{
                iconName: "Add"
              }}
              onClick={() => handleOnGraphClick()}
            />

            <ToolTipButton
              toolTipContent="Close"
              buttonProps={{
                text: "Close",
                onClick: onClose
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  });

import { useContext } from "react";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { DropzoneSectionAzureBlob } from "../../shared-components/DropzoneSectionAzureBlob.tsx";

export const UploadButtons = () => {
  const { setDocData, setUploadScreenSpinner, setUploadFileError } =
    useContext(InboxScreenContext);

  return (
    <DropzoneSectionAzureBlob
      isButton
      onDropRejected={setUploadFileError}
      onDropStarted={() => {
        setDocData(undefined);
        setUploadScreenSpinner(true);
      }}
      onDropFinished={() => {
        setUploadScreenSpinner(false);
      }}
    />
  );
};

import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";

import {
  DirectionalHint,
  GroupedList,
  Heading,
  IconButton,
  IGroupHeaderProps,
  IRenderFunction,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { ClaimAdjustmentDialogForm } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentDialogForm.tsx";
import { isClaimAdjustmentAllowed } from "@modules/acc/screens/claim-adjustment/components/utils.ts";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentHelper } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.ts";
import { ModalKeys } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.types.ts";
import { ClaimSectionHeadings } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { convertClinicalSideToDiagnosisSide } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/active-condition-list/active-condition-list.utils.ts";
import { ConditionModalFormValues } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/Condition.types.ts";
import { ConditionModal } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/ConditionModal.tsx";
import { ConditionContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionContext.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { SideNavContent } from "../SideNavContent.tsx";
import { ClaimAdjustment } from "./ClaimAdjustment.tsx";
import { ClaimAppointmentContext } from "./ClaimAppointmentContext.tsx";
import { ClaimConsultBadges } from "./ClaimConsultBadges.tsx";
import { ConditionViewIndex } from "./ClaimDetailsModel.ts";
import { ClaimReviewCell } from "./ClaimReviewCell.tsx";
import { ConditionDetailsContextMenu } from "./ConditionDetailsContextMenu.tsx";
import { ConditionDischarges } from "./ConditionDischarges.tsx";
import { ConditionOverview } from "./ConditionOverview.tsx";
import { ConditionDetailsModel } from "./ConditionsSidePanelHelper.ts";
import { ConditionVisits } from "./ConditionVisits.tsx";
import { LinkedConditionText } from "./LinkedConditionText.tsx";

interface ConditionDetailProps {
  model: ConditionDetailsModel;
  handleSelectedCondition(condition: ConditionDetailsModel | undefined): void;
  onEditConditionSucceeded(condition: ICondition): void;
  onClose?: () => void;
  onConsultViewAllClicked: () => void;
}

export const ConditionDetails: React.FC<ConditionDetailProps> = observer(
  ({
    model,
    handleSelectedCondition,
    onEditConditionSucceeded,
    onClose,
    onConsultViewAllClicked
  }) => {
    const root = useStores();
    const { routing, acc } = root;
    const theme = useTheme();
    const { clinicalRecord, setClaimAdjustment, isViewOnlyOrDischarged } =
      usePatientRecordScreenContext();

    const [visitCount, setVisitCount] = useState<number>(0);
    const isVisitsExisted = visitCount > 0;

    const sidePanelHelper = useContext(ClaimAppointmentContext);
    const { getCondition, isLinkedCondition } = sidePanelHelper;

    const condition = getCondition(model.condition?.episodeOfCareId);

    const [isLinked, setIsLinked] = useState<boolean>(
      isLinkedCondition(condition?.episodeOfCareId)
    );

    const backToConditionsList = () => {
      if (onClose) onClose();
      handleSelectedCondition(undefined);
    };

    useEffect(() => {
      if (condition) {
        const isLinked = isLinkedCondition(condition.episodeOfCareId);
        setIsLinked(isLinked);
      }
    }, [clinicalRecord.episodeOfCare, condition, isLinkedCondition]);

    const conditionHelper = new ConditionHelper(root, clinicalRecord.id, {
      onSubmit: async (
        values: ConditionModalFormValues,
        helper: ConditionHelper
      ) => {
        await helper.updateCondition(
          values,
          values.referralIn ? clinicalRecord.calendarEvent?.userId : undefined
        );
      },
      initialCondition: condition,
      onSubmitSucceeded: onEditConditionSucceeded,
      sidePanelHelper
    });

    const claim = condition?.claim;

    const claimAdjustmentHelper = useRef(
      claim ? new ClaimAdjustmentHelper(root, claim) : undefined
    );

    const handleEditCondition = () => {
      conditionHelper.setHiddenConditionModal(false);
    };

    const renderHeader: IRenderFunction<IGroupHeaderProps> = (
      props: IGroupHeaderProps,
      defaultRender
    ): JSX.Element | null => {
      if (!props || !defaultRender) {
        return null;
      }

      const renderGroupTitle = (item: IGroupHeaderProps) => {
        const titleStyle = {
          cursor: "pointer",
          fontWeight: !!props?.group?.isCollapsed ? 400 : 600
        };

        switch (props.group?.key) {
          case ClaimSectionHeadings.claimReviews:
            return <span style={titleStyle}>{item.group?.name}</span>;
          case ClaimSectionHeadings.overview:
            const statusText = acc.ref.claimStatuses.keyTextValues.find(
              x => x.key === condition?.claim?.claimStatus
            )?.text;

            return (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                tokens={{ childrenGap: 4 }}
                styles={{
                  root: {
                    width: "100%",
                    fontSize: 14
                  }
                }}
              >
                <span style={titleStyle}>{item.group?.name}</span>
                {condition && (
                  <DataFetcher<boolean>
                    fetch={async root => {
                      const defaultInsurers =
                        await root.practice.getDefaultInsurers();
                      await condition.claim?.loadClaimSchedules();

                      return (
                        condition.claim?.insurerContactId ===
                          defaultInsurers.defaultNzPublicInsurerId &&
                        !condition.claim.canEdit &&
                        !condition.claim.canUpdateClaimNumber
                      );
                    }}
                  >
                    {editDisabled => (
                      <TooltipHost
                        content={
                          editDisabled
                            ? `Cannot edit ${statusText?.toLowerCase()} claim`
                            : undefined
                        }
                      >
                        <IconButton
                          iconProps={{ iconName: "Edit" }}
                          onClick={event => {
                            // Stop menu from closing
                            event.stopPropagation();
                            handleEditCondition();
                          }}
                          disabled={editDisabled || isViewOnlyOrDischarged}
                        />
                      </TooltipHost>
                    )}
                  </DataFetcher>
                )}
              </Stack>
            );
          case ClaimSectionHeadings.discharges:
            return <span style={titleStyle}>{item.group?.name}</span>;
          case ClaimSectionHeadings.claimAdjustment:
            return (
              <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{ childrenGap: 4 }}
                styles={{
                  root: {
                    width: "100%",
                    fontSize: 14
                  }
                }}
              >
                <span style={titleStyle}>{item.group?.name}</span>
                <Navigate
                  styles={{ root: { fontSize: 12 } }}
                  onClick={e => {
                    e.stopPropagation();
                    claimAdjustmentHelper?.current?.setOpenModal(
                      ModalKeys.AdjustmentModal
                    );
                    if (claimAdjustmentHelper?.current)
                      claimAdjustmentHelper.current.setClaimAdjustmentId(
                        undefined
                      );
                  }}
                >
                  {`New ${item.group?.name}`}
                </Navigate>
              </Stack>
            );
          default:
            return (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                tokens={{ childrenGap: 4 }}
                styles={{
                  root: {
                    width: "100%",
                    fontSize: 14,
                    paddingRight: 16
                  }
                }}
              >
                <span style={titleStyle}>{`Consults (${visitCount})`}</span>
                <Navigate
                  disabled={!isVisitsExisted}
                  onClick={() => {
                    onConsultViewAllClicked();
                  }}
                  styles={{ root: { alignSelf: "end" } }}
                >
                  View all
                </Navigate>
              </Stack>
            );
        }
      };

      return defaultRender!({
        ...props,
        onRenderTitle: renderGroupTitle,
        onGroupHeaderClick: () => props.onToggleCollapse!(props.group!)
      });
    };

    const renderCell = (
      depth: number | undefined,
      model: ConditionDetailsModel,
      index: number | undefined
    ): React.ReactNode => {
      switch (index) {
        case ConditionViewIndex.reviews:
          return claim && sidePanelHelper.isAlliedHealthService(claim) ? (
            <ClaimReviewCell claim={claim} visitCount={visitCount} />
          ) : null;
        case ConditionViewIndex.overview:
          return <ConditionOverview model={model} />;
        case ConditionViewIndex.visits:
          return model.condition?.episodeOfCareId &&
            model.condition?.patientId ? (
            <ConditionVisits
              episodeOfCareId={model.condition.episodeOfCareId}
              patientId={model.condition.patientId}
              setVisitCount={setVisitCount}
              appointmentVisits={model.appointmentVisits}
            />
          ) : null;
        case ConditionViewIndex.acc32:
          return claim ? <ClaimAdjustment claim={claim} /> : null;
        case ConditionViewIndex.discharges:
          return <ConditionDischarges model={model} />;
        default:
          return null;
      }
    };

    const getClaimDetailsGroupedListGroups = () => {
      const groups = [];
      if (claim && sidePanelHelper.isAlliedHealthService(claim)) {
        groups.push({
          key: ClaimSectionHeadings.claimReviews,
          name: ClaimSectionHeadings.claimReviews,
          count: 1,
          startIndex: 0
        });
      }

      groups.push({
        key: ClaimSectionHeadings.overview,
        name: ClaimSectionHeadings.overview,
        count: 1,
        startIndex: 1
      });

      groups.push({
        key: ClaimSectionHeadings.consults,
        name: ClaimSectionHeadings.consults,
        count: 1,
        startIndex: 3
      });

      groups.push({
        key: ClaimSectionHeadings.discharges,
        name: ClaimSectionHeadings.discharges,
        count: 1,
        startIndex: 4
      });

      if (
        claim &&
        claim.claimStatus &&
        isClaimAdjustmentAllowed(claim.claimStatus)
      ) {
        groups.push({
          key: ClaimSectionHeadings.claimAdjustment,
          name: ClaimSectionHeadings.claimAdjustment,
          count: 1,
          startIndex: 2
        });
      }

      return groups;
    };

    const getNavTo = (
      claim: Claim | undefined,
      condition: ICondition | undefined
    ) => {
      if (claim) {
        return (
          <Navigate
            styles={{ root: { fontSize: 12 } }}
            to={{
              pathname: routes.claims.management.edit.path({
                id: claim.id
              })
            }}
            state={routing.getStateWithFromQuery()}
          >
            <IconButton iconProps={{ iconName: "Share" }} />
          </Navigate>
        );
      } else if (condition) {
        return (
          <Navigate
            styles={{ root: { fontSize: 12 } }}
            to={{
              pathname: routes.conditions.summary.path({
                id: condition?.episodeOfCareId
              })
            }}
            state={routing.getStateWithFromQuery()}
          >
            <IconButton iconProps={{ iconName: "Share" }} />
          </Navigate>
        );
      }
      return null;
    };

    return (
      <Stack styles={{ root: { height: "100%" } }}>
        <SideNavContent>
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 8 }}
            styles={{ root: { marginBottom: 8, paddingRight: 8 } }}
          >
            <IconButton
              iconProps={{ iconName: "ChevronLeft" }}
              onClick={backToConditionsList}
            />
            <Heading
              variant="section-heading"
              nowrap
              styles={{
                root: {
                  padding: "5px 0",
                  fontSize: 18,
                  marginRight: "auto"
                }
              }}
            >
              <TooltipHost
                directionalHint={DirectionalHint.leftCenter}
                content={model.diagnosis}
              >
                {model.diagnosis}
                {convertClinicalSideToDiagnosisSide(model.side)}
              </TooltipHost>
            </Heading>
            {getNavTo(claim, model.condition)}
            <ConditionDetailsContextMenu
              model={model}
              isNoLinkedConsults={!isVisitsExisted}
              onDelete={backToConditionsList}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 8 }}>
            {condition && (
              <ClaimConsultBadges
                condition={condition}
                clinicalRecord={clinicalRecord}
                showConsultsRemaining
                claimBadgeText={ConditionHelper.getClaimBadgeText(
                  model.condition
                )}
              />
            )}
            {isLinked && (
              <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{ childrenGap: 8 }}
                styles={{
                  root: {
                    background: theme.palette.themeLighterAlt,
                    padding: "8px 12px"
                  }
                }}
              >
                <LinkedConditionText compact />
              </Stack>
            )}
          </Stack>
          <Stack>
            <GroupedList
              styles={{
                root: {
                  selectors: {
                    ".ms-GroupHeader": {
                      marginTop: 15
                    }
                  }
                }
              }}
              compact={true}
              groups={getClaimDetailsGroupedListGroups()}
              items={[model, model, model, model, model]}
              onRenderCell={renderCell}
              groupProps={{
                showEmptyGroups: true,
                onRenderHeader: renderHeader
              }}
            />
          </Stack>
        </SideNavContent>
        {claimAdjustmentHelper.current && claim && (
          <ClaimAdjustmentContext.Provider
            value={claimAdjustmentHelper.current}
          >
            <ClaimAdjustmentDialogForm
              onSubmitSucceeded={claimAdjustment => {
                setClaimAdjustment(claimAdjustment);
                if (claimAdjustmentHelper.current)
                  claimAdjustmentHelper.current.setOpenModal(undefined);
              }}
              claim={claim}
            />
          </ClaimAdjustmentContext.Provider>
        )}
        <ConditionContext.Provider value={conditionHelper}>
          <ConditionModal disabled={claim?.canEdit === false} />
        </ConditionContext.Provider>
      </Stack>
    );
  }
);

import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  CollapsibleCard,
  FontIcon,
  FontSizes,
  Heading,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PatientTreatmentPlanDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof, nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";

import { getTreatmentData } from "../../SOTAP/SOTAP.utils.ts";
import {
  ConditionOption,
  ManagementFormValues
} from "./ManagementForm.Types.ts";

interface ConditionCardProps {
  clinicalRecord: ClinicalRecord;
  setLinkedItem: (value: boolean) => void;
}

export const ConditionCard: React.FC<ConditionCardProps> = observer(
  ({ clinicalRecord, setLinkedItem }) => {
    const theme = useTheme();
    const form = useForm<ManagementFormValues>();
    const {
      values: { linkId, treatmentPlanLinkType }
    } = useFormState<ManagementFormValues>();

    const linkedToEncounter = clinicalRecord.episodeOfCare?.id ?? linkId;
    const nameOf = nameOfFactory<ManagementFormValues>();
    const { clinical, core } = useStores();

    const [conditionInfo, setConditionInfo] = useState<{
      eocText: string | undefined;
      colour: TextBadgeColor | undefined;
    }>();

    const primaryDiagnosis = clinicalRecord.medicalHistories.find(
      x => x.episodeOfCareId === clinicalRecord.openEncounter?.episodeOfCareId
    );

    const getEoCText = useCallback(
      (eocId?: string) => {
        let colour: TextBadgeColor = TextBadgeColor.blue;
        let eocText: string = "No condition linked";

        if (linkedToEncounter || eocId) {
          const condition = clinicalRecord.getConditionByEocId(
            eocId ? eocId : clinicalRecord.openEncounter?.episodeOfCareId
          );

          const stashDiagnosis =
            clinicalRecord?.stashedClinicalData?.diagnoses?.diagnoses;

          const diagnoses =
            stashDiagnosis &&
            (clinicalRecord.clinicalData?.diagnoses
              ? clinicalRecord.clinicalData?.diagnoses.diagnoses
              : clinicalRecord.episodeOfCare?.diagnoses);

          eocText = diagnoses
            ? diagnoses?.find(x => x.isPrimaryDiagnosis)?.diagnosisCode
                ?.originalText ?? "Undiagnosed"
            : condition?.primaryDiagnosis ?? "Undiagnosed";

          return { colour, eocText };
        }
        colour = TextBadgeColor.yellow;
        return { colour, eocText };
      },
      [clinicalRecord, linkedToEncounter]
    );

    const { colour, eocText } = getEoCText();
    useEffect(() => {
      setConditionInfo({ colour, eocText });
    }, [colour, eocText]);

    const getConditionOptions = async () => {
      const eoc = await clinical.getPatientEpisodesOfCare(clinicalRecord.id);

      const filteredEoCs = eoc.filter(eoc => !eoc.discharged);

      const conditions = filteredEoCs.map(episodeOfCare => {
        let diagnosisText: string = "Undiagnosed";
        if (episodeOfCare.diagnoses && episodeOfCare.diagnoses.length > 0) {
          const primaryDiagnosis = episodeOfCare.diagnoses.filter(
            x => x.isPrimaryDiagnosis
          );

          if (primaryDiagnosis && primaryDiagnosis.length > 0) {
            const originalText =
              primaryDiagnosis[0].diagnosisCode?.originalText;
            if (originalText) {
              diagnosisText = originalText;
            }
          }
        }

        let injuryDate: string = "-";
        if (episodeOfCare.injuryDate) {
          injuryDate = `from: ${DateTime.fromISO(
            episodeOfCare.injuryDate
          ).toDayDefaultFormat()}`;
        }

        const coditionOption: ConditionOption = {
          key: episodeOfCare.id,
          text: diagnosisText,
          date: injuryDate
        };

        return coditionOption;
      });

      return conditions;
    };

    const onRenderHeading = () => {
      return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <FontIcon
            iconName="D365TalentHRCore"
            styles={{
              root: {
                color: theme.palette.themePrimary,
                fontSize: FontSizes.size24
              }
            }}
          />
          <Heading variant="section-heading">Condition</Heading>

          <TextBadge badgeColor={conditionInfo?.colour}>
            {conditionInfo?.eocText}
          </TextBadge>
        </Stack>
      );
    };

    const onRenderField = (conditions: ConditionOption[]) => {
      if (conditions.length > 0) {
        const selectedCondition = conditions[0];
        return onRenderOption(selectedCondition);
      } else {
        return null;
      }
    };

    const onRenderOption = (option: ConditionOption) => {
      return (
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 8 }}
          styles={{ root: { width: "100%" } }}
        >
          <Text>{option.text}</Text>
          <Text>{option.date}</Text>
        </Stack>
      );
    };

    const setFormValues = (value: string) => {
      let planAlreadyForCondition: PatientTreatmentPlanDataItemDto | undefined;
      if (!core.hasPermissions(Permission.MultiProviderClaimsAllowed)) {
        planAlreadyForCondition =
          clinicalRecord.stashedClinicalData?.patientTreatmentPlan?.treatmentPlans?.find(
            x => x.linkId === value && !x.isDeleted
          );
      } else {
        planAlreadyForCondition =
          clinicalRecord.stashedClinicalData?.patientTreatmentPlan?.treatmentPlans?.find(
            x =>
              x.linkId === value &&
              x.businessRoleCode ===
                clinicalRecord.openEncounter?.businessRole &&
              !x.isDeleted
          );
      }

      if (planAlreadyForCondition) {
        const treatments = getTreatmentData(
          planAlreadyForCondition?.treatments,
          false
        );

        const treatmentsBase = treatments.map(x => x.treatment);

        const goals =
          planAlreadyForCondition?.goals?.map(goal => ({
            ...goal,
            startDate: DateTime.jsDateFromISO(goal.startDate),
            endDate: DateTime.jsDateFromISO(goal.endDate),
            achievedDate: DateTime.jsDateFromISO(goal.achievedDate)
          })) || [];

        const planHasOtherTreatments =
          planAlreadyForCondition?.otherTreatments !== undefined;

        form.batch(() => {
          form.change(nameof("planTreatments"), treatments);
          form.change(nameof("planTreatmentsBase"), treatmentsBase);
          form.change(nameof("goals"), goals);
          form.change(nameof("planHasOtherTreatments"), planHasOtherTreatments);
          form.change(
            nameof("planOtherTreatments"),
            planAlreadyForCondition?.otherTreatments
          );
          form.change(
            nameof("planEducationOptions"),
            planAlreadyForCondition?.educationOptions
          );
          form.change(
            nameof("planEducationComment"),
            planAlreadyForCondition?.educationComment
          );
          form.change(
            nameof("planOtherEducationComment"),
            planAlreadyForCondition?.otherEducationComment
          );
          form.change(nameof("planReview"), planAlreadyForCondition?.plan);
        });
      } else {
        form.change(nameof("treatmentPlanLinkType"), "EOC");
      }
    };

    return (
      <CollapsibleCard
        heading="Condition"
        headingLevel="section-heading"
        iconName="D365TalentHRCore"
        openOnRender={!linkedToEncounter}
        onRenderHeading={onRenderHeading}
      >
        <DataFetcher<ConditionOption[]>
          fetch={async () => {
            await clinicalRecord.loadConditions();
            return await getConditionOptions();
          }}
          refetchId={`${primaryDiagnosis?.id} - ${primaryDiagnosis?.diagnosisDate}`}
          fallback={<Spinner />}
        >
          {conditions => (
            <Stack tokens={{ childrenGap: 8, maxWidth: 630 }}>
              {linkId &&
                treatmentPlanLinkType === "EOC" &&
                linkId !== clinicalRecord.openEncounter?.episodeOfCareId && (
                  <MessageBar messageBarType={MessageBarType.info}>
                    The condition will be linked to the ongoing consult on save
                  </MessageBar>
                )}
              <OptionsSelectField
                onRenderOption={onRenderOption}
                onRenderFieldContent={onRenderField}
                hideSearchOption={true}
                hideClearButton={true}
                multiSelect={false}
                disabled={!!linkedToEncounter}
                options={conditions}
                name={nameOf("linkId")}
                placeholder="Select a condition"
              />
              <FieldSpy
                name={nameof("linkId")}
                onChange={(value: string) => {
                  if (value) {
                    setFormValues(value);
                    const { colour, eocText } = getEoCText(value);
                    setConditionInfo({ eocText, colour });
                    setLinkedItem(true);
                  }
                }}
              />
            </Stack>
          )}
        </DataFetcher>
      </CollapsibleCard>
    );
  }
);

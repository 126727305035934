import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  ButtonsGroupOption,
  confirm,
  dataAttribute,
  DataAttributes,
  FontSizes,
  Heading,
  HideStack,
  IconButton,
  Link,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ConflictError } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import { InboxDocumentDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import {
  FormSubmitButtonsProps,
  submitButtonsText
} from "@ui-components/form/submission-form/FormSubmitButtons.types.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { getPropsForDeleteFileConfirmation } from "../../user-inbox/components/user-inbox-form/UserInboxActionForm.tsx";
import { UserInboxActionFormLabels } from "../../user-inbox/components/user-inbox-form/UserInboxActionForm.types.ts";
import {
  UploadDocFormLabels,
  UploadDocFormProps,
  UploadDocFormValues
} from "./UploadDocForm.types.ts";
import { UploadDocFormValidator } from "./UploadDocFormValidator.tsx";

export const UploadDocFormBase: React.FC<UploadDocFormProps> = observer(
  ({
    isDisabled,
    document,
    onRemove,
    onClose,
    onSubmitSucceeded,
    removeDocAfterSubmit,
    submitButtonProps,
    cancelButtonProps,
    confirmOnNewAssignee
  }) => {
    const {
      core,
      correspondence,
      userExperience,
      notification,
      practice,
      inbox
    } = useStores();

    const {
      isFormActive,
      setActiveForm,
      canDeleteDocument,
      setSelectedInboxDocKey
    } = useContext(InboxScreenContext);

    // Forces the document date to never exceed
    const clampDocumentDate = (
      receivedDate: Date | undefined,
      documentDate: Date | undefined
    ) => {
      if (documentDate && receivedDate && documentDate > receivedDate) {
        return receivedDate;
      }

      return documentDate ?? receivedDate ?? DateTime.jsDateNow();
    };

    const initialValues: UploadDocFormValues = {
      id: document.id,
      documentDetailId: document.documentDetailId,
      docName: document.name!,
      assignTo: document.assignedToUserId,
      destination: UploadDocFormLabels.Inbox,
      patient: document.patientId,
      docExtension: document.docExtension!,
      fromContactId: document.fromContactId,
      documentDate: clampDocumentDate(
        document.receivedDate,
        document.documentDate
      ),
      correspondenceType: document.correspondenceType,
      checkedBy: document.checkedBy
    };

    const nameOf = nameOfFactory<UploadDocFormValues>();

    const handleOnCancel = () => {
      setActiveForm(false);
      if (onClose) onClose();
    };

    const theme = useTheme();

    const options: ButtonsGroupOption<string>[] = [
      {
        key: UploadDocFormLabels.Inbox,
        text: UploadDocFormLabels.Inbox,
        disabled: isDisabled
      },
      {
        key: UploadDocFormLabels.ClinicalRecord,
        text: UploadDocFormLabels.ClinicalRecord,
        disabled: isDisabled
      }
    ];

    const onSubmit = async (values: UploadDocFormValues) => {
      const baseRequest: InboxDocumentDto = {
        id: values.id,
        receivedDate:
          document.receivedDate &&
          DateTime.jsDateToISODate(document.receivedDate),
        name: values.docName!,
        documentDetailId: values.documentDetailId,
        extension: values.docExtension!,
        patientId: values.patient ?? "",
        fromContactId: values.fromContactId ?? "",
        ...(values.documentDate && {
          documentDate: DateTime.jsDateToISODate(values.documentDate)
        }),
        correspondenceType: values.correspondenceType!,
        containerUri: document.containerUri,
        containerToken: document.containerToken,
        changeLog: document.changeLog,
        assignedToUserId: values.assignTo ?? "",
        eTag: document.eTag
      };

      try {
        if (values.destination === UploadDocFormLabels.Inbox) {
          if (baseRequest.assignedToUserId !== document.dto.assignedToUserId) {
            setSelectedInboxDocKey(undefined);
          }
          if (submitButtonProps?.text === UploadDocFormLabels.Update) {
            await inbox.updateAssignUserInboxDocument(baseRequest, {
              removeDocFromMap: removeDocAfterSubmit,
              assignedToUserIdChanged:
                document.dto.assignedToUserId !== baseRequest.assignedToUserId
            });
          } else {
            await inbox.updateAssignInboxDocument(baseRequest, {
              removeDocFromMap: removeDocAfterSubmit,
              assignedToUserIdChanged:
                document.dto.assignedToUserId !== baseRequest.assignedToUserId
            });
          }
        }

        if (values.destination === UploadDocFormLabels.ClinicalRecord) {
          const checkedBy = values.checkedByAnotherFlag
            ? values.checkedBy
            : values.assignTo;
          await inbox.updateStoreInboxDocument({
            inboxDocument: {
              ...baseRequest,
              checkedBy,
              checkedDateTime: DateTime.now().toISO()
            },
            storeInDestination: values.storeIn!
          });
        }
      } catch (error) {
        const isAlreadyActioned = error instanceof ConflictError;
        if (isAlreadyActioned) {
          const isConfirmed = await confirm({
            confirmButtonProps: {
              text: UserInboxActionFormLabels.Refresh
            },
            cancelButtonProps: {
              text: UserInboxActionFormLabels.Cancel,
              autoFocus: true //Prevents close button to be focused by default when dialog appears
            },
            dialogContentProps: {
              title: UserInboxActionFormLabels.FileMoved,
              subText: UserInboxActionFormLabels.ConflictErrorMessage,
              showCloseButton: true
            },
            maxWidth: 640
          });

          if (isConfirmed) {
            window.location.reload();
          }
        }
        throw new Error(error.message);
      }
    };

    const handleOnRemoveClick = () => {
      confirm(getPropsForDeleteFileConfirmation(initialValues.docName)).then(
        confirmed => {
          if (confirmed) {
            inbox
              .deleteInboxDocument(
                initialValues.id,
                initialValues.documentDetailId
              )
              .then(() => {
                handleOnCancel();
                if (onRemove)
                  onRemove(initialValues.id, initialValues.documentDetailId);
              });
          }
        }
      );

      return true;
    };

    const patientLabel = userExperience.localisedConfig("patientDisplay", {
      capitalizeFirst: true
    });

    const buttonsProps = {
      styles: {
        root: {
          padding: "24px 8px",
          margin: 0
        }
      },
      cancelButtonProps: {
        id: "close-inbox-upload-form",
        ...cancelButtonProps
      },
      onCancel: handleOnCancel,
      submitButtonProps: {
        text: UploadDocFormLabels.Save,
        iconProps: { hidden: true },
        ...submitButtonProps
      },
      ...(confirmOnNewAssignee && {
        promptOnSubmit: true,
        extraPromptConditionOnSubmit: (form: FormApi<UploadDocFormValues>) => {
          const assignTo = form.getFieldState(nameOf("assignTo"));
          return assignTo?.value !== document.assignedToUserId;
        },
        hideButtonsSeparator: true,
        submitConfirmProps: {
          dialogContentProps: {
            subText: UploadDocFormLabels.AssigneeChangedConfirmationSubText
          },
          confirmButtonProps: {
            text: UploadDocFormLabels.AssigneeChangedConfirmText
          },
          cancelButtonProps: {
            text: submitButtonsText.cancel
          }
        }
      })
    } as FormSubmitButtonsProps<UploadDocFormValues>;

    if (canDeleteDocument(document))
      buttonsProps.cancelButtonProps = {
        ...buttonsProps.cancelButtonProps,
        items: [
          {
            key: "removeDocument",
            text: UploadDocFormLabels.Delete,
            onClick: handleOnRemoveClick
          }
        ],
        styles: { root: { minWidth: 115 } }
      };

    return (
      <SubmissionForm<UploadDocFormValues>
        formName="upload-doc"
        styles={{
          root: {
            borderRadius: 2,
            border: isFormActive
              ? `1px solid ${theme.palette.neutralLighter}`
              : 0
          }
        }}
        initialValues={initialValues}
        onSubmitSucceeded={(values, form) => {
          if (values.destination === UploadDocFormLabels.Inbox) {
            core
              .getUser(values.assignTo!)
              .then(user =>
                notification.success(
                  `${UploadDocFormLabels.AssignedFileFeedback} ${user.fullName}`
                )
              );
          }
          if (values.destination === UploadDocFormLabels.ClinicalRecord) {
            practice
              .getContact(values.patient!)
              .then(contact =>
                notification.success(
                  `${UploadDocFormLabels.SavedFileFeedback} ${contact.name}`
                )
              );

            if (onRemove) {
              onRemove(values.id, values.documentDetailId);
            }
          }
          form.restart(values);
          onSubmitSucceeded && onSubmitSucceeded(values, form);
        }}
        validate={(values: UploadDocFormValues) => {
          const validator = new UploadDocFormValidator({
            destination: values.destination,
            assignTo: values.assignTo,
            initialAssignedToUserId: document.assignedToUserId,
            documentDate: document.receivedDate
          });
          return validator.validate(values);
        }}
        hideButtons={isDisabled && !isFormActive}
        onSubmit={onSubmit}
        buttonsProps={buttonsProps}
      >
        {({ form, values }) => {
          return (
            <Stack
              {...dataAttribute(DataAttributes.Element, "upload-doc-form")}
              className={
                isDisabled && !isFormActive ? "form-disabled" : undefined
              }
              tokens={{ childrenGap: 8 }}
              styles={(_props, { palette }) => ({
                root: {
                  padding: 8,
                  backgroundColor:
                    isDisabled && !isFormActive
                      ? palette.neutralLighterAlt
                      : undefined
                }
              })}
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <TextInputField
                  name={nameOf("docName")}
                  required
                  onRenderLabel={() => (
                    <Stack
                      horizontal
                      horizontalAlign="space-between"
                      styles={{ root: { width: "100%" } }}
                    >
                      <Heading
                        hasAsterisk
                        styles={{
                          root: {
                            color:
                              isDisabled && !isFormActive
                                ? theme.palette.neutralTertiary
                                : undefined
                          }
                        }}
                      >
                        {UploadDocFormLabels.Subject}
                      </Heading>
                      {isDisabled && !isFormActive && (
                        <Link
                          {...dataAttribute(
                            DataAttributes.Element,
                            "upload-docform-edit-btn"
                          )}
                          onClick={event => {
                            event.preventDefault();
                            setActiveForm(true);
                          }}
                          as="button"
                        >
                          {UploadDocFormLabels.Edit}
                        </Link>
                      )}
                    </Stack>
                  )}
                  disabled={isDisabled && !isFormActive}
                />
                <ContactPickerField
                  iconName="Search"
                  required
                  filter={{
                    types: [ContactType.Patient]
                  }}
                  name={nameOf("patient")}
                  label={patientLabel}
                  disabled={isDisabled && !isFormActive}
                />
                <Stack
                  horizontal
                  verticalAlign="baseline"
                  tokens={{ childrenGap: 8 }}
                >
                  <StaticPickerField
                    name={nameOf("correspondenceType")}
                    label={UploadDocFormLabels.CorrespondenceType}
                    required
                    fetchDataSource={() =>
                      correspondence.ref.correspondenceTypes.fetchAsKeyNameValues()
                    }
                    disabled={isDisabled && !isFormActive}
                    styles={{
                      itemsWrapper: { maxWidth: 145 },
                      text: { minWidth: 0 }
                    }}
                    fieldItemStyles={{
                      root: { flexGrow: 1, flexBasis: 0 }
                    }}
                  />
                  <DatePickerField
                    maxDate={document.receivedDate ?? DateTime.jsDateNow()}
                    name={nameOf("documentDate")}
                    label={UploadDocFormLabels.DocumentDate}
                    disabled={isDisabled && !isFormActive}
                  />
                </Stack>
                <Stack tokens={{ childrenGap: 8 }}>
                  <ButtonsGroupSingleChoiceField
                    label={UploadDocFormLabels.Destination}
                    required
                    name={nameOf("destination")}
                    options={options}
                    styles={{ root: { fontSize: FontSizes.size14 } }}
                    disabled={isDisabled}
                  />
                  <FieldCondition
                    when={nameOf("destination")}
                    is={UploadDocFormLabels.ClinicalRecord}
                  >
                    <DropdownField
                      name={nameOf("storeIn")}
                      withNoEmptyOption
                      options={inbox.ref.storeInDestinationsTypes.keyTextValues}
                      disabled={isDisabled && !isFormActive}
                    />
                  </FieldCondition>
                  <FieldCondition
                    when={nameOf("destination")}
                    is={UploadDocFormLabels.Inbox}
                  >
                    <BookableUserPickerField
                      name={nameOf("assignTo")}
                      label={UploadDocFormLabels.AddressedTo}
                      iconName="Search"
                      disabled={isDisabled && !isFormActive}
                      required
                      useBusinessRoleClass={true}
                    />
                  </FieldCondition>
                  <FieldCondition
                    when={nameOf("destination")}
                    is={UploadDocFormLabels.ClinicalRecord}
                  >
                    <BookableUserPickerField
                      name={nameOf("assignTo")}
                      label={
                        !values.checkedByAnotherFlag
                          ? UploadDocFormLabels.AddressedToAndCheckedBy
                          : UploadDocFormLabels.AddressedTo
                      }
                      iconName="Search"
                      disabled={isDisabled && !isFormActive}
                    />
                    <ToggleField
                      styles={{
                        root: { marginBottom: 0 }
                      }}
                      onText={UploadDocFormLabels.CheckedByAnotherOrUnchecked}
                      offText={UploadDocFormLabels.CheckedByAnotherOrUnchecked}
                      name={nameOf("checkedByAnotherFlag")}
                    />
                    <FieldSpy
                      name={nameOf("checkedByAnotherFlag")}
                      onChange={checked => {
                        if (checked) {
                          form.change(nameOf("checkedBy"), undefined);
                        }
                      }}
                    />
                    <HideStack when={!values.checkedByAnotherFlag}>
                      <Stack>
                        <Stack horizontal>
                          <Heading styles={{ root: { margin: "auto 0" } }}>
                            {UploadDocFormLabels.CheckedBy}
                          </Heading>
                          <TooltipHost content="Document remains ‘Unchecked’ unless ‘Checked by’ provider is selected">
                            <IconButton
                              iconProps={{
                                iconName: "Info",
                                styles: {
                                  root: { color: theme.palette.neutralPrimary }
                                }
                              }}
                            />
                          </TooltipHost>
                        </Stack>

                        <Stack>
                          <BookableUserPickerField
                            iconName="Search"
                            name={nameOf("checkedBy")}
                            inputProps={{
                              placeholder: "Unchecked if empty"
                            }}
                          />
                        </Stack>
                      </Stack>
                    </HideStack>
                  </FieldCondition>
                </Stack>
                <ContactPickerField
                  iconName="Search"
                  filter={{
                    types: [
                      ContactType.Patient,
                      ContactType.Organisation,
                      ContactType.Individual
                    ]
                  }}
                  name={nameOf("fromContactId")}
                  label={UploadDocFormLabels.From}
                  disabled={isDisabled && !isFormActive}
                />
              </Stack>
            </Stack>
          );
        }}
      </SubmissionForm>
    );
  }
);

export const UploadDocForm = withFetch(
  x => [
    x.inbox.ref.storeInDestinationsTypes.load(),
    x.correspondence.ref.correspondenceTypes.load()
  ],
  UploadDocFormBase
);

import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useField, useForm } from "react-final-form";

import {
  Heading,
  MessageBar,
  MessageBarType,
  NativeList,
  Separator,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimSectionHeadings } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { CurrentStatusAndPrognosisFormValues } from "@shared-types/acc/current-status-prognosis-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import {
  TextFieldActionButtonProps,
  TextInputField
} from "@ui-components/form/TextInputField.tsx";

import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { CurrentStatusAndPrognosisFormValidator } from "../validators/CurrentStatusAndPrognosisFormValidator.tsx";
import {
  ClaimAdjustmentFormLabels,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";
import { getGoalsTextList } from "./utils.ts";

const detailsFields: (keyof CurrentStatusAndPrognosisFormValues)[] = [
  "expectedTreatmentCompletionDate"
];

export const CurrentStatusAndPrognosisFormSectionBase: FC = observer(() => {
  const {
    claim,
    claimAdjustment,
    is12MonthsSinceAccidentDate,
    isNonStandardCodeSelected,
    isHandSplintingOver300,
    showCurrentStatusAndPrognosisSection,
    isSubsequentVisitRequest
  } = useClaimAdjustmentContext();

  const form = useForm<ClaimAdjustmentFormValues>();

  const { acc } = useStores();
  const { selectedMenuItemId } = useCardFormLayoutContext();

  const {
    input: { value: originalAccidentEvent }
  } = useField("originalAccidentEvent", {
    subscription: { value: true }
  });

  const {
    input: { value: currentConditionOrDiagnosis }
  } = useField("currentConditionOrDiagnosis", {
    subscription: { value: true }
  });

  const {
    input: { value: signAndSymptom }
  } = useField("signAndSymptom", {
    subscription: { value: true }
  });

  const getSideText = (sideCode: string) => {
    return acc.ref.diagnosisSides.keyTextValues.find(
      side => side.key === sideCode
    )?.text;
  };

  const copyCauseFromACC45 = () => {
    const text = originalAccidentEvent ? `${originalAccidentEvent}\n` : "";
    if (claim.causeOfAccident) {
      form.change(
        claimAdjustmentFormNameOf("originalAccidentEvent"),
        `${text}${claim.causeOfAccident}\n`
      );
    }
  };

  const copyDiagnosesFromACC45 = () => {
    const text = currentConditionOrDiagnosis
      ? `${currentConditionOrDiagnosis}\n`
      : "";

    let newText: string = "";

    claim.claimDiagnosis?.forEach(x => {
      newText += `${x.terminology?.text ? x.terminology?.text : ""} - ${
        x.diagnosisSide ? getSideText(x.diagnosisSide) : ""
      }\n`;
    });

    if (newText) {
      form.change(
        claimAdjustmentFormNameOf("currentConditionOrDiagnosis"),
        `${text}${newText}`
      );
    }
  };

  const copyGoalsFromEpisodeOfCare = () => {
    const text = signAndSymptom ? `${signAndSymptom}\n` : "";
    let newText: string = "";
    acc
      .getTreatmentPlanByClaimId(
        claim.id,
        claim.patient!.id,
        form.getState().values.providerContractType
      )
      .then(treatmentPlan => {
        if (treatmentPlan) {
          newText = getGoalsTextList(treatmentPlan);

          if (newText.length) {
            form.change(
              claimAdjustmentFormNameOf("signAndSymptom"),
              `${text}${newText}`
            );
          }
        }
      });
  };

  const copyCause: TextFieldActionButtonProps = {
    linkProps: {
      onClick: () => copyCauseFromACC45(),
      children: ClaimAdjustmentFormLabels.copyFromACC45
    }
  };

  const copyDiagnoses: TextFieldActionButtonProps = {
    linkProps: {
      onClick: () => copyDiagnosesFromACC45(),
      children: ClaimAdjustmentFormLabels.copyFromACC45
    }
  };

  const copyGoals: TextFieldActionButtonProps = {
    linkProps: {
      onClick: () => copyGoalsFromEpisodeOfCare(),
      children: ClaimAdjustmentFormLabels.copyGoals
    }
  };

  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.currentStatusAndPrognosis}
      errorProps={{ fields: detailsFields }}
      openOnRender={
        selectedMenuItemId === ClaimsAdjustmentCardIds.currentStatusAndPrognosis
      }
      heading={ClaimSectionHeadings.currentStatusAndPrognosis}
      iconName="DocumentSet"
      statusValidator={new CurrentStatusAndPrognosisFormValidator()}
      visible={
        showCurrentStatusAndPrognosisSection(isNonStandardCodeSelected()) &&
        !claimAdjustment.verballyApproved
      }
    >
      <MessageBar messageBarType={MessageBarType.warning}>
        Please provide more information because.
        <NativeList>
          {claimAdjustment?.isVoluntarySubmission && (
            <li>This is a voluntary request</li>
          )}
          {isSubsequentVisitRequest && (
            <li>This isn't the first request for additional treatment</li>
          )}
          {isNonStandardCodeSelected() && <li>Non-standard codes were used</li>}
          {is12MonthsSinceAccidentDate && (
            <li>This request is outside 12 months of the date of injury</li>
          )}
          {isHandSplintingOver300 && <li>Handsplinting over $300 (Ex GST)</li>}
        </NativeList>
      </MessageBar>
      <Separator styles={{ root: { margin: "24px 0" } }} />
      <Stack tokens={{ childrenGap: 8 }}>
        <TextInputField
          required
          label={ClaimAdjustmentFormLabels.originalAccidentEvent}
          name={claimAdjustmentFormNameOf("originalAccidentEvent")}
          multiline
          actionButton={copyCause}
          resizable={false}
          rows={4}
          maxLength={2000}
        />
        <TextInputField
          required
          label={ClaimAdjustmentFormLabels.currentConditionOrDiagnosis}
          name={claimAdjustmentFormNameOf("currentConditionOrDiagnosis")}
          multiline
          actionButton={copyDiagnoses}
          resizable={false}
          rows={4}
          maxLength={2000}
          placeholder={
            ClaimAdjustmentFormLabels.currentConditionOrDiagnosisPlaceholder
          }
        />
        <TextInputField
          required
          label={ClaimAdjustmentFormLabels.causeOfCurrentCondition}
          name={claimAdjustmentFormNameOf("causeOfCurrentCondition")}
          multiline
          resizable={false}
          rows={4}
          maxLength={2000}
          placeholder={
            ClaimAdjustmentFormLabels.causeOfCurrentConditionPlaceholder
          }
        />
        <TextInputField
          required
          label={ClaimAdjustmentFormLabels.causeOfNotResolvedCondition}
          name={claimAdjustmentFormNameOf("causeOfNotResolvedCondition")}
          multiline
          resizable={false}
          rows={4}
          maxLength={2000}
        />
      </Stack>
      <Separator styles={{ root: { margin: "24px 0" } }} />
      <Stack tokens={{ childrenGap: 8 }}>
        <Heading variant="section-heading-light">
          Treatment request details
        </Heading>
        <TextInputField
          required
          label={ClaimAdjustmentFormLabels.signAndSymptom}
          name={claimAdjustmentFormNameOf("signAndSymptom")}
          actionButton={copyGoals}
          multiline
          resizable={false}
          rows={4}
          maxLength={2000}
        />
        <TextInputField
          required
          label={ClaimAdjustmentFormLabels.proposedTreatment}
          name={claimAdjustmentFormNameOf("proposedTreatment")}
          multiline
          resizable={false}
          rows={4}
          maxLength={2000}
        />
        <DatePickerField
          name={claimAdjustmentFormNameOf("expectedTreatmentCompletionDate")}
          label={ClaimAdjustmentFormLabels.expectedTreatmentCompletionDate}
          required
          minDate={DateTime.now().minus({ days: 6 }).toJSDate()}
          validateOnInitialize={true}
        />
        <TextInputField
          required
          label={ClaimAdjustmentFormLabels.managementPlan}
          name={claimAdjustmentFormNameOf("managementPlan")}
          multiline
          resizable={false}
          rows={4}
          maxLength={2000}
          placeholder={ClaimAdjustmentFormLabels.managementPlanPlaceholder}
        />
      </Stack>
    </ClaimCard>
  );
});

export const CurrentStatusAndPrognosisFormSection = withFetch(
  x => [x.acc.ref.diagnosisSides.load()],
  CurrentStatusAndPrognosisFormSectionBase
);

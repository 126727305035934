import { PropsWithChildren, ReactNode } from "react";

import { Tile, TopBarWrapper } from "@bps/fluent-ui";

import { ObservationsResponsiveHeader } from "./ObservationsResponsiveHeader.tsx";

interface ObservationHeaderWrapperProps
  extends PropsWithChildren<{
    heading?: string;
    noGap?: boolean;
    pivotTab?: ReactNode;
    onClose: () => void;
  }> {}

export const ObservationsHeaderWrapper: React.FC<
  ObservationHeaderWrapperProps
> = props => {
  return (
    <TopBarWrapper
      styles={{
        root: {
          gap: props.noGap ? 0 : 16,
          margin: props.noGap || !props.onClose ? 0 : -16
        },
        content: {
          display: "flex"
        },
        subComponentStyles: {
          topBarStyles: {
            root: {
              height: "auto"
            }
          }
        }
      }}
      header={
        <ObservationsResponsiveHeader
          heading={props.heading}
          pivotTab={props.pivotTab}
          onClose={props.onClose}
        />
      }
    >
      <Tile
        styles={{
          root: {
            padding: props.noGap ? 0 : 16,
            width: "100%",
            height: "100%",

            overflowY: "auto"
          }
        }}
      >
        {props.children}
      </Tile>
    </TopBarWrapper>
  );
};

import { observer } from "mobx-react-lite";

import { dataAttribute, DataAttributes, Spinner } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { PromiseObservableButton } from "@ui-components/PromiseObservableButton/PromiseObservableButton.tsx";

import { ConditionConsultsExportDialogProps } from "./ConditionConsultsExportDialog.tsx";

type ConditionConsultsExportButtonProps = Omit<
  ConditionConsultsExportDialogProps,
  "diagnosis" | "claimBadgeText" | "patientName" | "patientId" | "providerId"
> & {
  onClick: () => void;
  clinicalRecord: ClinicalRecord;
  condition?: ICondition;
};

export const ConditionConsultsExportButton: React.FC<ConditionConsultsExportButtonProps> =
  observer(props => {
    const {
      onClick,
      claimId,
      claimNumber,
      clinicalRecord,
      episodeOfCareId,
      condition
    } = props;

    const patientId = clinicalRecord.patient?.id ?? "";
    const providerId = clinicalRecord.calendarEvent?.userId ?? "";

    const openConsultsExportDialog = () => {
      onClick();
    };

    const {
      onExportToEditorButtonClick,
      getInitialItems,
      onExportToPdfButtonClick
    } = usePatientRecordScreenContext();

    return (
      <DataFetcher
        fetch={async () => await getInitialItems(patientId, episodeOfCareId)}
        fallback={<Spinner />}
      >
        {encounters => {
          return (
            <PromiseObservableButton
              iconProps={{ iconName: "export" }}
              borderless
              text="Export"
              onClick={openConsultsExportDialog}
              items={[
                {
                  ...dataAttribute(DataAttributes.Element, "export-to-pdf"),
                  key: "export-to-pdf",
                  text: "Export all consults to PDF",
                  onClick: () =>
                    onExportToPdfButtonClick({
                      claimId,
                      claimNumber,
                      diagnosis: condition?.primaryDiagnosis,
                      episodeOfCareId,
                      patientId,
                      providerId,
                      encounters
                    })
                },
                {
                  ...dataAttribute(DataAttributes.Element, "export-to-editor"),
                  key: "export-to-editor",
                  text: "Export all consults to text editor",
                  onClick: () =>
                    onExportToEditorButtonClick({
                      claimId,
                      claimNumber,
                      episodeOfCareId,
                      patientId,
                      diagnosis: condition?.primaryDiagnosis,
                      providerId,
                      encounters
                    })
                }
              ]}
              {...dataAttribute(DataAttributes.Element, "export-consults")}
            />
          );
        }}
      </DataFetcher>
    );
  });

import { useState } from "react";

import {
  ActionButton,
  DetailsList,
  FontIcon,
  FontSizes,
  Heading,
  IColumn,
  Link,
  Separator,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ClinicalReminderReason } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";

import { ClinicalReminderPreferenceDialog } from "./ClinicalReminderPreferenceDialog.tsx";
import { nameOfCommPreferences } from "./CommunicationPreferencesForm.tsx";

export const ClinicalReminderPreferenceFields: React.FC = () => {
  const { comms } = useStores();

  const [
    showClinicalReminderPreferenceDialog,
    setShowClinicalReminderPreferenceDialog
  ] = useState<boolean>(false);

  const [selectedClinicalReminder, setSelectedClinicalReminder] = useState<
    ClinicalReminderReason | undefined
  >(undefined);

  const columns: IColumn[] = [
    {
      name: "Reason",
      key: "reason",
      minWidth: 100,
      onRender: (clinicalReminderReason: ClinicalReminderReason) => {
        return (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <Link
              onClick={() => {
                setShowClinicalReminderPreferenceDialog(true);
                setSelectedClinicalReminder(clinicalReminderReason);
              }}
            >
              {`${clinicalReminderReason.reason} `}
            </Link>
            {clinicalReminderReason.clinicallySignificant && (
              <TextBadge
                badgeColor={TextBadgeColor.blue}
                badgeSize={TextBadgeSize.small}
              >
                <FontIcon
                  iconName="UserOptional"
                  styles={{
                    root: {
                      fontSize: FontSizes.size16,

                      verticalAlign: "middle"
                    }
                  }}
                />
              </TextBadge>
            )}
          </Stack>
        );
      }
    },
    {
      name: "Default Interval",
      key: "defaultInterval",
      minWidth: 120,
      onRender: clinicalReminderReason => {
        return (
          <Text>{`${clinicalReminderReason.interval} ${clinicalReminderReason.frequency}`}</Text>
        );
      }
    }
  ];

  const items = comms.clinicalReminderPreference?.clinicalReminderReasons ?? [];

  return (
    <>
      <Stack tokens={{ childrenGap: 8 }}>
        <Separator styles={{ root: { marginBottom: 24 } }} />
        <Heading variant="section-heading-light">Clinical reminders</Heading>
        <CheckboxField
          label="Allow free-text reminders"
          name={nameOfCommPreferences("allowReminderFreeText")}
        />
        <DetailsList
          styles={{
            contentWrapper: {
              maxHeight: "40vh",
              overflowX: "hidden"
            }
          }}
          items={items}
          columns={columns}
          cellStyleProps={{
            cellLeftPadding: 0,
            cellRightPadding: 7,
            cellExtraRightPadding: 0
          }}
        />
        <ActionButton
          iconProps={{ iconName: "Add" }}
          title="Add another"
          onClick={() => {
            setShowClinicalReminderPreferenceDialog(true);
          }}
          styles={{ root: { width: 130 } }}
        >
          Add another
        </ActionButton>
      </Stack>
      {showClinicalReminderPreferenceDialog && (
        <ClinicalReminderPreferenceDialog
          selectedClinicalReminder={selectedClinicalReminder}
          onDismiss={() => {
            setShowClinicalReminderPreferenceDialog(false);
            setSelectedClinicalReminder(undefined);
          }}
        />
      )}
    </>
  );
};

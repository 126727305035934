import { observer } from "mobx-react-lite";
import { FC } from "react";

import { IColumn, NoDataTile, Spinner, Text } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { RecentPatientDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { TakeValue } from "../RecentPatientsScreen.tsx";
import { RecentPatientsAreaCell } from "./RecentPatientsAreaCell.tsx";
import { RecentPatientsDetailsCell } from "./RecentPatientsDetailsCell.tsx";

type RecentPatientsListProps = {
  take: TakeValue;
};

export const RecentPatientsList: FC<RecentPatientsListProps> = observer(
  ({ take }) => {
    const root = useStores();
    const {
      practice: { getContact }
    } = root;

    const columns: IColumn[] = [
      {
        name: "When",
        fieldName: nameof<RecentPatientDto>("eventTime"),
        key: nameof<RecentPatientDto>("eventTime"),
        onRender: (item: RecentPatientDto) => (
          <Text>
            {DateTime.fromISOOrNow(item.eventTime).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT_REVERSED
            )}
          </Text>
        ),
        minWidth: 180,
        maxWidth: 180
      },
      {
        name: "Patient",
        fieldName: nameof<RecentPatientDto>("patientId"),
        key: nameof<RecentPatientDto>("patientId"),
        onRender: (item: RecentPatientDto) => (
          <DataFetcher<Contact>
            fetch={async () => await getContact(item.patientId)}
            fallback={<Spinner />}
          >
            {contact => {
              return <Text>{contact.reversedName}</Text>;
            }}
          </DataFetcher>
        ),
        minWidth: 200,
        maxWidth: 320
      },
      {
        name: "Event",
        fieldName: nameof<RecentPatientDto>("eventType"),
        key: nameof<RecentPatientDto>("eventType"),
        onRender: (item: RecentPatientDto) => (
          <RecentPatientsAreaCell recentPatient={item} />
        ),
        minWidth: 200,
        maxWidth: 320
      },
      {
        name: "Details",
        fieldName: nameof<RecentPatientDto>("eventVerb"),
        key: nameof<RecentPatientDto>("eventVerb"),
        minWidth: 200,
        onRender: (item: RecentPatientDto) => (
          <RecentPatientsDetailsCell recentPatient={item} />
        )
      }
    ];

    return (
      <DataFetcher
        refetchId={take}
        fetch={({ userExperience }) =>
          userExperience.getRecentPatients({
            take: Number(take)
          })
        }
        noExceptionsHandlers
      >
        {(result, loading, error) =>
          loading || error || result?.results.length ? (
            <ShimmeredDetailsList
              errorMessage={error?.message}
              enableShimmer={loading}
              columns={columns}
              items={result?.results || []}
            />
          ) : (
            <NoDataTile
              styles={{ root: { boxShadow: "none" } }}
              textProps={{
                text: "No record recent interactions with patient records"
              }}
              linkProps={{ hidden: true }}
            />
          )
        }
      </DataFetcher>
    );
  }
);

import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";

import {
  mergeStyleSets,
  Spinner,
  Stack,
  Text,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { downloadFile, isImage } from "@libs/utils/file.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { PDFViewer } from "@ui-components/document-viewer/PDFViewerLazy.tsx";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { SELECT_FILE_TO_REVIEW } from "../inbox/Inbox.types.ts";
import {
  getInboxScreenStylesSet,
  sidePanelWidth
} from "../inbox/InboxScreen.styles.ts";
import { InboxUploadActionGroup } from "./components/InboxUploadActionGroup.tsx";
import { UserInboxScreenLeftSideList } from "./components/UserInboxScreenLeftSideList.tsx";

type UploadScreenProps = {
  heading?: string;
};

const UserInboxScreenBase: FC<UploadScreenProps> = observer(() => {
  const { inbox } = useStores();
  const theme = useTheme();
  const { noPDFWrapper, pdfInboxWrapper, uploadScreenTile } =
    getInboxScreenStylesSet(theme);

  const { docData, setDocData, fetchAndSetInboxDocument, clearOnLeave } =
    useContext(InboxScreenContext);

  const [emptyBoxText, setEmptyBoxText] = useState(SELECT_FILE_TO_REVIEW);

  useEffect(() => {
    inbox.resetUserInboxState();
  }, [inbox]);

  useEffect(() => {
    return () => {
      clearOnLeave();
    };
  }, [clearOnLeave]);

  const handleOnClose = () => {
    setDocData(undefined);
  };

  const handleTabItemSelected = (selectedTab: string) => {
    if (selectedTab === "Tasks") setEmptyBoxText("");
    else setEmptyBoxText(SELECT_FILE_TO_REVIEW);
    handleOnClose();
  };

  return (
    <Tile styles={uploadScreenTile}>
      <Stack
        horizontal
        tokens={{ childrenGap: 24 }}
        styles={{
          root: { height: 0, flexGrow: 1 }
        }}
      >
        <Stack styles={sidePanelWidth}>
          <UserInboxScreenLeftSideList
            onTabItemSelected={handleTabItemSelected}
          />
        </Stack>
        {docData?.id ? (
          <DataFetcher<InboxDocument>
            fetch={async () => fetchAndSetInboxDocument(docData)}
            key={docData.id}
            fallback={
              <Stack styles={pdfInboxWrapper}>
                <Spinner />
              </Stack>
            }
          >
            {document => {
              return (
                document.previewUri &&
                document.docExtension && (
                  <>
                    <Stack styles={pdfInboxWrapper}>
                      <PDFViewer
                        pdfUrl={document.previewUri}
                        onDownloadClick={() =>
                          document.fileUri
                            ? downloadFile(document.fileUri)
                            : undefined
                        }
                        showPrint={true}
                        documentTitle={document.name}
                        isImage={isImage(document.docExtension)}
                      />
                    </Stack>
                    <Stack.Item
                      styles={mergeStyleSets(sidePanelWidth, {
                        root: {
                          marginLeft: 12
                        }
                      })}
                    >
                      <Stack
                        styles={{
                          root: {
                            width: 320
                          }
                        }}
                      >
                        <InboxUploadActionGroup
                          document={document}
                          onClose={handleOnClose}
                        />
                      </Stack>
                    </Stack.Item>
                  </>
                )
              );
            }}
          </DataFetcher>
        ) : (
          <Stack styles={noPDFWrapper}>
            <Text
              styles={{
                root: {
                  fontStyle: "italic"
                }
              }}
            >
              {emptyBoxText}
            </Text>
          </Stack>
        )}
      </Stack>
    </Tile>
  );
});

const UserInboxScreen = withFetch(
  x => [
    x.correspondence.ref.correspondenceTypes.load(),
    x.inbox.ref.storeInDestinationsTypes.load(),
    x.inbox.ref.instructionsForReception.load(),
    x.inbox.ref.instructionsForProvider.load(),
    x.inbox.ref.userActionTypes.load(),
    x.inbox.ref.priorityTypes.load()
  ],
  UserInboxScreenBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default UserInboxScreen;

import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  GroupedList,
  Heading,
  IconButton,
  IGroupHeaderProps,
  IRenderFunction,
  NoDataTile,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  EncounterTreatments,
  TreatmentGroups
} from "./treatment/TreatmentForm.types.ts";
import { TreatmentAndManagementSidePanelContext } from "./TreatmentAndManagementSidePanelContext.ts";
import { collapseIconStyles, textBoldStyles } from "./TreatmentList.styles.ts";

interface TreatmentListProps {
  clinicalRecord: ClinicalRecord;
}

const TreatmentListBase: FunctionComponent<TreatmentListProps> = observer(
  ({ clinicalRecord }) => {
    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
    const { clinical } = useStores();

    const { getTreatments } = useContext(
      TreatmentAndManagementSidePanelContext
    );

    const onRenderCell = (
      depth?: number,
      item?: EncounterTreatments
    ): React.ReactNode => {
      return (
        <Stack
          tokens={{ childrenGap: 4 }}
          styles={{ root: { padding: "8px 0px", marginLeft: "33px" } }}
        >
          <Text styles={textBoldStyles}>{item?.title}</Text>
          <Text>{item?.comment}</Text>
        </Stack>
      );
    };

    const renderHeader: IRenderFunction<IGroupHeaderProps> = props => {
      const isCollapsed = props?.group?.isCollapsed || false;
      const isFinal = props?.groups?.length
        ? props?.groupIndex === props?.groups?.length - 1
        : false;

      return (
        <Stack
          horizontal
          styles={{
            root: {
              height: "fit-content",
              borderTop: "1px #D1EAEA solid",
              borderBottom:
                isFinal && isCollapsed ? "1px #D1EAEA solid" : undefined
            }
          }}
        >
          <IconButton
            {...dataAttribute(DataAttributes.Element, "collapse")}
            iconProps={{
              iconName: isCollapsed ? "ChevronRight" : "ChevronDown"
            }}
            styles={collapseIconStyles}
            onClick={() => {
              if (props?.onToggleCollapse && props?.group) {
                props.onToggleCollapse(props.group);
              }
            }}
          />
          <Stack
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: 4 }}
            styles={{
              root: {
                paddingTop: 8,
                paddingBottom: 8,
                paddingRight: 16,
                width: "100%"
              }
            }}
          >
            <Heading
              variant="section-sub-heading"
              styles={{
                root: { fontWeight: FontWeights.bold }
              }}
            >
              {props?.group?.name} ({props?.group?.count})
            </Heading>
            {props?.group?.key === clinicalRecord.openEncounter?.id && (
              <IconButton
                menuIconProps={{
                  iconName: "Edit"
                }}
                styles={{
                  root: { width: "32px", height: "20px", padding: 0 },
                  flexContainer: { width: "32px", height: "20px" }
                }}
                onClick={() => {
                  clinical.ui.setPatientClinicalContent({
                    type: ClinicalDataType.TreatmentAndEducation
                  });
                }}
              />
            )}
          </Stack>
        </Stack>
      );
    };

    return (
      <DataFetcher<TreatmentGroups>
        fetch={getTreatments}
        fallback={<Spinner />}
        refetchId={`${clinicalRecord.clinicalData?.treatmentAndEducation?.eTag}${clinicalRecord.clinicalData?.analysisAndPlan?.eTag}`}
      >
        {({ groups, items }) => {
          const filteredGroups = groups.filter(x => x.count > 0);

          return (
            <>
              {groups.length === 0 ? (
                <NoDataTile
                  textProps={{ text: "Nothing recorded yet" }}
                  linkProps={{
                    hidden: isViewOnlyOrDischarged,
                    text: "Add management activities",
                    onClick: () => {
                      clinical.ui.setPatientClinicalContent({
                        type: ClinicalDataType.TreatmentAndEducation
                      });
                    }
                  }}
                  showBoxShadow={false}
                  greyView
                />
              ) : (
                <GroupedList
                  groups={filteredGroups}
                  items={items}
                  onRenderCell={onRenderCell}
                  groupProps={{
                    onRenderHeader: renderHeader
                  }}
                  styles={{
                    root: {
                      overflow: "auto",
                      overflowX: "hidden",
                      maxHeight: "calc(100vh - 240px)"
                    }
                  }}
                />
              )}
            </>
          );
        }}
      </DataFetcher>
    );
  }
);

export const TreatmentList = withFetch(
  x => [
    x.clinical.ref.treatmentOptions.load(),
    x.clinical.ref.educationOptions.load(),
    x.clinical.ref.rxAnalysisOptions.load()
  ],
  TreatmentListBase
);

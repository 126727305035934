import { DateTime } from "@bps/utils";
import { ObservationMetadataItem } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";

import { FormattedMeasurement } from "./generateMeasurementColumns.tsx";
import { MeasurementRow } from "./ObservationsExpandedTable.tsx";

export enum ObservationsLabels {
  BloodPressure = "Blood Pressure",
  PulseBpm = "Pulse(bpm)",
  Respiration = "Respiration",
  Temperature = "Temperature (°C)",
  Height = "Height",
  Weight = "Weight",
  Neck = "Neck",
  Waist = "Waist",
  Hip = "Hip",
  O2SaturationRate = "O₂ saturation (%)",
  K10 = "K10",
  PSFS = "PSFS",
  GRCS = "GRCS",
  DASH = "DASH",
  DASH11 = "DASH 11",
  EPDS = "EPDS",
  DASS21 = "DASS 21",
  RAND36 = "RAND36",
  OREBRO = "OREBRO",
  NPRS = "NPRS",
  BMI = "Body mass index (BMI)",
  PulseSittingManual = "Sitting; Manual",
  PulseStandingManual = "Standing; Manual",
  PulseSittingMachine = "Sitting; Machine",
  PulseStandingMachine = "Standing; Machine",
  PulseLyingManual = "Lying; Manual",
  PulseLyingMachine = "Lying; Machine",
  BPLeftArmSitting = "Left arm; Sitting",
  BPLeftArmLying = "Left arm; Lying",
  BPLeftArmStanding = "Left arm; Standing",
  BPRightArmStanding = "Right arm; Standing",
  BPRightArmSitting = "Right arm; Sitting",
  BPRightArmLying = "Right arm; Lying",
  Machine = "Machine",
  Manual = "Manual",
  BGL = "Blood glucose level (BGL)"
}
export const getLabel = (type: string, summary?: string) => {
  switch (type) {
    case "PUL":
      let pulseText = "";
      switch (summary) {
        case "MAC":
          pulseText = ObservationsLabels.Machine;
          break;
        case "MAN":
          pulseText = ObservationsLabels.Manual;
          break;
      }
      return pulseText;
    case "PULSITMAN":
      return ObservationsLabels.PulseSittingManual;
    case "PULSTAMAN":
      return ObservationsLabels.PulseStandingManual;
    case "PULSLYMAN":
      return ObservationsLabels.PulseLyingManual;
    case "PULSITMAC":
      return ObservationsLabels.PulseSittingMachine;
    case "PULSTAMAC":
      return ObservationsLabels.PulseStandingMachine;
    case "PULSLYMAC":
      return ObservationsLabels.PulseLyingMachine;
    case "LSITSYS":
    case "LSITDIA":
      return ObservationsLabels.BPLeftArmSitting;
    case "LLYISYS":
    case "LLYIDIA":
      return ObservationsLabels.BPLeftArmLying;
    case "LSTASYS":
    case "LSTADIA":
      return ObservationsLabels.BPLeftArmStanding;
    case "RSTASYS":
    case "RSTADIA":
      return ObservationsLabels.BPRightArmStanding;
    case "RSITSYS":
    case "RSITDIA":
      return ObservationsLabels.BPRightArmSitting;
    case "RLYISYS":
    case "RLYIDIA":
      return ObservationsLabels.BPRightArmLying;
    case "SYS":
    case "DIA":
      return "";
    default:
      return type;
  }
};

export const bloodPressureTypes = new Set([
  "LSITSYS",
  "LSTASYS",
  "LLYISYS",
  "RSITSYS",
  "RSTASYS",
  "RLYISYS",
  "LSITDIA",
  "LSTADIA",
  "LLYIDIA",
  "RSITDIA",
  "RSTADIA",
  "RLYIDIA",
  "SBP",
  "DBP",
  "SYS",
  "DIA",
  "BP"
]);

export const clinicalTools = new Set([
  "K10",
  "GRCS",
  "PSFS",
  "DASH11",
  "DASS21",
  "OREBRO",
  "EPDS",
  "NPRS",
  "RAND36"
]);

export const pulseTypes = new Set([
  "PUL",
  "PULSITMAN",
  "PULSTAMAN",
  "PULSLYMAN",
  "PULSITMAC",
  "PULSTAMAC",
  "PULSLYMAC"
]);

export enum BloodPressureType {
  Systolic = "Systolic",
  Diastolic = "Diastolic"
}

export const getMeasurementTypeLabel = (type: string): string => {
  if (bloodPressureTypes.has(type)) return ObservationsLabels.BloodPressure;
  if (pulseTypes.has(type)) return ObservationsLabels.PulseBpm;
  if (type === "RRT") return ObservationsLabels.Respiration;
  if (type === "TMP") return ObservationsLabels.Temperature;
  if (type === "HGT") return ObservationsLabels.Height;
  if (type === "WGT") return ObservationsLabels.Weight;
  if (type === "NEK") return ObservationsLabels.Neck;
  if (type === "WST") return ObservationsLabels.Waist;
  if (type === "HIP") return ObservationsLabels.Hip;
  if (type === "O2S") return ObservationsLabels.O2SaturationRate;
  if (type === "K10") return ObservationsLabels.K10;
  if (type === "DASH") return ObservationsLabels.DASH11;
  if (type === "DASS21") return ObservationsLabels.DASS21;
  if (type === "GRCS") return ObservationsLabels.GRCS;
  if (type === "NPRS") return ObservationsLabels.NPRS;
  if (type === "OREBRO") return ObservationsLabels.OREBRO;
  if (type === "RAND36") return ObservationsLabels.RAND36;
  if (type === "PSFS") return ObservationsLabels.PSFS;
  if (type === "EPDS") return ObservationsLabels.EPDS;
  if (type === "BGL") return ObservationsLabels.BGL;
  if (type === "BMI") return ObservationsLabels.BMI;
  return type;
};

interface GroupedMeasurement {
  id: string;
  value: string;
  timestamp: string;
  type: string;
  summary: string;
}

export type GroupedByDateAndType = Record<
  string,
  Record<string, GroupedMeasurement[]>
>;

export const groupMeasurementsByDateAndType = (
  measurements: Measurement[],
  getTypeLabel: (type: string) => string
): GroupedByDateAndType => {
  return measurements.reduce((acc: GroupedByDateAndType, m) => {
    const dateKey = DateTime.fromISO(m.timestamp).toFormat("dd/MM/yy");
    const typeLabel = getTypeLabel(m.type);

    const formattedTimestamp = DateTime.fromISO(m.timestamp).toFormat(
      "h:mm:ss a"
    );

    if (!acc[dateKey]) {
      acc[dateKey] = {};
    }

    if (!acc[dateKey][typeLabel]) {
      acc[dateKey][typeLabel] = [];
    }

    acc[dateKey][typeLabel].push({
      id: m.id,
      value: `${m.value}`,
      timestamp: formattedTimestamp,
      type: m.type,
      summary: m.summary ? m.summary : ""
    });

    return acc;
  }, {});
};

export const formatMeasurements = (measurements: GroupedMeasurement[]) => {
  const groupedByTimestamp = measurements.reduce(
    (
      acc: Record<string, FormattedMeasurement>,
      measurement: GroupedMeasurement
    ): Record<string, FormattedMeasurement> => {
      if (!acc[measurement.timestamp]) {
        acc[measurement.timestamp] = {
          systolic: "",
          diastolic: "",
          value: "",
          label: getLabel(measurement.type, measurement.summary)
        };
      }

      if (bloodPressureTypes.has(measurement.type)) {
        if (measurement.type.endsWith("SYS")) {
          acc[measurement.timestamp].systolic = measurement.value;
        } else if (measurement.type.endsWith("DIA")) {
          acc[measurement.timestamp].diastolic = measurement.value;
        }
      } else if (pulseTypes.has(measurement.type)) {
        acc[measurement.timestamp].pulse = measurement.value;
        if (measurement.type.endsWith("PUL")) {
          acc[measurement.timestamp].label = getLabel(
            measurement.type,
            measurement.summary
          );
        } else {
          acc[measurement.timestamp].pulse = measurement.value;
        }
      } else {
        acc[measurement.timestamp].value = measurement.value;
      }

      return acc;
    },
    {}
  );

  return groupedByTimestamp;
};

export const formatObservations = (observations: GroupedMeasurement[]) => {
  const groupedByTimestamp = observations.reduce(
    (
      acc: Record<string, FormattedMeasurement>,
      observation: GroupedMeasurement
    ): Record<string, FormattedMeasurement> => {
      if (!acc[observation.timestamp]) {
        acc[observation.timestamp] = {
          value: "",
          label: observation.summary
        };
      }

      acc[observation.timestamp].value = observation.value;

      return acc;
    },
    {}
  );

  return groupedByTimestamp;
};
export const generateMeasurementRows = (
  uniqueTypes: Set<string>,
  dates: string[],
  groupedByDateAndType: GroupedByDateAndType
): MeasurementRow[] => {
  return Array.from(uniqueTypes)
    .map(type => ({
      key: type,
      type,
      ...dates.reduce((acc: Record<string, string>, date) => {
        const valuesForTypeOnDate =
          groupedByDateAndType[date]?.[type]?.map(
            measurement => measurement.value
          ) || [];

        acc[date] = valuesForTypeOnDate.join("\n");
        return acc;
      }, {})
    }))
    .sort((a, b) => {
      const aIsClinicalTool = clinicalTools.has(
        normalizeLabel(getMeasurementTypeLabel(a.type))
      );

      const bIsClinicalTool = clinicalTools.has(
        normalizeLabel(getMeasurementTypeLabel(b.type))
      );

      if (aIsClinicalTool && !bIsClinicalTool) return 1;
      if (!aIsClinicalTool && bIsClinicalTool) return -1;
      return 0;
    });
};

export const groupObservationsByDateAndType = (
  observations: Observation[]
): GroupedByDateAndType => {
  return observations.reduce((acc: GroupedByDateAndType, o) => {
    const dateKey = DateTime.fromISO(o.timestamp).toFormat("dd/MM/yy");
    const typeLabel = getMeasurementTypeLabel(o.type);

    const formattedTimestamp = DateTime.fromISO(o.timestamp).toFormat(
      "h:mm:ss a"
    );

    if (!acc[dateKey]) {
      acc[dateKey] = {};
    }

    if (!acc[dateKey][typeLabel]) {
      acc[dateKey][typeLabel] = [];
    }

    acc[dateKey][typeLabel].push({
      id: o.id,
      value: getObservationValue(o),
      timestamp: formattedTimestamp,
      type: o.type,
      summary: o.summary ? o.summary : ""
    });

    return acc;
  }, {});
};

const getMetaValue = (key: string, metaData: ObservationMetadataItem[]) => {
  return metaData.find(x => x.key === key)?.value;
};

const getObservationValue = (observation: Observation) => {
  if (observation.type === "BP") {
    const systolic = observation.values.find(
      x => getMetaValue("Type", x.metadata ?? []) === BloodPressureType.Systolic
    )?.value;

    const diastolic = observation.values.find(
      x =>
        getMetaValue("Type", x.metadata ?? []) === BloodPressureType.Diastolic
    )?.value;
    return `${systolic}/${diastolic}`;
  } else {
    const allValues = observation.values.map(x => x.value);
    return allValues.join(", ");
  }
};

export const normalizeLabel = (label: string) =>
  label.replace(/\s+/g, "").toUpperCase();

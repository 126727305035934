import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { initialiseDocEditor } from "@modules/clinical/screens/document-writer/components/utils.ts";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { SubmitAction } from "../../document-writer/components/DocumentWriterDetails.types.ts";
import { useTemplateWriterContext } from "../context/TemplateWriterContext.ts";
import { TemplateWriterDetailsFields } from "./TemplateWriterDetailsFields.tsx";
import { TemplateWriterDetailsValidator } from "./TemplateWriterDetailsValidator.ts";
import { TemplateWriterValues } from "./TemplateWriterValues.ts";

interface TemplateWriterDetailsComponentProps {
  documentSettings?: OrgUnitDocumentSettingsDto;
}

const validator = new TemplateWriterDetailsValidator();
const TemplateWriterDetailsComponent: React.FunctionComponent<TemplateWriterDetailsComponentProps> =
  observer(({ documentSettings }) => {
    const model = useTemplateWriterContext();

    const type = useRef<string>(SubmitAction.Save);

    const docEditor = useRef<DocumentEditor>();

    const beforeUnloadHandler = (evt: BeforeUnloadEvent) => {
      if (model.isDirty) {
        evt.preventDefault();
      }
    };

    useEffect(() => {
      window.addEventListener("beforeunload", beforeUnloadHandler, {
        capture: true
      });
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler, {
          capture: true
        });
      };
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      initialiseDocEditor(model.initialValues?.documentContent, docEditor, {
        goToHeader: model.currentTemplate.isLetterhead
      });
    }, [
      model.initialValues?.documentContent,
      model.currentTemplate.isLetterhead
    ]);

    return (
      <SubmissionForm<TemplateWriterValues>
        formName="template-writer"
        onSubmit={val => model.onSubmit(val, SubmitAction[type.current])}
        initialValues={model.initialValues}
        styles={{
          fields: {
            display: "flex",
            flexDirection: "row",
            height: "100%",
            overflowY: "inherit"
          },
          main: {
            backgroundColor: "inherit",
            overflowY: "inherit"
          }
        }}
        hideButtons
        validate={validator.validate}
        render={() => {
          return (
            <TemplateWriterDetailsFields
              type={type}
              docEditor={docEditor}
              documentSettings={documentSettings}
            />
          );
        }}
      />
    );
  });

export const TemplateWriterDetails = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  TemplateWriterDetailsComponent
);

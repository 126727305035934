import { useState } from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { BillingConfirmationDialog } from "@modules/billing/screens/billing-history/components/BillingConfirmationDialog.tsx";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import { Refund } from "@stores/billing/models/Refund.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface RefundViewButtonProps {
  refund: Refund;
}

const CANCEL_TEXT = "Cancel refund";

const RefundViewButtonsBase: React.FC<RefundViewButtonProps> = ({ refund }) => {
  const { routing, billing } = useStores();
  const [showDialog, setShowDialog] = useState(false);

  const close = () => {
    closeInvoiceOrPaymentPage(routing);
  };

  const handleCancel = async (reason: string) => {
    await billing.cancelRefund(refund.id, reason);
    close();
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 16 }}>
      {!refund.isCancelled && (
        <DefaultButton text={CANCEL_TEXT} onClick={() => setShowDialog(true)} />
      )}
      <DefaultButton text="Close" onClick={close} />
      <BillingConfirmationDialog
        className="cancel-dialog"
        hidden={!showDialog}
        label="Cancellation reason"
        confirmButtonText={CANCEL_TEXT}
        options={billing.ref.paymentCancelReason.keyTextValues}
        onCancel={() => setShowDialog(false)}
        onConfirm={reason => handleCancel(reason)}
      />
    </Stack>
  );
};

export const RefundViewButtons = withFetch(
  x => [x.billing.ref.paymentCancelReason.load()],
  RefundViewButtonsBase
);

// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { runInAction } from "mobx";
import { useField, useFormState } from "react-final-form";

import {
  CollapsibleCard,
  FontIcon,
  FontSizes,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DrawingCanvas } from "@modules/clinical/screens/patient-record/components/notes/draw/DrawingCanvas.tsx";

import { InjuryImagesHelper } from "./context/InjuryImagesHelper.tsx";
import { ImageType } from "./context/InjuryImagesHelper.types.ts";
import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";

interface BodyDiagramFormValues {
  frontBodyImage?: string;
  backBodyImage?: string;
  leftBodyImage?: string;
  rightBodyImage?: string;
}

export const BodyDiagramCard: React.FC = () => {
  const { clinicalRecord, isViewOnlyOrDischarged } =
    usePatientRecordScreenContext();

  const {
    input: { onChange: onFrontBodyImage }
  } = useField(sotapNameOf("frontBodyImage"));

  const {
    input: { onChange: onBackBodyImage }
  } = useField(sotapNameOf("backBodyImage"));

  const {
    input: { onChange: onLeftSideBodyImage }
  } = useField(sotapNameOf("leftBodyImage"));

  const {
    input: { onChange: onRightSideBodyImage }
  } = useField(sotapNameOf("rightBodyImage"));

  const { initialValues } = useFormState<BodyDiagramFormValues>({
    subscription: { initialValues: true, dirty: true }
  });

  const images = InjuryImagesHelper.getCanvasBodyImages(
    {
      initialFrontImage: initialValues.frontBodyImage,
      initialBackImage: initialValues.backBodyImage,
      initialLeftSideImage: initialValues.leftBodyImage,
      initialRightSideImage: initialValues.rightBodyImage
    },
    clinicalRecord.patient
  );

  const onCanvasSelected = (imageIndex: number) => {
    runInAction(() => {
      clinicalRecord.selectedSOTAPBodyImageIndex = imageIndex;
    });
  };

  const isBodyModified =
    clinicalRecord?.stashedClinicalData?.dirtyAreas?.get("physioBody") ?? false;

  const theme = useTheme();

  const renderTitle = (title: string, isBodyModified: boolean) => {
    return (
      <Text variant="large" bold>
        {title}

        {isBodyModified && (
          <FontIcon
            iconName="PageEdit"
            styles={{
              root: {
                fontSize: FontSizes.size16,
                color: theme.palette.neutralSecondary,
                paddingLeft: "8px"
              }
            }}
          />
        )}
      </Text>
    );
  };

  return (
    <div id={SotapMskId.BodyDiagram}>
      <CollapsibleCard
        heading={renderTitle(SotapMskLabel.BodyDiagram, isBodyModified)}
        headingLevel="section-heading"
        iconName="BpFullBody24"
      >
        {/* Wrap DrawingCanvas into Field to track canvas(s) changes for FinalForm  */}
        <DrawingCanvas
          isViewOnly={
            isViewOnlyOrDischarged ||
            clinicalRecord.dischargeInProgressOrCompleted()
          }
          onCanvasSelected={onCanvasSelected}
          initialSelectedImage={clinicalRecord.selectedSOTAPBodyImageIndex}
          showQuickColourPicker={!isViewOnlyOrDischarged}
          caption="Diagram"
          images={images}
          onChange={(canvas: Canvas, event: any) => {
            const canvasImageValue =
              event.target.dirty && canvas._objects.length > 0
                ? canvas?.toSVG()
                : undefined;
            if (
              canvas["lowerCanvasEl"].id === ImageType.frontImage.toString()
            ) {
              onFrontBodyImage(canvasImageValue);
            } else if (
              canvas["lowerCanvasEl"].id === ImageType.backImage.toString()
            ) {
              onBackBodyImage(canvasImageValue);
            } else if (
              canvas["lowerCanvasEl"].id === ImageType.leftImage.toString()
            ) {
              onLeftSideBodyImage(canvasImageValue);
            } else if (
              canvas["lowerCanvasEl"].id === ImageType.rightImage.toString()
            ) {
              onRightSideBodyImage(canvasImageValue);
            }
          }}
        />
      </CollapsibleCard>
    </div>
  );
};

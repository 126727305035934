import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import {
  GroupedList,
  IconButton,
  IGroup,
  IGroupHeaderProps,
  Spinner,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ConfidentialToolTipFontIcon } from "@modules/clinical/screens/patient-record/components/ConfidentialToolTipFontIcon.tsx";
import { HL7HeaderDataPreview } from "@modules/inbox/screens/shared-components/HL7HeaderDataPreview.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { getInboxScreenStylesSet } from "../../inbox/InboxScreen.styles.ts";
import { OutcomeLog } from "./OutcomeLog.tsx";
import { TaskActionForm } from "./TaskActionForm.tsx";
import { TaskDetailsForm } from "./TaskDetailsForm.tsx";
import { TasksScreenLabels } from "./Tasks.types.ts";

export type TaskProps = {
  task: UserTask;
};

export const Task: FC<TaskProps> = observer(({ task }) => {
  const theme = useTheme();
  const { groupedListHeaderStyles } = getInboxScreenStylesSet(theme);
  const {
    isFormActive,
    inboxDocument,
    showDocumentViewerDialog,
    setShowDocumentViewerDialog,
    setInboxDocument
  } = useContext(InboxScreenContext);

  const { inbox, core } = useStores();
  const getDocument = async (): Promise<Document> => {
    if (task.patientId && task.documentId) {
      const result = await inbox.getPatientDocument(
        task.patientId,
        task.documentId
      );

      setInboxDocument(result);

      return {
        entityId: result.id,
        documentId: result.documentDetailId,
        entityType: DocumentEntityType.Patient,
        name: result.name,
        extension: result.docExtension,
        downloadUri: result.fileUri,
        previewUri: result.blobSasUri
      } as Document;
    }

    return Promise.reject("Document not found");
  };

  const groups = (): IGroup[] => {
    if (
      inboxDocument?.headerDocumentDetailId &&
      inboxDocument?.headerEntityId
    ) {
      return [
        {
          key: "messageHeader",
          name: "Message header",
          startIndex: 0,
          count: 1,
          isCollapsed: false
        },
        {
          key: "details",
          name: TasksScreenLabels.TaskDetails,
          startIndex: 1,
          count: 1,
          isCollapsed: !isFormActive
        },
        {
          key: "actions",
          name: TasksScreenLabels.Outcomes,
          startIndex: 2,
          count: 1,
          isCollapsed: isFormActive
        }
      ];
    }

    return [
      {
        key: "details",
        name: TasksScreenLabels.TaskDetails,
        startIndex: 0,
        count: 1,
        isCollapsed: !isFormActive
      },
      {
        key: "actions",
        name: TasksScreenLabels.Outcomes,
        startIndex: 1,
        count: 1,
        isCollapsed: isFormActive
      }
    ];
  };

  const items = [
    <TaskDetailsForm key="task-detail-form" task={task} />,
    <Stack key="task-action-form" tokens={{ childrenGap: 16 }}>
      <TaskActionForm task={task} />
      {task.outcomeLog && <OutcomeLog outcomeLog={task.outcomeLog} />}
    </Stack>
  ];
  if (inboxDocument?.headerDocumentDetailId && inboxDocument?.headerEntityId) {
    items.unshift(<HL7HeaderDataPreview key="messageHeader" />);
  }
  return (
    <Stack
      tokens={{ childrenGap: 8 }}
      styles={{
        root: {
          flexGrow: 1,
          overflowY: "auto",
          height: "100%"
        }
      }}
    >
      {showDocumentViewerDialog && (
        <DocumentViewerDialog
          getDocument={getDocument}
          closeDocumentViewer={() => {
            setInboxDocument();
            setShowDocumentViewerDialog(false);
          }}
        />
      )}
      <GroupedList
        groups={groups()}
        items={items}
        styles={{
          root: {
            selectors: groupedListHeaderStyles,
            overflow: "auto"
          },
          group: {
            ".ms-List-page > *": {
              marginBottom: 16
            }
          }
        }}
        groupProps={{
          onRenderHeader: (props, defaultRender) => {
            if (!props || !defaultRender) {
              return null;
            }

            const titleStyle = {
              fontWeight: !!props?.group?.isCollapsed ? 400 : 600,
              fontSize: 16
            };

            const renderGroupTitle = (item: IGroupHeaderProps) => {
              switch (props.group?.name) {
                case TasksScreenLabels.TaskDetails:
                  return (
                    <Stack
                      horizontal
                      horizontalAlign="space-between"
                      verticalAlign="center"
                      tokens={{ childrenGap: 4 }}
                      styles={{
                        root: {
                          width: "100%",
                          fontSize: 14
                        }
                      }}
                    >
                      <span style={titleStyle}>{item.group?.name}</span>
                      {task?.documentId && (
                        <DataFetcher<boolean>
                          fetch={async () => {
                            if (task.patientId && task.documentId) {
                              const result = await inbox.getPatientDocument(
                                task.patientId,
                                task.documentId
                              );
                              if (result.secGroupId) {
                                return (
                                  result.secGroupId !==
                                  core.user?.privateSecGroupId
                                );
                              }
                            }
                            return false;
                          }}
                          fallback={<Spinner />}
                        >
                          {showConfidential =>
                            showConfidential ? (
                              <ConfidentialToolTipFontIcon
                                isShowConfidentialIcon={true}
                                content="Confidential attachment"
                                summaryStyling={true}
                              />
                            ) : (
                              <TooltipHost content="View attachment">
                                <IconButton
                                  iconProps={{ iconName: "Attach" }}
                                  onClick={() => {
                                    setShowDocumentViewerDialog(true);
                                  }}
                                />
                              </TooltipHost>
                            )
                          }
                        </DataFetcher>
                      )}
                    </Stack>
                  );
                case TasksScreenLabels.Outcomes:
                  return <span style={titleStyle}>{item.group?.name}</span>;
                default:
                  return null;
              }
            };

            return defaultRender!({
              ...props,
              styles: {
                headerCount: { display: "none" }
              },
              onRenderTitle: renderGroupTitle,
              onGroupHeaderClick: () => props.onToggleCollapse!(props.group!)
            });
          }
        }}
        onRenderCell={(depth, item: React.ReactNode) => item}
      />
    </Stack>
  );
});

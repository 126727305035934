// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import {
  ChoiceGroup,
  CSSTransition,
  DefaultButton,
  DirectionalHint,
  FontIcon,
  FontWeights,
  IButtonProps,
  IChoiceGroupOption,
  IconButton,
  mergeStyleSets,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Hotkey } from "@libs/utils/Hotkey.ts";

import { getDrawingToolbarStyles } from "../../../notes/draw/DrawingCanvas.styles.ts";
import { BrushKind, Options } from "../../../notes/draw/DrawingCanvas.types.ts";
import { DrawingCanvasHistory } from "../../../notes/draw/DrawingCanvasHistory.ts";
import { QuickColours } from "../../../notes/draw/QuickColours.tsx";
import { useBodyExaminationContext } from "../context/BodyExaminationContext.ts";
import { getNameOf, MEDIUM_BREAKPOINT, SMALL_BREAKPOINT } from "../utils.ts";
import { CanvasImageEditor } from "./CanvasImageEditor.tsx";
import {
  enterSlideSideStyle,
  enterSlideSideStyleActive,
  exitSlideSideStyle,
  exitSlideSideStyleActive
} from "./DiagramEditor.styles.tsx";
import { DiagramPickerSelect } from "./DiagramPickerSelect.tsx";
import { DrawingToolbar } from "./DrawingToolbar.tsx";

export interface DiagramEditorProps {
  isViewOnly?: boolean;
  panelWidth: number;
  isCompacted?: boolean;
  onCompactButtonClicked?: IButtonProps["onClick"];
  isDermAndMyotomes?: boolean;
  isCentralNervousSystem?: boolean;
  showQuickColours?: boolean;
}
export const DiagramEditor: React.FC<DiagramEditorProps> = observer(
  ({
    isViewOnly,
    panelWidth,
    onCompactButtonClicked,
    isCompacted,
    isDermAndMyotomes,
    isCentralNervousSystem,
    showQuickColours
  }) => {
    const {
      brush,
      setBrush,
      currentCanvas,
      isDrawingMode,
      setIsDrawingMode,
      activeObjects,
      setActiveObjects,
      switchCanvas,
      canvases,
      images,
      getThumbnails,
      isCanvasExist,
      setCurrentBodyArea
    } = useBodyExaminationContext();

    const styles = getDrawingToolbarStyles();
    const theme = useTheme();
    const handleRemoveClicked = () => {
      if (!currentCanvas || !activeObjects) {
        return;
      }

      currentCanvas.remove(...currentCanvas.getActiveObjects());

      setActiveObjects(null);
    };

    const clear = () => {
      const canvasObjects = currentCanvas?.getObjects();
      if (canvasObjects) {
        currentCanvas?.remove(...canvasObjects);
      }
    };

    const handleCursorClicked = () => {
      if (isDrawingMode) {
        setIsDrawingMode(false);
      }
    };

    const handleBrushClicked = (brushKind: BrushKind) => {
      if (!currentCanvas || (isDrawingMode && brush.kind === brushKind)) {
        return;
      }

      const newOptions: Options = {
        kind: brushKind,
        width: brushKind === "transparent" ? 20 : 2,
        color: brush.color,
        fill: brush.fill
      };

      setBrush(newOptions);
      setIsDrawingMode(true);
    };

    const nameOf = getNameOf(isDermAndMyotomes, isCentralNervousSystem);

    const thumbnails = getThumbnails(theme.semanticColors.disabledBackground);

    const [firstThumbnail] = thumbnails;

    const caption = thumbnails.find(
      t => t.id === currentCanvas?.getElement().id
    )?.text;

    const onQuickColourSelected = (color: string) => {
      setBrush({ ...brush, color, fill: color });
    };

    const optionSelected = (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option) {
        const canvas = canvases.find(c => c.getElement().id === option.id);

        const image = images.find(c => c.id === option.id);

        setCurrentBodyArea(image?.bodyArea);
        switchCanvas(canvas);
      }
    };

    useEffect(() => {
      const image = images.find(x => x.id === firstThumbnail?.key);
      setCurrentBodyArea(image?.bodyArea);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImageEditorKeyDown = (
      event: React.KeyboardEvent<HTMLCanvasElement>
    ) => {
      if (currentCanvas) {
        const isUndoHotkey = Hotkey.getHotkeyChecker("mod+z");
        const isRedoHotkey = Hotkey.getHotkeyChecker("mod+y");

        if (isUndoHotkey(event.nativeEvent)) {
          history.undo(currentCanvas);
        }

        if (isRedoHotkey(event.nativeEvent)) {
          history.redo(currentCanvas);
        }
      }
    };

    const handleSelectionChange = (ativeObjects: fabric.Object[] | null) => {
      if (ativeObjects) {
        setActiveObjects(ativeObjects);
      }
    };

    const getSelectedKey = () => {
      if (!currentCanvas?.getElement().id) {
        return firstThumbnail?.key;
      } else {
        return currentCanvas?.getElement().id;
      }
    };

    const { current: history } = useRef(new DrawingCanvasHistory());
    const isSmallerSize = panelWidth <= SMALL_BREAKPOINT;

    const possibleToCompact = panelWidth > MEDIUM_BREAKPOINT;
    const canvasCompacted =
      possibleToCompact && isCompacted && currentCanvas !== undefined;

    return (
      <Stack horizontal={!isSmallerSize}>
        <Stack verticalFill>
          {!isDermAndMyotomes && !isCentralNervousSystem ? (
            <DiagramPickerSelect />
          ) : (
            <Text
              styles={{
                root: {
                  marginTop: 6,
                  marginBottom: 8,
                  fontWeight: FontWeights.semibold
                }
              }}
            >
              Diagrams
            </Text>
          )}
          <ChoiceGroup
            options={thumbnails}
            styles={{
              root: {
                maxHeight: isSmallerSize ? 125 : 365,
                maxWidth: "100%",
                overflow: "auto"
              },
              label: {}
            }}
            onChange={optionSelected}
            selectedKey={getSelectedKey()}
          />
          {showQuickColours && (
            <QuickColours
              onColourSelected={onQuickColourSelected}
              buttonStyles={{
                flexContainer: { justifyContent: "flex-start" }
              }}
              orientVertically={!isSmallerSize}
            />
          )}
        </Stack>

        {canvasCompacted && caption && (
          <Stack
            styles={{
              root: {
                marginLeft: "auto",
                marginRight: 0
              }
            }}
          >
            <IconButton
              title="Wipe"
              onClick={x => {
                if (onCompactButtonClicked) {
                  onCompactButtonClicked(x);
                }
              }}
              disabled={false}
              styles={mergeStyleSets(styles.clearButton, {
                root: { color: theme.palette.accent, width: "auto" }
              })}
              iconProps={{ iconName: "DoubleChevronRight12" }}
            />

            <Stack
              horizontal
              verticalAlign="center"
              styles={{
                root: {
                  paddingBottom: "8px",
                  writingMode: "vertical-lr",
                  transform: "rotate(180deg)"
                }
              }}
            >
              <Text bold>{caption}</Text>
            </Stack>
          </Stack>
        )}

        <CSSTransition
          in={!canvasCompacted}
          timeout={300}
          unmountOnExit
          classNames={{
            enter: enterSlideSideStyle,
            enterActive: enterSlideSideStyleActive,
            exit: exitSlideSideStyle,
            exitActive: exitSlideSideStyleActive
          }}
        >
          <Stack styles={{ root: { margin: "0 auto" } }}>
            {!canvasCompacted && (
              <>
                <DrawingToolbar
                  caption={caption || ""}
                  onUndoClicked={() =>
                    currentCanvas && history.undo(currentCanvas)
                  }
                  onRedoClicked={() =>
                    currentCanvas && history.redo(currentCanvas)
                  }
                  onDeleteClicked={handleRemoveClicked}
                  onThicknessChanged={width => setBrush({ ...brush, width })}
                  onBrushColorChanged={color => setBrush({ ...brush, color })}
                  onFillColorChanged={color =>
                    setBrush({ ...brush, fill: color })
                  }
                  onCursorClicked={handleCursorClicked}
                  deleteEnabled={!!activeObjects && activeObjects?.length > 0}
                  fillColour={brush.fill}
                  brushColour={brush.color}
                  strokeWidth={brush.width}
                  onBrushChange={handleBrushClicked}
                  renderClearButton={() => {
                    return (
                      <div>
                        <TooltipHost
                          content="Clear all"
                          directionalHint={DirectionalHint.bottomCenter}
                        >
                          <DefaultButton
                            title="Clear"
                            onClick={clear}
                            disabled={false}
                            styles={mergeStyleSets(styles.clearButton, {
                              root: { color: theme.palette.accent }
                            })}
                          >
                            Clear
                          </DefaultButton>
                        </TooltipHost>

                        {possibleToCompact && (
                          <TooltipHost
                            content="Collapse"
                            directionalHint={DirectionalHint.bottomCenter}
                          >
                            <DefaultButton
                              title="Wipe"
                              onClick={x => {
                                if (onCompactButtonClicked) {
                                  onCompactButtonClicked(x);
                                }
                              }}
                              disabled={false}
                              styles={mergeStyleSets(styles.clearButton, {
                                root: { color: theme.palette.accent }
                              })}
                            >
                              <FontIcon
                                iconName="DoubleChevronLeft12"
                                styles={{ root: { fontSize: 14 } }}
                              />
                            </DefaultButton>
                          </TooltipHost>
                        )}
                      </div>
                    );
                  }}
                />
                <Stack
                  horizontal
                  styles={{
                    root: {
                      width: 486,
                      border: `1px solid ${theme.palette.neutralLighter}`,
                      margin: 8
                    }
                  }}
                >
                  {images.map((image, index) => (
                    <CanvasImageEditor
                      fieldName={`${nameOf("imageValue")}.${image.id}`}
                      key={`canvas-full-image-${image.id}`}
                      image={image}
                      history={history}
                      isViewOnly={isViewOnly}
                      options={brush}
                      showCurrentCanvas={
                        image.id === currentCanvas?.getElement().id
                      }
                      isDrawingMode={isDrawingMode}
                      handleKeyDown={handleImageEditorKeyDown}
                      handleSelectionChange={handleSelectionChange}
                      handleCanvasFocused={(canvas: Canvas) =>
                        switchCanvas(canvas)
                      }
                      setCanvas={(canvas: Canvas) => {
                        const isLastImage = index === images.length - 1;
                        if (!isCanvasExist(canvas.getElement().id)) {
                          canvases.push(canvas);
                        }
                        if (isLastImage && !currentCanvas) {
                          switchCanvas(canvases[0]);
                        }
                      }}
                    />
                  ))}
                </Stack>
              </>
            )}
          </Stack>
        </CSSTransition>
      </Stack>
    );
  }
);

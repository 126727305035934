import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";

export interface QuickAddContactFormValues {
  firstName?: string;
  lastName?: string;
  mobile?: string;
  email?: string;
  organisationName?: string;
  categories?: string[];
  suburb?: string | undefined;
  city?: string | undefined;
  postCode?: string | undefined;
  state?: string | undefined;
  phone?: string | undefined;
  hasPostal?: boolean;
  addresses?: AddressFieldValue[];
}

export const quickAffContactFormNameOf =
  nameOfFactory<QuickAddContactFormValues>();

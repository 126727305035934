import { observer, Observer } from "mobx-react-lite";
import React from "react";

import {
  GroupedList,
  Heading,
  IGroupHeaderProps,
  IRenderFunction,
  NoDataTile,
  Stack,
  Text,
  TextField,
  Tile,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";
import {
  ReportMetadataInterface,
  ReportType
} from "@libs/gateways/reports/ReportsGateway.dtos.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ReportButton } from "./components/report/ReportButton.tsx";
import { ReportDisplay } from "./components/report/ReportDisplay.tsx";
import { ReportLoadingSpinner } from "./components/report/ReportLoadingSpinner.tsx";
import { ReportListViewModel } from "./context/ReportScreenHelper.tsx";
import {
  ReportsScreenContextProvider,
  useReportScreenContext
} from "./context/ReportsScreenContext.tsx";
import { getReportScreenStylesSet } from "./ReportsScreen.styles.ts";

interface ReportsScreenInterface {
  parameters?: string;
}

const ReportsScreenBase: React.FC<ReportsScreenInterface> = observer(
  ({ parameters }) => {
    const theme = useTheme();
    const {
      groupList,
      mainHeading,
      listTile,
      noReportStyle,
      reportButtonIcon
    } = getReportScreenStylesSet(theme);

    const helper = useReportScreenContext();

    const {
      getEmbedConfiguration,
      setSearchString,
      reportDisplayList,
      hasReports,
      selectedReport,
      urlReportId,
      searchString
    } = helper;

    const metadata: ReportMetadataInterface =
      selectedReport?.baseReport?.metadata ?? {};

    const NO_SELECTED_REPORT_TEXT = "Select a report to view";
    const NO_REPORTS_TEXT =
      "No reports have been created, contact your administrator";

    const renderReportButton = (
      depth: number | undefined,
      reportView: ReportListViewModel
    ): JSX.Element => {
      return (
        <Observer>
          {() => (
            <ReportButton
              //have to use helper.selectedReport, rather than just selectedReport for the observer to work here
              selected={helper.selectedReport?.id === reportView.id}
              reportView={reportView}
            />
          )}
        </Observer>
      );
    };

    const renderSectionHeader: IRenderFunction<IGroupHeaderProps> = (
      props,
      defaultRender
    ) => {
      if (!props || !defaultRender) {
        return null;
      }
      return defaultRender!({
        ...props,
        styles: groupList.headingRender,
        onGroupHeaderClick: () =>
          props.onToggleCollapse && props.onToggleCollapse(props.group!)
      });
    };

    const screenSize = useScreenSize();
    const embedConfig = async () => {
      const embeddedConfig = await getEmbedConfiguration({
        reportId: helper.selectedReport?.id!,
        screenSize,
        reportParameters: parameters,
        type: helper.selectedReport?.definition?.type ?? ReportType.Base
      });
      return embeddedConfig;
    };

    return (
      <Stack
        horizontal
        styles={{
          root: { width: "100%", height: "100%" }
        }}
      >
        <Stack>
          <Stack horizontal>
            <Heading variant="section-heading" styles={mainHeading}>
              Reports
            </Heading>
          </Stack>
          <Tile styles={listTile}>
            <Stack
              styles={{
                root: {
                  margin: reportButtonIcon.root.paddingRight,
                  marginBottom: 0
                }
              }}
            >
              <TextField
                name="search reports"
                placeholder="Search"
                value={searchString}
                onChange={(_e, value) => setSearchString(value)}
                iconProps={{ iconName: "Search" }}
              />
            </Stack>
            <Observer>
              {() => (
                <GroupedList
                  onRenderCell={renderReportButton}
                  {...reportDisplayList}
                  groupProps={{
                    onRenderHeader: renderSectionHeader
                  }}
                />
              )}
            </Observer>
          </Tile>
        </Stack>
        {helper.selectedReport ? (
          <Stack
            styles={{
              root: {
                width: "100%",
                height: "100%",
                justifyContent: "center"
              }
            }}
          >
            <DataFetcher
              fetch={() => embedConfig()}
              fallback={<ReportLoadingSpinner label="Loading report..." />}
              refetchId={helper.selectedReport.id}
            >
              {embed =>
                embed && (
                  <ReportDisplay
                    embeddedConfig={{ ...embed.report }}
                    metadata={metadata}
                  />
                )
              }
            </DataFetcher>
          </Stack>
        ) : (
          <Stack styles={noReportStyle}>
            {!selectedReport && urlReportId ? (
              <NoDataTile
                textProps={{ text: "Report not found" }}
                linkProps={{ hidden: true }}
              />
            ) : (
              <Text styles={{ root: { textAlign: "center" } }}>
                {hasReports ? NO_SELECTED_REPORT_TEXT : NO_REPORTS_TEXT}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    );
  }
);

const ReportsScreen = withFetch(
  x => [x.reports.getAllReports()],
  () => {
    return (
      <ReportsScreenContextProvider>
        <ReportsScreenBase />
      </ReportsScreenContextProvider>
    );
  }
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ReportsScreen;

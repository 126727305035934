import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { FunctionComponent, PropsWithChildren, useRef } from "react";

import { DataTimeFormats } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  BillingStatuses,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ApplyToDateKeys } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsListFilter.types.ts";
import { TransactionFilterBarBase } from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/TransactionFilterBarBase.tsx";
import {
  claimStatusesFilter,
  searchFilter,
  usersSelectFilter
} from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/transactionFilters.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import {
  AccreditedBillingFilter,
  AccreditedBillingFilterQuery
} from "./AccreditedBillingList.types.ts";
import {
  getAccreditedBillingFilterInitialValues,
  onFilterChange,
  parseToGetByArgs
} from "./utils.ts";

const nameOf = nameOfFactory<AccreditedBillingFilter>();

const defaultInvoicesFilter: AccreditedBillingFilter = Object.freeze({
  search: undefined,
  accountIds: undefined,
  transactionStatuses: undefined,
  claimStatuses: undefined,
  startTime: undefined,
  endTime: undefined,
  userIds: undefined,
  applyToDate: ApplyToDateKeys.transactionDate
});

interface AccreditedBillingListFilterProps extends PropsWithChildren {
  insurerIds: string[];
}

const AccreditedBillingListFilterBase: FunctionComponent<AccreditedBillingListFilterProps> =
  observer(({ children, insurerIds }) => {
    const { billing, practice, routing } = useStores();

    const filterQuery: AccreditedBillingFilterQuery = parse(
      routing.location.search
    );

    const initialValues = useRef<AccreditedBillingFilter>(
      getAccreditedBillingFilterInitialValues(filterQuery)
    );

    // contacts pre-loaded by AccreditedBillingScreen
    const insurers = insurerIds.map(id => practice.contactsMap.get(id));

    const insurerOptions = insurers.map(insurer => ({
      key: insurer?.id || "",
      text: insurer?.name || ""
    }));

    const items: Item<AccreditedBillingFilter>[] = [
      searchFilter(),
      {
        type: "optionsSelect",
        name: nameOf("accountIds"),
        props: {
          id: "accredited-billing-filter-insurers",
          multiSelect: true,
          placeholder: "Insurers",
          hideSearchOption: true,
          calloutWidth: 220,
          options: insurerOptions
        }
      },
      {
        type: "optionsSelect",
        name: nameOf("transactionStatuses"),
        props: {
          id: "accredited-billing-filter-transaction-statuses",
          multiSelect: true,
          placeholder: "Transaction state",
          hideSearchOption: true,
          calloutWidth: 170,
          options: [
            {
              key: PaymentStatuses.unpaid,
              text: "Unpaid"
            },
            {
              key: PaymentStatuses.part,
              text: "Part-paid"
            },
            {
              key: PaymentStatuses.paid,
              text: "Paid"
            },
            {
              key: BillingStatuses.cancelled,
              text: "Cancelled"
            }
          ]
        }
      },
      claimStatusesFilter(billing.ref.claimStatuses.keyTextValues),
      usersSelectFilter(),
      {
        type: "datesRangePicker",
        name: "datesRangePicker",
        props: {
          id: "accredited-billing-filter-dates-range",
          startDateProps: { id: "accredited-billing-filter-start-date" },
          endDateProps: { id: "accredited-billing-filter-end-date" },
          startDateName: nameOf("startTime"),
          endDateName: nameOf("endTime"),
          placeholder: "Date(s)",
          renderValue: (start, end, values) => {
            if (!start || !end) {
              return "";
            }

            const _startDate = DateTime.fromJSDate(start).toFormat(
              DataTimeFormats.DAY_DEFAULT_FORMAT
            );

            const _endDate = DateTime.fromJSDate(end).toFormat(
              DataTimeFormats.DAY_DEFAULT_FORMAT
            );

            const label =
              values.applyToDate === ApplyToDateKeys.transactionDate
                ? "Updated:"
                : "Serviced:";

            const value =
              _startDate !== _endDate
                ? `${_startDate} - ${_endDate}`
                : _startDate;

            return `${label} ${value}`;
          },
          selectionProps: {
            name: "applyToDate",
            options: [
              {
                key: ApplyToDateKeys.transactionDate,
                text: "Updated",
                name: "applyToDate"
              },
              {
                key: ApplyToDateKeys.serviceDate,
                text: "Serviced",
                name: "applyToDate"
              }
            ]
          }
        }
      }
    ];

    return (
      <TransactionFilterBarBase<AccreditedBillingFilter>
        initialValues={initialValues.current}
        onChange={onFilterChange}
        items={items}
        defaultFilter={defaultInvoicesFilter}
        parseToGetByArgs={search => parseToGetByArgs(search, insurerIds)}
      >
        {children}
      </TransactionFilterBarBase>
    );
  });

export const AccreditedBillingListFilter = withFetch(
  x => [x.billing.ref.claimStatuses.load()],
  AccreditedBillingListFilterBase
);

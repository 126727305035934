import { FormApi } from "final-form";

import { upsertItem } from "@bps/utils";
import { DateTime } from "@bps/utils/dist/libs/date-time/DateTime.js";
import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  GoalDataItemDto,
  PatientTreatmentPlanDataItemDto,
  TreatmentDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionsSidePanelHelper } from "../../claims/ConditionsSidePanelHelper.ts";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { getTreatmentData } from "../../SOTAP/SOTAP.utils.ts";
import {
  ManagementFormValues,
  TreatmentPlanLinkType
} from "./ManagementForm.Types.ts";
import { ManagementFormContent } from "./ManagementFormContent.tsx";
import { ManagementFormValidator } from "./validator/ManagementFormValidator.ts";

interface ManagementFormProps {
  clinicalRecord: ClinicalRecord;
}

const ManagementFormBase: React.FunctionComponent<ManagementFormProps> = ({
  clinicalRecord
}) => {
  const root = useStores();
  const { clinical, core, notification } = root;
  const { isViewOnlyOrDischarged, setSOTAPTab } =
    usePatientRecordScreenContext();

  const hasBeenReset =
    !!clinicalRecord.stashedClinicalData?.haveBeenResetForms.get(
      ClinicalDataType.PatientTreatmentPlan
    );

  const { linkConditionToEncounter } = new ConditionsSidePanelHelper(
    clinicalRecord,
    root,
    setSOTAPTab
  );

  const onDismiss = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.PatientTreatmentPlan
    );
  };

  const onReset = () => {
    onDismiss();
    clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
      ClinicalDataType.PatientTreatmentPlan,
      true
    );
  };

  const getInitialValueTreatmentPlanData = () => {
    const treatmentPlan = hasBeenReset
      ? clinicalRecord?.clinicalData?.patientTreatmentPlan
      : clinicalRecord.stashedClinicalData?.patientTreatmentPlan;

    if (clinicalRecord.openEncounter?.episodeOfCareId) {
      if (core.hasPermissions(Permission.MultiProviderClaimsAllowed)) {
        return treatmentPlan?.treatmentPlans?.find(
          d =>
            d.businessRoleCode === clinicalRecord.openEncounter?.businessRole &&
            d.linkId === clinicalRecord.episodeOfCare?.id &&
            !d.isDeleted
        );
      } else {
        return treatmentPlan?.treatmentPlans?.find(
          d => d.linkId === clinicalRecord.episodeOfCare?.id && !d.isDeleted
        );
      }
    }

    const alreadyCreatedorEditedForCurrentEncounter =
      treatmentPlan?.treatmentPlans?.find(
        x =>
          x.updateLog?.updatedEncounterId ===
            clinicalRecord.openEncounter?.id ||
          x.createLog?.createdEncounterId === clinicalRecord.openEncounter?.id
      );

    return alreadyCreatedorEditedForCurrentEncounter;
  };

  const getInitialValues = (): ManagementFormValues => {
    const treatmentPlanData = getInitialValueTreatmentPlanData();
    const treatments = getTreatmentData(treatmentPlanData?.treatments, false);
    const treatmentsBase = treatments.map(x => x.treatment);

    const goals =
      treatmentPlanData?.goals?.map(goal => ({
        ...goal,
        startDate: DateTime.jsDateFromISO(goal.startDate),
        endDate: DateTime.jsDateFromISO(goal.endDate),
        achievedDate: DateTime.jsDateFromISO(goal.achievedDate)
      })) || [];

    return {
      linkId: treatmentPlanData
        ? treatmentPlanData.linkId
        : clinicalRecord.episodeOfCare?.id,
      treatmentPlanLinkType: TreatmentPlanLinkType.episodeOfCare,
      goals,
      treatmentPlanDiscussed: treatmentPlanData?.treatmentPlanDiscussed,
      warningExplained: treatmentPlanData?.warningExplained,
      consentObtained: treatmentPlanData?.consentObtained,
      culturalNeedsIdentified: treatmentPlanData?.culturalNeedsIdentified,
      hasAdditionalDiscussions: !!treatmentPlanData?.additionalDiscussions,
      additionalDiscussions: treatmentPlanData?.additionalDiscussions,
      planTreatments: treatments,
      planTreatmentsBase: treatmentsBase,
      planHasOtherTreatments: treatmentPlanData?.otherTreatments !== undefined,
      planOtherTreatments: treatmentPlanData?.otherTreatments,
      planEducationOptions: treatmentPlanData?.educationOptions,
      planEducationComment: treatmentPlanData?.educationComment,
      planOtherEducationComment: treatmentPlanData?.otherEducationComment,
      planReview: treatmentPlanData?.plan
    };
  };

  const convertFormValuesToData = (values: ManagementFormValues) => {
    const businessRoleCode = clinicalRecord.openEncounter?.businessRole;

    let treatmentDataItem: PatientTreatmentPlanDataItemDto | undefined;
    if (values.linkId) {
      if (core.hasPermissions(Permission.MultiProviderClaimsAllowed)) {
        treatmentDataItem =
          clinicalRecord.stashedClinicalData?.patientTreatmentPlan?.treatmentPlans?.find(
            d =>
              d.businessRoleCode === businessRoleCode &&
              d.linkId === values.linkId
          );
      } else {
        treatmentDataItem =
          clinicalRecord.stashedClinicalData?.patientTreatmentPlan?.treatmentPlans?.find(
            d => d.linkId === values.linkId
          );
      }
    } else {
      treatmentDataItem =
        clinicalRecord.stashedClinicalData?.patientTreatmentPlan?.treatmentPlans?.find(
          d =>
            d.createLog?.createdEncounterId === clinicalRecord.openEncounter?.id
        );
    }

    const treatmentValues: TreatmentDataItemDto[] | undefined =
      values.planTreatments
        ?.filter(x => !!x.treatment)
        .map(x => {
          return {
            treatment: x.treatment,
            comment: x.comment
          };
        });

    const goalValues: GoalDataItemDto[] | undefined = values.goals
      ?.filter(x => !!x.goal)
      .map(x => {
        return {
          goal: x.goal,
          startDate: DateTime.fromJSDate(x.startDate)?.toISODate(),
          endDate: DateTime.fromJSDate(x.endDate)?.toISODate(),
          isAchieved: x.isAchieved,
          achievedDate: DateTime.fromJSDate(x.achievedDate)?.toISODate(),
          notAchievedReason: x.notAchievedReason
        };
      });

    const newTreatmentPlanData: PatientTreatmentPlanDataItemDto = {
      ...treatmentDataItem,
      treatments: !!treatmentValues?.length ? treatmentValues : undefined,
      educationOptions: values.planEducationOptions,
      otherEducationComment: values.planOtherEducationComment,
      educationComment: values.planEducationComment,
      otherTreatments: values.planOtherTreatments,
      plan: values.planReview,
      businessRoleCode,
      psfsGoalsAdded: false,
      linkId: values.linkId,
      treatmentPlanLinkType: values.treatmentPlanLinkType,
      goals: goalValues,
      treatmentPlanDiscussed: values.treatmentPlanDiscussed,
      warningExplained: values.warningExplained,
      consentObtained: values.consentObtained,
      culturalNeedsIdentified: values.culturalNeedsIdentified,
      additionalDiscussions: values.additionalDiscussions
    };

    const plans = upsertItem({
      item: newTreatmentPlanData,
      array:
        clinicalRecord.clinicalData?.patientTreatmentPlan?.treatmentPlans ?? [],
      predicate: x => x.id === newTreatmentPlanData.id
    });

    const data: EncounterClinicalDataDto = {};

    data.patientTreatmentPlan = {
      eTag: clinicalRecord.clinicalData?.patientTreatmentPlan?.eTag,
      treatmentPlans: plans
    };

    return data;
  };

  const onSubmit = async (values: ManagementFormValues) => {
    if (
      values.linkId &&
      values.treatmentPlanLinkType === "EOC" &&
      !clinicalRecord.openEncounter?.episodeOfCareId
    ) {
      await linkConditionToEncounter(values.linkId);
    }

    const data = convertFormValuesToData(values);

    await clinicalRecord.saveClinicalData(data);
  };

  const onSubmitSucceeded = (
    values: ManagementFormValues,
    form: FormApi<ManagementFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.PatientTreatmentPlan
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onReset();
    }
  };

  const managementValidator = new ManagementFormValidator();

  return (
    <ClinicalSubmissionForm<ManagementFormValues>
      formName="management"
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      noGap
      initialValues={getInitialValues()}
      readOnly={isViewOnlyOrDischarged}
      disableRoutePrompt
      heading="Plan"
      onCancel={onReset}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyManagementForm
      }
      disableButtonCondition={stashedData => !stashedData?.dirtyManagementForm}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        getInitialValueTreatmentPlanData()
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        getInitialValueTreatmentPlanData()
      )}
      validate={managementValidator.validate}
    >
      <>
        <StashedClinicalDataFormSpy<ManagementFormValues>
          clinicalRecord={clinicalRecord}
          getData={convertFormValuesToData}
          areasToObserve={{
            patientTreatmentPlan: [ClinicalDataType.PatientTreatmentPlan]
          }}
          withHasBeenResetFormCheck={ClinicalDataType.PatientTreatmentPlan}
        />
        <ManagementFormContent clinicalRecord={clinicalRecord} />
      </>
    </ClinicalSubmissionForm>
  );
};
export const ManagementForm = withFetch(
  x => [
    x.clinical.ref.treatmentOptions.load(),
    x.clinical.ref.educationOptions.load()
  ],
  ManagementFormBase
);

import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  confirm,
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { useDialogOpenedAnalytics } from "@libs/analytics/hooks/useDialogOpenedAnalytics.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { ContactCardIdsEnum } from "../../shared-components/types/contact-card-ids.enum.ts";
import { OrganisationCardIds } from "../../shared-components/types/organisation-card-ids.enum.ts";
import { PatientCardIds } from "../../shared-components/types/patient-card-ids.enum.ts";

type ContactListActionMenuProps = {
  contact: Contact;
  canOpenClinical: boolean;
  onShowEdit: (cardId: string, id: string) => void;
};

export const ContactListActionMenu: FC<ContactListActionMenuProps> = observer(
  ({ contact, onShowEdit, canOpenClinical }) => {
    const { routing, core, practice, notification } = useStores();
    const trackDialogOpened = useDialogOpenedAnalytics();

    const { setShowPatientMergeModal, setMasterPatient } = practice.ui;

    const patientLabel = usePatientLabel(false);
    const patientLabelCapitalized = usePatientLabel(true);

    const { id, isPatient, status } = contact;
    const isIndividual = contact.type === ContactType.Individual;

    const menuItems: IContextualMenuItem[] = [];

    const formMenuItems = useFormMenu(contact, { asSubmenu: true });

    const onReactivatePatient = async () => {
      const patient = await practice.getContact(id);

      const withComma = (label: string, value?: string) =>
        !!value?.trim() ? `, ${label} ${value}` : "";

      const title = patient.title
        ? core.ref.titles.values.find(ref => ref.code === patient.title)?.text
        : "";

      const postCode = withComma("postcode", patient.physicalAddress?.postCode);
      const dob = withComma("dob", patient.birthDate?.toDayDefaultFormat());
      const patientDetails = `${patientLabelCapitalized} ${title} ${patient.preferredFullName}${dob}${postCode}`;
      const subText = `${patientDetails} will be restored to Active status. Refer to the Knowledge Base for a description of the reactivate ${patientLabel} function`;

      trackDialogOpened(
        "Patient merge reactivation dialog",
        core.user?.fullName
      );

      const ok = await confirm({
        confirmButtonProps: { text: "Confirm and reactivate" },
        dialogContentProps: {
          title: `Confirm reactivate ${patientLabel}`,
          subText
        }
      });

      if (ok) {
        try {
          await practice.updatePatientMergedStatus(id, {
            status: ContactStatus.Active
          });
          notification.success("Merged patient reactivated");
        } catch (e) {
          notification.error(e.message);
        }
      }
    };

    if (isPatient) {
      if (canOpenClinical) {
        menuItems.push(
          {
            key: "clinicalRecord",
            className: "open-clinical-menu-item",
            text: "Start consult",
            onClick: () => {
              routing.push(
                routes.records.record.path({ id }),
                routing.getStateWithFromQuery()
              );
            },
            disabled:
              !core.hasPermissions(Permission.EncounterWrite) ||
              (core.hasPermissions(Permission.LicencingAllowed) &&
                !core.hasPermissions(Permission.CreateConsultAllowed))
          },
          {
            key: "recordUpdate",
            className: "record-update-clinical-menu-item",
            text: "Record update",
            onClick: () => {
              routing.push(
                routes.records.recordUpdate.path({ id }),
                routing.getStateWithFromQuery()
              );
            },
            disabled: !core.hasPermissions(Permission.EncounterWrite)
          },
          {
            key: "viewClinicalRecord",
            className: "view-clinical-menu-item",
            text: "View clinical record",
            onClick: () => {
              routing.push(
                routes.records.recordView.path({ id }),
                routing.getStateWithFromQuery()
              );
            }
          }
        );
      }

      menuItems.push(...formMenuItems, {
        key: "editPatient",
        className: "edit-patient-menu-item",
        text: "Edit demographics",
        onClick: () => onShowEdit(PatientCardIds.patientHeader, id),
        disabled: !core.hasPermissions(Permission.PatientWrite)
      });
    } else if (isIndividual) {
      menuItems.push({
        key: "editIndividual",
        className: "edit-individual-menu-item",
        text: "Edit demographics",
        onClick: () => onShowEdit(ContactCardIdsEnum.contactHeader, id),
        disabled: !core.hasPermissions(Permission.ContactWrite)
      });
    } else {
      menuItems.push({
        key: "editOrgDetails",
        className: "edit-org-menu-item",
        text: "Edit details",
        onClick: () => onShowEdit(OrganisationCardIds.OrganisationHeader, id),
        disabled: !core.hasPermissions(Permission.ContactWrite)
      });
    }

    const isPatientMergeAllowed = core.hasPermissions([
      Permission.PatientMergeAllowed,
      Permission.PatientMergeJobWrite
    ]);

    const showPatientMergeModal = () => {
      setShowPatientMergeModal(true);
      setMasterPatient(contact);
    };

    const handleMergePatientClick = async () => {
      const orgUnit = await practice.getOrgUnit(core.location?.id);
      const isPhysitrackEnabled = orgUnit?.orgUnitEnabledPhysitrack?.enabled;
      if (isPhysitrackEnabled) {
        const subText = `Your practice has a Physitrack integration. Ensure you merge your master and duplicate ${patientLabel} in Physitrack (if they both exist) before proceeding with your Omni merge`;

        const continueMergePatient = await confirm({
          confirmButtonProps: { text: "Continue to merge" },
          dialogContentProps: {
            title: "Physitrack integration reminder",
            subText
          }
        });

        if (!continueMergePatient) return;
      }

      showPatientMergeModal();
    };

    if (isPatient && isPatientMergeAllowed) {
      if (status === ContactStatus.Merged) {
        menuItems.push({
          key: "reactivatePatient",
          text: `Reactivate ${patientLabel}`,
          onClick: () => {
            onReactivatePatient();
          }
        });
      } else {
        menuItems.push({
          key: "mergePatient",
          text: `Merge ${patientLabel}`,
          onClick: () => {
            handleMergePatientClick();
          }
        });
      }
    }

    return (
      <Stack horizontalAlign="center" verticalAlign="center">
        <TooltipHost content="More">
          <IconButton
            {...dataAttribute(
              DataAttributes.Element,
              "contact-list-actions-button"
            )}
            menuIconProps={{ iconName: "More" }}
            menuProps={{
              items: menuItems.map(i => ({
                ...dataAttribute(
                  DataAttributes.Element,
                  "contact-list-actions-lis-item"
                ),
                ...i
              })),
              calloutProps: {
                ...dataAttribute(
                  DataAttributes.Element,
                  "contact-list-actions-list"
                )
              }
            }}
            styles={{
              root: { width: "32px", height: "36px", padding: 0 },
              flexContainer: { width: "32px", height: "36px" }
            }}
          />
        </TooltipHost>
      </Stack>
    );
  }
);

import {
  FontWeights,
  IColumn,
  ITheme,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";

import { MeasurementRow } from "./ObservationsExpandedTable.tsx";
import {
  clinicalTools,
  formatObservations,
  getMeasurementTypeLabel,
  normalizeLabel
} from "./utils.ts";

interface ObservationsMeasurementColumnsProps {
  dates: string[];
  groupedByDateAndType: GroupedByDateAndType;
  theme: ITheme;
}

interface GroupedMeasurement {
  id: string;
  value: string;
  timestamp: string;
  type: string;
  summary: string;
}

type GroupedByDateAndType = Record<
  string,
  Record<string, GroupedMeasurement[]>
>;

export interface FormattedObservation {
  value?: string;
  label?: string;
}

export type FormattedObservations = Record<string, FormattedObservation>;
const EN_DASH: string = " – ";

export const generateObservationColumns = ({
  dates,
  groupedByDateAndType,
  theme
}: ObservationsMeasurementColumnsProps): IColumn[] => {
  const formatTimeWithoutSeconds = (timeString?: string): string => {
    return timeString ? timeString.replace(/:\d{2} /, " ") : "";
  };

  const isClinicalTool = (type: string) => {
    return clinicalTools.has(normalizeLabel(getMeasurementTypeLabel(type)));
  };

  const renderObservations = (
    formattedObservations: Record<string, FormattedObservation>,
    type: string
  ) => {
    const numOfObs = Object.keys(formattedObservations).length;
    return (
      <Stack styles={{ root: { alignSelf: "flex-start" } }}>
        {Object.keys(formattedObservations).map(timestamp => {
          const observation = formattedObservations[timestamp];
          return (
            <Stack key={timestamp}>
              <Stack horizontal>
                <Stack>
                  {isClinicalTool(type) ? (
                    <TooltipHost
                      content={observation.label}
                      styles={{
                        root: {
                          alignSelf: "center"
                        }
                      }}
                    >
                      <Text
                        variant="medium"
                        styles={{
                          root: {
                            fontWeight: FontWeights.semibold,
                            paddingRight: "5px",
                            color: theme.palette.neutralPrimary
                          }
                        }}
                      >
                        {observation.value}
                      </Text>
                    </TooltipHost>
                  ) : (
                    <Text
                      variant="medium"
                      styles={{
                        root: {
                          fontWeight: FontWeights.semibold,
                          paddingRight: "5px",
                          color: theme.palette.neutralPrimary
                        }
                      }}
                    >
                      {observation.value}
                    </Text>
                  )}
                </Stack>
                {numOfObs > 1 && (
                  <Stack>
                    <Text
                      variant="medium"
                      styles={{
                        root: { color: theme.palette.neutralPrimaryAlt }
                      }}
                    >
                      {EN_DASH}
                      {formatTimeWithoutSeconds(timestamp)}
                    </Text>
                  </Stack>
                )}
              </Stack>

              {observation.label && !isClinicalTool(type) && (
                <Stack>
                  <Text
                    variant="xSmall"
                    styles={{
                      root: {
                        color: theme.palette.neutralPrimaryAlt,
                        paddingBottom: "10px"
                      }
                    }}
                  >
                    {observation.label}
                  </Text>
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return [
    {
      key: "type",
      name: "",
      fieldName: "type",
      minWidth: 150,
      maxWidth: 170,
      isResizable: true,
      onRender: (item: MeasurementRow) => {
        return (
          <Stack styles={{ root: { alignSelf: "flex-start" } }}>
            <Text
              styles={{
                root: {
                  color: theme.palette.neutralPrimary
                }
              }}
              variant="medium"
            >
              {item.type}
            </Text>
          </Stack>
        );
      }
    },
    ...dates.map(date => ({
      key: date,
      name: date,
      fieldName: date,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: MeasurementRow) => {
        const measurementsForTypeOnDate =
          groupedByDateAndType[date]?.[item.type] || [];

        if (measurementsForTypeOnDate.length > 0) {
          const formattedObservations: FormattedObservations =
            formatObservations(measurementsForTypeOnDate);
          return renderObservations(formattedObservations, item.type);
        }
        return EN_DASH;
      }
    }))
  ];
};

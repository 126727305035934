import { useField, useForm } from "react-final-form";

import { Stack, useTheme } from "@bps/fluent-ui";
import {
  ClinicalNoteFormat,
  ClinicalWorkflowFormat,
  DockView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UserLabels } from "../../labels.ts";
import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import { UserClinicalFormLabel } from "./UserClinicalFormLabel.tsx";

interface ConsultationNotesSettingsFromProps {}

export const ConsultationNotesSettingsFormFields: React.FC<
  ConsultationNotesSettingsFromProps
> = () => {
  const { userExperience, core } = useStores();

  const theme = useTheme();
  const { formFields } = getUserStylesSet(theme);

  const form = useForm();
  const {
    input: { value: clinicalWorkflow }
  } = useField("clinicalWorkflow", {
    subscription: { value: true }
  });

  const defaultDockViewOptions = () => {
    if (clinicalWorkflow !== ClinicalWorkflowFormat.SOTAP) {
      return userExperience.ref.dockViews.keyTextValues.filter(
        x => x.key !== DockView.Sotap
      );
    } else {
      return userExperience.ref.dockViews.keyTextValues;
    }
  };

  return (
    <Fieldset
      styles={{
        root: formFields
      }}
    >
      <Stack>
        <When permission={Permission.SOTAPInitialEncounter}>
          <UserClinicalFormLabel
            label={UserLabels.consultationWorkflow}
            description={UserLabels.defaultWorkflowDescription}
          />
          <DropdownField
            className="clinicalWorkflowDropdown"
            options={[
              { key: ClinicalWorkflowFormat.None, text: "None" },
              {
                key: ClinicalWorkflowFormat.SOTAP,
                text: "SOTAP MSK"
              }
            ]}
            name={nameof("clinicalWorkflow")}
            styles={{
              root: {
                width: "100%",
                marginBottom: 40
              }
            }}
          />
          <FieldSpy
            name={nameof("clinicalWorkflow")}
            onChange={(value: string) => {
              if (value === ClinicalNoteFormat.SOTAP) {
                form.change(
                  nameof("clinicalNotesFormat"),
                  ClinicalNoteFormat.SOTAP
                );
              } else {
                form.change(nameof("defaultDockView"), DockView.TodaysNotes);
              }
            }}
          />
          <UserClinicalFormLabel
            label={UserLabels.notesFormat}
            description={UserLabels.notesFormatDescription}
          />
          {clinicalWorkflow === ClinicalWorkflowFormat.SOTAP ? (
            <DropdownField
              className="notesFormatDropdown"
              options={[
                {
                  key: ClinicalNoteFormat.Default,
                  text: "Default",
                  disabled: clinicalWorkflow === ClinicalWorkflowFormat.SOTAP
                },
                {
                  key: ClinicalNoteFormat.SOTAP,
                  text: ClinicalNoteFormat.SOTAP
                }
              ]}
              name={nameof("clinicalNotesFormat")}
              styles={{
                root: {
                  width: "100%",
                  marginBottom: 40
                }
              }}
              disabled={true}
              title="SOTAP"
            />
          ) : (
            <DropdownField
              className="notesFormatDropdown"
              options={[
                {
                  key: ClinicalNoteFormat.Default,
                  text: "Default"
                },
                {
                  key: ClinicalNoteFormat.SOTAP,
                  text: ClinicalNoteFormat.SOTAP
                },
                {
                  key: ClinicalNoteFormat.SOAP,
                  text: ClinicalNoteFormat.SOAP
                }
              ]}
              name={nameof("clinicalNotesFormat")}
              styles={{
                root: {
                  width: "100%",
                  marginBottom: 40
                }
              }}
            />
          )}
        </When>
        {!core.isNZTenant && (
          <>
            <UserClinicalFormLabel
              label={UserLabels.notesFormat}
              description={UserLabels.notesFormatDescription}
            />

            <DropdownField
              className="notesFormatDropdown"
              options={[
                {
                  key: ClinicalNoteFormat.Default,
                  text: "Default"
                },
                {
                  key: ClinicalNoteFormat.SOAP,
                  text: ClinicalNoteFormat.SOAP
                }
              ]}
              name={nameof("clinicalNotesFormat")}
              styles={{
                root: {
                  width: "100%",
                  marginBottom: 40
                }
              }}
            />
          </>
        )}
        <UserClinicalFormLabel
          label={UserLabels.defaultDockedTab}
          description={UserLabels.dockViewControlInfo}
        />
        <DropdownField
          className="defaultDockView"
          options={defaultDockViewOptions()}
          name={nameof("defaultDockView")}
          styles={{
            root: {
              width: "100%",
              marginBottom: 40
            }
          }}
        />
        <Stack horizontal>
          <UserClinicalFormLabel
            label={UserLabels.captureDiscardConsultReason}
          />
          <ToggleField
            name={nameof("enforceDiscardConsultReason")}
            onText="required"
            offText="optional"
            styles={{
              root: { paddingLeft: "10px" },
              text: { selectors: { "&.ms-Label": { fontWeight: 600 } } } // This selector is because the existing font-weight cannot be overridden without using 2+ selectors.  Please update this if a better method is found.
            }}
          />
        </Stack>

        <When permission={Permission.EncounterTimerAllowed}>
          <Stack horizontal>
            <UserClinicalFormLabel label={UserLabels.timerEnabled} />
            <ToggleField
              name={nameof("timerEnabled")}
              onText="automatically when consultations start"
              offText="manually"
              styles={{
                root: { paddingLeft: "10px" },
                text: { selectors: { "&.ms-Label": { fontWeight: 600 } } } // This selector is because the existing font-weight cannot be overridden without using 2+ selectors.  Please update this if a better method is found.
              }}
            />
          </Stack>
        </When>
      </Stack>
    </Fieldset>
  );
};

import { FormApi } from "final-form";
import { useRef } from "react";

import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import {
  BodyExaminationContext,
  useBodyExaminationContext
} from "../../full-body-clinical-data/examination/context/BodyExaminationContext.ts";
import { BodyExaminationHelper } from "../../full-body-clinical-data/examination/context/BodyExaminationHelper.ts";
import { CentralNervousSystemFields } from "./CentralNervousSystemFields.tsx";
import { CentralNervousSystemFormValues } from "./CentralNervousSystemForm.types.ts";
import { CentralNervousSystemValidator } from "./validator/CentralNervousSystemValidator.tsx";

interface CentralNervousSystemFormProps {
  clinicalRecord: ClinicalRecord;
}

const CentralNervousSystemFormBase: React.FC<CentralNervousSystemFormProps> = ({
  clinicalRecord
}) => {
  const { isViewOnly } = usePatientRecordScreenContext();
  const { clinical, notification } = useStores();

  const {
    getCNSStashedClinicalData,
    cnsOnCancel,
    cnsOnSubmit,
    cnsInitialValues
  } = useBodyExaminationContext();

  const validator = new CentralNervousSystemValidator();
  const onSubmitSucceeded = (
    values: CentralNervousSystemFormValues,
    form: FormApi<CentralNervousSystemFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.CentralNervousSystem
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      cnsOnCancel();
    }
  };

  return (
    <ClinicalSubmissionForm<CentralNervousSystemFormValues>
      formName="central-nervous-system"
      onSubmit={cnsOnSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={cnsInitialValues}
      readOnly={isViewOnly}
      disableRoutePrompt
      heading="Central nervous system"
      validate={validator.validate}
      onCancel={() => {
        cnsOnCancel();
      }}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyAreas?.get(
          "centralNervousSystem"
        )
      }
      disableButtonCondition={stashedData =>
        !stashedData?.dirtyAreas?.get("centralNervousSystem")
      }
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.centralNervousSystemConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.centralNervousSystemConfirmed
      )}
    >
      <>
        <CentralNervousSystemFields />
        <StashedClinicalDataFormSpy<CentralNervousSystemFormValues>
          clinicalRecord={clinicalRecord}
          getData={getCNSStashedClinicalData}
          areasToObserve={{
            centralNervousSystem: [ClinicalDataType.CentralNervousSystem]
          }}
        />
      </>
    </ClinicalSubmissionForm>
  );
};

export const CentralNervousSystemForm: React.FC<
  CentralNervousSystemFormProps
> = ({ clinicalRecord }) => {
  const { clinical } = useStores();

  const helper = useRef(
    new BodyExaminationHelper(clinical, clinicalRecord)
  ).current;

  return (
    <BodyExaminationContext.Provider value={helper}>
      <CentralNervousSystemFormBase clinicalRecord={clinicalRecord} />
    </BodyExaminationContext.Provider>
  );
};

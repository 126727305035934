import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { Heading, ICalloutProps, IDropdownOption, Stack } from "@bps/fluent-ui";
import {
  AppointmentStatusCode,
  CalendarEventPriority
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AppointmentStatusDropdown } from "../../shared-components/appointment-status/AppointmentStatusDropdown.tsx";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

export const BookingEventCalloutStatusDropdown: React.FC<
  Pick<ICalloutProps, "onDismiss">
> = observer(({ onDismiss }) => {
  const root = useStores();
  const { core } = root;

  const {
    calendarEvent,
    changeAppointmentStatus,
    isStatusUpdating,
    startPatientMatchWorkflow
  } = useContext(BookingCalendarEventContext);

  const canWriteCalendarEvent = core.hasPermissions(
    Permission.CalendarEventWrite
  );

  const urgent = calendarEvent.priority === CalendarEventPriority.Urgent;

  return (
    <Stack>
      <Stack>
        <Heading labelPaddings>Status</Heading>
      </Stack>
      <Stack>
        <AppointmentStatusDropdown
          styles={{
            root: { minWidth: 160 }
          }}
          urgent={urgent}
          disabled={isStatusUpdating.get() || !canWriteCalendarEvent}
          onChange={(e, item: IDropdownOption) => {
            changeAppointmentStatus(item.key as AppointmentStatusCode).then(
              () => {
                onDismiss && onDismiss();
                if (calendarEvent.canShowPatientMatchReview)
                  startPatientMatchWorkflow();
              }
            );
          }}
          selectedKey={
            calendarEvent.appointmentStatus || AppointmentStatusCode.Booked
          }
          withNoEmptyOption
        />
      </Stack>
    </Stack>
  );
});

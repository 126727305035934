import { IStyle } from "@bps/fluent-ui";

import {
  CalendarStyleProps,
  CalendarStyles,
  MIN_SLOT_HEIGHT
} from "./Calendar.types.tsx";

export const getCalendarStyles = (
  props: CalendarStyleProps
): CalendarStyles => {
  const {
    theme: { palette },
    className
  } = props;

  const style: IStyle = { position: "relative" };

  return {
    root: [
      "bp",
      className,
      {
        ...style,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 265px)",
        selectors: {
          ".week": {
            selectors: {
              ".rbc-time-header-content": {
                minWidth: "auto",
                width: "auto"
              },
              ".rbc-time-view-resources": {
                selectors: {
                  "&.rbc-header": {
                    width: "0px"
                  },
                  "&.rbc-day-slot.rbc-header": {
                    minWidth: "51px"
                  }
                }
              },
              ".rbc-time-gutter div": {
                width: 71,
                minWidth: 71,
                maxWidth: 71
              }
            }
          }
        }
      }
    ],
    bigCalendar: [
      {
        ".rbc-time-gutter": {
          ".rbc-timeslot-group": {
            ".rbc-time-slot": {
              minHeight: MIN_SLOT_HEIGHT,
              paddingLeft: 4,
              paddingRight: 4
            },
            ".rbc-time-slot:not(:first-child)": {
              color: palette.neutralTertiary
            }
          }
        },
        ".rbc-header": {
          borderLeft: `1px solid ${palette.neutralTertiary}`
        },
        ".rbc-events-container": {
          marginLeft: 6
        },
        ".rbc-day-slot .rbc-background-event": {
          opacity: 1
        },
        ".rbc-event-label": {
          display: "none"
        },
        ".rbc-toolbar-label": {
          display: "none"
        },
        ".rbc-allday-cell": {
          display: "none"
        },
        ".rbc-timeslot-group": {
          borderBottom: `1px solid ${palette.neutralTertiary}`,
          selectors: {
            ".rbc-timeslot-group__item": {
              display: "flex",
              flexGrow: 1,
              minHeight: MIN_SLOT_HEIGHT
            },
            ":nth-child(odd)": {
              selectors: {
                ".rbc-timeslot-group__item .rbc-time-slot": {
                  selectors: {
                    ":last-child": {
                      borderBottom: "1px dotted rgba(0, 0, 0, 0.12)"
                    }
                  }
                }
              }
            }
          }
        },
        ".rbc-day-slot .rbc-timeslot-group__item .rbc-time-slot": {
          borderTopWidth: "0",
          borderBottom: "1px dotted rgba(0, 0, 0, 0.12)",
          zIndex: 0
        },
        ".rbc-current-time-indicator": {
          backgroundColor: props.theme.palette.orangeLighter
        },
        ".rbc-addons-dnd-resize-ns-anchor": {
          cursor: "row-resize",
          selectors: {
            "& .rbc-addons-dnd-resize-ns-icon ": {
              width: "100%",
              visibility: "hidden"
            }
          }
        },
        ".rbc-time-content": {
          display: "flex",
          flex: "1 0",
          alignItems: "flex-start",
          width: "100%",
          borderTop: `1px solid ${palette.neutralTertiary}`,
          overflowY: "auto",
          position: "relative"
        },
        ".rbc-event-content": {
          fontSize: "12px"
        },
        ".rbc-time-view-resources .rbc-time-gutter": {
          // ⚠️ before you do any changes!
          // z-index value depends on few others z-indexes in:
          // 1. AppointmentsFilter.tsx;
          // 2. AppointmentsFilterOverlay.tsx;
          // 3. BookingCalendar.tsx - events styling;
          zIndex: 60
        },
        ".rbc-event, .rbc-event.rbc-selected, .rbc-background-event.bp-event": {
          background: "none",
          color: props.theme.palette.black,
          borderRadius: 0,
          padding: 0,
          zIndex: 2
        },
        ".rbc-event.bp-event--filtered": {
          // ⚠️ before you do any changes!
          // z-index value depends on few others z-indexes in:
          // 1. Calendar.styles.tsx - time column;
          // 2. AppointmentsFilter.tsx;
          // 3. AppointmentsFilterOverlay.tsx;
          zIndex: 60
        },
        ".event-title": {
          padding: "3px 0",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        },
        ".rbc-time-header-content": {
          borderLeft: `0px solid ${palette.neutralTertiary}`,
          marginRight: "0px"
        },
        ".rbc-time-header-content + .rbc-time-header-content": {
          marginLeft: "0px"
        },
        ".rbc-time-header .rbc-overflowing": {
          borderRight: "1px solid transparent",
          borderLeft: "1px solid transparent"
        },
        ".rbc-time-header-content > .rbc-row.rbc-row-resource": {
          borderBottom: "none"
        },
        ".rbc-time-view": {
          border: "1px solid transparent"
        },
        ".rbc-time-view, .rbc-today": {
          background: palette.white
        }
      }
    ]
  };
};

import { Validator } from "@libs/validation/Validator.ts";
import { checkIfProviderCanDiagnose } from "@modules/acc/screens/claim/components/utils.ts";
import { ProviderDeclarationValuesType } from "@shared-types/acc/provider-declaration-values.type.ts";

export class ProviderDeclarationDraftValidator extends Validator<ProviderDeclarationValuesType> {
  constructor() {
    super();
    this.forField("providerTypeCode", [checkIfProviderCanDiagnose()]);
  }
}

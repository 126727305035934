import React from "react";

import { Heading, IconButton, Stack, TooltipHost } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ClinicalDataTypeLabel
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { SideNavContent } from "../SideNavContent.tsx";

export interface ObservationsSidePanelProps {}

export const ObservationsSidePanel: React.FC<
  ObservationsSidePanelProps
> = () => {
  const { clinical } = useStores();
  const handleOnShowAsTableClick = () => {
    clinical.ui.setPatientClinicalContent({
      type: ClinicalDataType.Observations
    });
  };
  return (
    <>
      <Stack horizontal>
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          {ClinicalDataTypeLabel.Observations}
        </Heading>
        <TooltipHost content="Show as table">
          <IconButton
            iconProps={{ iconName: "GUID" }}
            onClick={() => handleOnShowAsTableClick()}
            styles={{ icon: { height: "17px" } }}
          />
        </TooltipHost>
      </Stack>
      <SideNavContent />
    </>
  );
};

import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { PropsWithChildren, useRef } from "react";

import { DateTime } from "@bps/utils";
import { GetClaimsDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  ContactResultItemDto,
  ContactType,
  OrganisationContactDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { filterInsurers } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

enum ClaimFilterLabel {
  SearchClaimNumber = "Search by claim number"
}

export const defaultClaimsFilter: ClaimsFilterState = {
  claimNumber: undefined,
  providers: [],
  insurers: [],
  patients: undefined,
  claimStatusCode: [],
  dateFrom: undefined,
  dateTo: undefined,
  claimsActionRequired: undefined
};

export interface ClaimsFilterQuery extends Omit<GetClaimsDto, "discharged"> {}

export interface ClaimsFilterState
  extends Omit<ClaimsFilterQuery, "dateFrom" | "dateTo"> {
  dateFrom?: Date;
  dateTo?: Date;
  claimsActionRequired?: boolean;
}

const ClaimsFilterBase: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const { acc, routing } = useStores();

    const claimOptions = acc.ref.claimStatuses.keyTextValues;
    const sortedClaimOptions = Array.from(claimOptions).sort((a, b) =>
      a.text.localeCompare(b.text)
    );

    const setFilter = (newFilter: Partial<ClaimsFilterState>) => {
      const existingFilter: ClaimsFilterState = parse(routing.location.search, {
        parseBooleans: true
      });

      const query: ClaimsFilterState = {
        ...existingFilter,
        ...newFilter
      };

      if (!isEqual(query, existingFilter)) {
        routing.pushQueryStringParams(query);
      }
    };

    const filterQuery: ClaimsFilterQuery = parse(routing.location.search, {
      parseBooleans: true
    });

    const convertStringToArray = (
      filterQuery: string[] | undefined | string
    ) => {
      if (!filterQuery) {
        return [];
      }
      if (Array.isArray(filterQuery)) {
        return filterQuery;
      }
      return [filterQuery];
    };

    const onFilterChange = (
      field: keyof ClaimsFilterState,
      values: ClaimsFilterState
    ) => {
      if (field === "dateTo" || field === "dateFrom") {
        const value = values[field];
        return setFilter({
          [field]: value ? DateTime.jsDateToISODate(value) : undefined
        });
      }

      return setFilter({ [field]: values[field] });
    };

    const isFilterPristine = isEqual(filterQuery, defaultClaimsFilter);

    const initialValues = useRef<ClaimsFilterState>({
      patients: filterQuery.patients,
      claimStatusCode: convertStringToArray(filterQuery.claimStatusCode),
      claimNumber: filterQuery.claimNumber,
      insurers: convertStringToArray(filterQuery.insurers),
      claimIds: filterQuery.claimIds,
      providers: convertStringToArray(filterQuery.providers),
      dateTo: filterQuery.dateTo
        ? DateTime.jsDateFromISO(filterQuery.dateTo)
        : undefined,
      dateFrom: filterQuery.dateFrom
        ? DateTime.jsDateFromISO(filterQuery.dateFrom)
        : undefined,
      claimsActionRequired: filterQuery.claimsActionRequired
        ? filterQuery.claimsActionRequired
        : undefined
    });
    return (
      <FilterBar<ClaimsFilterState>
        initialValues={initialValues.current}
        presets={[
          {
            text: "Action required",
            name: "claimsActionRequired",
            id: "claims-action-required",
            iconName: "Warning",
            tooltip:
              "Only showing claims with the status of accredited employer, error, declined, not verified, ready or incomplete. This will not show private claims.",
            tooltipPresses:
              "Only showing claims with the status of accredited employer, error, declined, not verified, ready or incomplete. This will not show private claims.",
            valuesToBeSetOnToggleOn: {
              ...defaultClaimsFilter,
              claimsActionRequired: true
            },
            valuesToBeSetOnToggleOff: defaultClaimsFilter
          }
        ]}
        onChange={({ field, values }) => onFilterChange(field, values)}
        customResetButtonCondition={() => isFilterPristine}
        items={[
          {
            type: "contactPicker",
            name: "patients",
            stickItem: true,
            props: {
              iconName: "filter",
              styles: { root: { width: 500, minWidth: 500 } },
              pickerCalloutProps: { calloutWidth: 400 },
              inputProps: { placeholder: "Search by name" }
            }
          },
          {
            name: "claimNumber",
            type: "searchBox",
            stickItem: true,
            props: {
              placeholder: ClaimFilterLabel.SearchClaimNumber,
              styles: { root: { maxWidth: 250, minWidth: 250 } }
            }
          },
          {
            type: "bookableUsersSelect",
            name: "providers",
            props: {
              id: "claim-filter-providers",
              multiSelect: true,
              placeholder: "Provider",
              calloutWidth: 250
            }
          },
          {
            type: "contactsSelect",
            name: "insurers",
            props: {
              id: "claim-filter-insurers",
              multiSelect: true,
              placeholder: "Insurer",
              calloutWidth: 250,
              contactFilter: { types: [ContactType.Organisation] },
              resultFilterPredicate: (contact: ContactResultItemDto) => {
                const organisation = contact.value as OrganisationContactDto;
                return filterInsurers(organisation, false);
              }
            }
          },
          {
            type: "optionsSelect",
            name: "claimStatusCode",
            props: {
              id: "claim-filter-status",
              placeholder: "Status",
              options: sortedClaimOptions,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "datesRangePicker",
            name: "datesRangePicker",
            props: {
              id: "claims-filter-dates-range",
              startDateProps: { id: "claims-filter-start-date" },
              endDateProps: { id: "claims-filter-end-date" },
              startDateName: "dateFrom",
              endDateName: "dateTo",
              placeholder: "Date(s)"
            }
          }
        ]}
        styles={{
          peoplePicker: { minWidth: 400 }
        }}
        onReset={(actions, values) => {
          if (!isEqual(defaultClaimsFilter, values)) {
            actions.reset(defaultClaimsFilter);
            routing.pushQueryStringParams(defaultClaimsFilter);
          }
        }}
      >
        {() => children}
      </FilterBar>
    );
  }
);

export const ClaimsFilter = withFetch(
  x => x.acc.ref.claimStatuses.load(),
  ClaimsFilterBase
);

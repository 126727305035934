import { dataAttribute, DataAttributes, IconButton } from "@bps/fluent-ui";
import {
  MedicationClinicalDataItemDto,
  MedicationsTestElements
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";

interface MedicationListContextMenuProps {
  medication: MedicationClinicalDataItemDto;
}

export const MedicationListContextMenu: React.FC<
  MedicationListContextMenuProps
> = ({ medication }) => {
  const helper = useMedicationsContext();

  const cease = medication.lastRx;

  const onCeaseClick = () => {
    helper.ceaseMedication(medication);
  };

  const onDeleteClick = () => {
    helper.setSelectedCurrentMeds([medication]);
    helper.setDeleteDialogVisible(true);
  };

  return (
    <IconButton
      {...dataAttribute(
        DataAttributes.Element,
        MedicationsTestElements.ContextualMenu
      )}
      menuIconProps={{
        iconName: "More"
      }}
      menuProps={{
        items: cease
          ? [{ key: "cease", text: "Cease", onClick: onCeaseClick }]
          : [{ key: "delete", text: "Delete", onClick: onDeleteClick }]
      }}
    />
  );
};

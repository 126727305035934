import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  ButtonsGroupOption,
  confirm,
  ConfirmOptions,
  FontIcon,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  DocumentDestinationType,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  InboxStoreInDestinationType,
  ReportTypeKeys,
  UserActionType
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { InboxScreenContext } from "@modules/inbox/screens/context/InboxScreenContext.ts";
import { UploadDocFormLabels } from "@modules/inbox/screens/shared-components/upload-doc-form/UploadDocForm.types.ts";
import { UserInboxActionFields } from "@modules/inbox/screens/shared-components/UserInboxActionFields.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FormSubmitButtonsProps } from "@ui-components/form/submission-form/FormSubmitButtons.types.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import {
  UserInboxActionFormLabels,
  UserInboxActionFormValues,
  UserInboxActionProps,
  VisibilityAndConfidentiality
} from "./UserInboxActionForm.types.ts";
import { UserInboxActionFormValidator } from "./UserInboxActionFormValidator.tsx";

const validator = new UserInboxActionFormValidator();

export const getPropsForDeleteFileConfirmation = (
  fileName?: string
): ConfirmOptions => {
  return {
    dialogContentProps: {
      title: UploadDocFormLabels.DialogDeleteConfirmationTitle,
      subText: `Do you want to permanently remove ${fileName || ""}?`
    },
    cancelButtonProps: { text: "Cancel" },
    confirmButtonProps: { text: "Delete file" },
    minWidth: 480,
    maxWidth: 480
  };
};

const UserInboxActionFormBase: React.FC<UserInboxActionProps> = observer(
  ({ document, onClose }) => {
    const theme = useTheme();
    const { notification, inbox, core } = useStores();

    const { onInboxActionSubmit } = useContext(InboxScreenContext);

    const reportTypeLabel = (storeIn: string) => {
      if (storeIn === InboxStoreInDestinationType.ClinicalImages) {
        return UserInboxActionFormLabels.ImageIs;
      } else if (storeIn === InboxStoreInDestinationType.Investigations) {
        return UserInboxActionFormLabels.ResultIs;
      } else {
        return UserInboxActionFormLabels.DocumentIs;
      }
    };

    const initialValues: UserInboxActionFormValues = {
      storeIn: InboxStoreInDestinationType.CorrespondenceIn,
      documentId: document.id
    };

    const nameOf = nameOfFactory<UserInboxActionFormValues>();

    const handleOnRemoveClick = () => {
      confirm(getPropsForDeleteFileConfirmation(document.name)).then(
        confirmed => {
          if (confirmed) {
            inbox
              .deleteInboxDocument(document.id, document.documentDetailId)
              .then(() => {
                notification.success(
                  UserInboxActionFormLabels.RemoveFileFeedback
                );
                onClose();
              });
          }
        }
      );

      return true;
    };

    const buttonsProps: FormSubmitButtonsProps<UserInboxActionFormValues> = {
      styles: { root: { padding: "24px 0", marginTop: 8 } },
      onCancel: onClose,
      submitButtonProps: {
        text: UserInboxActionFormLabels.Save,
        iconProps: undefined
      },
      cancelButtonProps: {
        items: [
          {
            key: "removeDocument",
            text: UploadDocFormLabels.Delete,
            onClick: handleOnRemoveClick
          }
        ],
        styles: { root: { minWidth: 115 } }
      },
      hideButtonsSeparator: true
    };

    const iconColour = theme.palette.themePrimary;

    const visibilityOptions: ButtonsGroupOption<string>[] = [];

    if (core.hasPermissions(Permission.ConfidentialDataAllowed)) {
      visibilityOptions.push({
        key: VisibilityAndConfidentiality.Confidential,
        text: "confidential",
        onRenderText: () => {
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 4 }}
            >
              <FontIcon
                iconName="ShieldAlert"
                styles={{ root: { fontSize: 16, color: iconColour } }}
              />
              <Text>Confidential</Text>
            </Stack>
          );
        }
      });
    }

    visibilityOptions.push({
      key: VisibilityAndConfidentiality.Timeline,
      text: "Display on timeline",
      onRenderText: () => {
        return (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
            <FontIcon
              iconName="Clock"
              styles={{
                root: {
                  fontSize: 16,
                  color: iconColour,
                  verticalAlign: "center"
                }
              }}
            />
            <Text>Display on timeline</Text>
          </Stack>
        );
      }
    });

    return (
      <SubmissionForm<UserInboxActionFormValues>
        formName="user-inbox-action"
        initialValues={initialValues}
        styles={{
          fields: { overflowY: "hidden" },
          root: {
            padding: 8,
            borderRadius: 2,
            border: `1px solid ${theme.palette.neutralLighter}`
          }
        }}
        onSubmitSucceeded={values => {
          if (
            values.instructionCode === UserActionType.NoAction &&
            values.storeIn !== StoreType.Prescriptions
          ) {
            notification.success(
              `Document has been saved to ${
                values.storeIn === StoreType.Correspondence
                  ? DocumentDestinationType.Correspondence
                  : DocumentDestinationType.Investigations
              }`
            );
          } else {
            notification.success("Document has been sent to your Tasks");
          }

          // after document is actioned,
          // document is removed from the inboxDocumentsMap
          runInAction(() => {
            inbox.inboxDocumentsMap.delete(document.id);
          });

          onClose();
        }}
        onSubmitFailed={() =>
          notification.error("Error occurred while updating file")
        }
        validate={validator.validate}
        onSubmit={onInboxActionSubmit}
        buttonsProps={buttonsProps}
      >
        {({ values }) => {
          const reportTypeOptions = inbox.ref.reportTypes.keyTextValues
            .filter(reportType => reportType.key !== ReportTypeKeys.unchecked)
            .map((keyText: KeyTextValue) =>
              keyText.key === ReportTypeKeys.unacceptable &&
              values.storeIn === InboxStoreInDestinationType.Investigations
                ? { key: keyText.key, text: "Unacceptable" }
                : keyText
            );

          const filteredReportTypeOption =
            values.storeIn === InboxStoreInDestinationType.CorrespondenceIn
              ? reportTypeOptions.filter(
                  option => option.key !== ReportTypeKeys.normal
                )
              : reportTypeOptions;

          return (
            <Fieldset>
              <DropdownField
                label={UserInboxActionFormLabels.StoreIn}
                required
                options={inbox.ref.storeInDestinationsTypes.keyTextValues}
                name={nameOf("storeIn")}
                withNoEmptyOption
              />
              <ButtonsGroupSingleChoiceField
                label={reportTypeLabel(values.storeIn)}
                name={nameOf("reportType")}
                options={filteredReportTypeOption}
                styles={{
                  label: { paddingLeft: 10, paddingRight: 10 }
                }}
              />
              <ButtonsGroupSingleChoiceField
                label={UserInboxActionFormLabels.VisibilityAndConfidentiality}
                name={nameOf("clinicalProperty")}
                options={visibilityOptions}
                styles={{
                  label: { paddingLeft: 10, paddingRight: 10 }
                }}
              />
              <UserInboxActionFields />
            </Fieldset>
          );
        }}
      </SubmissionForm>
    );
  }
);

export const UserInboxActionForm = withFetch(
  x => [x.inbox.ref.reportTypes.load()],
  UserInboxActionFormBase
);

import { computed, observable, runInAction } from "mobx";

import { upsertItem } from "@bps/utils";
import {
  AddOutboundCommTemplateDto,
  OutboundCommChannel,
  OutboundCommTemplateMasterDto,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CommsDefaultCampaignSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";

import { TemplateConstants } from "../../TemplateConstants.ts";
import { AddTemplateFormValues } from "../AddTemplateFormValues.ts";

export class TemplateFormHelper {
  @observable templateList: OutboundCommTemplate[] = [];
  @observable templatesMasterList: OutboundCommTemplateMasterDto[] = [];
  @observable formattedTemplate: string = "";

  isClose: boolean;
  constructor(private root: IRootStore) {}

  get campaignDefaultTemplates() {
    return this.root.userExperience
      .tenantCommsDefaultCampaignSettings as CommsDefaultCampaignSettingDto[];
  }

  getDefaultTemplate = (templateId: string, outboundCommTypeCode: string) => {
    const tenantCommsDefaultCampaignSettings = this.campaignDefaultTemplates;
    return (
      tenantCommsDefaultCampaignSettings &&
      tenantCommsDefaultCampaignSettings.find(
        x => x.templateId === templateId && x.commType === outboundCommTypeCode
      )
    );
  };

  isDefaultTemplate = (templateId: string, outboundCommTypeCode: string) => {
    return !!this.getDefaultTemplate(templateId, outboundCommTypeCode);
  };

  isDefaultCampaignTemplateUpdateRequired = (
    isChecked: boolean,
    templateId: string,
    outboundCommTypeCode: string
  ) => {
    const isDefault = this.isDefaultTemplate(templateId, outboundCommTypeCode);
    return (!isDefault && isChecked) || (isDefault && !isChecked);
  };

  //Default template irrespective of outboundCommType.
  //This should get changed after the ability to record default templates per CommType
  getDefaultTemplateName = () => {
    if (
      this.campaignDefaultTemplates &&
      this.campaignDefaultTemplates.length > 0 &&
      this.templateList.length > 0
    ) {
      return this.templateList.find(
        x => x.id === this.campaignDefaultTemplates[0].templateId
      )?.name;
    }

    return undefined;
  };

  getNewDefaultCampaign = (
    template: OutboundCommTemplate,
    isChecked: boolean
  ) => {
    const tenantCommsDefaultCampaignSettings =
      this.campaignDefaultTemplates ?? [];

    const templateIndex = tenantCommsDefaultCampaignSettings.findIndex(
      x =>
        x.commType === template.outboundCommTypeCode &&
        x.templateId === template.id
    );
    if (templateIndex >= 0 && !isChecked) {
      tenantCommsDefaultCampaignSettings.splice(templateIndex, 1);
      return { value: tenantCommsDefaultCampaignSettings };
    } else {
      return {
        value: upsertItem<CommsDefaultCampaignSettingDto>({
          item: {
            commType: template.outboundCommTypeCode,
            templateId: template.id
          },
          array: tenantCommsDefaultCampaignSettings,
          predicate: x => x.commType === template.outboundCommTypeCode
        })
      };
    }
  };

  getTemplateList = async () => {
    const result = await this.root.comms.getAllOutboundCommTemplates();
    runInAction(() => {
      this.templateList = result;
    });
    return this.templateList;
  };

  getTemplatesMasterList = async () => {
    const result = await this.root.comms.getAllMasterTemplates();
    runInAction(() => {
      this.templatesMasterList = result;
    });
    return this.templatesMasterList;
  };

  handleClose = () => {
    this.isClose = true;
  };

  @computed
  get template() {
    const cachedList = this.templateList.length
      ? this.templateList
      : Array.from(this.root.comms.outboundCommTemplatesMap.values());

    const templateId = this.root.routing.match(
      routes.settings.communications.template.edit
    )
      ? this.root.routing.match(routes.settings.communications.template.edit)
          ?.params.id
      : undefined;

    return cachedList.find(x => x.id === templateId);
  }

  getInitialValues = () => {
    if (this.template) {
      return {
        templateName: this.template.name,
        templateType: this.template.outboundCommTypeCode,
        templateEditor: this.template.channelTemplates[0].template,
        templateId: this.template.id,
        isDefaultTemplate: this.isDefaultTemplate(
          this.template.id,
          this.template.outboundCommTypeCode
        ),
        defaultTemplateName: this.getDefaultTemplateName()
      };
    } else {
      return {} as AddTemplateFormValues;
    }
  };

  addEditTemplate = async (formValues: AddTemplateFormValues) => {
    let template: OutboundCommTemplate;
    try {
      if (
        this.root.routing.match(routes.settings.communications.template.new)
      ) {
        template = await this.root.comms.addOutboundCommTemplate(
          this.getUpdatedTemplateValues(formValues)
        );

        //Update template list
        runInAction(() => {
          this.templateList.push(template);
        });
      } else {
        template = await this.root.comms.patchOutboundCommTemplate({
          ...this.getUpdatedTemplateValues(formValues),
          id: formValues.templateId
        });

        //Update template list
        runInAction(() => {
          this.templateList = this.templateList.filter(
            x => x.id !== template.id
          );
          this.templateList.push(template);
        });
      }

      //Update tenant default campaign settings
      const isDefaultTemplate = formValues.isDefaultTemplate ?? false;
      const updateRequired = this.isDefaultCampaignTemplateUpdateRequired(
        isDefaultTemplate,
        template.id,
        template.outboundCommTypeCode
      );
      if (updateRequired) {
        await this.root.userExperience.updateTenantSetting({
          ...this.root.userExperience.tenantSetting?.dto,
          eTag: this.root.userExperience.tenantSetting?.eTag ?? "",
          commsDefaultCampaign: this.getNewDefaultCampaign(
            template,
            isDefaultTemplate
          )
        });
      }

      if (!this.isClose)
        this.root.routing.push(
          routes.settings.communications.template.edit.path({
            id: template.id
          })
        );
      else {
        this.root.routing.push(
          routes.settings.communications.templates.pattern
        );
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  renderTemplate = async (template: string) => {
    const map = new Map();

    if (template !== undefined || null) {
      this.tags.forEach(element => {
        if (template.match(`{{${element.name}}}`)) {
          const elements = element.name.split(/(\s+)/);
          map.set(elements[elements.length - 1], element.mappedValue);
        }
      });

      if (map.size !== 0) {
        let templateParameters = JSON.stringify(Object.fromEntries(map));
        templateParameters = JSON.parse(templateParameters);

        const response = await this.root.comms.renderOutboundCommTemplate({
          template,
          templateParameters,
          outboundCommTypeCode: OutboundCommType.ApptReminder
        });

        runInAction(() => {
          this.formattedTemplate = response.content;
        });
      } else
        runInAction(() => {
          this.formattedTemplate = template;
        });
    } else
      runInAction(() => {
        this.formattedTemplate = "";
      });
  };

  @computed
  get smsCount() {
    if (
      this.formattedTemplate.length > 0 &&
      this.formattedTemplate.length <=
        TemplateConstants.SMS_ONEMSG_CHARACTER_COUNT
    )
      return 1;
    else if (
      this.formattedTemplate.length >
        TemplateConstants.SMS_ONEMSG_CHARACTER_COUNT &&
      this.formattedTemplate.length <=
        TemplateConstants.SMS_TWOMSG_CHARACTER_COUNT
    )
      return 2;
    else if (
      this.formattedTemplate.length >
        TemplateConstants.SMS_TWOMSG_CHARACTER_COUNT &&
      this.formattedTemplate.length <=
        TemplateConstants.SMS_THREEMSG_CHARACTER_COUNT
    )
      return 3;
    else if (
      this.formattedTemplate.length >
        TemplateConstants.SMS_THREEMSG_CHARACTER_COUNT &&
      this.formattedTemplate.length <=
        TemplateConstants.SMS_FOURMSG_CHARACTER_COUNT
    )
      return 4;
    else if (
      this.formattedTemplate.length >
        TemplateConstants.SMS_FOURMSG_CHARACTER_COUNT &&
      this.formattedTemplate.length <=
        TemplateConstants.SMS_FIVEMSG_CHARACTER_COUNT
    )
      return 5;
    else if (
      this.formattedTemplate.length >
        TemplateConstants.SMS_FIVEMSG_CHARACTER_COUNT &&
      this.formattedTemplate.length <=
        TemplateConstants.SMS_SIXMSG_CHARACTER_COUNT
    )
      return 6;
    else if (
      this.formattedTemplate.length >
        TemplateConstants.SMS_SIXMSG_CHARACTER_COUNT &&
      this.formattedTemplate.length <=
        TemplateConstants.SMS_SEVENMSG_CHARACTER_COUNT
    )
      return 7;
    else if (
      this.formattedTemplate.length >
        TemplateConstants.SMS_SEVENMSG_CHARACTER_COUNT &&
      this.formattedTemplate.length <= TemplateConstants.SMS_MAX_CHARACTER_COUNT
    )
      return 8;
    else return 0;
  }

  @computed
  get isTemplateLengthValid() {
    return (
      this.formattedTemplate.length <= TemplateConstants.SMS_MAX_CHARACTER_COUNT
    );
  }

  private getUpdatedTemplateValues = (
    values: AddTemplateFormValues
  ): AddOutboundCommTemplateDto => {
    return {
      outboundCommTypeCode: values.templateType,
      name: values.templateName,
      channelTemplates: [
        {
          outboundCommChannelCode: OutboundCommChannel.Sms,
          template: values.templateEditor,
          outboundCommTemplateId: values.templateId
        }
      ]
    };
  };

  readonly tags: { name: string; text: string; mappedValue: string }[] = [
    { name: "ContactFirstName", text: "First name", mappedValue: "George" },
    { name: "ContactLastName", text: "Last name", mappedValue: "Wright" },
    {
      name: "ContactPreferredName",
      text: "Preferred name",
      mappedValue: "George Wright"
    },
    {
      name: "ContactFullName",
      text: "Full name",
      mappedValue: "George Wright"
    },
    {
      name: "Date CalendarEventDateTime",
      text: "Date",
      mappedValue: "2022-10-25T23:00:10.584+10:00"
    },
    {
      name: "Time CalendarEventDateTime",
      text: "Start time",
      mappedValue: "2022-10-25T23:00:10.584+10:00"
    },
    {
      name: "DayOfWeek CalendarEventDateTime",
      text: "Day of week",
      mappedValue: "2022-10-25T23:00:10.584+10:00"
    },
    {
      name: "AppointmentType",
      text: "Appointment type",
      mappedValue: "Short consultation"
    },
    {
      name: "ProviderFirstName",
      text: "Provider first name",
      mappedValue: "David"
    },
    {
      name: "ProviderLastName",
      text: "Provider last name",
      mappedValue: "Fielding"
    },
    { name: "ProviderTitle", text: "Provider title", mappedValue: "Dr." },
    {
      name: "ProviderFullNameWithTitle",
      text: "Provider fullname with title",
      mappedValue: "Dr David Fielding"
    },
    {
      name: "PracticeLocation",
      text: "Practice name",
      mappedValue: "Market Street Medical Practice"
    },
    {
      name: "FullAddress",
      text: "Full address",
      mappedValue: "181 Old Cleveland Road, Capalaba Qld"
    },
    { name: "Suburb", text: "Suburb", mappedValue: "Capalaba" },
    { name: "Postcode", text: "Postcode", mappedValue: "4156" }
  ];
}

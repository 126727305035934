import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, memo, useContext, useEffect } from "react";

import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentFormFields } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/AppointmentFormFields.tsx";
import { AppointmentFormHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormHelper.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { ICondition as AppointmentCondition } from "@shared-types/clinical/condition.interface.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { SubmissionFormDialogProps } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.types.ts";

import { WaitingListReBookAppointmentDialog } from "../../../waiting-list/components/waiting-list-details-dialog/WaitingListReBookAppointmentDialog.tsx";
import { getSubmissionFormStyles } from "./components/appointment-form/AppointmentForm.styles.tsx";
import { AppointmentFormValidator } from "./components/appointment-form/AppointmentFormValidator.ts";
import { AppointmentFormContext } from "./components/appointment-form/context/AppointmentFormContext.ts";
import { SecondColumnFieldsSwitcher } from "./components/appointment-form/SecondColumnFieldsSwitcher.tsx";

export interface AppointmentDialogProps {}

const AppointmentDialogBase: React.FunctionComponent<AppointmentDialogProps> =
  observer(() => {
    const {
      initialValues,
      handleSubmit,
      createdConditions,
      clearCreatedConditions,
      isGroupAppointment
    } = useContext(AppointmentFormContext);

    const appointmentFormValidator = new AppointmentFormValidator(
      isGroupAppointment
    );

    const { booking, acc, clinical } = useStores();

    useEffect(() => {
      // this useEffect is to ensure any temporary events get deleted when the dialog is closed
      //  or when the user closes / refreshes the app while the dialog is stil open
      window.addEventListener(
        "beforeunload",
        booking.deleteTemporaryReservation
      );

      return () => {
        window.removeEventListener(
          "beforeunload",
          booking.deleteTemporaryReservation
        );
        booking.deleteTemporaryReservation().then(
          action(() => {
            booking.ui.isEditSingleEvent = false;
          })
        );
      };
    }, [booking]);

    const handleDismiss = async () => {
      // delete the new created conditions
      if (createdConditions.length > 0) {
        createdConditions.forEach(async createdCondition => {
          createdCondition.claim &&
            (await acc.deleteBulkClaims([createdCondition.claim.id]));
          if (!createdCondition.claim) {
            //Only EpisodesOfCare / conditions that have no linked claims can be deleted from the front end,
            //linked episodesOfCare/ Conditions will be removed via event when the claim is deleted
            await clinical.deleteEpisodeOfCare(
              createdCondition.episodeOfCareId
            );
          }
        });
      }
      if (booking.ui.currentAppointment?.onCancel) {
        booking.ui.currentAppointment.onCancel();
      }
      booking.ui.hideCalendarEventDialog();
    };

    const handleSubmitted: SubmissionFormDialogProps<AppointmentFormValues>["onSubmitSucceeded"] =
      values => {
        clearCreatedConditions();
        if (booking.ui.currentAppointment?.onSubmitted) {
          booking.ui.currentAppointment.onSubmitted(values);
        }
        booking.ui.hideCalendarEventDialog();
      };

    return (
      <SubmissionFormDialog<AppointmentFormValues>
        dialogName={`${
          !booking?.ui.currentAppointment?.id ? "New" : "Edit"
        } appointment dialog`}
        dialogProps={{
          onDismiss: handleDismiss,
          maxWidth: "auto",
          minWidth: "auto",
          dialogContentProps: {
            styles: {
              title: { display: "none" },
              inner: { padding: 0, height: "100%" },
              innerContent: { padding: 0 }
            }
          }
        }}
        validate={appointmentFormValidator.validate}
        onSubmit={handleSubmit}
        onSubmitSucceeded={handleSubmitted}
        initialValues={initialValues}
        styles={getSubmissionFormStyles(
          booking.ui.currentAppointment?.secondColumnContent
        )}
        hideButtons
        sidePanel={<SecondColumnFieldsSwitcher />}
      >
        {() => <AppointmentFormFields onCancel={handleDismiss} />}
      </SubmissionFormDialog>
    );
  });

export const AppointmentDialogWithContext: FunctionComponent<{
  appointmentType: AppointmentType;
  condition: AppointmentCondition | undefined;
}> = memo(({ appointmentType, condition }) => {
  const root = useStores();
  const helper = new AppointmentFormHelper(root, appointmentType, condition);

  return (
    <AppointmentFormContext.Provider value={helper}>
      <AppointmentDialogBase />
    </AppointmentFormContext.Provider>
  );
});

const AppointmentDialog: React.FunctionComponent = observer(() => {
  const root = useStores();
  const { booking, core } = root;
  if (booking.ui.cancelledAppointmentDetails) {
    const { providerId, timeOptions, orgUnitId } =
      booking.ui.cancelledAppointmentDetails;

    if (timeOptions) {
      const { startTime, endTime } = timeOptions;
      if (startTime && endTime) {
        return (
          <DataFetcher
            fetch={() => booking.getWaitingListRecordsFilteredValues({})}
          >
            {() => (
              <WaitingListReBookAppointmentDialog
                providerId={providerId}
                startTime={startTime}
                endTime={endTime}
                orgUnitId={orgUnitId}
              />
            )}
          </DataFetcher>
        );
      }
    }
  }

  if (
    booking.ui.currentAppointment &&
    booking.ui.currentAppointment.type === CalendarEventType.Appointment
  ) {
    const loadCondition = async (
      root: RootStore
    ): Promise<AppointmentCondition | undefined> => {
      const isExistingOrLinkedWithConditionAppt =
        booking.ui.currentAppointment?.id ||
        booking.ui.currentAppointment?.initialValues?.episodeOfCareId;

      if (isExistingOrLinkedWithConditionAppt) {
        let episodeOfCareId: string | undefined;

        if (booking.ui.currentAppointment?.id) {
          const calendarEvent = await root.booking.getCalendarEvent(
            booking.ui.currentAppointment.id
          );
          if (calendarEvent?.reason?.episodeOfCareId) {
            episodeOfCareId = calendarEvent?.reason?.episodeOfCareId;
          }
        }

        if (!episodeOfCareId) {
          episodeOfCareId =
            booking.ui.currentAppointment?.initialValues?.episodeOfCareId;
        }

        if (episodeOfCareId) {
          const condition = await root.clinical.getCondition(episodeOfCareId);
          await condition.loadClaim();
          await condition?.claim?.loadCalendarEvent();
          return {
            episodeOfCareId: condition.id,
            claim: condition.claim,
            patientId: condition.claim?.patientId,
            providerId: condition.claim?.providerId,
            primaryDiagnosis: condition.primaryDiagnosis,
            claimEpisodeOfCare: condition.claimEpisodeOfCare,
            isPrivate: condition.isPrivate,
            referralNumber: condition.referralNumber,
            discharged: condition.discharged,
            createdDate: condition.createdDate
          };
        }
      }
      return undefined;
    };

    const loadAppointmentData = async (
      root: RootStore
    ): Promise<AppointmentCondition | undefined> => {
      const [, condition] = await Promise.all([
        root.booking.loadAppointmentTypes(),
        core.isNZTenant ? loadCondition(root) : undefined,
        root.practice.ref.accProviderTypes.load(),
        root.practice.ref.accProviderContractTypes.load()
      ]);

      return condition;
    };

    return (
      <DataFetcher fetch={loadAppointmentData}>
        {condition => {
          const activeTypes = booking.activeAppointmentTypes;

          return (
            <AppointmentDialogWithContext
              appointmentType={activeTypes[0]}
              condition={condition}
            />
          );
        }}
      </DataFetcher>
    );
  }

  return null;
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default AppointmentDialog;

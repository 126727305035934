import { Observer, observer } from "mobx-react-lite";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";

import {
  DetailsRow,
  DetailsRowFields,
  Heading,
  IColumn,
  IDetailsRowFieldsProps,
  IDetailsRowProps,
  Link,
  mergeStyleSets,
  NoDataTile,
  ScrollablePane,
  Selection,
  SelectionMode,
  Stack,
  Text,
  useFormContext,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import {
  AdvancedFilter,
  DocumentEntityType
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { InboxScreenLabels } from "@modules/inbox/screens/inbox/Inbox.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { getPDFStyles } from "@ui-components/document-viewer/pdf.styles.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { ItalicCenterText } from "@ui-components/ItalicCenterText.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { hideUnlessPrintingStyles } from "@ui-components/printing/Print.styles.ts";
import { PrintContentWrapper } from "@ui-components/printing/PrintContentWrapper.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { IncomingLabels } from "../IncomingScreen.types.ts";
import { IncomingFilterValues } from "./IncomingFilter.tsx";

export const IncomingList: FC = observer(() => {
  const {
    core,
    inbox,
    practice: {
      ui: { showContactDetails }
    }
  } = useStores();

  const theme = useTheme();

  const [showDialog, setShowDialog] = useState(false);
  const { noMarginsLandscapePdfstyles } = getPDFStyles(theme);

  const {
    state: { dirty, values },
    actions: { reset }
  } = useFormContext<IncomingFilterValues>();

  const {
    removedInboxDocId,
    setSelectedInboxDocKey,
    inboxDocument,
    selectedInboxDocKey,
    fetchAndSetInboxDocument,
    setInboxDocument
  } = useContext(InboxScreenContext);

  const search = useCallback(
    (query?: PagingOptions) => {
      const filter = {
        ...values,
        receivedDate: DateTime.jsDateToISODate(values.receivedDate)
      };

      return inbox.fetchInboxDocuments({
        ...query,
        ...filter,
        advancedFilter: AdvancedFilter.InboxIncoming
      });
    },
    [inbox, values]
  );

  const handleLinkClicked = async (
    e: React.MouseEvent,
    item: InboxDocument
  ) => {
    e.preventDefault();
    const fetchedInboxDoc = await fetchAndSetInboxDocument({
      documentDetailId: item.documentDetailId,
      id: item.id
    });
    setInboxDocument(fetchedInboxDoc);
    setShowDialog(true);
  };

  const columns: IColumn[] | undefined = [
    {
      name: IncomingLabels.Received,
      onRender: (item: InboxDocument) => {
        const date = DateTime.fromJSDate(
          item.receivedDate
        )?.toDayDefaultFormat();
        return <Observer>{() => <Text>{date}</Text>}</Observer>;
      },
      key: IncomingLabels.Received,
      minWidth: 80,
      maxWidth: 90
    },
    {
      name: IncomingLabels.PatientName,
      onRender: (item: InboxDocument) => (
        <Observer>
          {() => {
            return item.contact ? (
              <Navigate onClick={() => showContactDetails(item.contact!.id)}>
                {item.contact.name}
              </Navigate>
            ) : (
              <Text styles={{ root: { fontStyle: "italic" } }}>
                {IncomingLabels.Unassigned}
              </Text>
            );
          }}
        </Observer>
      ),
      key: IncomingLabels.PatientName,
      minWidth: 150,
      maxWidth: 250
    },
    {
      name: IncomingLabels.Subject,
      onRender: (item: InboxDocument) => (
        <Observer>
          {() => {
            return (
              <Link
                data-selection-disabled={true}
                onClick={async (event: React.MouseEvent) =>
                  handleLinkClicked(event, item)
                }
              >{`${item.name}.${item.docExtension}`}</Link>
            );
          }}
        </Observer>
      ),
      key: IncomingLabels.Test,
      minWidth: 150,
      maxWidth: 450
    },
    {
      name: IncomingLabels.AddressedTo,
      onRender: (item: InboxDocument) => (
        <Observer>
          {() => {
            return item.user ? (
              <Navigate
                to={routes.settings.users.user.path({ id: item.user.id })}
              >
                {item.user.fullName}
              </Navigate>
            ) : (
              <Text styles={{ root: { fontStyle: "italic" } }}>
                {IncomingLabels.NotAddressed}
              </Text>
            );
          }}
        </Observer>
      ),
      key: IncomingLabels.AddressedTo,
      minWidth: 200,
      maxWidth: 450
    }
  ];
  useEffect(() => {
    if (!selectedInboxDocKey) {
      selection.current.setAllSelected(false);
    }
  }, [selectedInboxDocKey]);

  const selection = useRef(
    new Selection({
      onSelectionChanged: async () => {
        const indexes = selection.current.getSelectedIndices();
        const itemKey = indexes[0];
        const item = selection.current.getItems()[itemKey] as InboxDocument;

        if (!itemKey) {
          setSelectedInboxDocKey();
          setInboxDocument(item);
        }

        if (item) {
          setSelectedInboxDocKey({
            documentDetailId: item.documentDetailId,
            inboxDocumentId: item.id
          });

          setInboxDocument(item);
        }
        return;
      }
    })
  );

  const handleOnRenderRow = (props: IDetailsRowProps): JSX.Element => {
    return (
      <DetailsRow
        {...props}
        styles={{
          root: {
            "&.is-selected:hover": {
              backgroundColor: theme.semanticColors.listItemBackgroundChecked
            }
          }
        }}
        rowFieldsAs={renderRowFields}
      />
    );
  };

  const renderRowFields = (props: IDetailsRowFieldsProps) => {
    return (
      <Stack verticalAlign="center">
        <DetailsRowFields {...props} />
      </Stack>
    );
  };

  const onRenderNoResults = () => {
    return dirty ? (
      //position: "absolute" is used here because otherwise the resetFilters link of <NoSearchResult> will not be clickable
      //as <ScrollablePane> also has position absolute;
      <Stack
        horizontal
        styles={{
          root: {
            height: "100%",
            width: "100%",
            position: "absolute",
            paddingTop: 50
          }
        }}
      >
        <NoDataTile
          styles={{ root: { width: "100%", height: "100%" } }}
          textProps={{ text: "0 matches found" }}
          linkProps={{
            text: "Clear filters",
            onClick: () => reset()
          }}
          showBoxShadow={false}
        />
      </Stack>
    ) : (
      <ItalicCenterText>{InboxScreenLabels.NoReports}</ItalicCenterText>
    );
  };

  const dateOfPrint = DateTime.now().toDayDefaultFormat();

  return (
    <div style={{ position: "relative", flexGrow: 1 }}>
      <ScrollablePane>
        <PrintContentWrapper hidePrintContent={false}>
          {/* Heading for printing only */}
          <Heading variant="section-heading" styles={hideUnlessPrintingStyles}>
            {InboxScreenLabels.IncomingReports}
          </Heading>
          <InfiniteScrollList<InboxDocument>
            getItems={search}
            selectionMode={SelectionMode.single}
            selection={selection.current}
            selectionPreservedOnEmptyClick={true}
            onRenderRow={handleOnRenderRow}
            columns={columns}
            setKey="incoming-list"
            onRenderNoResults={onRenderNoResults}
            refreshKey={removedInboxDocId}
          />
          {/* Footer for printing only */}
          <Stack
            styles={mergeStyleSets(hideUnlessPrintingStyles, {
              root: {
                paddingTop: 24,
                fontStyle: "italic"
              }
            })}
          >
            <Text block>{`${IncomingLabels.DatePrinted}: ${dateOfPrint}`}</Text>
            <Text
              block
            >{`${IncomingLabels.PrintedBy}: ${core.user?.name}`}</Text>
          </Stack>
        </PrintContentWrapper>
      </ScrollablePane>

      {showDialog && inboxDocument && (
        <DocumentViewerDialog
          previewIfAvailable
          pageStyle={noMarginsLandscapePdfstyles}
          getDocument={async () => {
            const documentId = inboxDocument.id;

            if (documentId) {
              return {
                documentId,
                entityId: inboxDocument.id,
                previewUri: inboxDocument.previewUri,
                extension: inboxDocument.docExtension ?? "pdf",
                entityType: DocumentEntityType.Patient,
                downloadUri: inboxDocument?.downloadLinkUri,
                name: inboxDocument.name
              };
            }
            throw Error("documentId not been specified!");
          }}
          closeDocumentViewer={() => {
            setShowDialog(false);
          }}
        />
      )}
    </div>
  );
});

import {
  ScreenWidthMinXXLarge,
  ScrollablePane,
  SideRailMenu,
  SideRailMenuItem,
  Stack,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";

interface SOTAPFormWrapperProps {
  children?: React.ReactNode;
  sideRailMenuItems?: SideRailMenuItem[];
}

export const SOTAPFormWrapper: React.FC<SOTAPFormWrapperProps> = ({
  children,
  sideRailMenuItems
}) => {
  const theme = useTheme();

  const { width: screenWidth } = useScreenSize();
  const isScreenWidthMinXXLarge = screenWidth <= ScreenWidthMinXXLarge;
  if (sideRailMenuItems) {
    return (
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{
          root: {
            height: "100%",
            backgroundColor: theme.palette.neutralLighterAlt
          }
        }}
      >
        <SideRailMenu
          styles={{
            root: {
              justifyContent: "center",
              display: "grid",
              gridTemplateColumns: !isScreenWidthMinXXLarge
                ? "1fr 2fr"
                : "1fr 4fr"
            },
            containerWrapper: {
              height: "100%",
              width: !isScreenWidthMinXXLarge ? 270 : 200,
              padding: 16,
              justifySelf: !isScreenWidthMinXXLarge ? "end" : "start"
            }
          }}
          items={sideRailMenuItems}
        >
          <div style={{ position: "relative" }}>
            <ScrollablePane>
              <Stack
                tokens={{ childrenGap: 16 }}
                styles={{
                  root: {
                    maxWidth: 726,
                    marginTop: 16,
                    marginBottom: 16
                  }
                }}
                grow
              >
                {children}
              </Stack>
            </ScrollablePane>
          </div>
        </SideRailMenu>
      </Stack>
    );
  } else {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          backgroundColor: theme.palette.neutralLighterAlt
        }}
      >
        <ScrollablePane>
          <Stack horizontal horizontalAlign="center">
            <Stack.Item
              styles={{
                root: {
                  maxWidth: 726,
                  marginTop: 16,
                  marginBottom: 16
                }
              }}
              grow
            >
              <Stack tokens={{ childrenGap: 16 }}>{children}</Stack>
            </Stack.Item>
          </Stack>
        </ScrollablePane>
      </div>
    );
  }
};

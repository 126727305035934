import { useState } from "react";

import {
  ChoiceGroup,
  ConfirmDialog,
  IChoiceGroupOption,
  IDialogContentProps
} from "@bps/fluent-ui";

export interface BillingConfirmationDialogProps {
  onConfirm: (reason: string) => void;
  options: { key: string; text: string }[];
  label: string;
  confirmButtonText: string;
  onCancel?: () => void;
  hidden: boolean;
  className?: string;
  dialogContentProps?: IDialogContentProps;
}

export const BillingConfirmationDialog: React.FC<
  BillingConfirmationDialogProps
> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(props.options[0].key);

  const children = (
    <ChoiceGroup
      options={props.options}
      label={props.label}
      defaultSelectedKey={props.options[0].key}
      onChange={(evt, option: IChoiceGroupOption) => setReason(option.key)}
    />
  );

  const {
    hidden,
    onConfirm,
    confirmButtonText,
    className,
    dialogContentProps,
    ...rest
  } = props;

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onConfirm(reason);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmDialog
      {...rest}
      modalProps={{ className }}
      hidden={hidden}
      onConfirm={handleConfirm}
      children={children}
      confirmButtonProps={{ text: confirmButtonText }}
      confirming={isLoading}
      dialogContentProps={dialogContentProps}
    />
  );
};

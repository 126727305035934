import { DateTime, upsertItem } from "@bps/utils";
import { EMPTY_GUID } from "@libs/constants/constants.ts";
import {
  ClinicalActivityClinicalDataDto,
  ClinicalActivityClinicalDataItemDto,
  ClinicalActivityMetadataItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalActivityFormValues } from "@shared-types/clinical/clinical-activity-values.type.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { ClinicalActivityStatus } from "../../../../../../shared-types/clinical/clinical-activity-status.type.ts";
import { ClinicalActivityListFilter } from "./ClinicalActivityList.tsx";
import { ClinicalActivityDue } from "./types/clinical-activity-due.type.ts";
import {
  ClinicalActivityDescriptionCode,
  ClinicalActivityType
} from "./types/clinical-activity.type.ts";

export class PatientClinicalActivityFormModel {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root?: RootStore,
    private activity?: ClinicalActivity
  ) {}

  get initialValues() {
    if (this.activity) {
      let claimIdString = "";

      if (this.activity.metadata) {
        const claimMetadataItems = this.activity.metadata.filter(
          x => x.key === "ClaimId"
        );
        if (claimMetadataItems && claimMetadataItems.length > 0) {
          claimIdString = claimMetadataItems[0].value;
        }
      }

      return {
        id: this.activity.id,
        activityType: this.activity.activityType,
        dueChoice: this.activity.dueDate
          ? ClinicalActivityDue.Date
          : ClinicalActivityDue.Consult,
        dueDate: DateTime.jsDateFromISO(this.activity.dueDate),
        dueInVisits: this.activity.dueInVisits ?? 0,
        activityPriority: this.activity.activityPriority,
        activityStatus: ClinicalActivityStatus.New,
        remainingVisits: this.activity.remainingVisits,
        comment: this.activity.comment,
        lockedBy: this.activity.lockedBy,
        isLocked: !!this.activity.lockedBy,
        isSystemGenerated: this.activity.isSystemGenerated,
        secGroupId: this.activity.secGroupId,
        confidential: !!this.activity.secGroupId,
        descriptionCode:
          this.activity.freeText ?? this.activity.descriptionCode,
        freeText: this.activity.freeText,
        isDeleted: false,
        taskSelectedClaim: claimIdString ?? undefined
      };
    } else {
      return {
        activityType: ClinicalActivityType.Task,
        dueDate: undefined,
        dueInVisits: 0,
        descriptionCode: "",
        activityPriority: "",
        isLocked: false,
        isSystemGenerated: false,
        activityStatus: ClinicalActivityStatus.New,
        isDeleted: false,
        taskSelectedClaim: undefined
      };
    }
  }
  public onSubmit = async (values: ClinicalActivityFormValues) => {
    const clinicalActivity: ClinicalActivityClinicalDataDto = this
      .clinicalRecord.clinicalData?.clinicalActivity || {
      clinicalActivities: [],
      eTag: undefined
    };

    let { dueInVisits, remainingVisits, dueDate, isSystemGenerated } = values;

    if (values.dueChoice === ClinicalActivityDue.Date) {
      dueInVisits = undefined;
      remainingVisits = undefined;
    } else {
      dueDate = undefined;

      const storedClinicalActivity = clinicalActivity.clinicalActivities?.find(
        x => x.id === values.id
      );
      if (storedClinicalActivity) {
        isSystemGenerated = storedClinicalActivity.isSystemGenerated;
        if (storedClinicalActivity.dueInVisits !== values.dueInVisits)
          remainingVisits = values.dueInVisits;
      } else {
        remainingVisits = values.dueInVisits;
        isSystemGenerated = false;
      }
    }

    const metadata: ClinicalActivityMetadataItem[] = values.metadata ?? [];

    if (values.taskSelectedClaim) {
      metadata.push({ key: "ClaimNumber", value: values.taskSelectedClaim });
    }

    const item: ClinicalActivityClinicalDataItemDto = {
      id: values.id ?? EMPTY_GUID,
      patientId: this.clinicalRecord.id,
      activityType: values.activityType,
      descriptionCode: values.freeText
        ? ClinicalActivityDescriptionCode.Other
        : values.descriptionCode,
      freeText: values.freeText,
      activityStatus: values.activityStatus,
      dueDate: DateTime.jsDateToISODate(dueDate),
      dueInVisits,
      remainingVisits,
      activityPriority: values.activityPriority,
      comment: values.comment,
      lockedBy: values.lockedBy,
      isSystemGenerated,
      metadata,
      businessRole: values.businessRole,
      secGroupId: values.confidential
        ? this.clinicalRecord.core.user?.privateSecGroupId
        : undefined
    };

    await this.clinicalRecord.saveClinicalData({
      clinicalActivity: {
        ...clinicalActivity,
        clinicalActivities: upsertItem({
          item,
          array: clinicalActivity.clinicalActivities ?? [],
          predicate: x => x.id === values.id
        })
      }
    });

    this.clinicalRecord.loadClinicalActivities();
  };

  public deleteConfirmed = async (
    incompleteNotifications: ClinicalActivity[],
    reasonForDelete: string,
    reasonForDeleteComment?: string
  ) => {
    const clinicalActivity: ClinicalActivityClinicalDataDto = this
      .clinicalRecord.clinicalData?.clinicalActivity || {
      clinicalActivities: [],
      deletedItems: [],
      eTag: undefined
    };

    clinicalActivity.clinicalActivities =
      clinicalActivity.clinicalActivities ?? [];
    clinicalActivity.deletedItems = clinicalActivity.deletedItems ?? [];

    const storeNotifications =
      await this.root?.clinical?.getPatientClinicalActivities(
        this.clinicalRecord.id
      );

    incompleteNotifications.forEach(notification => {
      if (storeNotifications?.some(s => s.id === notification.id)) {
        const item: ClinicalActivityClinicalDataItemDto = {
          id: notification.id,
          patientId: notification.patientId,
          activityType: notification.activityType,
          dueDate: notification.dueDate,
          activityPriority: notification.activityPriority,
          activityStatus: ClinicalActivityStatus.New,
          comment: notification.comment,
          completionNotes: notification.completionNotes,
          reasonForDelete,
          deletedComment: reasonForDeleteComment,
          isSystemGenerated: notification.isSystemGenerated,
          descriptionCode: notification.descriptionCode,
          freeText: notification.freeText
        };
        if (!clinicalActivity.deletedItems) {
          clinicalActivity.deletedItems = [];
        }

        clinicalActivity.deletedItems.push(item);
      }
    });

    clinicalActivity.clinicalActivities =
      clinicalActivity.clinicalActivities.filter(
        x => !incompleteNotifications.some(s => s.id === x.id)
      );

    await this.clinicalRecord.saveClinicalData({ clinicalActivity });
  };

  public markCompleted = async (
    incompleteNotifications: ClinicalActivity[],
    notes?: string
  ) => {
    const clinicalActivity: ClinicalActivityClinicalDataDto = this
      .clinicalRecord.clinicalData?.clinicalActivity || {
      clinicalActivities: [],
      deletedItems: [],
      eTag: undefined
    };

    incompleteNotifications.forEach(notification => {
      const item: ClinicalActivityClinicalDataItemDto = {
        id: notification.id,
        patientId: notification.patientId,
        activityType: notification.activityType,
        dueDate: notification.dueDate,
        activityPriority: notification.activityPriority,
        activityStatus: ClinicalActivityStatus.Completed, //mark status as completed
        comment: notification.comment,
        deletedComment: notification.deletedComment,
        completionNotes: notes,
        isSystemGenerated: notification.isSystemGenerated,
        descriptionCode: notification.freeText
          ? ClinicalActivityDescriptionCode.Other
          : notification.descriptionCode,
        freeText: notification.freeText,
        completedBy: this.root?.core.userId,
        completedDate: DateTime.now().toISO()
      };
      clinicalActivity.clinicalActivities = upsertItem({
        item,
        array: clinicalActivity.clinicalActivities ?? [],
        predicate: x => x.id === notification.id
      });
    });

    await this.clinicalRecord.saveClinicalData({ clinicalActivity });
  };

  public filterActivities = (
    clinicalActivities: ClinicalActivity[],
    values: ClinicalActivityListFilter
  ) => {
    let allActivities = clinicalActivities.filter(x => !x.isDeleted);

    if (values.activityPriorities && values.activityPriorities.length > 0) {
      allActivities = allActivities.filter(
        x =>
          x.activityPriority &&
          values.activityPriorities?.includes(x.activityPriority)
      );
    }

    if (values.activityTypes && values.activityTypes.length > 0) {
      allActivities = allActivities.filter(
        x => x.activityType && values.activityTypes?.includes(x.activityType)
      );
    }

    if (values.activityStatuses && values.activityStatuses.length > 0) {
      allActivities = allActivities.filter(
        x =>
          x.activityStatus &&
          values.activityStatuses?.includes(x.activityStatus)
      );
    }

    if (
      values.activityDescriptionSearch &&
      values.activityDescriptionSearch.length > 0
    ) {
      // Description could be a code or it could be a custom text, we need to find the code associated with the text.
      const descriptions =
        this.clinicalRecord.clinical.ref.clinicalActivityDescriptions
          .keyTextValues;

      const searchAsPossibleDescription = descriptions.filter(
        x =>
          values.activityDescriptionSearch &&
          x.text.includes(values.activityDescriptionSearch)
      );

      allActivities = allActivities.filter(
        x =>
          x.descriptionCode &&
          values.activityDescriptionSearch &&
          (searchAsPossibleDescription.find(y => y.key === x.descriptionCode) ||
            x.descriptionCode.includes(values.activityDescriptionSearch))
      );
    }

    if (values.dueIncrement) {
      const visitNumber = Number.parseInt(values.dueIncrement);

      allActivities = allActivities.filter(
        x => x.remainingVisits && x.remainingVisits > visitNumber
      );
    }

    if (values.dueDateStartDate && values.dueDateEndDate) {
      allActivities = allActivities.filter(
        x =>
          x.dueDate &&
          DateTime.fromJSDate(values.dueDateStartDate!) <=
            DateTime.fromISO(x.dueDate) &&
          DateTime.fromISO(x.dueDate) <=
            DateTime.fromJSDate(values.dueDateEndDate!)
      );
    }

    return allActivities;
  };
}

import { observer } from "mobx-react-lite";

import {
  Accordion,
  AccordionItem,
  Heading,
  IconButton,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  compareDatesPredicate,
  formatCalendarDate,
  ordinalNumber
} from "@bps/utils";
import {
  ConsultEncounterTypes,
  TodaysNotes
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionConsultsExportButton } from "../condition-consults-export/ConditionConsultsExportButton.tsx";
import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";
import { PastVisitDetailsStructuredNotes } from "../past-visit/PastVisitDetailsStructuredNotes.tsx";
import { ClaimConsultBadges } from "./ClaimConsultBadges.tsx";
import { ConditionConsultsWrapper } from "./ConditionConsultsWrapper.tsx";
import { ConditionDetailsModel } from "./ConditionsSidePanelHelper.ts";

export interface ConditionConsultsExpandedViewProps {
  model: ConditionDetailsModel;
  onClosed: () => void;
}

export const ConditionConsultsExpandedView: React.FC<ConditionConsultsExpandedViewProps> =
  observer(({ model, onClosed }) => {
    const { clinical, core } = useStores();

    const { setConditionConsultsExportDialogVisible } = clinical.ui;

    const theme = useTheme();

    const { clinicalRecord, isViewOnly } = usePatientRecordScreenContext();

    const getEncounterClinicalNotes = async () => {
      const episodeOfCareId = model.condition?.episodeOfCareId;
      const eocEncounters = await clinical.getEncounters({
        patientId: model.condition?.patientId ?? "",
        episodeOfCareIds: episodeOfCareId ? [episodeOfCareId] : undefined,
        encounterTypeCodes: ConsultEncounterTypes
      });

      const sortedDates = Array.from(eocEncounters).sort((a, b) =>
        compareDatesPredicate(b.startDateTime, a.startDateTime)
      );

      const encounterNotes = await Promise.all(
        sortedDates.map(async x => {
          if (x.secGroupId && x.dto.userId !== core.user?.id) {
            return { encounter: x, notes: undefined };
          }

          const encounterClinicalNote =
            await clinicalRecord.getEncounterClinicalNotes(x.dto.id);

          return { encounter: x, notes: encounterClinicalNote };
        })
      );

      return encounterNotes;
    };

    const renderConsultItems = (
      encounterNotes: {
        encounter: Encounter;
        notes: TodaysNotes | undefined;
      }[]
    ) => {
      const accordionItems = encounterNotes.map((x, index, array) => {
        const title = `${ordinalNumber(
          array.length - index
        )} - ${formatCalendarDate(x.encounter.startDateTime)}`;

        if (x.notes) {
          return (
            <AccordionItem
              key={`consult_${x.encounter.id}`}
              title={title}
              extendedByDefault={index === 0}
            >
              <PastVisitDetailsStructuredNotes todaysNotes={x.notes} />
            </AccordionItem>
          );
        }

        return (
          <Stack
            key={`consult_${x.encounter.id}`}
            styles={{
              root: {
                border: "solid",
                borderWidth: 1,
                paddingTop: 4,
                paddingBottom: 4,
                borderColor: theme.palette.neutralLighter,
                borderBottom: index < array.length ? 0 : 1 // The borders overlap with content below, so hide it if its not the last in the list.
              }
            }}
            horizontal
            verticalAlign="center"
          >
            <ConfidentialToolTipFontIcon isShowConfidentialIcon={true} />
            <Text styles={{ root: { margin: -4 } }}>{title}</Text>
          </Stack>
        );
      });

      return accordionItems;
    };

    return (
      <DataFetcher fetch={getEncounterClinicalNotes} fallback={<Spinner />}>
        {encounterNotes => {
          return (
            <Stack
              tokens={{ childrenGap: 8 }}
              styles={{
                root: {
                  flexFlow: "column",
                  height: "100%"
                }
              }}
            >
              <Stack>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 8 }}
                  styles={{ root: { marginBottom: 8 } }}
                >
                  <IconButton
                    iconProps={{ iconName: "ChevronLeft" }}
                    onClick={() => {
                      onClosed();
                    }}
                  />
                  <Heading
                    variant="section-heading"
                    nowrap
                    styles={{
                      root: {
                        padding: "5px 0",
                        fontSize: 18,
                        marginRight: "auto"
                      }
                    }}
                  >
                    {model.diagnosis} consults ({encounterNotes.length})
                  </Heading>
                </Stack>
                <Stack tokens={{ childrenGap: 8 }}>
                  {model.condition && (
                    <ClaimConsultBadges
                      condition={model.condition}
                      clinicalRecord={clinicalRecord}
                      showConsultsRemaining
                      claimBadgeText={ConditionHelper.getClaimBadgeText(
                        model.condition
                      )}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                styles={{
                  root: {
                    flex: 2,
                    overflow: "auto"
                  }
                }}
                data-is-scrollable={true}
              >
                <Accordion
                  multiple
                  withActions
                  styles={{
                    actionsWrapper: {
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }
                  }}
                  onRenderExtraActions={() =>
                    !isViewOnly && (
                      <ConditionConsultsExportButton
                        clinicalRecord={clinicalRecord}
                        onClick={() =>
                          setConditionConsultsExportDialogVisible(true)
                        }
                        condition={model.condition}
                        episodeOfCareId={model.condition?.episodeOfCareId ?? ""}
                        claimId={model.condition?.claim?.id}
                        claimNumber={model.condition?.claim?.claimNumber}
                      />
                    )
                  }
                >
                  {renderConsultItems(encounterNotes)}
                </Accordion>
                <ConditionConsultsWrapper
                  patientName={clinicalRecord.patient?.nameWithTitle ?? ""}
                  diagnosis={model.diagnosis ?? ""}
                  claimBadgeText={ConditionHelper.getClaimBadgeText(
                    model.condition
                  )}
                  patientId={clinicalRecord.patient?.id ?? ""}
                  episodeOfCareId={model.condition?.episodeOfCareId ?? ""}
                  claimId={model.condition?.claim?.id}
                  providerId={clinicalRecord.calendarEvent?.userId ?? ""}
                  claimNumber={model.condition?.claim?.claimNumber}
                />
              </Stack>
            </Stack>
          );
        }}
      </DataFetcher>
    );
  });

import { observer } from "mobx-react-lite";

import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const ContactCommunicationColumn = observer(
  ({ contact }: { contact: Contact }) => {
    const { practice } = useStores();

    const phoneCommunications = contact.communications.filter(
      x =>
        x.type !== CommunicationType.Email &&
        x.type !== CommunicationType.Fax &&
        x.type !== CommunicationType.Website
    );
    // would display number but none given
    if (phoneCommunications.length === 0) return null;

    // if preferred not set and more than one of comms of top type
    // then display number is false
    const displayNumber =
      phoneCommunications[0].preferred ||
      phoneCommunications.filter(x => x.type === phoneCommunications[0].type)
        .length === 1;

    // yes, there is a number to display
    if (displayNumber) return <>{phoneCommunications[0].value}</>;

    return (
      <Navigate onClick={() => practice.ui.showContactDetails(contact.id)}>
        Multiple numbers
      </Navigate>
    );
  }
);

import { observer } from "mobx-react-lite";
import { stringifyUrl } from "query-string";
import { FunctionComponent } from "react";
import { Navigate, useParams } from "react-router-dom";

import {
  dataAttribute,
  DataAttributes,
  PrimaryButton,
  Stack,
  Tile
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PeopleTabs, routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { Labels } from "../shared-components/types/labels.enums.types.ts";
import { ContactList } from "./components/ContactList.tsx";
import { ContactListFilters } from "./components/ContactListFilters.tsx";
import { PeopleScreenPivot } from "./components/PeopleScreenPivot.tsx";

export const PeopleScreen: FunctionComponent = observer(() => {
  const {
    core,
    practice: {
      ui: { showAddContact }
    }
  } = useStores();

  const patientLabel = usePatientLabel(false);
  const { tab } = useParams();

  if (!tab) {
    return (
      <Navigate
        replace
        to={stringifyUrl({
          url: routes.addressBook.basePath.path({ tab: PeopleTabs.Patients })
        })}
      />
    );
  }

  const onAddContact = (type: ContactType) => () => {
    showAddContact(type);
  };

  const canOpenRecord = core.hasPermissions([
    Permission.ContactRead,
    Permission.ContactWrite,
    Permission.PatientRead,
    Permission.PatientWrite
  ]);

  return (
    <Stack
      grow
      styles={{
        root: {
          height: "100%",
          maxWidth: 1500,
          margin: "0 auto"
        }
      }}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { padding: "4px 0 12px " } }}
      >
        <PeopleScreenPivot />
        {canOpenRecord && (
          <Stack verticalAlign="end">
            <PrimaryButton
              split
              onClick={onAddContact(ContactType.Patient)}
              iconProps={{ iconName: "Add" }}
              text={`New ${patientLabel}`}
              menuProps={{
                items: [
                  {
                    key: "addContact",
                    onClick: onAddContact(ContactType.Individual),
                    text: `New non-${patientLabel}`
                  },
                  {
                    key: "addOrganisation",
                    onClick: onAddContact(ContactType.Organisation),
                    text: Labels.newOrganisation
                  }
                ]
              }}
              {...dataAttribute(DataAttributes.Element, "btn-add-contact")}
            />
          </Stack>
        )}
      </Stack>
      <Tile styles={{ root: { height: "100%" } }}>
        <Stack verticalFill horizontal tokens={{ childrenGap: 48 }}>
          <Stack
            grow
            styles={{
              root: {
                height: "100%"
              }
            }}
          >
            <ContactListFilters key={tab} activeTab={tab}>
              {({ values: filter }) => (
                <ContactList activeTab={tab} filter={filter} />
              )}
            </ContactListFilters>
          </Stack>
        </Stack>
      </Tile>
    </Stack>
  );
});
PeopleScreen.displayName = "PeopleScreen";
// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PeopleScreen;

import { FormApi } from "final-form";
import { action, computed, observable, runInAction } from "mobx";

import { IGroup, ThematicButtonData } from "@bps/fluent-ui";
import {
  clone,
  compareDatesPredicate,
  DateTime,
  formatCalendarDate,
  newGuid,
  ordinalNumber
} from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AppointmentStatusCode,
  AppointmentTypeCode
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  AccClinicalTab,
  AddDocumentDto,
  ClinicalWorkflowFormat,
  CodedFieldClinicalDataItemDto,
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  DiagnosisDataItemDto,
  DischargeDataItemDto,
  DocumentContentType,
  DocumentCreateOptions,
  DocumentDto,
  DocumentEnum,
  DocumentMetadataItem,
  DocumentSource,
  EncounterLocation,
  EncounterStatus,
  EncounterType,
  PermanentClinicalTab,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  TemplateRenderDto,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { getNewMetadata } from "@modules/clinical/utils/clinical.utils.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { ClinicalTabItem } from "@stores/clinical/models/clinical-tab/ClinicalTabItem.ts";
import { ClinicalDataTypeName } from "@stores/clinical/models/clinical-tab/ClinicalTabName.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  clinicalRecordMapKey,
  patientTabKey,
  sortByDischargeItemCreatedDateDesc
} from "@stores/clinical/utils/clinical.utils.ts";

import { EncounterDiscardFormValues } from "../patient-record/components/clinical-form/EncounterDiscardFormValues.tsx";
import { ConditionConsultEncounterItem } from "../patient-record/components/condition-consults-export/ConditionConsultsExportDialog.tsx";
import { getSideOfBodyKey } from "../patient-record/components/medical-history/utils.ts";
import { setClaimTab } from "../patient-record/PatientRecord.utils.ts";
import { AppInsightsClinicalRecordHelper } from "./AppInsightsClinicalRecordHelper.ts";

export class PatientRecordScreenHelper extends AppInsightsClinicalRecordHelper {
  constructor(protected root: IRootStore) {
    super(root);
  }

  get multiProviderEnabled() {
    return this.core.hasPermissions(Permission.MultiProviderClaimsAllowed);
  }

  @observable claimAdjustment: ClaimAdjustment | undefined;

  @observable claimReviewClaim: Claim | undefined;

  @observable encounterId: string | undefined;

  encounterFormApi: FormApi<EncounterFormValues> | undefined;

  get routing() {
    return this.root.routing;
  }

  get clinical() {
    return this.root.clinical;
  }

  get userExperience() {
    return this.root.userExperience;
  }

  get core() {
    return this.root.core;
  }

  get booking() {
    return this.root.booking;
  }

  get acc() {
    return this.root.acc;
  }

  get patientId() {
    return this.clinical.activeRecordPatientId!;
  }

  get isDischargeCompleted() {
    if (this.clinicalRecord) {
      return (
        this.clinicalRecord.getDischargeStatus(
          this.clinicalRecord.openEncounter?.businessRole
        ) === DischargeStatus.Completed
      );
    }
    return false;
  }

  get isViewOnlyOrDischarged() {
    return this.clinical.activeRecordIsView || this.isDischargeCompleted;
  }

  get isViewOnly() {
    return this.clinical.activeRecordIsView;
  }

  get isRecordUpdate() {
    return this.clinical.activeRecordUpdate;
  }

  @computed
  get clinicalRecord() {
    const record = this.clinical.multipleEncountersRecordsMap.get(
      clinicalRecordMapKey(this.patientId, this.encounterId)
    )!;

    return record;
  }

  getUpdatedDiagnosis = async (
    diagnosisCode: CodedFieldClinicalDataItemDto,
    isPrimaryDiagnosis?: boolean,
    diagnosisSideSelected?: string[]
  ) => {
    if (!this.clinicalRecord.episodeOfCare?.id) return;

    const diagnoses = (
      await this.clinical.getEpisodeOfCare(
        this.clinicalRecord.episodeOfCare?.id
      )
    ).diagnoses;

    let updatedDiagnosis: DiagnosisDataItemDto[] = diagnoses
      ? clone(diagnoses)
      : [];

    const primaryDiagnosis: DiagnosisDataItemDto = {
      diagnosisCode,
      diagnosisSide: getSideOfBodyKey(diagnosisSideSelected),
      isPrimaryDiagnosis
    };

    if (updatedDiagnosis && updatedDiagnosis.length > 1) {
      updatedDiagnosis.map(x => {
        if (x.isPrimaryDiagnosis === true) {
          return { ...primaryDiagnosis };
        }
        return x;
      });
    } else {
      updatedDiagnosis = [primaryDiagnosis];
    }
    return updatedDiagnosis;
  };

  fetchUserOnSecGroupId = async (id: string) => {
    //fetching User with SecGroupId
    const fetchUsers = await this.root.core.fetchUsers({
      privateSecGroupIds: id
    });
    if (fetchUsers.length > 0) {
      return fetchUsers[0];
    } else {
      return undefined;
    }
  };

  showPrimaryDiagnosis = async (isProcedure?: boolean) => {
    const condition = this.clinicalRecord.condition;

    const isPrivate = condition?.isPrivate;

    const clinicalView = (
      await this.userExperience.getUserSetting(this.core.userId)
    )?.clinicalView;

    const isNoneWorkflow =
      clinicalView?.clinicalWorkflow === ClinicalWorkflowFormat.None;
    return condition && isPrivate && isNoneWorkflow && !isProcedure;
  };

  setEncounterFormApi = (formApi: FormApi<EncounterFormValues> | undefined) => {
    this.encounterFormApi = formApi;
  };

  @action clearCurrentTabsEncounterData = (contextId?: string) => {
    const type =
      this.root.clinical.ui.tabs.currentPatientRecordTab?.activeTab?.type;
    if (type) {
      this.root.clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type,
        contextId
      });
    }
  };

  @action
  public setClaimAdjustment = (claimAdjustment?: ClaimAdjustment) => {
    this.claimAdjustment = claimAdjustment;
    if (this.root.clinical.ui.tabs.currentPatientRecordTab) {
      this.root.clinical.ui.tabs.currentPatientRecordTab.currentClinicalClaimAdjustmentId =
        claimAdjustment?.id || undefined;
    }

    setClaimTab(
      this.clinicalRecord,
      AccClinicalTab.Adjustment,
      !claimAdjustment
    );
  };

  @action
  public setClaimReviewTab = (claim?: Claim) => {
    this.claimReviewClaim = claim;
    if (this.root.clinical.ui.tabs.currentPatientRecordTab) {
      this.root.clinical.ui.tabs.currentPatientRecordTab.currentClaimReviewClaimId =
        claim?.id || undefined;
    }

    setClaimTab(this.clinicalRecord, AccClinicalTab.Review, !claim);
  };

  @computed
  public get isClaimReviewOpen() {
    return this.root.clinical.ui.tabs.currentPatientRecordTab?.hasTabTypeOpen(
      AccClinicalTab.Review
    );
  }

  @action
  private setEncounterId = (encounterId: string | undefined) => {
    this.encounterId = encounterId;
  };

  async createAppointmentEncounterLinks(
    encounterId: string,
    calendarEventId: string
  ) {
    if (this.clinicalRecord.openEncounter) {
      await this.booking.addAppointmentEncounters({
        encounterId,
        calendarEventId
      });
    }
  }

  private getOrgUnitIdByCurrentTime = async (): Promise<string | undefined> => {
    const currentTime = DateTime.now();
    const from = currentTime.startOf("day");
    const to = from.plus({ day: 1 });
    const workingHours = await this.booking.getUserWorkingHoursAllLocations({
      from,
      to,
      userIds: [this.core.userId],
      isStandardHours: false
    });

    const userWorkingHours = workingHours.find(
      u => u.userId === this.core.userId
    );

    const timeRanges = userWorkingHours?.orgUnitRanges.timeRanges ?? [];
    return timeRanges.find(
      timeRange => currentTime >= timeRange.from && currentTime < timeRange.to
    )?.orgUnitId;
  };

  private getLocationId = async (calendarEvent: CalendarEvent | undefined) => {
    if (!this.core.hasMultiLocationOrgUnit) {
      return this.core.locationId;
    } else if (calendarEvent?.orgUnitId) {
      return calendarEvent?.orgUnitId;
    }

    const orgUnitIdByCurrentTime = await this.getOrgUnitIdByCurrentTime();
    if (orgUnitIdByCurrentTime) return orgUnitIdByCurrentTime;

    const userAvailability = await this.booking.getUserAvailability(
      this.core.userId
    );

    return userAvailability.availableOrgUnitIds.length === 1
      ? userAvailability.availableOrgUnitIds[0]
      : this.core.locationId;
  };

  addEncounter = async (calendarEventId?: string) => {
    const businessRoleCode = this.core.primaryBusinessRole?.code ?? "";

    const calendarEvent = calendarEventId
      ? await this.booking.getCalendarEvent(calendarEventId)
      : undefined;

    const episodeOfCareId = calendarEvent?.reason?.episodeOfCareId ?? undefined;

    const appointmentType = await calendarEvent?.loadAppointmentType();

    const encounterType =
      this.isRecordUpdate ||
      appointmentType?.code === AppointmentTypeCode.RecordUpdate
        ? EncounterType.RecordUpdate
        : EncounterType.Consultation;

    const defaultValues = {
      encounterType,
      encounterLocation: EncounterLocation.Practice,
      orgUnitId: await this.getLocationId(calendarEvent),
      patientId: this.patientId,
      userId: this.core.userId,
      businessRole: businessRoleCode,
      episodeOfCareId
    };

    const encounter = await this.clinical.addEncounter({
      ...defaultValues
    });

    this.setEncounterId(encounter.id);

    const clinicalRecord = await this.clinical.getRecord(this.patientId, {
      encounterId: encounter.id
    });

    if (calendarEvent) {
      await this.createAppointmentEncounterLinks(
        encounter.id,
        calendarEvent.id
      );
      await Promise.all([
        this.core.hasPermissions(Permission.ClaimRead)
          ? clinicalRecord.calendarEvent?.loadClaim()
          : undefined,
        clinicalRecord.loadInvoiceItems()
      ]);
    }

    await Promise.all([
      clinicalRecord.loadlinkedEncounters(),
      clinicalRecord.loadPatientNotices(),
      clinicalRecord.addAppointmentTypeInvoice(encounter)
    ]);

    return clinicalRecord;
  };

  basicEncounterLookup = async () => {
    let clinicalRecord: ClinicalRecord | undefined;
    const encounters = await this.clinical.getEncounters({
      patientId: this.patientId,
      userIds: [this.core.userId],
      statuses: [EncounterStatus.Open, EncounterStatus.Committed]
    });

    if (encounters && encounters.length > 0) {
      const sortedEncounters = encounters.sort((a, b) =>
        compareDatesPredicate(a.startDateTime, b.startDateTime, true)
      );

      const mostRecentEncounter = sortedEncounters[0];

      this.setEncounterId(mostRecentEncounter.id);

      clinicalRecord = await this.clinical.getRecord(this.patientId, {
        encounterId: mostRecentEncounter.id
      });

      await this.loadExtraClinicalRecordItems(clinicalRecord!);

      if (
        !this.isViewOnlyOrDischarged &&
        this.root.routing.location.state?.from
      ) {
        clinicalRecord.setShowEncounterExistsPrompt(true);
        this.setShowEncounterExistsPrompt(true);
      }
    } else {
      if (!this.isViewOnlyOrDischarged) {
        const { activeCalendarEvent } = this.clinical;

        clinicalRecord = await this.addEncounter(activeCalendarEvent);
      } else {
        clinicalRecord = await this.clinical.getRecord(this.patientId);
      }
    }

    return clinicalRecord;
  };

  getClinicalRecordForMultiEncounter = async (
    encounterId: string,
    showPrompt?: boolean
  ) => {
    this.setEncounterId(encounterId);
    const clinicalRecord = await this.clinical.getRecord(this.patientId, {
      encounterId
    });
    if (clinicalRecord) {
      await this.loadExtraClinicalRecordItems(clinicalRecord);
    }

    if (showPrompt || clinicalRecord.showEncounterExistsPrompt) {
      this.setShowEncounterExistsPrompt(true);
      clinicalRecord.setShowEncounterExistsPrompt(false);
    }

    return clinicalRecord;
  };

  getClinicalRecordByCalendarEventIdForMultiEncounter = async (
    calendarEventId: string
  ) => {
    let clinicalRecord: ClinicalRecord | undefined;
    const calendarEvent = await this.booking.getCalendarEvent(calendarEventId);

    const episodeOfCareId = calendarEvent?.reason?.episodeOfCareId ?? undefined;

    if (episodeOfCareId) {
      const encounterForCondition = await this.clinical.getEncounters({
        patientId: this.patientId,
        userIds: [this.core.userId],
        statuses: [EncounterStatus.Open, EncounterStatus.Committed],
        episodeOfCareIds: [episodeOfCareId]
      });

      const encounter = encounterForCondition[0];
      if (encounter) {
        clinicalRecord = await this.getClinicalRecordForMultiEncounter(
          encounter.id,
          !this.isViewOnlyOrDischarged && this.root.routing.location.state?.from
        );
      } else {
        if (!this.isViewOnlyOrDischarged) {
          clinicalRecord = await this.addEncounter(calendarEventId);
        } else {
          clinicalRecord = await this.clinical.getRecord(this.patientId);
        }
      }
    }

    return clinicalRecord;
  };

  multiEncountersLoadClinicalRecord = async (
    viewOnly: boolean,
    encounterId?: string
  ): Promise<ClinicalRecord | undefined> => {
    let clinicalRecord: ClinicalRecord | undefined;

    const calendarEventId = this.clinical.activeCalendarEvent;

    if (encounterId) {
      clinicalRecord =
        await this.getClinicalRecordForMultiEncounter(encounterId);
    } else if (calendarEventId) {
      clinicalRecord =
        await this.getClinicalRecordByCalendarEventIdForMultiEncounter(
          calendarEventId
        );
    }

    if (!clinicalRecord) {
      clinicalRecord = await this.basicEncounterLookup();
    }

    if (clinicalRecord) {
      await Promise.all([
        clinicalRecord.loadEpisodeOfCare(),
        clinicalRecord.loadConditions(),
        clinicalRecord.loadClinicalData()
      ]);

      const openEncounterId = viewOnly
        ? undefined
        : clinicalRecord.openEncounter?.id;

      const loadNotesEncounterId =
        viewOnly ||
        (clinicalRecord.openEncounter &&
          this.clinical.encountersDeletedElsewhere.includes(
            clinicalRecord.openEncounter.id
          ))
          ? undefined
          : clinicalRecord.openEncounter?.id;

      await clinicalRecord.loadStructuredNotes(
        loadNotesEncounterId ?? "",
        this.userExperience.settings.ClinicalNotesFormat
      );

      const tabsKey = patientTabKey(this.patientId, openEncounterId);

      const hasTab = this.clinical.ui.tabs.patientClinicalTabs.has(
        `${tabsKey}${viewOnly ? "-view" : ""}`
      );

      if (!hasTab) {
        clinicalRecord.clinical.ui.tabs.createPatientTab(
          this.patientId,
          openEncounterId
        );
      }
    }

    // ⚠️ For NZ tenant only
    if (this.core.hasPermissions(Permission.ClaimRead)) {
      const claimAdjustmentId =
        this.root.clinical.ui.tabs.currentPatientRecordTab
          ?.currentClinicalClaimAdjustmentId;

      const claimReviewClaimId =
        this.root.clinical.ui.tabs.currentPatientRecordTab
          ?.currentClaimReviewClaimId;

      const promises: Promise<any>[] = [];

      if (claimAdjustmentId) {
        promises.push(
          this.root.acc
            .getClaimAdjustment(claimAdjustmentId)
            .then(ca => this.setClaimAdjustment(ca))
            .catch(() => this.setClaimAdjustment())
        );
      }
      if (claimReviewClaimId) {
        promises.push(
          this.root.acc
            .getClaim(claimReviewClaimId)
            .then(c => this.setClaimReviewTab(c))
        );
      }
      if (promises.length) {
        await Promise.all(promises);
      }
    }

    return clinicalRecord;
  };

  loadExtraClinicalRecordItems = async (clinicalRecord: ClinicalRecord) => {
    await Promise.all([
      clinicalRecord
        .loadCalendarEvent()
        .then(() =>
          Promise.all([
            this.core.hasPermissions(Permission.ClaimRead)
              ? clinicalRecord.calendarEvent?.loadClaim()
              : undefined,
            clinicalRecord!.loadInvoiceItems()
          ])
        ),
      clinicalRecord.loadlinkedEncounters(),
      clinicalRecord.loadPatientNotices()
    ]);
  };

  setInjuryTab = async () => {
    const { currentPatientRecordTab: currentTab } = this.clinical.ui.tabs;

    if (currentTab) {
      const accTab = currentTab.allTabs.find(
        t => t.type === AccClinicalTab.Injury
      );

      if (
        this.core.hasPermissions(Permission.ClaimWrite) &&
        !this.isViewOnly &&
        this.clinicalRecord?.openEncounter?.type !==
          EncounterType.RecordUpdate &&
        this.clinicalRecord.episodeOfCare
      ) {
        const claimEpisodesOfCare = await this.acc.getClaimEpisodesOfCare({
          episodesOfCare: [this.clinicalRecord.episodeOfCare.id]
        });

        if (claimEpisodesOfCare.length > 0) {
          setClaimTab(this.clinicalRecord, AccClinicalTab.Injury);
        }
      } else if (accTab) {
        currentTab.removeTab(accTab.id);

        if (!this.clinical.ui.tabs.currentPatientRecordTab?.activeTab) {
          this.clinical.ui.setPatientClinicalContent({
            type: PermanentClinicalTab.TodaysNotes
          });
        }
      }
    }
  };

  setSOTAPTab = async () => {
    if (this.core.tenantDetails?.country === Country.NewZealand) {
      const clinicalView = await this.userExperience.userSettingMap?.get(
        this.core.userId
      )?.clinicalView;

      const { currentPatientRecordTab: currentTab } = this.clinical.ui.tabs;

      const followOnEncounter = this.clinicalRecord?.isFollowOnEncounter;
      const isClinicalWorkFlow =
        !!clinicalView && !!clinicalView.clinicalWorkflow;

      if (currentTab) {
        const sotapTab = currentTab.allTabs.find(
          t => t.type === PermanentClinicalTab.SOTAP
        );

        if (
          this.clinicalRecord?.openEncounter?.type !==
            EncounterType.RecordUpdate &&
          this.clinicalRecord?.episodeOfCare
        ) {
          if (
            this.core.hasPermissions(Permission.SOTAPInitialEncounter) &&
            !this.isViewOnly
          ) {
            if (
              !isClinicalWorkFlow ||
              clinicalView?.clinicalWorkflow === ClinicalWorkflowFormat.SOTAP
            ) {
              if (
                !followOnEncounter ||
                (followOnEncounter &&
                  this.core.hasPermissions(Permission.SOTAPFollowOn))
              ) {
                if (!sotapTab) {
                  const sotapTab = new ClinicalTabItem({
                    type: PermanentClinicalTab.SOTAP,
                    title: ClinicalDataTypeName[PermanentClinicalTab.SOTAP]
                  });

                  currentTab.insertTab(sotapTab);

                  const defaultActiveTab = clinicalView?.defaultDockView;
                  if (
                    defaultActiveTab &&
                    defaultActiveTab === PermanentClinicalTab.SOTAP
                  ) {
                    currentTab.setActive(PermanentClinicalTab.SOTAP);
                  }
                }
              }
            }
          }
        } else if (sotapTab) {
          currentTab.removeTab(sotapTab.id);

          if (!this.clinical.ui.tabs.currentPatientRecordTab?.activeTab) {
            this.clinical.ui.setPatientClinicalContent({
              type: PermanentClinicalTab.TodaysNotes
            });
          }
        }
      }
    }
  };

  static getGroupedListItems = (treeData: ThematicButtonData[]) => {
    let startIndex = 0;
    const items = [];
    const groups: IGroup[] = [];
    for (const group of treeData) {
      groups.push({
        key: group.key,
        name: group.name,
        count: group.items.length,
        startIndex
      });
      for (const item of group.items) {
        items.push(item);
        startIndex += 1;
      }
    }
    return {
      groups,
      items
    };
  };

  @observable private _showEncounterExistsPrompt = false;

  // EncounterExistsPrompt
  @computed get showEncounterExistsPrompt() {
    //Hide dialog for finalisedEncounters with incomplete notes

    const currentCalendarEvent = this.clinical.activeCalendarEvent
      ? this.booking.calendarEventsMap.get(this.clinical.activeCalendarEvent)
      : undefined;

    const isFinalisedEncounterwithIncompleteNotes =
      currentCalendarEvent &&
      currentCalendarEvent.appointmentStatus ===
        AppointmentStatusCode.Finalised;

    return (
      this._showEncounterExistsPrompt &&
      !isFinalisedEncounterwithIncompleteNotes
    );
  }

  @action
  setShowEncounterExistsPrompt = (showPrompt: boolean) => {
    this._showEncounterExistsPrompt = showPrompt;
  };

  @observable isFinalising: boolean = false;

  @action setIsFinalising = (value: boolean) => {
    this.isFinalising = value;
  };

  discardEncounter = async (
    values: EncounterDiscardFormValues,
    clinicalRecord: ClinicalRecord
  ) => {
    const { reasonForDiscard, reasonForDiscardComment } = values;
    if (clinicalRecord.openEncounter) {
      const encounterId = clinicalRecord.openEncounter.id;
      clinicalRecord.encounterDiscardedByUser = true;
      await this.root.clinical.deleteEncounter(
        encounterId,
        reasonForDiscard,
        reasonForDiscardComment
      );

      // ⚠ ️It is important that DiscardConfirmationDialog MUST be dismissed before clinicalRecord closing.
      this.clinical.ui.closeDiscardConfirmationDialog();
      await clinicalRecord.close(encounterId);
    }
  };

  getLatestActiveDischargeClinicalDataItem = (
    businessRoleCode: string | undefined,
    dataItems: DischargeDataItemDto[] | undefined
  ) => {
    const filteredDataItems = dataItems
      ?.filter(
        x =>
          (this.multiProviderEnabled &&
            x.businessRoleCode === businessRoleCode) ||
          !this.multiProviderEnabled
      )
      .sort(sortByDischargeItemCreatedDateDesc);

    let dischargeDataItem: DischargeDataItemDto | undefined;

    if (filteredDataItems) {
      if (filteredDataItems.length === 1) {
        dischargeDataItem = filteredDataItems[0];
      } else if (filteredDataItems.length > 1) {
        const nonReverseDischargeExists = filteredDataItems?.some(
          x => x.dischargeStatus !== DischargeStatus.Reversed
        );

        dischargeDataItem = filteredDataItems?.find(
          x =>
            (nonReverseDischargeExists &&
              x.dischargeStatus !== DischargeStatus.Reversed) ||
            !nonReverseDischargeExists
        );
      }
    }

    if (!dischargeDataItem) {
      dischargeDataItem = {
        businessRoleCode
      };
    }

    return dischargeDataItem;
  };

  @observable selectedPreviousEncounterId: string;

  @action
  setSelectedPreviousEncounterId = (value: string) => {
    this.selectedPreviousEncounterId = value;
  };

  onExportToEditorButtonClick = async (options: {
    patientId: string;
    providerId: string;
    episodeOfCareId: string;
    claimId: string | undefined;
    claimNumber: string | undefined;
    diagnosis: string | undefined;
    encounters: ConditionConsultEncounterItem[];
  }) => {
    const { document, notification, correspondence } = this.root;
    const {
      claimId,
      episodeOfCareId,
      patientId,
      providerId,
      claimNumber,
      diagnosis,
      encounters
    } = options;

    const activeEncounterId = this.clinicalRecord.openEncounter?.id;

    const documentName = this.getDocumentNameForExport(claimNumber, diagnosis);

    const documentId = newGuid();

    try {
      const templates = await document.getTemplates({
        name: "Condition consults export"
      });

      const consultIds: string = encounters
        .filter(encounter => encounter.selected)
        .map(encounter => encounter.id)
        .join(", ");

      const context = this.getDocumentTemplateContext(
        consultIds,
        {
          userId: this.core.userId,
          episodeOfCareId,
          patientId,
          claimId
        },
        true
      );

      const documentTemplate: TemplateRenderDto = await document.renderTemplate(
        templates.length ? templates[0].id : "",
        {
          contentType: DocumentContentType.Sfdt,
          skipMerge: false,
          isPreview: false,
          context
        }
      );

      const metadata: DocumentMetadataItem[] = [
        { key: DocumentEnum.Extension, value: "pdf" },
        { key: DocumentEnum.ContentType, value: DocumentContentType.Sfdt },
        {
          key: DocumentEnum.Name,
          value: documentName
        },
        { key: DocumentEnum.EncounterId, value: activeEncounterId },
        { key: DocumentEnum.Date, value: DateTime.now().toISODate() }
      ];

      const dto: DocumentDto = {
        id: documentId,
        patientId,
        from: providerId,
        type: CorrespondenceType.Report,
        direction: CorrespondenceDirection.Out,
        status: CorrespondenceStatus.Draft,
        content: documentTemplate.content,
        showOnTimeline: true,
        metadata,
        eTag: ""
      };

      correspondence.mergeCorrespondence(dto);
      correspondence.activeDocumentTab = {
        documentId,
        patientId,
        encounterId: activeEncounterId
      };
    } catch {
      notification.error("An error occurred - unable to export to editor");
    }
  };
  onExportToPdfButtonClick = async (options: {
    patientId: string;
    providerId: string;
    episodeOfCareId: string;
    claimId: string | undefined;
    claimNumber: string | undefined;
    diagnosis: string | undefined;
    encounters: ConditionConsultEncounterItem[];
  }) => {
    const { document, notification, correspondence, clinical } = this.root;
    const {
      claimId,
      episodeOfCareId,
      patientId,
      providerId,
      claimNumber,
      diagnosis,
      encounters
    } = options;

    const activeEncounterId = this.clinicalRecord.openEncounter?.id;

    const documentName = this.getDocumentNameForExport(claimNumber, diagnosis);

    const documentId = newGuid();

    try {
      const templates = await document.getTemplates({
        name: "Condition consults export"
      });

      const consultIds: string = encounters
        .map(encounter => encounter.id)
        .join(", ");

      const context = this.getDocumentTemplateContext(
        consultIds,
        {
          userId: this.core.userId,
          episodeOfCareId,
          patientId,
          claimId
        },
        false
      );

      const documentTemplate: TemplateRenderDto = await document.renderTemplate(
        templates.length ? templates[0].id : "",
        {
          contentType: DocumentContentType.Pdf,
          skipMerge: false,
          isPreview: false,
          context
        }
      );

      const documentContent: AddDocumentDto = { metadata: [] };
      const { Extension, ContentType, Name, EncounterId, Date } = DocumentEnum;
      const getMetadata = () =>
        getNewMetadata(documentContent.metadata, {
          [Extension]: "pdf",
          [ContentType]: DocumentContentType.ApplicationPdf,
          [Name]: documentName,
          [EncounterId]: activeEncounterId,
          [Date]: DateTime.now().toISODate()
        });

      documentContent.patientId = patientId;
      documentContent.from = providerId;
      documentContent.content = documentTemplate.content;
      documentContent.metadata = getMetadata();
      documentContent.store = StoreType.Correspondence;
      documentContent.type = CorrespondenceType.Report;
      documentContent.direction = CorrespondenceDirection.Out;
      documentContent.showOnTimeline = true;
      documentContent.id = documentId;

      const options: DocumentCreateOptions = {
        source: DocumentSource.Content,
        documents: [documentContent]
      };

      if (activeEncounterId) {
        await correspondence.addDocuments(activeEncounterId, options);
      }

      clinical.ui.setConditionConsultsDocumentId(documentId);
      clinical.ui.setConditionConsultsExportDialogVisible(false);
    } catch {
      notification.error("An error occurred - unable to export to PDF");
    }
  };

  getDocumentTemplateContext = (
    consultIds: string,

    contextIds: {
      patientId: string;
      episodeOfCareId: string;
      claimId?: string;
      userId: string;
    },
    documentEdited: boolean
  ): TemplateRenderOptions["context"] => {
    const { episodeOfCareId, patientId, claimId, userId } = contextIds;

    const baseContext = {
      PatientId: patientId,
      EpisodeOfCareId: episodeOfCareId,
      DocumentEdited: documentEdited,
      ConsultIds: consultIds,
      UserId: userId
    };

    return claimId
      ? {
          ...baseContext,
          ClaimId: claimId
        }
      : baseContext;
  };

  getDocumentNameForExport = (
    claimNumber: string | undefined,
    diagnosis: string = "undiagnosed"
  ) => {
    return claimNumber
      ? `Export of consultation notes - ${diagnosis} - ${claimNumber}`
      : `Export of consultation notes - ${diagnosis}`;
  };

  getInitialItems = async (
    patientId: string,
    episodeOfCareId: string
  ): Promise<ConditionConsultEncounterItem[]> => {
    const eocEncounters = await this.root.clinical.getEncounters({
      patientId,
      episodeOfCareIds: [episodeOfCareId]
    });

    const sortedEncounters = Array.from(eocEncounters).sort((a, b) =>
      compareDatesPredicate(b.startDateTime, a.startDateTime)
    );

    return sortedEncounters.map((x, index, array) => {
      return {
        label: `${ordinalNumber(array.length - index)} - ${formatCalendarDate(
          x.startDateTime
        )} with ${x.user?.fullName}`,
        id: x.id,
        selected: true
      };
    });
  };

  private loadDialogs = async () => {
    const { clinical } = this.root;
    const activeEncounterId = clinical.activeRecordEncounterId;

    if (!this.clinicalRecord.openEncounter && !this.isViewOnly) {
      runInAction(() => {
        if (this.encounterId) {
          clinical.encountersDeletedElsewhere.push(this.encounterId);
        }
      });
    }

    const currentEncounterId =
      this.clinicalRecord.openEncounter?.id ?? activeEncounterId;

    const encounterId = this.isViewOnly ? undefined : currentEncounterId;

    if (encounterId && this.clinicalRecord.openEncounter) {
      const encounter = await clinical.getEncounter(encounterId);
      if (encounter.status === EncounterStatus.Closed) {
        runInAction(() => {
          clinical.recentlyFinalisedEncounterId = encounterId;
        });
      }
    }
  };

  //If we loaded the encounter but haven't routed to it check to see if we can and route to the encounter.
  private get shouldGoToEncounter() {
    const { clinical } = this.root;
    const onEncounterScreen = !!clinical.activeRecordEncounterId;
    const patientId = clinical.activeRecordPatientId;
    const encounterToGoToId = this.clinicalRecord.openEncounter?.id;

    const encounterId = this.isViewOnly ? undefined : encounterToGoToId;

    if (
      !onEncounterScreen &&
      encounterId &&
      !this.showEncounterExistsPrompt &&
      patientId
    ) {
      return { patientId, encounterId };
    }
    return false;
  }

  private goToEncounter = (patientId: string, encounterId: string) => {
    const { routing } = this.root;

    routing.replaceRetainingState(
      routes.records.encounter.path({
        id: patientId,
        encounterId
      })
    );
  };

  public loadHelper = async (trackEvent: (eventData: object) => void) => {
    const { billing, clinical, document } = this.root;
    await billing.getInvoiceSettings();
    //encounter not loaded or encounter has changed.
    if (
      !this.encounterId ||
      this.encounterId !== clinical.activeRecordEncounterId
    ) {
      this.setEncounterId(undefined);
      const activeEncounterId = clinical.activeRecordEncounterId;

      await this.multiEncountersLoadClinicalRecord(
        this.isViewOnlyOrDischarged,
        activeEncounterId
      );

      await this.loadDialogs();

      const gotToEncounterProps = this.shouldGoToEncounter;
      if (gotToEncounterProps) {
        this.goToEncounter(
          gotToEncounterProps.patientId,
          gotToEncounterProps.encounterId
        );
        //end loading helper if redirected
        return;
      }
    }

    //encounter is loaded.
    if (
      this.isViewOnly ||
      (!!this.encounterId &&
        this.encounterId === clinical.activeRecordEncounterId)
    ) {
      //encounter are dealt with before routing to them
      this.setShowEncounterExistsPrompt(false);
      const { clinicalRecord } = this;

      const encounterId = this.isViewOnly ? undefined : this.encounterId;

      clinical.addActiveClinicalRecord(encounterId);

      await Promise.all([
        this.trackEncounterStartedEvent(trackEvent),
        this.clinicalRecord.updateReasonForVisitFromDiagnosis(),
        this.setSOTAPTab(),
        this.setInjuryTab(),
        document.getAutofills(),
        clinicalRecord.isClearingInvoiceItemsRequired && !this.isViewOnly
          ? clinicalRecord.updateInvoice([])
          : undefined
      ]);
    }
  };
}

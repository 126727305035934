import { FC } from "react";

import {
  FontIcon,
  FontSizes,
  FontWeights,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { ResubmitStatusEnum, Step } from "./ResubmitInvoiceDialog.tsx";

interface ResubmitStatusProps {
  step: Step;
}

export const ResubmitStatus: FC<ResubmitStatusProps> = ({
  step: { name, status }
}) => {
  const theme = useTheme();

  const getIconProperties = () => {
    switch (status) {
      case ResubmitStatusEnum.Success:
        return { iconName: "Completed", color: theme.palette.green };
      case ResubmitStatusEnum.Error:
        return { iconName: "ErrorBadge", color: theme.palette.red };
      case ResubmitStatusEnum.Loading:
        return { iconName: "Sync", color: theme.palette.themePrimary };
      default:
        return {
          iconName: "StatusCircleRing",
          color: theme.semanticColors.disabledText
        };
    }
  };

  const getTextColor = () => {
    return status === ResubmitStatusEnum.Initial
      ? theme.semanticColors.disabledText
      : theme.semanticColors.bodyText;
  };

  const { iconName, color } = getIconProperties();

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <FontIcon
        iconName={iconName}
        styles={{
          root: {
            fontSize: FontSizes.large,
            color
          }
        }}
      />
      <Text
        styles={{
          root: {
            color: getTextColor(),
            fontWeight:
              status === ResubmitStatusEnum.Loading
                ? FontWeights.bold
                : undefined
          }
        }}
      >
        {name}
        {status === ResubmitStatusEnum.Loading && "..."}
      </Text>
    </Stack>
  );
};

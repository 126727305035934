import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const ContactNameColumn: FC<{
  contact: Contact;
}> = observer(({ contact: { id, reversedName, type } }) => {
  const {
    practice: {
      ui: { showContactDetails }
    }
  } = useStores();

  const onNameClick = () => {
    showContactDetails(id);
  };

  return (
    <Navigate
      onClick={onNameClick}
      data-contact-id={id}
      data-contact-type={type}
    >
      {reversedName}
    </Navigate>
  );
});

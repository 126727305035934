import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";

import {
  confirm,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Toggle
} from "@bps/fluent-ui";
import { ProviderOnlineStatus } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { UserSecurityFormLabels } from "./UserSecurityForm.types.ts";
import {
  saveBhbProviderOnlineStatus,
  updateShowOnCalendarValue
} from "./utils.ts";

type UserOnApptBookProps = {
  user: User;
  bhbProvider: BhbProvider | undefined;
};

export const UserOnApptBook: FC<UserOnApptBookProps> = observer(
  ({ user, bhbProvider }) => {
    const [toggleChecked, setToggleChecked] = useState<boolean | undefined>(
      !!user.userSetting?.showOnCalendar
    );

    useEffect(() => {
      setToggleChecked(!!user.userSetting?.showOnCalendar);
    }, [user.userSetting?.showOnCalendar]);

    const [isLoading, setIsLoading] = useState(false);

    const { booking, core, notification, userExperience, bhb } = useStores();

    const onCancelConfirm = (checked: boolean | undefined) => {
      setIsLoading(false);
      setToggleChecked(!checked);
    };

    const updateCalendarValue = async (
      checked: boolean | undefined,
      isProviderOnlineStatusUpdated: boolean | undefined
    ) => {
      setIsLoading(false);
      updateShowOnCalendarValue(user.id, user.name, {
        checked,
        core,
        notification,
        userExperience
      });
      isProviderOnlineStatusUpdated &&
        saveBhbProviderOnlineStatus(bhbProvider, {
          core,
          bhb
        });
      setToggleChecked(checked);
    };

    const updateShowUserOnCalendar = async (checked: boolean | undefined) => {
      setIsLoading(true);
      const hasFutureAppts = await booking.hasFutureCalendarEvents(user.id);
      const isProviderOnline =
        bhbProvider?.providerOnlineStatus &&
        bhbProvider?.providerOnlineStatus === ProviderOnlineStatus.YES;
      if (!checked) {
        setIsLoading(false);
        if (hasFutureAppts || isProviderOnline) {
          const isUpdateFutureApptsConfirmed = await confirm({
            confirmButtonProps: { text: "Hide user from calendar" },
            maxWidth: 600,
            minWidth: 600,

            dialogContentProps: {
              showCloseButton: true
            },
            title: "Hide provider from calendar",

            children: (
              <Stack tokens={{ childrenGap: 8 }}>
                {hasFutureAppts && (
                  <MessageBar messageBarType={MessageBarType.warning}>
                    Provider has upcoming appointments
                  </MessageBar>
                )}
                <Stack tokens={{ childrenGap: 16 }}>
                  {isProviderOnline && (
                    <MessageBar messageBarType={MessageBarType.warning}>
                      Provider is displayed in the practice online booking page
                    </MessageBar>
                  )}

                  {hasFutureAppts && (
                    <>
                      <Text>
                        Turning off {user.name}'s working hours may lead to
                        missed appointments as appointments will not be viewable
                        in the appointment book.
                      </Text>
                      <Text>
                        It is recommended you do not hide a provider from the
                        calendar if they have upcoming appointments.
                      </Text>
                    </>
                  )}
                  {isProviderOnline && (
                    <Text>
                      Hiding a provider from the appointment book will hide them
                      from the online booking page
                    </Text>
                  )}
                </Stack>
              </Stack>
            )
          });
          if (!isUpdateFutureApptsConfirmed) {
            onCancelConfirm(checked);
            return;
          }
        }
        updateCalendarValue(checked, isProviderOnline);
      } else {
        const isProviderNoShow =
          bhbProvider?.providerOnlineStatus &&
          bhbProvider?.providerOnlineStatus === ProviderOnlineStatus.NO;
        updateCalendarValue(checked, isProviderNoShow);
      }
    };

    return (
      <Stack horizontal tokens={{ childrenGap: 32 }}>
        <Toggle
          automationAttribute="hide-provider"
          onText={UserSecurityFormLabels.Show}
          offText={UserSecurityFormLabels.Hide}
          checked={toggleChecked}
          disabled={!core.hasUserSettingWritePermission}
          onChange={async (ev, checked: boolean | undefined) => {
            try {
              updateShowUserOnCalendar(checked);
            } catch (error) {
              setIsLoading(false);
            }
          }}
          label={UserSecurityFormLabels.ShowOnCalendar}
        />
        {isLoading === true && (
          <Spinner
            label="Loading"
            styles={{ root: { alignSelf: "flex-start", paddingTop: 10 } }}
            size={SpinnerSize.small}
          />
        )}
      </Stack>
    );
  }
);

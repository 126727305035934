import { useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  HideStack,
  Stack
} from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { IntakeDeleteDialog } from "./IntakeDeleteDialog.tsx";
import {
  IntakeDomain,
  IntakeFormItem,
  IntakeFormValues
} from "./IntakeForm.types.ts";
import { IntakeStatusField } from "./IntakeStatusField.tsx";
import { IntakeTable } from "./IntakeTable.tsx";
import { isIntakeStatusCurrent, isIntakeStatusEx } from "./utils.ts";

interface IntakeFormContentProps {
  domain: IntakeDomain;
  clinicalRecord: ClinicalRecord;
  maxIntakes?: number;
}
export const IntakeFormContent: React.FunctionComponent<
  IntakeFormContentProps
> = ({ domain, clinicalRecord, maxIntakes }) => {
  const nameOf = nameOfFactory<IntakeFormValues>();
  const form = useForm<IntakeFormValues>();

  const onConfirmDelete =
    () => async (reasonForDelete: string, deletedComment?: string) => {
      const { getState, change, mutators } = form;
      const { deleteIntake, currentIntakes, historicalIntakes } =
        getState().values;
      if (deleteIntake && deleteIntake.id) {
        // push intake to deleted intakes
        const intakeFormItem: IntakeFormItem = {
          ...deleteIntake,
          isDeleted: true,
          reasonForDelete,
          deletedComment
        };
        mutators.push(nameOf("deletedIntakes"), intakeFormItem);

        const isCurrent: boolean = deleteIntake.isCurrent;

        const index = (
          isCurrent ? currentIntakes : historicalIntakes
        ).findIndex(i => i.id === deleteIntake.id);

        const intakeName = isCurrent
          ? nameOf("currentIntakes")
          : nameOf("historicalIntakes");

        mutators.remove(intakeName, index);

        // if we deleted the last current intake, we add one empty row
        if (
          intakeName === nameOf("currentIntakes") &&
          currentIntakes.length === 0
        ) {
          const newIntake: IntakeFormItem = {
            type: "",
            frequency: "",
            isEdit: false,
            isCurrent: true
          };

          mutators.push(nameOf("currentIntakes"), newIntake);
        }
        change(nameOf("deleteIntake"), null);
      }
    };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <IntakeStatusField domain={domain} />
      <HideStack when={domain !== ClinicalDataType.Tobacco}>
        <FieldCondition when={nameOf("status")} is={isIntakeStatusEx}>
          <div
            {...dataAttribute(
              DataAttributes.Element,
              "ex-smoker-less-12-months-checkbox"
            )}
          >
            <CheckboxField
              label="Ex-smoker < 12 months"
              name={nameOf("exSmokerLessThan12Months")}
            />
          </div>
        </FieldCondition>
      </HideStack>
      <TextInputField
        multiline={true}
        label="Comment"
        name={nameOf("comment")}
        autoAdjustHeight
        resizable={false}
        styles={{
          root: { paddingBottom: 24, width: 600 }
        }}
        rows={4}
      />
      <When permission={Permission.CommonIntakeDetailAllowed}>
        <FieldCondition when={nameOf("status")} is={isIntakeStatusCurrent}>
          <div {...dataAttribute(DataAttributes.Element, "current-intakes")}>
            <IntakeTable
              name={nameOf("currentIntakes")}
              domain={domain}
              tableTitle="Current intake"
              maxIntakes={maxIntakes}
              clinicalRecord={clinicalRecord}
              initialValuesFromDto={
                form.getState().initialValues.currentIntakes ?? []
              }
            />
          </div>
        </FieldCondition>
        <FieldCondition
          when={nameOf("status")}
          is={(status: string) =>
            isIntakeStatusCurrent(status) || isIntakeStatusEx(status)
          }
        >
          <div {...dataAttribute(DataAttributes.Element, "historical-intakes")}>
            <IntakeTable
              name={nameOf("historicalIntakes")}
              domain={domain}
              tableTitle="Historical intake"
              clinicalRecord={clinicalRecord}
              initialValuesFromDto={
                form.getState().initialValues.historicalIntakes ?? []
              }
            />
          </div>
        </FieldCondition>
      </When>
      <FieldCondition
        when={nameOf("deleteIntake")}
        is={(value: any) => !!value}
      >
        <IntakeDeleteDialog
          hidden={false}
          onCancel={() => form.change(nameOf("deleteIntake"), null)}
          onConfirm={onConfirmDelete()}
        />
      </FieldCondition>
    </Stack>
  );
};

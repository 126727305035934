import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";

import { IGroup, Spinner, Stack } from "@bps/fluent-ui";
import { compareDatesPredicate, DateTime } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { InboxScreenContext } from "@modules/inbox/screens/context/InboxScreenContext.ts";
import { GroupedListItem } from "@modules/inbox/screens/inbox/Inbox.types.ts";
import { NoFileDataTile } from "@modules/inbox/screens/user-inbox/components/UserInboxScreenLeftSideList.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { ScrollListener } from "@ui-components/ScrollListener.tsx";

import { InboxGroupedList } from "../../shared-components/InboxGroupedList.tsx";
import { useUploadedResult } from "./useUploadedResult.tsx";

export const UploadScreenLeftSideList: React.FC = observer(() => {
  const { inbox } = useStores();

  const { searchResults, isLoading, isLoadingMore, handleScrolledToBottom } =
    useUploadedResult({ refreshKey: inbox.ui.documentRefreshKey });

  const { mapToGroupedListItem } = useContext(InboxScreenContext);

  useEffect(() => {
    const { hub } = inbox;
    // subscribe listener on mount
    hub.onEntityEvent(
      Entity.DocumentWorkflowInboxDocument,
      inbox.onDocumentUploadedEvent
    );

    // unsubscribe listener on unmount
    return () => {
      hub.unsubscribe(
        Entity.DocumentWorkflowInboxDocument,
        inbox.onDocumentUploadedEvent
      );
    };
  }, [inbox]);

  const uploadedDocs = Array.from(inbox.uploadSessionDocsMap.values())
    .map(mapToGroupedListItem)
    .sort((a: GroupedListItem, b: GroupedListItem) => {
      const dateA = DateTime.fromISO(a.changeLog.createdDate);
      const dateB = DateTime.fromISO(b.changeLog.createdDate);
      return compareDatesPredicate(dateA, dateB, true);
    });

  const itemIsNotInUploadedDocsFilter = (other: InboxDocument) =>
    uploadedDocs.findIndex(up => up.key === other.id) < 0;

  const otherDocs =
    searchResults?.value?.results
      .filter(itemIsNotInUploadedDocsFilter)
      .map(mapToGroupedListItem) ?? [];

  const allDocs = uploadedDocs.concat(otherDocs);

  const groups: IGroup[] = [];

  if (uploadedDocs.length > 0)
    groups.push({
      key: "currentUpload",
      name: "This upload",
      startIndex: 0,
      count: uploadedDocs.length
    });

  if (otherDocs.length > 0)
    groups.push({
      key: "otherUpload",
      name: "Others",
      startIndex: uploadedDocs.length,
      count: (searchResults.value?.total ?? 0) - uploadedDocs.length
    });

  return (
    <Stack
      styles={{
        root: {
          overflowY: "auto"
        }
      }}
    >
      {isLoading && <Spinner />}

      {!isLoading && allDocs.length > 0 && (
        <>
          <InboxGroupedList groups={groups} files={allDocs} />
          <ScrollListener
            searchResults={searchResults}
            onScrolledToBottom={handleScrolledToBottom}
          />
        </>
      )}

      {!searchResults.value?.results.length && !isLoading && !isLoadingMore && (
        <NoFileDataTile />
      )}
    </Stack>
  );
});

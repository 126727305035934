import { FormApi } from "final-form";
import { FunctionComponent, useState } from "react";
import { FormSpy, useForm } from "react-final-form";

import { IContextualMenuItem } from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { SubmitAction } from "@modules/clinical/screens/document-writer/components/DocumentWriterDetails.types.ts";
import { DocumentWriterHeader } from "@modules/clinical/screens/document-writer/components/DocumentWriterHeader.tsx";
import { SyncFusionDocumentEditor } from "@modules/clinical/screens/document-writer/components/sync-fusion/SyncFusionDocumentEditor.tsx";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { AllMutators } from "@ui-components/form/customMutators.ts";
import { When } from "@ui-components/withPerm.tsx";

import { useTemplateWriterContext } from "../context/TemplateWriterContext.ts";
import { TemplateWriterDialog } from "./TemplateWriterDialog.tsx";
import { TemplateWriterValues } from "./TemplateWriterValues.ts";

interface TemplateWriterDetailsFieldsProps {
  type: React.MutableRefObject<string>;
  docEditor: React.MutableRefObject<DocumentEditor | undefined>;
  documentSettings?: OrgUnitDocumentSettingsDto;
}

export const TemplateWriterDetailsFields: FunctionComponent<
  TemplateWriterDetailsFieldsProps
> = ({ type, docEditor, documentSettings }) => {
  const form = useForm<TemplateWriterValues>();

  const model = useTemplateWriterContext();

  const [showTemplateWriterDialog, setShowTemplateWriterDialog] =
    useState<boolean>(false);

  const onSplitButtonCallback = (
    form: Omit<
      FormApi<TemplateWriterValues, Partial<TemplateWriterValues>>,
      "mutators"
    > & {
      mutators: AllMutators;
    },
    clickedPropType?: IContextualMenuItem
  ) => {
    type.current = clickedPropType?.key || SubmitAction.Save;
    form.submit();
  };

  const setSyncFusionDocumentEditor = (documentEditor: DocumentEditor) => {
    docEditor.current = documentEditor;
  };

  const fieldsToBeRegistered: (keyof TemplateWriterValues)[] = [
    "name",
    "documentType"
  ];

  const disableLink = form.getState().values.documentContent === "";

  const registeredFields = form.getRegisteredFields();
  if (registeredFields.length <= 1) {
    fieldsToBeRegistered.forEach(fieldToBeRegistered => {
      form.registerField(fieldToBeRegistered, () => {}, {});
    });
  }

  const headerWithCTA = (
    <DocumentWriterHeader
      disableSubmitButton={!model.isEditMode}
      onSplitButtonCallback={item => onSplitButtonCallback(form, item)}
      onClickEditDetail={(value: boolean) => {
        setShowTemplateWriterDialog(value);
      }}
      isTemplateEditor={true}
      writerModel={model}
    />
  );

  const OnMessageBarLinkClick = () => {
    type.current = SubmitAction.Preview;
    form.submit();
  };

  const showMessageBar =
    !!documentSettings?.usePracticeLetterhead &&
    !model.currentTemplate.isLetterhead &&
    model.currentTemplate.documentType !== CorrespondenceType.Autofill;

  const heightCalc = showMessageBar
    ? "calc(100vh - 236px)"
    : "calc(100vh - 202px)";

  return (
    <>
      <When permission={Permission.SyncFusionEditor}>
        <SyncFusionDocumentEditor
          height={heightCalc}
          docEditor={docEditor.current}
          encounterId={undefined}
          setDocumentEditor={setSyncFusionDocumentEditor}
          headerWithCTA={headerWithCTA}
          isTemplateEditor={true}
          onMessageBarLinkClick={OnMessageBarLinkClick}
          showMessageBar={showMessageBar}
          disableLink={disableLink}
        />
      </When>
      <FormSpy
        subscription={{ dirty: true }}
        onChange={({ dirty }) => {
          model.setIsDirty(dirty);
        }}
      />
      <TemplateWriterDialog
        isDialogHidden={!showTemplateWriterDialog}
        onDismiss={() => {
          setShowTemplateWriterDialog(false);
          model.setIsEditAsCopy(false);
        }}
        model={model}
      />
    </>
  );
};

import { RecentPatientDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";

export interface RecentPatientsDetailsProps {
  recentPatient: RecentPatientDto;
}

export enum EventType {
  appointment = "Appointment",
  consult = "CON",
  invoice = "Invoice",
  patient = "Patient",
  recordUpdate = "RU"
}

export enum EventVerb {
  encounterClosed = "EncounterClosed",
  encounterStarted = "EncounterStarted"
}

import { observer } from "mobx-react-lite";
import React from "react";

import {
  DefaultButton,
  Dialog,
  Heading,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  MeasurementDto,
  QuestionnaireItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { Rand36ReponseTypes } from "./Rand36Form.types.ts";
import { findUnansweredQuestions, getColumnItems } from "./utils.ts";

interface Rand36ScoreModalProps {
  measurements: MeasurementDto[];
  onClose: () => void;
  answers?: QuestionnaireItem[];
}

export const Rand36ScoreModal: React.FC<Rand36ScoreModalProps> = observer(
  ({ measurements, answers, onClose }) => {
    const theme = useTheme();

    // Manually split the columns up, as occationally they can return in reverse order.
    const leftColumn = getColumnItems(measurements, [
      Rand36ReponseTypes.PhysicalFunctioning,
      Rand36ReponseTypes.LimitationsPhysicalHealth,
      Rand36ReponseTypes.LimitationsEmotionalProblems,
      Rand36ReponseTypes.EnergyFatigue
    ]);

    const rightColumn = getColumnItems(measurements, [
      Rand36ReponseTypes.EmotionalWellBeing,
      Rand36ReponseTypes.SocialFunctioning,
      Rand36ReponseTypes.Pain,
      Rand36ReponseTypes.GeneralHealth
    ]);

    const getBgColour = (index: number) => {
      return index % 2 === 0 ? "#F3F2F1" : theme.palette.white; // First instance of this colour. Does not seem to be recorded in the themes.
    };

    const renderRow = (listIndex: number) => {
      const unansweredLeftQuestions = findUnansweredQuestions(
        answers,
        leftColumn[listIndex].summary
      );

      const unansweredRightQuestions = findUnansweredQuestions(
        answers,
        rightColumn[listIndex].summary
      );

      const leftValue = Number.isInteger(leftColumn[listIndex].value)
        ? leftColumn[listIndex].value
        : leftColumn[listIndex].value.toPrecision(3);

      const rightValue = Number.isInteger(rightColumn[listIndex].value)
        ? rightColumn[listIndex].value
        : rightColumn[listIndex].value.toPrecision(3);

      return (
        <Stack
          horizontal
          styles={{
            root: { backgroundColor: getBgColour(listIndex), padding: 16 }
          }}
        >
          <Stack styles={{ root: { width: "50%", paddingRight: 8 } }}>
            <Text>
              {`${leftColumn[listIndex].summary} = ${leftValue}%`}
              <Stack>
                {unansweredLeftQuestions && (
                  <i>Questions not answered: {unansweredLeftQuestions} </i>
                )}
              </Stack>
            </Text>
          </Stack>
          <Stack styles={{ root: { width: "50%" } }}>
            <Text>
              {`${rightColumn[listIndex].summary} = ${rightValue}%`}
              <Stack>
                {unansweredRightQuestions && (
                  <i>Questions not answered: {unansweredRightQuestions} </i>
                )}
              </Stack>
            </Text>
          </Stack>
        </Stack>
      );
    };

    return (
      <Dialog
        minWidth={750}
        hidden={false}
        onDismiss={() => {
          onClose();
        }}
        dialogContentProps={{
          showCloseButton: true,
          title: <Heading variant="modal-heading">RAND 36</Heading>
        }}
      >
        <Stack>
          <Stack
            styles={{
              root: {
                padding: "12px 8px"
              }
            }}
          >
            {leftColumn.map((x, index) => {
              return renderRow(index);
            })}
          </Stack>
          <Stack
            horizontal
            horizontalAlign="end"
            styles={{ root: { paddingTop: 16 } }}
            tokens={{ childrenGap: 8 }}
          >
            <DefaultButton
              onClick={() => {
                onClose();
              }}
            >
              Close
            </DefaultButton>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem
} from "@bps/fluent-ui";
import { MedicationsTestElements } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { PrescriptionItem } from "./PrescriptionsList.tsx";

interface PrescriptionListContextMenuProps {
  prescription: PrescriptionItem;
}

export const PrescriptionListContextMenu: React.FC<
  PrescriptionListContextMenuProps
> = ({ prescription }) => {
  const helper = useMedicationsContext();

  const getSubMenuItems = () => {
    if (prescription.medications && prescription.medications.length > 1) {
      const subMenuItems: IContextualMenuItem[] = prescription.medications.map(
        x =>
          ({
            key: x.id,
            text: x.productName,
            onClick: () => {
              helper.createPrescription([x]);
            }
          }) as IContextualMenuItem
      );
      return subMenuItems;
    }
    return [];
  };

  const items: IContextualMenuItem[] =
    prescription.medications && prescription.medications.length > 1
      ? [
          {
            key: "represcripe",
            text: "Represcribe",
            subMenuProps: {
              items: getSubMenuItems()
            }
          }
        ]
      : [
          {
            key: "represcripe",
            text: "Represcribe",
            onClick: () => {
              if (prescription.medications)
                helper.createPrescription([prescription.medications[0]]);
            }
          }
        ];

  return (
    <IconButton
      {...dataAttribute(
        DataAttributes.Element,
        MedicationsTestElements.ContextualMenu
      )}
      menuIconProps={{
        iconName: "More"
      }}
      menuProps={{
        items
      }}
    />
  );
};

import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import { useContext, useMemo } from "react";

import { SideRailMenuItem, Stack } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactStatus,
  EmployerDto,
  HealthProviderDto,
  OrganisationRoleType,
  PrivateInsurerDto,
  PublicInsurerDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PatientDemographicContext } from "@modules/practice/screens/contact-details/context/PatientDemographicContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Contact,
  toOrganisationRoles
} from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { toAddressDto } from "../../../shared-components/utils/contact.utils.ts";
import { ContactSubmissionFormDialog } from "../../shared-components/edit/ContactSubmissionFormDialog.tsx";
import { EditFormLayout } from "../../shared-components/edit/EditFormLayout.tsx";
import { EditProfile } from "../../shared-components/edit/EditProfile.tsx";
import { toCommunicationDto } from "../../shared-components/edit/utils.ts";
import { EditOrganisationalLocation } from "./EditOrganisationalLocation.tsx";
import { EditOrganisationBilling } from "./EditOrganisationBillingInfo.tsx";
import { EditOrganisationFormValues } from "./EditOrganisationFormValues.tsx";
import { EditOrganisationHeader } from "./EditOrganisationHeader.tsx";
import { EditOrganisationType } from "./EditOrganisationType.tsx";
import { EditOrgRelationships } from "./EditOrgRelationships.tsx";
import {
  getOrganisationFormValues,
  orgMenuItems,
  toRelationshipDto
} from "./utils.ts";
import { OrganisationFormValidator } from "./validators/OrganisationFormValidator.ts";

type OrganisationFormDialogProps = {
  organisation: Contact | undefined;
};

const OrganisationFormDialogBase: React.FC<OrganisationFormDialogProps> =
  observer(({ organisation }) => {
    const { core, practice, notification } = useStores();

    const {
      ui: { demographicInitialValues, newContactType }
    } = practice;

    const isNewOrganisationVisible = !!newContactType;

    const helper = useContext(PatientDemographicContext);

    const country = core.tenantDetails?.country;
    const getNewRelationships = (values: EditOrganisationFormValues) => {
      const relIndex = [...values.relationships].findIndex(
        a => a.org[0] === RelationshipType.Managing
      );

      if (relIndex < 0) {
        if (values.linkOrganizationId) {
          values.relationships.push({
            relatedContactId: values.linkOrganizationId,
            professional: [],
            org: [RelationshipType.Managing],
            autoFocus: false,
            id: newGuid(),
            currentJob: false
          });
        }
      } else if (values.linkOrganizationId) {
        values.relationships[relIndex].relatedContactId =
          values.linkOrganizationId;
      } else {
        values.relationships.splice(relIndex);
      }

      return values.relationships;
    };

    const submitPicture = async (contact: Contact, fileStagingId: string) => {
      await practice.updateProfilePicture({
        entityId: contact.id,
        fileStagingId
      });
    };

    const initialValues = useMemo(
      () =>
        getOrganisationFormValues(organisation, isNewOrganisationVisible, {
          core,
          practice
        }),
      [core, isNewOrganisationVisible, organisation, practice]
    );

    const orgMenuItemsEdit: SideRailMenuItem[] = [
      ...orgMenuItems,
      {
        text: Labels.profile,
        id: OrganisationCardIds.OrganisationProfile
      }
    ];

    const handleValidate = (formValues: EditOrganisationFormValues) => {
      const validator = new OrganisationFormValidator(
        core.ref.countries.values,
        practice.isNzPublicInsurer(initialValues.organisationRoles),
        initialValues.organisationName!
      );
      return validator.validate(formValues, FORM_ERROR);
    };

    const onSubmit = async (values: EditOrganisationFormValues) => {
      const {
        organisationName,
        relationships,
        status,
        organisationRoles,
        profilePictureUrl,
        privateInsurer,
        employer,
        ...restValues
      } = values;

      const newRelationships = getNewRelationships(values);

      const rolesIncludePrivateInsurer = organisationRoles?.some(
        x =>
          x.organisationRoleTypeCode ===
            OrganisationRoleType.NzPrivateInsurer ||
          x.organisationRoleTypeCode === OrganisationRoleType.AuPrivateInsurer
      );

      let newPrivateInsurer: PrivateInsurerDto | undefined;

      if (rolesIncludePrivateInsurer) {
        if (country === Country.NewZealand) {
          newPrivateInsurer = {
            organisationRoleTypeCode: OrganisationRoleType.NzPrivateInsurer,
            accPrivateInsurer: privateInsurer?.accPrivateInsurer,
            claimsAdminEmail: privateInsurer?.claimsAdminEmail,
            invoicingEmail: privateInsurer?.invoicingEmail,
            differentInvoicingEmail: privateInsurer?.differentInvoicingEmail
          };
        } else {
          newPrivateInsurer = {
            organisationRoleTypeCode: OrganisationRoleType.AuPrivateInsurer
          };
        }
      }

      const rolesIncludePublicInsurer = organisationRoles?.some(
        x =>
          x.organisationRoleTypeCode === OrganisationRoleType.NzPublicInsurer ||
          x.organisationRoleTypeCode === OrganisationRoleType.AuPublicInsurer
      );

      let publicInsurer: PublicInsurerDto | undefined;

      if (rolesIncludePublicInsurer) {
        if (country === Country.NewZealand) {
          publicInsurer = {
            organisationRoleTypeCode: OrganisationRoleType.NzPublicInsurer
          };
        } else {
          publicInsurer = {
            organisationRoleTypeCode: OrganisationRoleType.AuPublicInsurer
          };
        }
      }

      const rolesIncludeEmployer = organisationRoles?.some(
        x =>
          x.organisationRoleTypeCode === OrganisationRoleType.NzEmployer ||
          x.organisationRoleTypeCode === OrganisationRoleType.AuEmployer
      );

      let newEmployer: EmployerDto | undefined;

      if (rolesIncludeEmployer) {
        if (country === Country.NewZealand) {
          if (employer) {
            newEmployer = {
              organisationRoleTypeCode: OrganisationRoleType.NzEmployer,
              accreditedEmployer: employer.accreditedEmployer,
              visitsPerClaim: employer.visitsPerClaim,
              privateInsurerContactId: employer.privateInsurerContactId,
              claimsAdminEmail: employer.claimsAdminEmail,
              invoicingEmail: employer.invoicingEmail,
              differentInvoicingEmail: employer.differentInvoicingEmail
            };
          }
        } else {
          newEmployer = {
            organisationRoleTypeCode: OrganisationRoleType.AuEmployer
          };
        }
      }

      const rolesIncludeHealthProvider = organisationRoles?.some(
        x =>
          x.organisationRoleTypeCode ===
            OrganisationRoleType.NzHealthProvider ||
          x.organisationRoleTypeCode === OrganisationRoleType.AuHealthProvider
      );

      let newHealthProvider: HealthProviderDto | undefined;

      if (rolesIncludeHealthProvider) {
        if (country === Country.NewZealand) {
          newHealthProvider = {
            organisationRoleTypeCode: OrganisationRoleType.NzHealthProvider
          };
        } else {
          newHealthProvider = {
            organisationRoleTypeCode: OrganisationRoleType.AuHealthProvider
          };
        }
      }

      const baseRequest = {
        ...restValues,
        organisationName,
        contactStatus: status,
        communications: toCommunicationDto(values.communications),
        addresses: toAddressDto(values.addresses),
        relationships: toRelationshipDto(newRelationships),
        organisationRoles:
          newPrivateInsurer || newEmployer || newHealthProvider
            ? toOrganisationRoles(
                {
                  privateInsurerDto: newPrivateInsurer,
                  publicInsurerDto: publicInsurer,
                  employerDto: newEmployer,
                  healthProviderDto: newHealthProvider
                },
                country!
              )
            : organisationRoles?.filter(r => !!r.organisationRoleTypeCode)
      };
      let updateOrganisation;

      if (!!initialValues.profilePictureUrl && !values.profilePictureUrl) {
        try {
          await practice.deleteProfilePicture(organisation?.id!);
        } catch (error) {
          notification.error("Error occurred while removing profile photo");
        }
      }

      if (!values.id) {
        updateOrganisation = await practice.addOrganisation({
          ...baseRequest,
          contactStatus: ContactStatus.Active
        });
      } else {
        updateOrganisation = await practice.updateOrganisation({
          id: values.id,
          ...baseRequest
        });
      }

      if (helper.profilePictureStagingId && updateOrganisation) {
        // stores picture and fetches contact with new picture url
        await submitPicture(updateOrganisation, helper.profilePictureStagingId);
      }
    };

    return (
      <ContactSubmissionFormDialog<EditOrganisationFormValues>
        initialValues={initialValues}
        validate={handleValidate}
        onSubmit={onSubmit}
        buttonsProps={{
          hideButtonsSeparator: true,
          disableSubmitOnPristine: !demographicInitialValues,
          cancelButtonProps: {
            id: "close-organization-form-dialog"
          },
          styles: {
            root: {
              marginTop: 0
            }
          }
        }}
      >
        {() => (
          <EditFormLayout
            menuItems={
              isNewOrganisationVisible ? orgMenuItems : orgMenuItemsEdit
            }
            contact={organisation}
          >
            <Stack tokens={{ childrenGap: 48 }} grow>
              <EditOrganisationHeader organisation={organisation} />
              {organisation?.organisationRoles.some(
                x =>
                  x.organisationRoleTypeCode ===
                  OrganisationRoleType.NzPublicInsurer
              ) ? null : (
                <When permission={[Permission.SaveDraftAllowed]}>
                  <EditOrganisationBilling />
                </When>
              )}
              {practice.ref.organisationRoleType.values.length > 0 && (
                <EditOrganisationType />
              )}

              <EditOrganisationalLocation />
              <EditOrgRelationships />
              {!isNewOrganisationVisible && (
                <EditProfile
                  contact={organisation}
                  sectionId={OrganisationCardIds.OrganisationProfile}
                />
              )}
            </Stack>
          </EditFormLayout>
        )}
      </ContactSubmissionFormDialog>
    );
  });

export const OrganisationFormDialog = withFetch(
  x => [x.practice.ref.organisationRoleType.load()],
  OrganisationFormDialogBase
);

import { ReactNode } from "react";

import {
  IColumn,
  IDetailsListProps,
  IRenderFunction,
  IStyle,
  ITheme
} from "@bps/fluent-ui";
import { Location } from "@stores/router/RouterStore.ts";
import { SpinNumberInputFieldProps } from "@ui-components/form/SpinNumberInputField.tsx";

import { ColumnOptions } from "./types.ts";

export type InvalidItemsIndexes = number[];

export type ItemListBaseProps = IDetailsListProps & {
  columnOptions?: ColumnOptions;
  name: string;
  getColumns: (renderItem: RenderItemProps) => IColumn[];
  options: {
    footer?: FooterOptions;
    extraHeader?: HeaderOptions; // appropriate for Payment in the current design
    unallocatedAmount?: number; // appropriate for Payment only
    invalidItemsIndexes?: InvalidItemsIndexes;
  };
};

export interface FooterOptions {
  startIndex?: number /* A footer row start position (column index) */;
  fields: TotalField[];
  onRenderFooterButton?: IRenderFunction<{}>;
}

export interface HeaderOptions extends FooterOptions {
  header?: ReactNode;
}

export enum PaymentTotals {
  gst = "gst",
  owing = "owing",
  total = "paymentTotal",
  allocated = "allocated",
  unallocated = "unallocated",
  totalIncGst = "itemTotal",
  totalExcGst = "totalExcGst",
  owingExcGst = "owingExcGst",
  balanceOwing = "balanceOwing",
  creditAllocated = "creditAllocated",
  creditOriginated = "creditOriginated",
  itemCount = "itemCount"
}

export enum InvoiceTotals {
  gst = "gst",
  owing = "owing",
  totalIncGst = "totalIncGst"
}

export interface TotalField {
  key: string;
  title: string;
  showState?: boolean; // indicated in color
  dataAttr?: string; // for testing purposes
  hideHeaderValue?: boolean;
  boldHeading?: boolean;
}

export interface RenderTextOptions {
  value: string | JSX.Element;
  styles?: (theme: ITheme) => IStyle;
  dataAttributeElement?: string;
}

export interface RenderItemProps {
  text: (options: RenderTextOptions) => JSX.Element;
  input: (props: TextInputProps) => JSX.Element;
  checkbox: (props: ToggleInputProps) => JSX.Element;
  link: (options: {
    path: Partial<Location>;
    value: string;
    styles?: (theme: ITheme) => IStyle;
  }) => JSX.Element;
}

export interface TextInputProps
  extends Omit<SpinNumberInputFieldProps, "onChange" | "name"> {
  name: string;
  index: number;
  onChange?: (
    value: string | number | boolean,
    values?: object,
    index?: number
  ) => void;
}

export interface ToggleInputProps {
  index: number | undefined;
  name: string;
  disabled?: boolean;
  onChange?: (
    value: string | number | boolean,
    values?: object,
    index?: number
  ) => void;
}

import { FormApi } from "final-form";
import { FunctionComponent, useState } from "react";
import { FormSpy, useForm, useFormState } from "react-final-form";

import { IContextualMenuItem, Spinner } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { SendClinicalDocumentDialog } from "@modules/clinical/screens/patient-record/components/SendClinicalDocumentDialog.tsx";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { AllMutators } from "@ui-components/form/customMutators.ts";
import { When } from "@ui-components/withPerm.tsx";

import { useDocumentWriterContext } from "../context/DocumentWriterContext.ts";
import { SubmitAction } from "./DocumentWriterDetails.types.ts";
import { DocumentWriterHeader } from "./DocumentWriterHeader.tsx";
import { DocumentWriterHeaderDialog } from "./DocumentWriterHeaderDialog.tsx";
import { DocumentWriterValues } from "./DocumentWriterValues.ts";
import { SyncFusionDocumentEditor } from "./sync-fusion/SyncFusionDocumentEditor.tsx";

interface DocumentWriterDetailsFieldsProps {
  type: React.MutableRefObject<string>;
  docEditor: React.MutableRefObject<DocumentEditor | undefined>;
  encounterId: string | undefined;
}

export const DocumentWriterDetailsFields: FunctionComponent<
  DocumentWriterDetailsFieldsProps
> = ({ type, docEditor, encounterId }) => {
  const model = useDocumentWriterContext();
  const form = useForm<DocumentWriterValues>();
  const { values } = useFormState<DocumentWriterValues>({
    subscription: { values: true }
  });

  const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false);
  const [showDocumentWriterDialog, setShowDocumentWriterDialog] =
    useState<boolean>(false);

  const onSplitButtonCallback = (
    form: Omit<
      FormApi<DocumentWriterValues, Partial<DocumentWriterValues>>,
      "mutators"
    > & {
      mutators: AllMutators;
    },
    clickedPropType?: IContextualMenuItem
  ) => {
    type.current = clickedPropType?.key || SubmitAction.Save;
    if (type.current === SubmitAction.Email) {
      setShowEmailDialog(true);
      return;
    }
    form.submit();
  };

  const fieldsToBeRegistered: (keyof DocumentWriterValues)[] = [
    "documentTitle",
    "providerId",
    "recipientId",
    "documentType"
  ];

  const registeredFields = form.getRegisteredFields();
  if (registeredFields.length <= 1) {
    fieldsToBeRegistered.forEach(fieldToBeRegistered => {
      form.registerField(fieldToBeRegistered, () => {}, {});
    });
  }

  const setSyncFusionDocumentEditor = (documentEditor: DocumentEditor) => {
    docEditor.current = documentEditor;
  };

  const recipientId = values.recipientId;

  return (
    <ContactFetcher contactId={model.patientId} fallback={<Spinner />}>
      {patient => {
        const headerWithCTA = (
          <DocumentWriterHeader
            recipientId={values.recipientId}
            patientNameWithTitle={patient.nameWithTitle}
            disableSubmitButton={!model.templateId}
            onSplitButtonCallback={item => onSplitButtonCallback(form, item)}
            onClickEditDetail={(value: boolean) => {
              setShowDocumentWriterDialog(value);
            }}
            writerModel={model}
          />
        );

        return (
          <>
            <When permission={Permission.SyncFusionEditor}>
              <SyncFusionDocumentEditor
                height="calc(100vh - 202px)"
                docEditor={docEditor.current}
                patientId={model.patientId}
                encounterId={encounterId}
                setDocumentEditor={setSyncFusionDocumentEditor}
                headerWithCTA={headerWithCTA}
                contactId={values.recipientId}
                showMessageBar={false}
              />
            </When>
            <DocumentWriterHeaderDialog
              isDialogHidden={!showDocumentWriterDialog}
              patientName={patient.nameWithTitle}
              onDismiss={() => setShowDocumentWriterDialog(false)}
            />

            <FormSpy
              subscription={{ dirty: true }}
              onChange={({ dirty }) => {
                model.setIsDirty(dirty);
              }}
            />
            {showEmailDialog && (
              <SendClinicalDocumentDialog
                onDismiss={() => {
                  model.destinationEmail = undefined;
                  setShowEmailDialog(false);
                }}
                onSubmit={({ email }) => {
                  model.destinationEmail = email;
                  setShowEmailDialog(false);
                  form.submit();
                }}
                patientId={recipientId ?? patient.id}
                documentTitle={values.documentTitle}
                extension="docx"
              />
            )}
          </>
        );
      }}
    </ContactFetcher>
  );
};

import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useRef
} from "react";

import { PresetButtonType, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  AccScheduleTransactionStatuses,
  BillingStatuses,
  GetTransactionsArgs
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { TransactionFilterBarBase } from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/TransactionFilterBarBase.tsx";
import {
  claimStatusesFilter,
  searchFilter,
  usersSelectFilter
} from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/transactionFilters.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import { getAccInvoiceFilterQuery } from "../utils.ts";
import {
  AccInvoicesFilter,
  accInvoicesFilterNameOf,
  AccInvoicesFilterQuery,
  defaultAccInvoicesFilter
} from "./AccInvoiceTransactionsListFilter.types.ts";
import { onFilterChange } from "./utils.ts";

const AccInvoiceTransactionsListFilterBase: FunctionComponent<PropsWithChildren> =
  observer(({ children }) => {
    const { routing, billing } = useStores();
    const { defaultNzPublicInsurerId } = useContext(AccInvoicesListContext);

    const theme = useTheme();

    const initialValues = useRef<AccInvoicesFilter>(
      getAccInvoiceFilterQuery(routing.location.search || "")
    );

    const parseToGetByArgs = (searchQuery: string): GetTransactionsArgs => {
      const { actionRequired, ...filter }: AccInvoicesFilterQuery = searchQuery
        ? parse(searchQuery)
        : {};

      return {
        ...filter,
        transactionStatuses: actionRequired
          ? [
              AccScheduleTransactionStatuses.Error,
              AccScheduleTransactionStatuses.Invalid,
              AccScheduleTransactionStatuses.InException,
              AccScheduleTransactionStatuses.Cancelled,
              AccScheduleTransactionStatuses.Incomplete,
              AccScheduleTransactionStatuses.SentToAR
            ]
          : filter.transactionStatuses,
        accountIds: [defaultNzPublicInsurerId],
        billingStatuses: [BillingStatuses.current],
        endTime: filter.isOwing
          ? DateTime.now().minus({ days: 7 }).toISODate()
          : undefined
      };
    };

    const items: Item<AccInvoicesFilter>[] = [
      searchFilter(),
      {
        type: "optionsSelect",
        name: accInvoicesFilterNameOf("transactionStatuses"),
        props: {
          id: "acc-invoices-filter-transaction-statuses",
          multiSelect: true,
          placeholder: "State",
          hideSearchOption: true,
          calloutWidth: 170,
          options: [
            { key: AccScheduleTransactionStatuses.NotReady, text: "Not ready" },
            {
              key: AccScheduleTransactionStatuses.Ready,
              text: "Ready",
              isDivider: true
            },

            { key: AccScheduleTransactionStatuses.SentToAR, text: "A/R" },
            {
              key: AccScheduleTransactionStatuses.Authorised,
              text: "Authorised"
            },
            {
              key: AccScheduleTransactionStatuses.AwaitingAuthorisation,
              text: "Authorising"
            },
            {
              key: AccScheduleTransactionStatuses.Cancelled,
              text: "Cancelled"
            },
            { key: AccScheduleTransactionStatuses.Error, text: "Error" },
            {
              key: AccScheduleTransactionStatuses.InException,
              text: "Exception"
            },
            {
              key: AccScheduleTransactionStatuses.Incomplete,
              text: "Incomplete"
            },
            { key: AccScheduleTransactionStatuses.Invalid, text: "Invalid" },
            { key: AccScheduleTransactionStatuses.Paid, text: "Paid" },
            {
              key: AccScheduleTransactionStatuses.PartiallyPaid,
              text: "Part-paid"
            },
            { key: AccScheduleTransactionStatuses.Pending, text: "Pending" },
            { key: AccScheduleTransactionStatuses.Queued, text: "Queued" },
            {
              key: AccScheduleTransactionStatuses.CompleteAndAwaitingValidation,
              text: "Validating"
            }
          ]
        }
      },
      claimStatusesFilter(billing.ref.claimStatuses.keyTextValues),
      usersSelectFilter(),
      {
        type: "datesRangePicker",
        name: "datesRangePicker",
        props: {
          id: "acc-invoices-filter-dates-range",
          startDateProps: { id: "acc-invoices-filter-start-date" },
          endDateProps: { id: "acc-invoices-filter-end-date" },
          startDateName: accInvoicesFilterNameOf("serviceStartTime"),
          endDateName: accInvoicesFilterNameOf("serviceEndTime"),
          placeholder: "Date(s)"
        }
      }
    ];

    const presets: PresetButtonType<AccInvoicesFilter>[] = [
      {
        text: "Owing",
        name: accInvoicesFilterNameOf("isOwing"),
        id: "owing",
        iconName: "BpOwing",
        tooltip: "Only show Owing/Unpaid invoices that are older than 1 week",
        tooltipPresses:
          "Only showing Owing/Unpaid invoices that are older than 1 week"
      },
      {
        text: "Action required",
        name: accInvoicesFilterNameOf("actionRequired"),
        id: "action-required",
        iconName: "Warning",
        tooltip:
          "Only showing invoices with Error, Cancelled, Invalid, Exception, Incomplete, or Sent to A/R status",
        tooltipPresses:
          "Only showing invoices with Error, Cancelled, Invalid, Exception, Incomplete, or Sent to A/R status"
      },
      {
        text: "Comments",
        name: accInvoicesFilterNameOf("hasStatusReason"),
        id: "comments",
        iconName: "CannedChat",
        iconColor: theme.semanticColors.inputIcon,
        tooltip: "Only show invoices with provider comments",
        tooltipPresses: "Only showing invoices with provider comments"
      }
    ];

    return (
      <TransactionFilterBarBase<AccInvoicesFilter>
        initialValues={initialValues.current}
        onChange={onFilterChange}
        items={items}
        presets={presets}
        defaultFilter={defaultAccInvoicesFilter}
        parseToGetByArgs={parseToGetByArgs}
      >
        {children}
      </TransactionFilterBarBase>
    );
  });

export const AccInvoiceTransactionsListFilter = withFetch(
  x => [
    x.billing.ref.claimStatuses.load(),
    x.billing.ref.accScheduleTransactionStatuses.load()
  ],
  AccInvoiceTransactionsListFilterBase
);

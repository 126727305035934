import { useCallback } from "react";

import { PagingOptions } from "@libs/api/dtos/index.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useInfiniteScroll } from "@ui-components/InfiniteScrollList/useInfiniteScroll.ts";

export const useInboxSearch = ({ refreshKey }: { refreshKey: string }) => {
  const { inbox } = useStores();

  const getItems = useCallback(
    (args: PagingOptions) => {
      return inbox.getCurrentUserInboxDocuments(args);
    },
    [inbox]
  );

  const { searchResults, isLoading, isLoadingMore, handleScrolledToBottom } =
    useInfiniteScroll({
      getItems,
      refreshKey
    });

  return {
    searchResults,
    isLoading,
    isLoadingMore,
    handleScrolledToBottom
  };
};

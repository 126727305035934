import { observer } from "mobx-react-lite";

import { getScreenSelector, HideStack, Stack } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";

import {
  BASIC_PADDING,
  FILTERS_MIN_WIDTH
} from "./BookingCalendarFilters.types.ts";
import { BookingCalendarLocationSelect } from "./BookingCalendarLocationSelect.tsx";
import { BookingCalendarScreenCommands } from "./BookingCalendarScreenCommands.tsx";
import { CalendarEventViewSelector } from "./CalendarEventViewSelector.tsx";
import { DayNavigator } from "./DayNavigator.tsx";

export const NAV_CONTAINER_HEIGHT = 44;
export const LARGE_SCREEN_BREAKPOINT = 1610;

export const BookingCalendarNavigation = observer(() => {
  const { hideLeftSide } = useBookingCalendarScreenContext();

  return (
    <Stack
      horizontal
      horizontalAlign={!hideLeftSide ? "space-between" : "end"}
      verticalAlign="center"
      tokens={{ childrenGap: 16 }}
      styles={{
        root: {
          marginLeft: 0,
          minHeight: NAV_CONTAINER_HEIGHT,
          selectors: {
            [getScreenSelector(0, LARGE_SCREEN_BREAKPOINT)]: {
              // ⚠️ Hack to move BookingCalendarNavigation left for laptop screen width,
              // previously we rendered two versions of layouts, now one only with css handlers
              // happy for another solution if someone comes up with. Ilya S.
              marginLeft: !hideLeftSide
                ? -Math.abs(FILTERS_MIN_WIDTH + BASIC_PADDING)
                : 0
            }
          }
        }
      }}
    >
      <BookingCalendarLocationSelect />
      <HideStack when={hideLeftSide}>
        <DayNavigator />
      </HideStack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ childrenGap: 24 }}
        grow
      >
        <CalendarEventViewSelector />
        <BookingCalendarScreenCommands />
      </Stack>
    </Stack>
  );
});

import { FormApi } from "final-form";
import React, { useState } from "react";

import { confirm, Spinner } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  MeasurementDto,
  PSFSReason,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { ConfidentialShield } from "../shared-components/ConfidentialShield.tsx";
import { PSFSFormModel } from "./context/PSFSFormModel.ts";
import { PSFSHelper } from "./context/PSFSHelper.ts";
import { PSFSBaselineForm } from "./PSFSBaselineForm.tsx";
import {
  PSFS_FORM_HEADING,
  PSFSFormValues,
  psfsNameOf
} from "./PSFSForm.types.ts";
import { PSFSScoreModal } from "./PSFSScoreModal.tsx";
import { PSFSSeriesForm } from "./PSFSSeriesForm.tsx";
import { PSFSTrendDialog } from "./PSFSTrendDialog.tsx";
import { PSFSFormValidator } from "./validators/PSFSFormValidator.ts";

interface PSFSFormProps {
  clinicalRecord: ClinicalRecord;
  contextId: string;
}

const PSFSFormBase: React.FunctionComponent<
  PSFSFormProps & { questionnaire: QuestionnaireDto }
> = ({ clinicalRecord, contextId, questionnaire }) => {
  const { clinical, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const [summary, setSummary] = useState<string>("");
  const [showTrend, setShowTrend] = useState<boolean>(false);
  const onMeasurementSaved = (measurement: MeasurementDto) => {
    setSummary(measurement.summary ?? measurement[0].value.toString());
  };

  const openTrendingDialog = (): void => {
    setShowTrend(true);
  };

  const [closeOnSubmit, setCloseOnSubmit] = useState<boolean>(false);

  const canSubmit = async (
    form: FormApi<PSFSFormValues, Partial<PSFSFormValues>>
  ) => {
    const values = form.getState().values;

    if (values[psfsNameOf("reason")] === PSFSReason.Discharge) {
      const isConfirmed = await confirm({
        confirmButtonProps: {
          text: "Close Series"
        },
        cancelButtonProps: {
          text: "Cancel"
        },
        dialogContentProps: {
          title: "Discharge PSFS",
          subText:
            "This will be the final PSFS in this series. Are you sure you would like to proceed?"
        }
      });
      if (isConfirmed) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const encounterId = clinicalRecord.openEncounter?.id;
  const model = new PSFSFormModel(clinical, clinicalRecord, {
    onMeasurementSaved,
    contextId
  });

  const helper = new PSFSHelper(clinicalRecord, contextId, encounterId);
  const nameOf = nameOfFactory<PSFSFormValues>();

  const modalClose = () => {
    if (closeOnSubmit) {
      model.onCancel();
    } else {
      setSummary("");
    }
  };

  const onSubmitSucceeded = (
    values: PSFSFormValues,
    form: FormApi<PSFSFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.PSFS,
        contextId: model.context?.contextId,
        encounterId: model.context?.contextId
          ? clinicalRecord.openEncounter?.id
          : undefined
      });
      setCloseOnSubmit(false);
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      setCloseOnSubmit(true);
    }
  };

  return (
    <>
      <ClinicalSubmissionForm<PSFSFormValues>
        formName="psfs"
        onSubmit={model.onSubmit(questionnaire)}
        onSubmitSucceeded={onSubmitSucceeded}
        canSubmit={canSubmit}
        hideButtons
        validate={(values: PSFSFormValues) => {
          const contextClinicalData =
            clinicalRecord?.stashedClinicalData?.psfsContext?.contexts.filter(
              x => {
                return (
                  x.diagnosis.code === values.diagnosisKey &&
                  x.side === values.side
                );
              }
            );

          const validator = new PSFSFormValidator({
            contextClinicalData,
            contextId
          });

          return validator.validate(values);
        }}
        initialValues={model.initialValues}
        readOnly={isViewOnlyOrDischarged}
        disableRoutePrompt
        heading={PSFS_FORM_HEADING}
        onCancel={model.onCancel}
        extraPromptConditionOnCancel={() =>
          !!(
            clinicalRecord.stashedClinicalData?.dirtyAreas?.get("psfs") ||
            clinicalRecord.stashedClinicalData?.dirtyAreas?.get("psfsContext")
          )
        }
        disableButtonCondition={stashedData =>
          !stashedData?.dirtyAreas?.get("psfs") &&
          !stashedData?.dirtyAreas?.get("psfsContext")
        }
        lastUpdatedDate={model.lastUpdatedDate}
        lastUpdatedUserId={model.lastUpdatedUserId}
        confidentialShield={
          <ConfidentialShield name={nameOf("confidential")} />
        }
      >
        <>
          <StashedClinicalDataFormSpy<PSFSFormValues>
            clinicalRecord={clinicalRecord}
            getData={model.getStashedClinicalData(questionnaire)}
            contextId={model.context?.contextId}
            encounterId={
              model.context?.contextId
                ? clinicalRecord.openEncounter?.id
                : undefined
            }
            areasToObserve={{
              psfs: [ClinicalDataType.PSFS],
              psfsContext: [ClinicalDataType.PSFS]
            }}
          />
          {helper.isInitialAssessment ? (
            <PSFSBaselineForm
              questionnaire={questionnaire}
              context={model.context}
              clinicalRecord={clinicalRecord}
            />
          ) : (
            <PSFSSeriesForm
              questionnaire={questionnaire}
              context={model.context}
            />
          )}
        </>
      </ClinicalSubmissionForm>
      {summary && (
        <PSFSScoreModal
          title={PSFS_FORM_HEADING}
          onClose={modalClose}
          openTrendingDialog={openTrendingDialog}
          diagnosis={summary}
          isInitialAssessment={!helper.isInitialAssessment}
        />
      )}
      {showTrend && (
        <PSFSTrendDialog
          clinicalRecord={clinicalRecord}
          contextId={contextId!}
          onClose={modalClose}
        />
      )}
    </>
  );
};

export const PSFSForm: React.FunctionComponent<PSFSFormProps> = props => {
  return (
    <DataFetcher<QuestionnaireDto>
      fetch={async x => {
        return await x.clinical.getQuestionnaires(QuestionnaireType.PSFSV1);
      }}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <PSFSFormBase {...props} questionnaire={questionnaire} />
      )}
    </DataFetcher>
  );
};

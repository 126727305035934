import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { FontSizes, Pivot, PivotItem } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";

import { CalendarPage } from "../BookingCalendarScreen.types.ts";

export const CalendarEventViewSelector: FunctionComponent = observer(() => {
  const { calendarPage, setCalendarPage } = useBookingCalendarScreenContext();

  const handleViewClick = (item: PivotItem): void => {
    const selectedValue =
      item.props && (item.props.itemKey as CalendarPage | undefined);

    if (selectedValue === calendarPage) {
      return;
    }
    setCalendarPage(selectedValue);
  };

  return (
    <Pivot
      style={{ flexGrow: 1, flexShrink: 0 }}
      styles={{
        root: {
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          flexGrow: 1,
          flexShrink: 0
        },
        overflowMenuButton: {
          selectors: {
            i: {
              fontSize: FontSizes.size18
            }
          }
        }
      }}
      selectedKey={calendarPage ?? "Appointments"}
      onLinkClick={handleViewClick}
      overflowBehavior="menu"
    >
      <PivotItem headerText="Appointments" />
      <PivotItem headerText="Schedule" itemKey="agenda" />
      <PivotItem headerText="Waiting room" itemKey="waitingRoom" />
      <PivotItem headerText="Waiting list" itemKey="waitingList" />
      <PivotItem headerText="Incomplete" itemKey="didNotArrive" />
      <PivotItem headerText="Appt reminder" itemKey="eventReminders" />
    </Pivot>
  );
});

import { useContext } from "react";

import { IconButton, IContextualMenuItem, Stack } from "@bps/fluent-ui";
import { EncounterStatus } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClaimAppointmentContext } from "./ClaimAppointmentContext.tsx";
import { ConditionConsultsWrapper } from "./ConditionConsultsWrapper.tsx";
import { ConditionContextMenuOptions } from "./ConditionDetails.types.ts";
import { ConditionDetailsModel } from "./ConditionsSidePanelHelper.ts";

interface ConditionDetailsContextMenuProps {
  model: ConditionDetailsModel;
  isNoLinkedConsults?: boolean;
  onDelete?: () => void;
}

export const ConditionDetailsContextMenu: React.FC<
  ConditionDetailsContextMenuProps
> = ({ model, isNoLinkedConsults, onDelete }) => {
  const root = useStores();
  const { core, clinical } = root;
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { isLinkedCondition } = useContext(ClaimAppointmentContext);

  const {
    setConditionConsultsExportDialogVisible,
    setIsUnlinkConditionConfirmationDialogVisible
  } = clinical.ui;

  const condition = model.condition;
  const linkedCalendarEventIds = condition?.calendarEventIds;
  const patientName = clinicalRecord.patient?.nameWithTitle ?? "";
  const diagnosis = model.diagnosis ?? "";
  const claimBadgeText = ConditionHelper.getClaimBadgeText(condition);
  const patientId = clinicalRecord.patient?.id ?? "";
  const currentEpisodeOfCareId = condition?.episodeOfCareId ?? "";
  const claimId = condition?.claim?.id;
  const providerId = clinicalRecord.calendarEvent?.userId ?? "";
  const claimNumber = condition?.claim?.claimNumber;

  const appointmentVisits = model.appointmentVisits ?? [];

  const isBilled = appointmentVisits
    ? !!appointmentVisits.find(x => x.billed)
    : false;

  const isLinked = isLinkedCondition(currentEpisodeOfCareId);

  const isRemovable =
    condition &&
    !isLinked &&
    isNoLinkedConsults &&
    !claimNumber &&
    !isBilled &&
    (linkedCalendarEventIds?.length === 0 || !linkedCalendarEventIds) &&
    core.hasPermissions(Permission.EoCWrite);

  const items: IContextualMenuItem[] = [
    {
      key: ConditionContextMenuOptions.Export,
      disabled: isNoLinkedConsults,
      text: "Export",
      onClick: () => {
        setConditionConsultsExportDialogVisible(true);
      }
    },
    {
      key: ConditionContextMenuOptions.Unlink,
      text: "Unlink",
      disabled:
        !isLinked ||
        clinicalRecord.openEncounter?.status !== EncounterStatus.Open,
      onClick: () => setIsUnlinkConditionConfirmationDialogVisible(true)
    },
    {
      key: ConditionContextMenuOptions.Delete,
      text: "Delete",
      disabled: !isRemovable,
      onClick: async () => {
        await clinical.deleteEpisodeOfCare(currentEpisodeOfCareId);
        if (onDelete) onDelete();
      }
    }
  ].filter(
    x =>
      x.key !== ConditionContextMenuOptions.Unlink ||
      core.hasPermissions(Permission.UnlinkConditionAllowed)
  );

  return (
    <Stack>
      <IconButton
        menuIconProps={{ iconName: "More" }}
        menuProps={{
          items
        }}
      />
      <ConditionConsultsWrapper
        patientName={patientName}
        diagnosis={diagnosis}
        claimBadgeText={claimBadgeText}
        patientId={patientId}
        episodeOfCareId={currentEpisodeOfCareId}
        claimId={claimId}
        providerId={providerId}
        claimNumber={claimNumber}
      />
    </Stack>
  );
};

import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import {
  ActionButton,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PulseDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import {
  MEDIUM_BREAKPOINT,
  SMALL_BREAKPOINT
} from "../../full-body-clinical-data/examination/utils.ts";
import {
  GeneralExamFieldConditionKeys,
  Positions
} from "./GeneralExamination.types.ts";
import { nameOfGeneralExaminationForm } from "./GeneralExaminationFields.tsx";
import { GeneralExaminationFormValues } from "./GeneralExaminationFormValues.ts";
import { GeneralHighLowSevereWarningMessageBar } from "./GeneralHighLowSevereWarningMessageBar.tsx";
import { getButtonGroupBreakLineStyles, getFieldSetStyles } from "./utils.ts";

interface PulsesListProps {
  width: number;
}

const PulseListBase: React.FunctionComponent<PulsesListProps> = observer(
  ({ width }) => {
    const wrapValues = width <= MEDIUM_BREAKPOINT;
    const breakLines = width <= SMALL_BREAKPOINT;

    const { clinical } = useStores();
    const { fields: pulses } = useFieldArray<PulseDataItemDto>("pulses");
    const form = useForm<GeneralExaminationFormValues>();

    const isPulseManualMethodSelected = pulses.value.some(
      x => x.method === GeneralExamFieldConditionKeys.Manual
    );

    if (!isPulseManualMethodSelected) {
      form.change(nameOfGeneralExaminationForm("pulseRhythm"), undefined);
      form.change(
        nameOfGeneralExaminationForm("pulseCharacteristic"),
        undefined
      );
    }

    const addNewPulse = () => {
      pulses.push({
        heartRate: undefined,
        position: undefined,
        method: undefined,
        timeStamp: DateTime.now().toISO()
      });
    };

    const removeBPRow = (index: number) => {
      pulses.remove(index);
    };

    const renderPulsePositionWarning = (currentPulse: PulseDataItemDto) => {
      const { position, heartRate } = currentPulse;
      if (position === Positions.Standing && heartRate) {
        const comparablePulses = pulses.value.filter(
          x =>
            x.position === Positions.Lying || x.position === Positions.Sitting
        );

        const isDrop = comparablePulses.some(x =>
          x.heartRate ? heartRate - x.heartRate <= -20 : false
        );

        const isRise = comparablePulses.some(x =>
          x.heartRate ? heartRate - x.heartRate >= 20 : false
        );

        if (isDrop || isRise) {
          return (
            <Stack tokens={{ childrenGap: 8 }}>
              {isDrop && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  Postural drop in heart rate
                </MessageBar>
              )}
              {isRise && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  Postural rise in heart rate
                </MessageBar>
              )}
            </Stack>
          );
        }
        return null;
      }
      return null;
    };

    return (
      <Stack>
        <Fieldset
          tokens={{ childrenGap: 8 }}
          legendLevel="section-heading-light"
          legend="Pulse"
          frame
          styles={getFieldSetStyles()}
        >
          <FormItemField
            name={nameOfGeneralExaminationForm("pulses")}
            styles={{ root: { marginBottom: 8 } }}
          >
            {pulses.map((name, index) => {
              const currentPulse = pulses.value[index];
              const heartRate = currentPulse?.heartRate;

              return (
                <Stack key={name} styles={{ root: { marginBottom: 8 } }}>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 8 }}
                    styles={{ root: { marginBottom: 8 } }}
                  >
                    <Fieldset frame>
                      <Stack
                        horizontal={!wrapValues}
                        tokens={{ childrenGap: 8 }}
                      >
                        <FieldSpy
                          name={`${name}.heartRate`}
                          subscription={{ value: true }}
                        >
                          {(_, value) => {
                            return (
                              <SpinNumberInputField
                                required={!!value}
                                label="Heart rate"
                                name={`${name}.heartRate`}
                                min={1}
                                max={999}
                                maxDigitsNumber={3}
                                styles={{ root: { width: 100 } }}
                                validateOnInitialize
                              />
                            );
                          }}
                        </FieldSpy>
                        <Stack
                          horizontal={!breakLines}
                          tokens={{ childrenGap: 8 }}
                        >
                          <ButtonsGroupSingleChoiceField
                            label="Position"
                            options={clinical.ref.bpPositions.keyTextValues}
                            name={`${name}.position`}
                          />
                          <ButtonsGroupSingleChoiceField
                            label="Method"
                            options={clinical.ref.pulseMethods.keyTextValues}
                            name={`${name}.method`}
                          />
                        </Stack>
                      </Stack>
                      <Stack tokens={{ childrenGap: 8 }}>
                        {heartRate && (
                          <GeneralHighLowSevereWarningMessageBar
                            min={60}
                            max={100}
                            minMessage="Low heart rate"
                            maxMessage="High heart rate"
                            value={heartRate}
                          />
                        )}
                        {renderPulsePositionWarning(currentPulse)}
                      </Stack>
                    </Fieldset>
                    <DeleteButton
                      onClick={() => removeBPRow(index)}
                      disabled={pulses.length === 1}
                    />
                  </Stack>
                </Stack>
              );
            })}
          </FormItemField>
        </Fieldset>

        <ActionButton
          iconProps={{ iconName: "Add" }}
          title="Add more"
          onClick={addNewPulse}
          styles={{ root: { width: 400 } }}
        >
          Add pulse
        </ActionButton>
        {isPulseManualMethodSelected && (
          <Stack tokens={{ childrenGap: 8 }}>
            <ButtonsGroupSingleChoiceField
              options={clinical.ref.pulseRhythms.keyTextValues}
              name={nameOfGeneralExaminationForm("pulseRhythm")}
              label="Rhythm"
            />
            <ButtonsGroupSingleChoiceField
              options={clinical.ref.pulseCharacteristics.keyTextValues}
              name={nameOfGeneralExaminationForm("pulseCharacteristic")}
              label="Characteristic"
              vertical={breakLines}
              styles={getButtonGroupBreakLineStyles(breakLines)}
            />
          </Stack>
        )}
      </Stack>
    );
  }
);
export const PulseList = withFetch(
  x => [
    x.clinical.ref.bpPositions.load(),
    x.clinical.ref.pulseCharacteristics.load(),
    x.clinical.ref.pulseRhythms.load(),
    x.clinical.ref.pulseMethods.load(),
    x.clinical.ref.bpPositions.load()
  ],
  PulseListBase
);

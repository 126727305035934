import {
  Chronicity,
  Fracture,
  MedicalCertainty,
  Severity
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export type AddMedicalHistoryFormValues = {
  active: boolean;
  details?: string;
  diagnosisKey?: string;
  certainty?: MedicalCertainty;
  clinicallySignificant?: boolean;
  saveAsReasonForVisit: boolean;
  isDiagnosis?: boolean;
  isProcedure?: boolean;
  confidential: boolean;
} & MedicalHistoryDateFieldsValues;

export type MedicalHistoryFormValues = {
  active?: boolean;
  details?: string;
  diagnosisKey?: string;
  diagnosisSideSelected?: string[];
  clinicallySignificant?: boolean;
  certainty?: MedicalCertainty;
  saveAsReasonForVisit?: boolean;
  primaryDiagnosis?: boolean;
  confidential: boolean;
  chronicity?: Chronicity;
  severity?: Severity;
  fracture?: Fracture;
  fractureTypes?: string[];
} & MedicalHistoryDateFieldsValues;

export type MedicalHistoryDateFieldsValues = {
  dateKey?: MedicalHistoryDateKey;
  year?: string;
  month?: string;
  date?: Date;
  age?: string;
};

export enum MedicalHistoryDateKey {
  Year = "y",
  YearMonth = "ym",
  Age = "a",
  Exact = "e"
}

export interface TerminologyData {
  terminologyText: string;
  terminologyCode: string;
  isDiagnosis: boolean | undefined;
  isProcedure: boolean | undefined;
}

export enum SubmitAction {
  Save = "Save",
  SaveAndNew = "Save&New"
}

import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import {
  DefaultButton,
  mergeStyleSets,
  Selection,
  Stack
} from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { showUnlessPrintingStyles } from "@ui-components/printing/Print.styles.ts";
import { PrintContext } from "@ui-components/printing/Print.tsx";

import { AppointmentInformationEditButton } from "./AppointmentInformationEditButton.tsx";

interface AppointmentInformationModalButtonsProps {
  selection: Selection;
  onDismiss: () => void;
  selectionCount: number | undefined;
  noAppointments: boolean;
  apptItems: CalendarEvent[];
}

export const AppointmentInformationModalButtons: FC<AppointmentInformationModalButtonsProps> =
  observer(
    ({ apptItems, noAppointments, onDismiss, selection, selectionCount }) => {
      const { booking, notification } = useStores();
      const print = useContext(PrintContext);

      const editAppointment = () => {
        const selectedIndexes: number[] = selection.getSelectedIndices();
        const item = selection.getItems();
        const calendarEvent = item[selectedIndexes[0]];
        if (calendarEvent) {
          booking.ui.showCalendarEventDialog({
            type: CalendarEventType.Appointment,
            id: calendarEvent.key?.toString()
          });
        } else {
          notification.warn(notificationMessages.selectAppt);
        }
      };

      return (
        <Stack
          tokens={{ childrenGap: 8 }}
          horizontal
          styles={mergeStyleSets(showUnlessPrintingStyles, {
            root: {
              paddingTop: 40
            }
          })}
        >
          <Stack grow horizontalAlign="start">
            <DefaultButton
              iconProps={{ iconName: "Print" }}
              onClick={() => print.print?.()}
            >
              Print
            </DefaultButton>
          </Stack>
          <AppointmentInformationEditButton
            selectionCount={selectionCount}
            editAppointment={editAppointment}
            noAppointments={noAppointments}
            apptItems={apptItems}
          />
          <DefaultButton onClick={onDismiss} text="Close" />
        </Stack>
      );
    }
  );

import { FunctionComponent } from "react";

import {
  FontIcon,
  Heading,
  ITooltipProps,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { AccScheduleTransactionStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { BillingTransactionStatusText } from "@ui-components/RefText.tsx";

interface AuthorisedReceivedProps {
  transactionStatus: AccScheduleTransactionStatuses;
  label: string;
}

export const TransactionTransmissionStatusCellAuthorised: FunctionComponent<
  AuthorisedReceivedProps
> = ({ transactionStatus, label }) => {
  const theme = useTheme();

  const paidStatuses = [
    AccScheduleTransactionStatuses.Paid,
    AccScheduleTransactionStatuses.PartiallyPaid
  ];

  const tooltipProps: ITooltipProps = {
    calloutProps: {
      styles: {
        beak: { background: theme.palette.themeLighterAlt },
        beakCurtain: { background: theme.palette.themeLighterAlt },
        calloutMain: { background: theme.palette.themeLighterAlt }
      }
    }
  };

  return (
    <TooltipHost
      tooltipProps={tooltipProps}
      content={
        <Stack horizontalAlign="center">
          <Heading styles={{ root: { color: theme.palette.themeDarkAlt } }}>
            <BillingTransactionStatusText code={transactionStatus} />
          </Heading>
          <Text>This state is reflecting internal ACC processing</Text>
          {transactionStatus && paidStatuses.includes(transactionStatus) ? (
            <Text>Await payment advice</Text>
          ) : (
            <Text>No comment has been provided</Text>
          )}
        </Stack>
      }
    >
      <TextBadge
        badgeSize={TextBadgeSize.medium}
        badgeColor={TextBadgeColor.blue}
        horizontal
        horizontalAlign="center"
        tokens={{ childrenGap: 4 }}
        styles={{ root: { backgroundColor: theme.palette.themeLighterAlt } }}
      >
        <FontIcon iconName="info" styles={{ root: { fontSize: 16 } }} />
        <span>{label}</span>
      </TextBadge>
    </TooltipHost>
  );
};

import { observer } from "mobx-react-lite";

import {
  dataAttribute,
  DataAttributes,
  Persona,
  PersonaSize
} from "@bps/fluent-ui";
import {
  DateTime,
  getUniqueObjectsByKeys,
  isDefined,
  TIME_FORMATS,
  unique
} from "@bps/utils";
import { CalendarEventStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useEventReminderScreenContext } from "@modules/booking/screens/event-reminders/context/EventReminderScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

export interface EventReminderFilterInternal {
  appointmentType?: string[];
  providerName?: string[];
  location?: string[];
  appointmentStatus?: string[];
  appointmentTime?: string[];
  phoneNumber?: string;
  patientName?: string;
  deliveryStatus?: string;
}

interface Option {
  key: string;
  text: string;
}

interface ProviderOption extends Option {
  initials: string;
}

interface EventReminderListFilterProps
  extends Pick<FilterBarProps<EventReminderFilterInternal>, "children"> {}

export const EventReminderFiltersBase: React.FC<EventReminderListFilterProps> =
  observer(({ ...rest }) => {
    const { booking } = useStores();
    const { eventReminderSearchResults } = useEventReminderScreenContext();

    const locationOptions =
      (eventReminderSearchResults.value &&
        eventReminderSearchResults.value?.map(x => ({
          key: x.locationName,
          text: x.locationName
        }))) ||
      [];

    const uniqueLocationOptions = getUniqueObjectsByKeys({
      array: locationOptions,
      keys: ["key"]
    }).sort((a, b) =>
      a.key.trim().toLowerCase() > b.key.trim().toLowerCase() ? 1 : -1
    );

    const apptTypes =
      (eventReminderSearchResults.value &&
        eventReminderSearchResults.value?.map(x => ({
          key: x.appointmentType,
          text: x.appointmentType
        }))) ||
      [];

    const uniqueApptTypesOptions = getUniqueObjectsByKeys({
      array: apptTypes,
      keys: ["key"]
    }).sort((a, b) =>
      a.key.trim().toLowerCase() > b.key.trim().toLowerCase() ? 1 : -1
    );

    booking.ref.outboundCommStatuses.load();
    const deliveryStatuses: Option[] =
      booking.ref.outboundCommStatuses.keyTextValues;

    const uniqueDeliveryStatusOptions = getUniqueObjectsByKeys({
      array: deliveryStatuses,
      keys: ["key"]
    }).sort((a, b) =>
      a.key.trim().toLowerCase() > b.key.trim().toLowerCase() ? 1 : -1
    );

    const apptStatuses: Option[] =
      booking.ref.appointmentStatuses.keyTextValues;

    const calendarEventStatuses: Option[] =
      booking.ref.calendarEventStatuses.keyTextValues;

    const allApptStatuses = calendarEventStatuses
      .concat(apptStatuses)
      .filter(x => {
        return x.key !== CalendarEventStatus.Confirmed;
      });

    const uniqueApptStatusesOptions: Option[] = getUniqueObjectsByKeys({
      array: allApptStatuses,
      keys: ["key"]
    }).sort((a, b) =>
      a.text.trim().toLowerCase() > b.text.trim().toLowerCase() ? 1 : -1
    );

    const startTimeHours = unique(
      (eventReminderSearchResults.value &&
        eventReminderSearchResults.value?.map(
          x => x.calendarEvent?.startDateTime.hour
        )) ||
        []
    ).filter(isDefined);

    let durationOptions: {
      key: string;
      text: string;
    }[] = [];
    if (startTimeHours.length > 0) {
      durationOptions = startTimeHours
        .sort((a, b) => a - b)
        .map(x => {
          const startDateTime = DateTime.now().set({
            hour: x,
            minute: 0,
            second: 0
          });

          const startHour = startDateTime.toFormat(
            TIME_FORMATS.DEFAULT_TIME_FORMAT
          );

          const endHour = startDateTime
            .set({ hour: x + 1 })
            .toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);
          return {
            key: startDateTime.hour.toString(),
            text: `${startHour} - ${endHour}`
          };
        });
    }

    const providerOptions = unique(
      (eventReminderSearchResults.value &&
        eventReminderSearchResults.value?.map(x => ({
          key: x.providerId,
          text: x.providerName,
          lastName: x.provider?.lastName ?? "",
          initials: x.initials
        }))) ||
        []
    );

    const uniqueProviderOptions = getUniqueObjectsByKeys({
      array: providerOptions,
      keys: ["key"]
    }).sort((a, b) =>
      a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase() ? 1 : -1
    );

    return (
      <FilterBar<EventReminderFilterInternal>
        {...rest}
        {...dataAttribute(DataAttributes.Element, "event-reminder-list-filter")}
        items={[
          {
            type: "searchBox",
            name: "patientName",
            stickItem: true,
            props: {
              ...dataAttribute(
                DataAttributes.Element,
                "event-reminder-list-filter-patient-name"
              ),
              autoFocus: true,
              placeholder: "Search for patient"
            }
          },
          {
            type: "searchBox",
            name: "phoneNumber",
            stickItem: true,
            props: {
              placeholder: "Search for phone number"
            }
          },
          {
            type: "optionsSelect",
            name: "appointmentType",
            props: {
              id: "event-reminder-list-filter-appt-type",
              placeholder: "Appt type",
              options: uniqueApptTypesOptions,
              multiSelect: true,
              calloutWidth: 250
            }
          },
          {
            type: "optionsSelect",
            name: "providerName",
            props: {
              multiSelect: true,
              options: uniqueProviderOptions,
              placeholder: "Providers",
              calloutWidth: 250,
              onRenderOption: (option: ProviderOption) => {
                return (
                  <Persona
                    id={option.key}
                    size={PersonaSize.size24}
                    text={option.text}
                    imageInitials={option.initials}
                  />
                );
              }
            }
          },
          {
            type: "optionsSelect",
            name: "location",
            props: {
              id: "event-reminder-list-filter-location",
              placeholder: "Location",
              options: uniqueLocationOptions,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "optionsSelect",
            name: "appointmentStatus",
            props: {
              placeholder: "Appt status",
              options: uniqueApptStatusesOptions,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 180
            }
          },
          {
            type: "optionsSelect",
            name: "appointmentTime",
            props: {
              placeholder: "Time",
              options: durationOptions,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 210
            }
          },
          {
            type: "optionsSelect",
            name: "deliveryStatus",
            props: {
              id: "event-reminder-list-filter-delivery-status",
              placeholder: "Delivery status",
              options: uniqueDeliveryStatusOptions,
              multiSelect: true,
              calloutWidth: 250
            }
          }
        ]}
        styles={{
          root: { marginBottom: 16 },
          searchBox: { minWidth: 220 }
        }}
      />
    );
  });

export const EventReminderFilters = withFetch(
  x => [
    x.booking.ref.calendarEventStatuses.load(),
    x.booking.ref.outboundCommStatuses.load()
  ],
  EventReminderFiltersBase
);

import { FormApi } from "final-form";
import { ReactNode, useRef } from "react";

import { DateTime } from "@bps/utils";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

import { FormHeaderWrapper } from "./FormHeaderWrapper.tsx";

type ClinicalSubmissionFormProps<FormValues extends object = object> = Omit<
  SubmissionFormProps<FormValues>,
  "onSubmitSucceeded"
> & {
  onSubmitSucceeded?: (
    values: FormValues,
    form: FormApi<FormValues>,
    isSaveAndClose?: boolean
  ) => void;
  heading: string;
  onCancel?: (form: FormApi<any, Partial<any>>) => void;
  disableButtonCondition?: (
    stashedData?: StashedEncounterClinicalData
  ) => boolean;
  lastUpdatedDate?: DateTime;
  lastUpdatedUserId?: string;
  extraPromptConditionOnCancel?: (form: FormApi<any, Partial<any>>) => boolean;
  canSubmit?: (form: FormApi<any, Partial<any>>) => Promise<boolean>;
  additionalDisableCheck?: (values: Partial<any>) => boolean;
  clinicalToolAfterSubmit?: (value: boolean) => void;
  submitButtonTextCondition?: (
    stashedData?: StashedEncounterClinicalData
  ) => string;
  noGap?: boolean;
  promptOnCancel?: boolean;
  confidentialShield?: ReactNode;
  onClose?: () => void;
  children?: ReactNode;
  hideSubmit?: boolean;
};

export const ClinicalSubmissionFormUnTyped = <
  FormValues extends object = object
>(
  props: ClinicalSubmissionFormProps<FormValues>
) => {
  const {
    heading,
    onCancel,
    disableButtonCondition,
    lastUpdatedDate,
    lastUpdatedUserId,
    extraPromptConditionOnCancel,
    canSubmit,
    clinicalToolAfterSubmit,
    noGap,
    promptOnCancel,
    confidentialShield,
    onClose,
    children,
    submitButtonTextCondition,
    additionalDisableCheck,
    hideSubmit
  } = props;

  const isSaveAndClose = useRef<boolean>(false);

  const saveAndCloseOnClick = async (form: FormApi) => {
    isSaveAndClose.current = true;
    await form.submit();
  };

  return (
    <SubmissionForm
      {...props}
      onSubmitSucceeded={(values, form) =>
        props.onSubmitSucceeded &&
        props.onSubmitSucceeded(values, form, isSaveAndClose.current)
      }
      buttonsProps={{
        ...props.buttonsProps,
        onCancel: () => {
          isSaveAndClose.current = false;
        }
      }}
      styles={{
        root: {
          margin: -16
        }
      }}
      autoFocus={false}
      render={() => {
        return (
          <FormHeaderWrapper
            heading={heading}
            onCancel={onCancel}
            extraPromptConditionOnCancel={extraPromptConditionOnCancel}
            disableButtonCondition={disableButtonCondition}
            lastUpdatedDate={lastUpdatedDate}
            lastUpdatedUserId={lastUpdatedUserId}
            canSubmit={canSubmit}
            clinicalToolAfterSubmit={clinicalToolAfterSubmit}
            noGap={noGap}
            promptOnCancel={promptOnCancel}
            confidentialShield={confidentialShield}
            onClose={onClose}
            saveAndCloseOnClick={saveAndCloseOnClick}
            submitButtonTextCondition={submitButtonTextCondition}
            additionalDisableCheck={additionalDisableCheck}
            hideSubmit={hideSubmit}
          >
            {children}
          </FormHeaderWrapper>
        );
      }}
    />
  );
};

export const ClinicalSubmissionForm = <FormValues extends object = object>(
  props: ClinicalSubmissionFormProps<FormValues>
) => (
  <ClinicalSubmissionFormUnTyped
    {...(props as ClinicalSubmissionFormProps<any>)}
  />
);

import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Text, useTheme } from "@bps/fluent-ui";

import { RecentApptLink } from "./RecentApptLink.tsx";
import { RecentConsultLink } from "./RecentConsultLink.tsx";
import { RecentInvoiceLink } from "./RecentInvoiceLink.tsx";
import { RecentPatientLink } from "./RecentPatientLink.tsx";
import {
  EventType,
  RecentPatientsDetailsProps
} from "./RecentPatientsList.types.ts";
import { RecentRecordUpdateLink } from "./RecentRecordUpdateLink.tsx";

export const RecentPatientsDetailsCell: FC<RecentPatientsDetailsProps> =
  observer(({ recentPatient }) => {
    const theme = useTheme();
    switch (recentPatient.eventType) {
      case EventType.appointment:
        return <RecentApptLink recentPatient={recentPatient} />;

      case EventType.patient:
        return <RecentPatientLink recentPatient={recentPatient} />;

      case EventType.invoice:
        return <RecentInvoiceLink recentPatient={recentPatient} />;

      case EventType.consult:
        return <RecentConsultLink recentPatient={recentPatient} />;

      case EventType.recordUpdate:
        return <RecentRecordUpdateLink recentPatient={recentPatient} />;

      default:
        break;
    }
    return (
      <Text
        styles={{
          root: {
            fontStyle: "italic",
            color: theme.palette.neutralTertiary
          }
        }}
      >
        Not identified event type
      </Text>
    );
  });

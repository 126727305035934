export enum OrganisationLabels {
  Type = "Type",
  Email = "Email",
  Phone = "Phone",
  Remove = "Remove",
  Claims = "Claims",
  NoTypes = "NoTypes",
  Employer = "Employer ",
  Invoicing = "Invoicing",
  AddAnother = "Add another",
  AccAccredited = "ACC accredited",
  InvoicingEmail = "Invoicing email",
  HealthProvider = "Health provider",
  PrivateInsurer = "Private insurer",
  SetAsAnEmployer = "Set as an employer",
  RelationshipType = "Relationship type",
  ConsultsPerClaim = "Consults per claim",
  SelectOrganisation = "Select organisation",
  EmailNotProvided = "Email is not provided",
  ClaimsAndInvoicing = "Claims and Invoicing",
  UsingPrivateInsurer = "Using private insurer",
  PrivateInsurerForAcc = "Private insurer for ACC",
  AccAccreditedEmployer = "ACC accredited employer",
  AddAnOrganisationType = "Add an organisation type",
  GoToLinkedOrganisation = "Go to linked organisation",
  ViewAccreditedEmployers = "View accredited employers",
  DifferentInvoicingEmail = "Different invoicing email",
  NoOrganisationTypes = "No organisation types assigned",
  ClaimsAdministratorEmail = "Claims administrator email",
  LinkedRelatedOrganisation = "Linked or related organisation",
  PrivateInsurerEmployerHealthProvider = "Private insurer, employer, health provider",
  AddressDetailsRequired = "Address details are required for ACC accredited employers"
}

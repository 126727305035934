import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { ReactNode, useRef } from "react";

import { FormMethods, PresetButtonType, Spinner } from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import {
  GetTransactionsArgs,
  ItemType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import {
  defaultTransactionFilter,
  TransactionFilter,
  transactionFilterNameOf,
  TransactionFilterQuery
} from "./TransactionFilter.types.ts";
import { TransactionFilterBarBase } from "./TransactionFilterBarBase.tsx";
import { TransactionFilterBase } from "./TransactionFilterBase.types.ts";
import {
  billToSelectFilter,
  datesRangePickerFilter,
  itemTypeFilters,
  numberSearchFilter,
  patientFilter,
  searchFilter,
  usersSelectFilter
} from "./transactionFilters.ts";

interface TransactionFilterBarProps {
  children: ReactNode;
  defaultNzPublicInsurerId?: string;
  accountContact?: Contact;
}

export const TransactionFilterBarInner: React.FC<TransactionFilterBarProps> =
  observer(({ children, defaultNzPublicInsurerId, accountContact }) => {
    const { routing, billing, core } = useStores();

    const isGeneralAccountsScreen = !!routing.match(routes.accounts.basePath);

    const items: Item<TransactionFilterBase>[] = [
      isGeneralAccountsScreen || accountContact ? undefined : searchFilter(),
      patientFilter(),
      itemTypeFilters(
        billing.ref.itemTypes.keyTextValues.filter(
          x =>
            (x.key !== ItemType.CreditNote && x.key !== ItemType.Allocation) ||
            (x.key === ItemType.CreditNote &&
              core.hasPermissions(Permission.CreditAllowed))
        )
      ),
      !accountContact ? billToSelectFilter() : undefined,
      numberSearchFilter(),
      usersSelectFilter(),
      datesRangePickerFilter()
    ].filter(isDefined);

    const getInitialValues = () => {
      const query: TransactionFilterQuery = parse(routing.location.search);
      let itemTypes =
        typeof query.itemTypes === "string"
          ? [query.itemTypes]
          : query.itemTypes;

      const invoiceOnly = !(query.invoiceOnly === "false");
      const accExcluded = query.accExcluded === "true";

      if (!itemTypes && invoiceOnly) {
        itemTypes = [ItemType.Invoice];
      }

      return {
        invoiceOnly,
        accExcluded,
        itemTypes
      };
    };

    const initialValues = useRef<TransactionFilter>(getInitialValues());

    const parseToGetByArgs = (searchQuery: string): GetTransactionsArgs => {
      const { accExcluded, ...filter }: TransactionFilterQuery = searchQuery
        ? parse(searchQuery)
        : {};

      const accountIds = [];

      const excludedAccountIds = [];

      if (accountContact) {
        accountIds.push(accountContact.id);
      } else if (
        (!accExcluded || accExcluded === "false") &&
        defaultNzPublicInsurerId
      ) {
        excludedAccountIds.push(defaultNzPublicInsurerId);
      }

      return {
        ...getInitialValues(),
        ...filter,
        accountIds: accountIds.length ? accountIds : filter.accountIds,
        excludedAccountIds: excludedAccountIds.length
          ? excludedAccountIds
          : undefined,
        excludeAllocations: true
      };
    };

    const onFilterChange = (
      options: {
        field: string | number | symbol;
        actions: Pick<FormMethods, "setValue" | "reset">;
        values: TransactionFilter;
      },
      setFilter: (newFilter: Partial<TransactionFilterQuery>) => void
    ) => {
      const { field, values } = options;

      if (field === transactionFilterNameOf("invoiceOnly")) {
        return setFilter({
          itemTypes: values.itemTypes,
          [field]: values[field]
        });
      }

      return setFilter({ [field]: values[field] });
    };

    const presets: PresetButtonType<TransactionFilter>[] = [];

    if (!accountContact && defaultNzPublicInsurerId) {
      presets.push({
        text: "ACC",
        name: transactionFilterNameOf("accExcluded"),
        id: "acc-only",
        iconName: "BPInvPartPaid",
        tooltip: "Include ACC",
        tooltipPresses: "Including ACC"
      });
    }

    presets.push({
      text: "Invoices only",
      name: transactionFilterNameOf("invoiceOnly"),
      id: "invoice-only",
      iconName: "M365InvoicingLogo",
      tooltip: "Only show Invoices",
      tooltipPresses: "Only showing Invoices",
      valuesToBeSetOnToggleOn: {
        itemTypes: [ItemType.Invoice]
      },
      valuesToBeSetOnToggleOff: {
        itemTypes: undefined
      }
    });

    return (
      <TransactionFilterBarBase<TransactionFilter>
        onChange={onFilterChange}
        parseToGetByArgs={parseToGetByArgs}
        initialValues={initialValues.current}
        items={items}
        defaultFilter={defaultTransactionFilter}
        presets={presets}
        children={children}
      />
    );
  });

const TransactionDataFetcher: React.FC<
  Pick<TransactionFilterBarProps, "accountContact" | "children">
> = ({ children, accountContact }) => {
  const fetch = async ({ core, practice }: IRootStore) => {
    if (core.tenantDetails?.country === Country.NewZealand) {
      const insurers = await practice.getDefaultInsurers();
      return insurers.defaultNzPublicInsurerId;
    } else {
      return undefined;
    }
  };

  return (
    <DataFetcher fetch={fetch} fallback={<Spinner />}>
      {defaultNzPublicInsurerId => (
        <TransactionFilterBarInner
          accountContact={accountContact}
          children={children}
          defaultNzPublicInsurerId={defaultNzPublicInsurerId}
        />
      )}
    </DataFetcher>
  );
};

export const TransactionFilterBar = withFetch(
  ({ billing }) => [billing.ref.itemTypes.load()],
  TransactionDataFetcher
);

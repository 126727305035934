import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Pivot, PivotItem } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useDraftRoute } from "@modules/billing/screens/draft-items/hooks/useDraftRoute.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useRouteDefinitionsFilter } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";

enum BillingScreenTabKeys {
  Accounts = "accounts",
  AccInvoices = "acc-invoices",
  AccreditedBilling = "accredited-billing",
  DraftItems = "draft-items"
}

export const BillingScreenTabs: FunctionComponent = observer(() => {
  const { core, routing } = useStores();
  const routeFilter = useRouteDefinitionsFilter();
  const goToDrafts = useDraftRoute();

  const getSelectedKey = (): BillingScreenTabKeys => {
    if (routes.accounts.accInvoices.basePath.match(routing.location.pathname)) {
      return BillingScreenTabKeys.AccInvoices;
    }

    if (
      routes.accounts.accreditedBilling.basePath.match(
        routing.location.pathname
      )
    ) {
      return BillingScreenTabKeys.AccreditedBilling;
    }

    if (routes.accounts.draftItems.basePath.match(routing.location.pathname)) {
      return BillingScreenTabKeys.DraftItems;
    }

    return BillingScreenTabKeys.Accounts;
  };

  const handlePivotClicked = (item: PivotItem): void => {
    switch (item.props.itemKey) {
      case BillingScreenTabKeys.Accounts:
        routing.push(routes.accounts.basePath.path({}));
        break;
      case BillingScreenTabKeys.AccInvoices:
        routing.push(routes.accounts.accInvoices.basePath.path({}));
        break;
      case BillingScreenTabKeys.AccreditedBilling:
        routing.push(routes.accounts.accreditedBilling.basePath.path({}));
        break;
      case BillingScreenTabKeys.DraftItems:
        goToDrafts();
        break;
    }
  };

  // These functions are required as <When> component doesn't work properly inside a <Pivot>
  const renderDraftItemsTab = () => {
    if (!routeFilter({ path: routes.accounts.draftItems.basePath }))
      return null;

    return (
      <PivotItem
        headerText="Draft items"
        itemKey={BillingScreenTabKeys.DraftItems}
      />
    );
  };

  const renderAccBillingTab = () => {
    if (
      !core.isNZTenant ||
      !routeFilter({ path: routes.accounts.accInvoices.basePath })
    )
      return null;

    return (
      <PivotItem
        headerText="ACC billing"
        itemKey={BillingScreenTabKeys.AccInvoices}
      />
    );
  };

  const renderAccreditedBillingTab = () => {
    if (
      !core.isNZTenant ||
      !routeFilter({ path: routes.accounts.accreditedBilling.basePath })
    )
      return null;

    return (
      <PivotItem
        headerText="Accredited billing"
        itemKey={BillingScreenTabKeys.AccreditedBilling}
      />
    );
  };

  return (
    <Pivot
      selectedKey={getSelectedKey()}
      onLinkClick={handlePivotClicked}
      styles={{
        link: { backgroundColor: "inherit" }
      }}
    >
      <PivotItem
        headerText="General accounts"
        itemKey={BillingScreenTabKeys.Accounts}
      />
      {renderAccBillingTab()}
      {renderAccreditedBillingTab()}
      {renderDraftItemsTab()}
    </Pivot>
  );
});

import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { FormRenderProps } from "react-final-form";

import { BillingConfirmationDialog } from "@modules/billing/screens/billing-history/components/BillingConfirmationDialog.tsx";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { fullScreenForm } from "@modules/billing/screens/shared-components/billing.styles.ts";
import { BillingLayout } from "@modules/billing/screens/shared-components/BillingLayout.tsx";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { InvoiceDetailsModal } from "./invoice-details-modal/InvoiceDetailsModal.tsx";
import { InvoiceAddServicesModal } from "./InvoiceAddServicesModal.tsx";
import {
  InvoiceFormButtons,
  InvoiceFormProps,
  InvoiceFormSubmitButtonClicked
} from "./InvoiceForm.types.tsx";
import { InvoiceFormFields } from "./InvoiceFormFields.tsx";
import { InvoiceFormHeader } from "./InvoiceFormHeader.tsx";
import { InvoiceFormHeaderMessageBar } from "./InvoiceFormHeaderMessageBar.tsx";

const InvoiceFormBase: React.FC<InvoiceFormProps> = observer(
  ({ initialValues }) => {
    const { billing } = useStores();

    const {
      handleSubmitAction,
      isConfirmationDialogVisible,
      isInvoiceDetailsModalVisible,
      onSubmitInvoiceDetailsModal,
      setAdjustmentReason,
      setIsConfirmationDialogVisible,
      setIsInvoiceDetailsModalVisible,
      setSubmitButtonClicked,
      validateForm,
      isAdjustMode
    } = useContext(InvoiceFormContext);

    return (
      <SubmissionForm<InvoiceFormValues>
        formName="invoice-form"
        initialValues={initialValues}
        onSubmit={handleSubmitAction}
        onSubmitFailed={() => {
          setSubmitButtonClicked(InvoiceFormSubmitButtonClicked.Save);
        }}
        hideButtons
        validate={validateForm}
        styles={fullScreenForm}
      >
        {({ form, values }: FormRenderProps<InvoiceFormValues>) => {
          const onSubmitModal = async (
            values: InvoiceDetailsModalFormValues
          ) => {
            await onSubmitInvoiceDetailsModal(form, values);
            setIsInvoiceDetailsModalVisible(false);
          };

          const { claimId, invoiceItems, accountContactId } = values;

          return (
            <BillingLayout
              messageBar={
                <InvoiceFormHeaderMessageBar
                  claimId={claimId}
                  invoiceItems={invoiceItems}
                  accountContactId={accountContactId}
                />
              }
              header={<InvoiceFormHeader />}
            >
              <InvoiceFormFields />
              <InvoiceAddServicesModal />
              <InvoiceDetailsModal
                isAdjustMode={isAdjustMode}
                initialValues={values}
                hidden={!isInvoiceDetailsModalVisible}
                onDismiss={() => setIsInvoiceDetailsModalVisible(false)}
                onSubmit={onSubmitModal}
              />
              <BillingConfirmationDialog
                className="adjust-dialog"
                hidden={!isConfirmationDialogVisible}
                label={InvoiceFormLabels.adjustmentReason}
                confirmButtonText={InvoiceFormButtons.adjustInvoice}
                options={billing.ref.invoiceAdjustmentReason.keyTextValues}
                onCancel={() => setIsConfirmationDialogVisible(false)}
                onConfirm={(reason: string) => {
                  setAdjustmentReason(reason);
                  form.submit();
                }}
              />
            </BillingLayout>
          );
        }}
      </SubmissionForm>
    );
  }
);

export const InvoiceForm = withFetch(
  x => [x.billing.ref.invoiceAdjustmentReason.load()],
  InvoiceFormBase
);

import { Stack } from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { PaymentStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

export interface CancelInvoiceDialogProps {
  onConfirm: (reason: string) => void | Promise<void>;
  onCancel: () => void;
  hidden: boolean;
  invoice?: Invoice;
  initialValues?: Partial<CancelInvoiceFormValues>;
}

interface CancelInvoiceFormValues {
  reason: string;
}

const CancelInvoiceDialogBase: React.FC<CancelInvoiceDialogProps> = props => {
  const { billing, notification } = useStores();

  const options = billing.ref.invoiceCancelReason.keyTextValues;

  const { invoice, hidden, onConfirm, onCancel, initialValues } = props;

  if (hidden || !invoice) {
    return null;
  }

  const handleConfirm = async (values: CancelInvoiceFormValues) => {
    try {
      await billing.cancelInvoice(invoice.id, values.reason);

      notification.success(
        notificationMessages.invoiceCanceled(invoice.number)
      );

      if (
        invoice.paymentStatus === PaymentStatuses.paid ||
        invoice.paymentStatus === PaymentStatuses.part
      ) {
        notification.warn(notificationMessages.unallocatedPaymentAmount);
      }

      await onConfirm(values.reason);
    } catch (e) {
      notification.error(e.message);
    }
  };

  return (
    <SubmissionFormDialog<CancelInvoiceFormValues>
      dialogName="Cancel Invoice"
      onSubmit={handleConfirm}
      initialValues={{
        reason: options[0].key,

        ...initialValues
      }}
      dialogProps={{
        minWidth: 430,
        maxWidth: 430,
        onDismiss: onCancel,
        dialogContentProps: {
          className: "cancel-dialog",
          title: "Cancel invoice"
        }
      }}
      buttonsProps={{
        disableSubmitOnPristine: false,
        submitButtonProps: {
          text: "Cancel invoice",
          iconProps: {
            hidden: true
          }
        },
        cancelButtonProps: {
          onClick: onCancel
        }
      }}
    >
      {() => (
        <Stack tokens={{ childrenGap: 16 }}>
          <ChoiceGroupField
            name="reason"
            options={billing.ref.invoiceCancelReason.keyTextValues}
            label="Reason"
            required
          />
        </Stack>
      )}
    </SubmissionFormDialog>
  );
};

export const CancelInvoiceDialog = withFetch(
  x => [x.billing.ref.invoiceCancelReason.load()],
  CancelInvoiceDialogBase
);

import { FunctionComponent } from "react";

import { DefaultButton, Stack, Text, useTheme } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactsFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { getOptionalTextStyleSecondary } from "../../shared-components/PeopleScreen.styles.ts";
import { extractName } from "../../shared-components/utils/contact.utils.ts";

interface ContactListNotFoundProps {
  filter: ContactsFilter;
}
export const ContactListNotFound: FunctionComponent<
  ContactListNotFoundProps
> = ({ filter }) => {
  const theme = useTheme();
  const { practice } = useStores();

  const noMatchesText = `0 matches found ${
    !!filter.search ? `for ${filter.search}` : ""
  }`;

  const getAddNewText = () => {
    if (!filter.types?.length) return "";

    const type = practice.ref.contactTypes
      .get(filter.types[0] as ContactType)
      ?.text.toLowerCase();

    return !!filter.search
      ? `Add '${filter.search}' as a new ${type}`
      : `Add a new ${type}`;
  };

  return (
    <Stack verticalAlign="center" horizontalAlign="center" grow>
      <Text
        variant="large"
        styles={{
          root: { color: theme.palette.neutralSecondary, fontStyle: "italic" }
        }}
      >
        {noMatchesText}
      </Text>
      {filter.types?.length === 1 && (
        <Stack
          verticalAlign="center"
          horizontalAlign="center"
          tokens={{ childrenGap: 16 }}
        >
          <Text styles={getOptionalTextStyleSecondary}>Would you like to</Text>
          <DefaultButton
            id="add-search-name"
            text={getAddNewText()}
            onClick={() =>
              filter.types &&
              practice.ui.showAddContact(
                filter.types[0] as ContactType,
                extractName(filter.search)
              )
            }
          />
        </Stack>
      )}
    </Stack>
  );
};

import { observer } from "mobx-react-lite";
import { FC, MutableRefObject, useContext, useEffect, useRef } from "react";

import {
  CheckboxVisibility,
  DetailsRow,
  GroupedList,
  IColumn,
  IGroup,
  IObjectWithKey,
  Selection,
  SelectionMode,
  SelectionZone,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { GroupedListItem } from "../inbox/Inbox.types.ts";
import { getInboxScreenStylesSet } from "../inbox/InboxScreen.styles.ts";

type InboxGroupedListProps = {
  groups: IGroup[];
  files: GroupedListItem[];
};

export const InboxGroupedList: FC<InboxGroupedListProps> = observer(
  ({ files, groups }) => {
    const { inbox } = useStores();
    const theme = useTheme();
    const { setDocData, docData, setActiveForm } =
      useContext(InboxScreenContext);

    useEffect(() => {
      if (!docData) {
        selection.current.setAllSelected(false);
      }
    }, [docData]);

    const selection: MutableRefObject<Selection<IObjectWithKey>> = useRef(
      new Selection({
        selectionMode: SelectionMode.single,
        onSelectionChanged: async () => {
          const indexes = selection.current.getSelectedIndices();
          if (indexes.length === 0) {
            setDocData(undefined);
            setActiveForm(false);
            return;
          }

          const itemKey = indexes[0];
          const item = selection.current.getItems()[itemKey] as GroupedListItem;

          if (item) {
            setDocData({
              id: item.key,
              documentDetailId: item.documentDetailId
            });
            setActiveForm(false);
          }
        },
        onItemsChanged: async () => {
          // onItemsChanged gets called when a new item is uploaded, the list refreshes
          //  and when the user triggers the infinite scroll
          if (inbox.uploadSessionDocsMap.size) {
            const indexes = selection.current.getSelectedIndices();
            if (!indexes.length) {
              selection.current.setIndexSelected(0, true, true);
            }
          }
        }
      })
    );

    useEffect(() => {
      selection.current.setItems(files, false);
    }, [files]);

    const { groupedListHeaderStyles } = getInboxScreenStylesSet(theme);

    const columns: IColumn[] = [
      {
        key: "pdfPage",
        name: "pdfPage",
        minWidth: 300,
        maxWidth: 300,
        onRender: (item: GroupedListItem) => (
          <Text
            styles={{
              root: {
                whiteSpace: "normal",
                wordBreak: "break-all"
              }
            }}
          >
            {item.name}
          </Text>
        )
      }
    ];

    const onRenderCell = (
      _nestingDepth?: number,
      item?: GroupedListItem,
      itemIndex?: number
    ): React.ReactNode => (
      <DetailsRow
        columns={columns}
        styles={{ root: { width: "100%" } }}
        item={item}
        itemIndex={itemIndex!}
        selection={selection.current}
        checkboxVisibility={CheckboxVisibility.always}
        selectionMode={SelectionMode.single}
      />
    );

    return (
      <SelectionZone
        selection={selection.current}
        selectionMode={SelectionMode.single}
      >
        <GroupedList
          groups={groups}
          items={files}
          selectionMode={SelectionMode.single}
          selection={selection.current}
          styles={{
            root: {
              selectors: groupedListHeaderStyles,
              ".ms-DetailsRow.is-selected:hover": {
                backgroundColor: theme.semanticColors.listItemBackgroundChecked
              }
            }
          }}
          groupProps={{
            onRenderHeader: (props, defaultRender) => {
              if (!props || !defaultRender) {
                return null;
              }
              return defaultRender!({
                ...props,
                onGroupHeaderClick: () => {
                  // Prevent selecting all items when the group header is clicked
                  if (props.group && props.group.isCollapsed) {
                    props.onToggleCollapse!(props.group);
                  }
                }
              });
            }
          }}
          onRenderCell={onRenderCell}
        />
      </SelectionZone>
    );
  }
);

import { FunctionComponent } from "react";
import { Field, useFormState } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { MedicalHistoryClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";

import { MedicalHistoryAgePicker } from "./MedicalHistoryAgePicker.tsx";
import { MedicalHistoryDatePicker } from "./MedicalHistoryDatePicker.tsx";
import {
  MedicalHistoryDateFieldsValues,
  MedicalHistoryDateKey,
  MedicalHistoryFormValues
} from "./MedicalHistoryFormValues.ts";
import { MedicalHistoryYearPicker } from "./MedicalHistoryYearPicker.tsx";
import { getYearFromAge } from "./utils.ts";

export interface MedicalHistoryDateFieldsProps {
  label?: string;
  birthDate?: Date;
}

export const medHistoryNameOf = nameOfFactory<MedicalHistoryFormValues>();

export const getMedicalHistoryDateValues = (
  values: MedicalHistoryDateFieldsValues,
  birthDate?: Date
) => {
  const data: Partial<MedicalHistoryClinicalDataItemDto> = {};
  switch (values.dateKey) {
    case MedicalHistoryDateKey.Age:
      if (birthDate && values.age) {
        data.patientAge = Number(values.age);
        data.diagnosisDate = String(
          getYearFromAge(DateTime.fromJSDate(birthDate), values.age)
        );
      }
      break;
    case MedicalHistoryDateKey.Year:
      data.patientAge = undefined;
      data.diagnosisDate = values.year;
      break;
    case MedicalHistoryDateKey.YearMonth:
      data.patientAge = undefined;
      data.diagnosisDate = `${values.year}-${values.month}`;
      break;
    case MedicalHistoryDateKey.Exact:
      data.patientAge = undefined;
      data.diagnosisDate = DateTime.jsDateToISODate(values.date);
      break;
  }
  return data;
};

export const MedicalHistoryDateFields: FunctionComponent<
  MedicalHistoryDateFieldsProps
> = ({ birthDate, label }) => {
  const { values } = useFormState<MedicalHistoryDateFieldsValues>();

  const renderCurrentPicker = () => {
    const { dateKey } = values;
    if (dateKey === MedicalHistoryDateKey.Year) {
      return <MedicalHistoryYearPicker birthDate={birthDate} />;
    } else if (dateKey === MedicalHistoryDateKey.YearMonth) {
      return <MedicalHistoryYearPicker birthDate={birthDate} showMonth />;
    } else if (dateKey === MedicalHistoryDateKey.Age) {
      return <MedicalHistoryAgePicker birthDate={birthDate} />;
    } else if (dateKey === MedicalHistoryDateKey.Exact) {
      return <MedicalHistoryDatePicker birthDate={birthDate} />;
    } else {
      return null;
    }
  };

  const getButtonOptions = () => {
    let buttonOptions = [
      { key: MedicalHistoryDateKey.YearMonth, text: "Year & month" }
    ];

    const diff = birthDate
      ? DateTime.now().diff(DateTime.fromJSDate(birthDate), "months").months
      : null;

    if (diff !== null) {
      if (diff >= 12) {
        buttonOptions = [
          { key: MedicalHistoryDateKey.Year, text: "Year" }
        ].concat(buttonOptions);
      }
      if (diff >= 24) {
        buttonOptions.push({
          key: MedicalHistoryDateKey.Age,
          text: "At age"
        });
      }
    }

    buttonOptions.push({
      key: MedicalHistoryDateKey.Exact,
      text: "Exact date"
    });

    return buttonOptions;
  };

  return (
    <Field
      name={medHistoryNameOf("clinicallySignificant")}
      subscription={{ value: true }}
    >
      {clinicallySignificant => (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <ButtonsGroupSingleChoiceField<MedicalHistoryDateKey>
            label={label}
            name={medHistoryNameOf("dateKey")}
            options={getButtonOptions()}
            required={clinicallySignificant.input.value}
            styles={{ root: { whiteSpace: "nowrap" } }}
          />
          {values.dateKey && (
            <Stack tokens={{ childrenGap: 4 }}>{renderCurrentPicker()}</Stack>
          )}
        </Stack>
      )}
    </Field>
  );
};

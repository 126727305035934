import { useField } from "react-final-form";

import { focusElement, ITag, Stack } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { computePersonInitials } from "@libs/utils/utils.ts";
import { DemographicProfilePictureField } from "@modules/practice/screens/contact-details/shared-components/edit/DemographicProfilePictureField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TitlePickerField } from "@ui-components/form/TitlePickerField.tsx";

import { ContactCardIdsEnum } from "../../../shared-components/types/contact-card-ids.enum.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { EditPronounHeader } from "./EditPronounHeader.tsx";
import { contactFormNameOf } from "./PatientEditFormValues.tsx";

interface EditContactHeaderProps {
  contact: Contact | undefined;
}

const EditContactHeaderBase: React.FC<EditContactHeaderProps> = ({
  contact
}) => {
  const { practice } = useStores();
  const {
    ui: { selectedEditCard }
  } = practice;

  const {
    input: { value: firstName }
  } = useField<string | undefined>(contactFormNameOf("firstName"), {
    subscription: { value: true }
  });

  const {
    input: { value: lastName }
  } = useField<string | undefined>(contactFormNameOf("lastName"), {
    subscription: { value: true }
  });

  const headerIsVisible =
    !selectedEditCard || selectedEditCard === PatientCardIds.patientHeader;

  const nameInputRef = headerIsVisible ? focusElement : undefined;
  return (
    <Stack
      id={`${ContactCardIdsEnum.contactHeader}-edit`}
      tokens={{ childrenGap: 8 }}
      styles={{
        root: {
          paddingRight: 16,
          paddingTop: 21
        }
      }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        horizontalAlign="space-between"
      >
        <Stack.Item grow={1} basis={0}>
          <TitlePickerField
            label="Title"
            name={contactFormNameOf("title")}
            styles={{
              text: {
                minWidth: "auto"
              }
            }}
          />
        </Stack.Item>
        <Stack.Item grow={3} basis={0}>
          <TextInputField
            name={contactFormNameOf("firstName")}
            label="First name"
            required
            componentRef={nameInputRef}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="start">
        <Stack.Item grow={1} basis={0}>
          <DemographicProfilePictureField
            contact={contact}
            imageInitials={computePersonInitials(firstName, lastName)}
          />
        </Stack.Item>

        <Stack.Item grow={3} basis={0}>
          <Stack
            verticalAlign="center"
            tokens={{ childrenGap: 8 }}
            verticalFill
          >
            <TextInputField
              name={contactFormNameOf("lastName")}
              label="Last name"
              required
            />

            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Stack.Item grow>
                <DropdownField
                  name={contactFormNameOf("gender")}
                  placeholder="Select gender"
                  label="Gender"
                  options={practice.ref.genders.keyTextValues}
                />
              </Stack.Item>
              <EditPronounHeader
                pronounSubjectiveName={contactFormNameOf("pronounSubjective")}
                pronounObjectiveName={contactFormNameOf("pronounObjective")}
                pronounPossessiveName={contactFormNameOf("pronounPossessive")}
              />
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
      <StaticTagPickerField
        fetchDataSource={() =>
          practice.ref.contactCategories.keyNameValuesWithContactType
        }
        excludeSelectedItems
        excludeItemsFilter={items =>
          items.filter(
            (item: ITag & { contactType: ContactType }) =>
              item.contactType === ContactType.Individual
          )
        }
        label="Categories"
        name={contactFormNameOf("categories")}
      />
    </Stack>
  );
};

export const EditContactHeader = withFetch(
  x => x.practice.ref.contactCategories.load(),
  EditContactHeaderBase
);

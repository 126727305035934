import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import {
  CommandBarButton,
  dataAttribute,
  DataAttributes,
  DirectionalHint,
  IconButton,
  IIconProps,
  IOverflowSetItemProps,
  ResizableOverflowBar,
  Spinner,
  Stack,
  TooltipHost,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";
import { TreeView } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { HotkeyActionNames } from "@stores/user-experience/UserHotkeysHelper.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionsSidePanel } from "./claims/ConditionsSidePanel.tsx";
import { ClickedEventDetail } from "./ClickedEventDetail.ts";
import { ClinicalImagesSidePanel } from "./clinical-images/ClinicalImagesSidePanel.tsx";
import { ClinicalTools } from "./clinical-tools/clinical-tool-list/ClinicalTools.tsx";
import { Correspondences } from "./correspondence/Correspondences.tsx";
import { FamilySocialHistory } from "./family-social-history/FamilySocialHistory.tsx";
import { HistoryExamination } from "./HistoryExamination.tsx";
import { InteractionTimeline } from "./interaction-time-line/InteractionTimeline.tsx";
import { InvestigationsSidePanel } from "./investigations/side-panel/InvestigationsSidePanel.tsx";
import { MedicalHistory } from "./medical-history/MedicalHistory.tsx";
import { ObservationsSidePanel } from "./observations/ObservationsSidePanel.tsx";
import { PastVisits } from "./past-visit/PastVisits.tsx";
import { Medications } from "./prescribing/Medications.tsx";
import { TreatmentAndManagementSidePanel } from "./treatmentAndManagement/TreatmentAndManagementSidePanel.tsx";

const treeViewIcons = [
  { key: TreeView.Timeline, iconName: "Clock" },
  { key: TreeView.PastConsults, iconName: "SchoolDataSyncLogo" },
  { key: TreeView.MedicalHistory, iconName: "BpMedHx" },
  { key: TreeView.Correspondence, iconName: "Mail" },
  { key: TreeView.ClinicalImages, iconName: "ImagePixel" },
  { key: TreeView.TreatmentPlan, iconName: "Trackers" },
  {
    key: TreeView.Medications,
    iconName: "Pill"
  },
  { key: TreeView.ClinicalTools, iconName: "Hospital" },
  { key: TreeView.HistoryExamination, iconName: "Health" },
  { key: TreeView.FamilyAndSocialHistory, iconName: "Family" },
  {
    key: TreeView.Conditions,
    iconName: "BpConditions"
  },
  {
    key: TreeView.Investigations,
    iconName: "BPDataView"
  },
  {
    key: TreeView.Observations,
    iconName: "HistoricalWeather"
  }
];

export enum SidePanelTestElements {
  SideNavButton = "side-nav-button"
}

export interface ClinicalSidePanelProps {
  clinicalRecord: ClinicalRecord;
}

const ClinicalSidePanelBase: React.FunctionComponent<ClinicalSidePanelProps> =
  observer(({ clinicalRecord }) => {
    const { clinical, core, userExperience } = useStores();
    const [clickedTimelineEventDetail, setClickedTimelineEventDetail] =
      useState<ClickedEventDetail | undefined>();

    useEffect(
      () =>
        userExperience.ui.userHotkeysHelper.registerAction(
          HotkeyActionNames.openWordProcessor,
          () => {
            clinicalRecord.setDocumentDialogVisible(true);
            onNavigationIconClick(TreeView.Correspondence);
          }
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [clinicalRecord, userExperience.ui.userHotkeysHelper]
    );

    const { width } = useScreenSize();
    const sidePanelSize =
      clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize;

    const sidePanelOpacity =
      clinical.ui.tabs.currentPatientRecordTab?.sidePanelOpacity;

    const renderPanelContent = () => {
      if (!clinical.ui.tabs.currentPatientRecordTab) {
        return null;
      }

      switch (clinical.ui.tabs.currentPatientRecordTab.sidePanelTab) {
        default:
        case TreeView.Timeline:
          return (
            <InteractionTimeline
              clinicalRecord={clinicalRecord}
              clickedEventDetail={clickedTimelineEventDetail}
              onInteractionRowClick={(
                clickedEventDetail: ClickedEventDetail
              ) => {
                if (clickedEventDetail) {
                  setClickedTimelineEventDetail(clickedEventDetail);
                }
              }}
            />
          );
        case TreeView.PastConsults:
          return <PastVisits clinicalRecord={clinicalRecord} />;
        case TreeView.MedicalHistory:
          return <MedicalHistory clinicalRecord={clinicalRecord} />;
        case TreeView.Correspondence:
          return <Correspondences clinicalRecord={clinicalRecord} />;
        case TreeView.ClinicalImages:
          return <ClinicalImagesSidePanel clinicalRecord={clinicalRecord} />;
        case TreeView.TreatmentPlan:
          return (
            <TreatmentAndManagementSidePanel clinicalRecord={clinicalRecord} />
          );
        case TreeView.ClinicalTools:
          return <ClinicalTools clinicalRecord={clinicalRecord} />;
        case TreeView.HistoryExamination:
          return <HistoryExamination clinicalRecord={clinicalRecord} />;
        case TreeView.FamilyAndSocialHistory:
          return <FamilySocialHistory clinicalRecord={clinicalRecord} />;
        case TreeView.Medications:
          return <Medications />;
        case TreeView.Conditions:
          return <ConditionsSidePanel clinicalRecord={clinicalRecord} />;
        case TreeView.Investigations:
          return <InvestigationsSidePanel clinicalRecord={clinicalRecord} />;
        case TreeView.Observations:
          return <ObservationsSidePanel />;
      }
    };

    const onToggleExpandOrCollapse = async (isExpanding: boolean) => {
      clinical.ui.tabs.currentPatientRecordTab?.toggleSidePanel(
        isExpanding,
        width
      );
    };

    const onNavigationIconClick = (key: string) => {
      if (key !== clinical.ui.tabs.currentPatientRecordTab!.sidePanelTab) {
        runInAction(() => {
          clinical.ui.tabs.currentPatientRecordTab!.previousNavigatedTab = key;
          clinical.ui.tabs.currentPatientRecordTab!.sidePanelTabId = undefined;
          clinical.ui.tabs.currentPatientRecordTab!.sidePanelTab = key;
        });
      }

      if (sidePanelSize === TreeViewSize.IconsOnly) {
        clinical.ui.tabs.currentPatientRecordTab?.toggleSidePanel(true, width);
      }
    };

    const theme = useTheme();
    const baseIconProps: IIconProps = {
      styles: ({ theme }) => ({
        root: {
          color: theme!.palette.neutralPrimary,
          selectors: {
            "&:hover": {
              color: theme!.palette.neutralPrimary
            }
          }
        }
      })
    };

    //Filter out tree view options that the user does not have access to
    const treeViewItems = userExperience.ref.treeViewOptions.values;

    const availableTreeViewItems = treeViewItems.filter(i => {
      return i.permission ? core.hasPermissions(i.permission) : true;
    });

    const sidePanelLinks: IOverflowSetItemProps[] = availableTreeViewItems.map(
      i => ({
        iconProps: {
          ...baseIconProps,
          iconName: treeViewIcons.find(ic => ic.key === i.code)?.iconName
        },
        name: i.text,
        key: i.code,
        onClick: () => onNavigationIconClick(i.code)
      })
    );

    const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
      return (
        <TooltipHost
          content={item.name}
          directionalHint={DirectionalHint.leftCenter}
        >
          <CommandBarButton
            {...dataAttribute(
              DataAttributes.Element,
              `${SidePanelTestElements.SideNavButton}-${item.key}`
            )}
            {...item}
            styles={{
              root: {
                height: 44,
                padding: "0 7px",
                backgroundColor:
                  item.key ===
                  clinical.ui.tabs.currentPatientRecordTab?.sidePanelTab
                    ? theme.palette.neutralLight
                    : "transparent"
              }
            }}
            onClick={item.onClick}
          />
        </TooltipHost>
      );
    };

    const onRenderOverflowButton = (
      overflowItems: any[] | undefined
    ): JSX.Element => {
      return (
        <CommandBarButton
          role="menuitem"
          title="More items"
          menuIconProps={{ iconName: "More" }}
          menuProps={{
            items: overflowItems!
          }}
        />
      );
    };

    return (
      <Stack
        verticalFill
        horizontal
        styles={{ root: { opacity: sidePanelOpacity } }}
      >
        <Stack
          styles={{
            root: {
              height: "100%",
              backgroundColor: theme.palette.themeLighterAlt
            }
          }}
        >
          <ResizableOverflowBar
            items={sidePanelLinks}
            onRenderOverflow={onRenderOverflowButton}
            onRenderItem={onRenderItem}
            vertical
            itemSize={45}
            itemsGap={0}
            styles={{
              wrapper: { height: "100%" },
              container: { height: "100%" }
            }}
          />

          <IconButton
            styles={{
              root: {
                width: "100%",
                borderRadius: 0
              }
            }}
            iconProps={{
              iconName: "DoubleChevronRight12",
              styles: props => ({
                root: {
                  fontSize: props.theme!.fonts.small.fontSize
                }
              })
            }}
            onClick={() => onToggleExpandOrCollapse(false)}
            title="Collapse"
            disabled={sidePanelSize === TreeViewSize.IconsOnly}
            {...dataAttribute(
              DataAttributes.Element,
              "collapse-clinical-side-panel-button"
            )}
          />
          <IconButton
            styles={{
              root: {
                width: "100%",
                borderRadius: 0
              }
            }}
            iconProps={{
              iconName: "DoubleChevronLeft12",
              styles: props => ({
                root: {
                  fontSize: props.theme!.fonts.small.fontSize
                }
              })
            }}
            onClick={() => onToggleExpandOrCollapse(true)}
            title="Expand"
            disabled={sidePanelSize === TreeViewSize.Expanded}
            {...dataAttribute(
              DataAttributes.Element,
              "expand-clinical-side-panel-button"
            )}
          />
        </Stack>
        {sidePanelSize !== TreeViewSize.IconsOnly && (
          <Stack
            styles={{
              root: {
                overflow: "hidden",
                width: "100%"
              }
            }}
            {...dataAttribute(
              DataAttributes.Element,
              `${clinical.ui.tabs.currentPatientRecordTab?.sidePanelTab}`
            )}
          >
            <Stack
              tokens={{ childrenGap: 8 }}
              grow
              verticalFill
              styles={{
                root: {
                  padding: 16,
                  maxHeight: "100%",
                  position: "relative"
                }
              }}
            >
              {renderPanelContent()}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  });

const ClinicalSidePanel = withFetch(
  x => [x.userExperience.ref.treeViewOptions.load()],
  ClinicalSidePanelBase,
  { fallback: <Spinner /> }
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClinicalSidePanel;

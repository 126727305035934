import { FunctionComponent } from "react";
import { useField } from "react-final-form";

import {
  calendarStrings,
  dataAttribute,
  DataAttributes,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { getValueWithUnits } from "@libs/utils/utils.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { medHistoryNameOf } from "./MedicalHistoryDateFields.tsx";
import { getAgeOnYear, getAgeOnYearAndMonth } from "./utils.ts";

interface MedicalHistoryYearPickerProps {
  birthDate?: Date;
  showMonth?: boolean;
}
export const MedicalHistoryYearPicker: FunctionComponent<
  MedicalHistoryYearPickerProps
> = ({ birthDate, showMonth }) => {
  const {
    input: { value: year },
    meta: { error: yearError }
  } = useField("year", {
    subscription: { value: true, error: true }
  });

  const {
    input: { value: month },
    meta: { error: monthError }
  } = useField("month", {
    subscription: { value: true, error: true }
  });

  const currentYear = DateTime.now().year;

  const renderPatientAgeText = () => {
    let age = "";
    if (birthDate) {
      const birthdate = DateTime.fromJSDate(birthDate);
      if (month && year) {
        const ageYearsMonths = getAgeOnYearAndMonth(birthdate, year, month) || {
          years: -1,
          months: -1
        };
        if (ageYearsMonths.years > 0) {
          if (ageYearsMonths.months > 0) {
            age = `${getValueWithUnits(
              ageYearsMonths.years,
              "year"
            )} and ${getValueWithUnits(ageYearsMonths.months, "month")}`;
          } else {
            age = getValueWithUnits(ageYearsMonths.years, "year");
          }
        } else if (ageYearsMonths.years === 0) {
          if (ageYearsMonths.months > 0) {
            age = getValueWithUnits(ageYearsMonths.months, "month");
          } else if (ageYearsMonths.months === 0) {
            age = "1 month";
          }
        }
      } else if (year) {
        const ageOnYear = Number(getAgeOnYear(birthdate, year));
        if (ageOnYear > 0) {
          age = getValueWithUnits(ageOnYear, "year");
        } else {
          age = "1 year";
        }
      }
    }
    return `Age (approx.) ${age}`;
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <SpinNumberInputField
          label="Year"
          name={medHistoryNameOf("year")}
          min={birthDate ? birthDate.getFullYear() : 1900}
          max={currentYear}
          styles={{ root: { width: 10 } }}
          maxDigitsNumber={4}
        />
        {showMonth && (
          <DropdownField
            label="Month"
            name={medHistoryNameOf("month")}
            styles={{ root: { width: 140 } }}
            options={calendarStrings.months.map((month, i) => ({
              key: i < 9 ? `0${i + 1}` : `${i + 1}`,
              text: month
            }))}
          />
        )}
      </Stack>
      {!monthError && !yearError && (
        <Text
          {...dataAttribute(
            DataAttributes.Element,
            "medical-history-patient-age"
          )}
          variant="small"
        >
          {renderPatientAgeText()}
        </Text>
      )}
    </Stack>
  );
};

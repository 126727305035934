export enum InvoiceFormLabels {
  invoiceNumber = "Invoice number",
  invoiceDetails = "Invoice details",
  date = "Date",
  location = "Location",
  provider = "Provider",
  status = "Status",
  address = "Billing address",
  billType = "Bill type",
  billTo = "Bill to",
  reference = "Reference",
  billSchedule = "Schedule",
  allocatedPayments = "Allocated payments",
  adjustmentReason = "Adjustment reason",
  cancellationReason = "Cancellation reason",
  patientUnallocatedCredBalance = "Unallocated credit balance",
  invoicedItems = "Invoiced items",
  originalInvoiceItems = "Original invoiced items"
}

import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  HideStack,
  Stack
} from "@bps/fluent-ui";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { EmailConfirmationModal } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { OptedOutTooltip } from "../../shared-components/OptedOutTooltip.tsx";
import { CancelInvoiceDialog } from "./CancelInvoiceDialog.tsx";
import { InvoiceFormButtons } from "./InvoiceForm.types.tsx";
import { InvoiceViewPrimaryButton } from "./InvoiceViewPrimaryButton.tsx";

interface InvoiceViewButtonsProps {
  onClose: () => void;
  onSubmit: () => void;
}

export const InvoiceViewButtons: React.FC<InvoiceViewButtonsProps> = observer(
  ({ onClose, onSubmit }) => {
    const { billing, practice } = useStores();

    const {
      handleOnAdjustClick,
      handleOnCancelClick,
      invoice,
      isConfirmationDialogVisible,
      isInvoiceEmailDialogVisible,
      isStatusCancelledOrAdjusted,
      isSendEmailDisabled,
      onOpenInvoicePdf,
      setInvoiceEmailDialogVisible
    } = useContext(InvoiceViewContext);

    const accountContact = practice.contactsMap.get(invoice.accountId);

    const isNotBilledToPublicInsurer =
      !accountContact?.organisationRoles ||
      !practice.isNzPublicInsurer(accountContact.organisationRoles);

    return (
      <Stack>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
          {!isStatusCancelledOrAdjusted && (
            <When permission={Permission.PaymentCreate}>
              {/* show only for unpaid and part-paid */}
              <InvoiceViewPrimaryButton
                isBilledToAcc={!isNotBilledToPublicInsurer}
              />

              {/* ⚠️ the next two div(s) are for unit test purposes only since Fluent render split bts in DOM root Callout */}
              <HideStack when={true}>
                <div
                  {...dataAttribute(
                    DataAttributes.Element,
                    "test-adjust-invoice"
                  )}
                  className="visually-hidden"
                  onClick={handleOnAdjustClick}
                />
                <div
                  {...dataAttribute(
                    DataAttributes.Element,
                    "test-cancel-invoice"
                  )}
                  className="visually-hidden"
                  onClick={() => handleOnCancelClick(true)}
                />
              </HideStack>
              {isNotBilledToPublicInsurer && (
                <OptedOutTooltip contact={accountContact}>
                  <DefaultButton
                    {...dataAttribute(
                      DataAttributes.Element,
                      "get-invoice-sendemail"
                    )}
                    text={InvoiceFormButtons.sendEmail}
                    iconProps={{ iconName: "Mail" }}
                    disabled={isSendEmailDisabled}
                    onClick={() => setInvoiceEmailDialogVisible(true)}
                  />
                </OptedOutTooltip>
              )}
              <DefaultButton
                {...dataAttribute(DataAttributes.Element, "get-invoice-pdf")}
                text={InvoiceFormButtons.pdf}
                iconProps={{ iconName: "pdf" }}
                disabled={isStatusCancelledOrAdjusted}
                onClick={() => onOpenInvoicePdf()}
              />
            </When>
          )}

          <DefaultButton
            {...dataAttribute(DataAttributes.Element, "close-invoice")}
            text={InvoiceFormButtons.close}
            onClick={onClose}
          />
        </Stack>

        <CancelInvoiceDialog
          hidden={!isConfirmationDialogVisible}
          onCancel={() => handleOnCancelClick(false)}
          onConfirm={onSubmit}
          invoice={invoice}
        />

        {isInvoiceEmailDialogVisible && (
          <EmailConfirmationModal
            accountContactId={invoice.accountId}
            onDismiss={() => {
              setInvoiceEmailDialogVisible(false);
            }}
            onSubmit={async ({ email }) => {
              await billing.sendInvoicePdf([{ invoiceId: invoice.id, email }]);
              setInvoiceEmailDialogVisible(false);
              onClose();
            }}
            commType={OutboundCommType.Invoice}
          />
        )}
      </Stack>
    );
  }
);

import { MutableRefObject, PropsWithChildren, ReactNode } from "react";

import { Selection, Tile, TopBarWrapper } from "@bps/fluent-ui";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { MedicationFormResponsiveHeader } from "./MedicationFormResponsiveHeader.tsx";

export interface MedicationHeaderWrapperProps
  extends PropsWithChildren<{
    heading?: string;
    noGap?: boolean;
    pivotTab?: ReactNode;
    onClose: () => void;
  }> {
  selection: MutableRefObject<
    Selection<
      MedicationClinicalDataItemDto & {
        key: string;
      }
    >
  >;
}

export const MedicationFormHeaderWrapper: React.FC<
  MedicationHeaderWrapperProps
> = props => {
  return (
    <TopBarWrapper
      styles={{
        root: {
          gap: props.noGap ? 0 : 16,
          margin: props.noGap || !props.onClose ? 0 : -16
        },
        content: {
          display: "flex"
        },
        subComponentStyles: {
          topBarStyles: {
            root: {
              height: "auto"
            }
          }
        }
      }}
      header={
        <MedicationFormResponsiveHeader
          heading={props.heading}
          pivotTab={props.pivotTab}
          onClose={props.onClose}
          selection={props.selection}
        />
      }
    >
      <Tile
        styles={{
          root: {
            padding: props.noGap ? 0 : 16,
            width: "100%",
            height: "100%",

            overflowY: "auto"
          }
        }}
      >
        {props.children}
      </Tile>
    </TopBarWrapper>
  );
};

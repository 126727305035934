import { observer } from "mobx-react-lite";
import { FunctionComponent, memo, useRef, useState } from "react";

import { PivotItem, PivotTabs, Selection } from "@bps/fluent-ui";
import {
  Medication,
  MedicationClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { CurrentMedicationsList } from "./CurrentMedicationsList.tsx";
import { DeleteCurrentMedicationDialog } from "./DeleteCurrentMedicationDialog.tsx";
import {
  MedicationsContext,
  useMedicationsContext
} from "./helpers/MedicationsContext.tsx";
import { MedicationsHelper } from "./helpers/MedicationsHelper.ts";
import { MedicationFormHeaderWrapper } from "./MedicationFormHeaderWrapper.tsx";
import { PrescriptionsList } from "./PrescriptionsList.tsx";
import { PreviousMedicationsList } from "./PreviousMedicationsList.tsx";

export interface MedicationsExpandedTableProps {
  clinicalRecord: ClinicalRecord;
}

const MedicationsExpandedTableBase: React.FC<MedicationsExpandedTableProps> =
  observer(({ clinicalRecord }) => {
    const { clinical } = useStores();
    const helper = useMedicationsContext();
    const { setSelectedCurrentMeds, isDeleteDialogVisible } = helper;
    const [selectedKey, setSelectedKey] = useState(Medication.Current);

    const handlePivotItemClick = (item?: any) => {
      if (item?.props.itemKey) {
        setSelectedKey(item.props.itemKey);
      }
    };

    const selection = useRef(
      new Selection<MedicationClinicalDataItemDto & { key: string }>({
        onSelectionChanged: async () => {
          const selectedMeds = selection.current.getSelection();

          setSelectedCurrentMeds(selectedMeds);
        }
      })
    );

    return (
      <MedicationFormHeaderWrapper
        heading={Medication.Title}
        onClose={clinical.ui.tabs.currentPatientRecordTab!.hideActive}
        pivotTab={
          <PivotTabs
            selectedKey={selectedKey}
            onLinkClick={handlePivotItemClick}
          >
            <PivotItem
              headerText={Medication.Current}
              itemKey={Medication.Current}
            />
            <PivotItem
              headerText={Medication.Scripts}
              itemKey={Medication.Scripts}
            />
            <PivotItem
              headerText={Medication.Previous}
              itemKey={Medication.Previous}
            />
          </PivotTabs>
        }
        selection={selection}
      >
        {selectedKey === Medication.Current && (
          <CurrentMedicationsList
            clinicalRecord={clinicalRecord}
            selection={selection}
          />
        )}
        {selectedKey === Medication.Scripts && (
          <PrescriptionsList clinicalRecord={clinicalRecord} />
        )}
        {selectedKey === Medication.Previous && (
          <PreviousMedicationsList clinicalRecord={clinicalRecord} />
        )}

        {helper.selectedCurrentMeds && helper.selectedCurrentMeds[0] && (
          <DeleteCurrentMedicationDialog
            hidden={!isDeleteDialogVisible}
            onCancel={() => {
              helper.setDeleteDialogVisible(false);
            }}
            onConfirm={() => {
              helper.deleteCurrentMedication(helper.selectedCurrentMeds[0]);
            }}
            medication={helper.selectedCurrentMeds[0]}
          />
        )}
      </MedicationFormHeaderWrapper>
    );
  });

const MedicationsTableContextProvider: FunctionComponent = memo(() => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const root = useStores();
  const helper = new MedicationsHelper(clinicalRecord, root);
  return (
    <MedicationsContext.Provider value={helper}>
      <MedicationsExpandedTableBase clinicalRecord={clinicalRecord} />
    </MedicationsContext.Provider>
  );
});

export const MedicationsExpandedTable = withFetch(
  x => [
    x.clinical.ref.dosingFrequencies.load(),
    x.clinical.ref.dosingAdministrationUnits.load(),
    x.clinical.ref.dosingOtherInstructions.load(),
    x.clinical.ref.dosingDurationPeriods.load(),
    x.clinical.ref.dosingRoutes.load(),
    x.clinical.ref.dosingFood.load(),
    x.clinical.ref.rxTypes.load()
  ],
  MedicationsTableContextProvider
);

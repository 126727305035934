import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { MessageBar, MessageBarType, SideRailMenuItem } from "@bps/fluent-ui";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { CLAIM_FORM_ID } from "@modules/acc/screens/claim/components/ClaimForm.types.ts";
import { OnHoldMessageBar } from "@modules/acc/screens/claim/components/OnHoldMessageBar.tsx";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { AccidentAndEmploymentDetailsDraftValidator } from "@modules/acc/screens/claim/validators/AccidentAndEmploymentDetailsDraftValidator.tsx";
import { PatientDeclarationDraftValidator } from "@modules/acc/screens/claim/validators/PatientDeclarationDraftValidator.tsx";
import { PersonalDetailsDraftValidator } from "@modules/acc/screens/claim/validators/PersonalDetailsDraftValidator.tsx";
import { ClaimDocumentViewerDialog } from "@modules/acc/screens/claims/components/ClaimDocumentViewerDialog.tsx";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { ClaimFormProps } from "../types/claim.types.ts";
import { ProviderDeclarationDraftValidator } from "../validators/ProviderDeclarationDraftValidator.tsx";
import { ClaimEditFormBody } from "./ClaimEditFormBody.tsx";
import { formStyles } from "./ClaimForm.styles.ts";
import { ClaimFormSideRailLabels, ClaimsCardIds } from "./ClaimFormEnums.ts";
import { ClaimViewFormBody } from "./ClaimViewFormBody.tsx";

const personalDetailsDraftValidator = new PersonalDetailsDraftValidator();
const patientDeclarationDraftValidator = new PatientDeclarationDraftValidator();
const providerDeclarationDraftValidator =
  new ProviderDeclarationDraftValidator();

const accidentAndEmploymentDetailsDraftValidator =
  new AccidentAndEmploymentDetailsDraftValidator();

const ClaimFormBase: React.FC<ClaimFormProps> = observer(
  ({ header, initialValues, onSubmitSucceeded, children }) => {
    const { handleSubmit, claim, handleSubmitSucceeded, getInitialFormValues } =
      useContext(ClaimContext);

    const claimMenuItems: SideRailMenuItem[] = [
      {
        text: ClaimFormSideRailLabels.personalDetails,
        id: ClaimsCardIds.personalDetails
      },
      {
        text: ClaimFormSideRailLabels.accidentAndEmploymentDetails,
        id: ClaimsCardIds.accidentAndEmploymentDetails
      },
      {
        text: ClaimFormSideRailLabels.patientDeclaration,
        id: ClaimsCardIds.patientDeclaration
      },
      {
        text: ClaimFormSideRailLabels.injuryDiagnosisAndAssistance,
        id: ClaimsCardIds.injuryDiagnosisAndAssistance
      },
      {
        text: ClaimFormSideRailLabels.providerDeclaration,
        id: ClaimsCardIds.providerDeclaration
      }
    ];

    if (
      !claim.isViewMode ||
      (claim.isViewMode && !!claim.referralDetails?.length)
    ) {
      claimMenuItems.push({
        text: ClaimFormSideRailLabels.referralsOut,
        id: ClaimsCardIds.referralsOut
      });
    }

    const getMessageBar = () => {
      const messageBarComponents = [];

      if (claim.private) {
        messageBarComponents.push(
          <MessageBar
            key="private-warning"
            messageBarType={MessageBarType.warning}
          >
            This claim is no longer valid. This condition is being paid for
            privately.
          </MessageBar>
        );
      }

      if (claim.isOnHold) {
        messageBarComponents.push(<OnHoldMessageBar />);
      }

      return messageBarComponents.length > 0 ? (
        <>{messageBarComponents}</>
      ) : undefined;
    };

    return (
      <SubmissionForm<ClaimFormValues>
        formName="claim-form"
        subscription={{}}
        onSubmitSucceeded={(values, form) => {
          handleSubmitSucceeded(values);
          onSubmitSucceeded && onSubmitSucceeded(values, form);
        }}
        onSubmit={handleSubmit}
        styles={formStyles}
        hideButtons
        initialValues={initialValues || getInitialFormValues()}
        validate={values => {
          return {
            ...patientDeclarationDraftValidator.validate(values),
            ...personalDetailsDraftValidator.validate(values),
            ...accidentAndEmploymentDetailsDraftValidator.validate(values),
            ...providerDeclarationDraftValidator.validate(values)
          };
        }}
        formId={CLAIM_FORM_ID}
      >
        {({ form }) => (
          <CardFormLayout
            sideRailMenuItems={claimMenuItems}
            header={header}
            messageBar={getMessageBar()}
          >
            {claim.isViewMode ||
            claim.claimStatus === ClaimStatuses.Accredited ? (
              <ClaimViewFormBody />
            ) : (
              <ClaimEditFormBody />
            )}
            {children && children(form)}
            <ClaimDocumentViewerDialog />
          </CardFormLayout>
        )}
      </SubmissionForm>
    );
  }
);

const ClaimForm = withFetch(
  x => [
    x.acc.ref.accidentLocations.load(),
    x.acc.ref.accidentScenes.load(),
    x.acc.ref.occupations.load(),
    x.acc.ref.sports.load()
  ],
  ClaimFormBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClaimForm;

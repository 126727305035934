import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontWeights,
  Link,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { EthnicityText, SexText } from "@ui-components/RefText.tsx";

import { getIntakeStatusText } from "../intake/utils.ts";
import { NotRecorded } from "./NotRecorded.tsx";

type PatientHeaderTagsProps = {
  intakeOptions: { key: string; text: string }[];
  patient: Contact;
  onHandleEditModal: (cardId: string, id: string) => () => void;
  onClinicalDataLinkClick: (clinicalDataType: ClinicalDataType) => () => void;
  clinicalRecord: ClinicalRecord;
};
export const PatientHeaderTags: React.FC<PatientHeaderTagsProps> = observer(
  ({
    intakeOptions,
    onClinicalDataLinkClick,
    patient,
    onHandleEditModal,
    clinicalRecord
  }) => {
    const { id, sex, ethnicities, birthDate, pronoun } = patient;

    const { clinicalData: latestData } = clinicalRecord;
    const tagItems: {
      key: string;
      element: React.ReactNode;
      isDisplayedForMale: boolean;
      onclick: () => void;
      notRecorded: boolean;
    }[] = [
      {
        key: "pronoun",
        element: pronoun ?? "Pronouns",
        isDisplayedForMale: true,
        onclick: onHandleEditModal(PatientCardIds.patientHeader, id),
        notRecorded: pronoun === undefined
      }
    ];

    tagItems.push({
      key: "sex",
      element: sexText(sex),
      isDisplayedForMale: true,
      onclick: onHandleEditModal(PatientCardIds.patientHeader, id),
      notRecorded: sex === undefined
    });

    if (birthDate !== undefined) {
      const patientAge = DateTime.now().diff(birthDate, "years").years;
      const patientAgeBetween15And50Inclusive =
        patientAge >= 15 && patientAge <= 50;
      if (
        patientAgeBetween15And50Inclusive ||
        (!patientAgeBetween15And50Inclusive &&
          latestData?.social?.isPregnant?.observed)
      ) {
        tagItems.push({
          key: "pregnant",
          element: <>{pregnantText(latestData?.social?.isPregnant)}</>,
          isDisplayedForMale: false,
          onclick: onClinicalDataLinkClick(ClinicalDataType.Social),
          notRecorded: latestData?.social?.isPregnant?.observed === undefined
        });
      }
      if (
        patientAgeBetween15And50Inclusive ||
        (!patientAgeBetween15And50Inclusive &&
          latestData?.social?.isBreastfeeding?.observed)
      ) {
        tagItems.push({
          key: "breastFeeding",
          element: latestData
            ? breastFeedingText(latestData.social?.isBreastfeeding)
            : "",
          isDisplayedForMale: false,
          onclick: onClinicalDataLinkClick(ClinicalDataType.Social),
          notRecorded:
            latestData?.social?.isBreastfeeding?.observed === undefined
        });
      }
    }

    tagItems.push({
      key: "ethinicities",
      element: <>{ethnicityText(ethnicities)}</>,
      isDisplayedForMale: true,
      onclick: onHandleEditModal(PatientCardIds.patientHeader, id),
      notRecorded: !ethnicities.length
    });
    tagItems.push({
      key: "smoking",
      element: latestData ? smokingText(latestData, intakeOptions) : "",
      isDisplayedForMale: true,
      onclick: onClinicalDataLinkClick(ClinicalDataType.Tobacco),
      notRecorded:
        getIntakeStatusText(latestData?.tobacco?.status, intakeOptions) ===
        "Not recorded"
    });
    tagItems.push({
      key: "alcohol",
      element: latestData ? alcoholText(latestData, intakeOptions) : "",
      isDisplayedForMale: true,
      onclick: onClinicalDataLinkClick(ClinicalDataType.Alcohol),
      notRecorded:
        getIntakeStatusText(latestData?.alcohol?.status, intakeOptions) ===
        "Not recorded"
    });

    const items = tagItems.filter(item => {
      if (sex === Sex.Female) {
        return item;
      } else return item.isDisplayedForMale;
    });

    return (
      <Stack
        styles={{
          root: {
            fontWeight: FontWeights.light
          }
        }}
        horizontal
        wrap
        tokens={{ childrenGap: 4 }}
      >
        {items.map(item => (
          <TextBadge
            key={item.key}
            styles={{
              root: {
                fontWeight: FontWeights.regular,
                fontSize: 12,
                padding: "4px 8px"
              }
            }}
            badgeSize={TextBadgeSize.medium}
            badgeColor={
              item.notRecorded
                ? TextBadgeColor.yellow
                : TextBadgeColor.lightGrey
            }
          >
            {item.notRecorded ? (
              <NotRecorded element={item.element} onClick={item.onclick} />
            ) : (
              <Link onClick={item.onclick}>{item.element}</Link>
            )}
          </TextBadge>
        ))}
      </Stack>
    );
  }
);

function sexText(sex: Sex | undefined) {
  return sex ? (
    <>
      Assigned:&nbsp;
      <SexText code={sex} />
    </>
  ) : (
    "Sex"
  );
}

function ethnicityText(ethnicities: string[]) {
  return (
    <>
      {(ethnicities.length && <EthnicityText code={ethnicities[0]} />) ||
        "Ethnicity"}
      {ethnicities.length > 1 ? ` (${ethnicities.length - 1})` : undefined}
    </>
  );
}

function smokingText(
  latestData: EncounterClinicalData,
  intakeOptions: { key: string; text: string }[]
) {
  const intakeStatusText = getIntakeStatusText(
    latestData.tobacco?.status,
    intakeOptions
  );

  switch (intakeStatusText) {
    case "Not recorded":
      return "Smoking";
    case "Never":
      return "Never smokes";
    default:
      return intakeStatusText;
  }
}

function alcoholText(
  latestData: EncounterClinicalData,
  intakeOptions: { key: string; text: string }[]
) {
  const intakeStatusText = getIntakeStatusText(
    latestData.alcohol?.status,
    intakeOptions
  );

  switch (intakeStatusText) {
    case "Not recorded":
      return "Alcohol";
    case "Never":
      return "Never drinks";
    default:
      return intakeStatusText;
  }
}

function pregnantText(observation: { observed?: boolean } | undefined) {
  if (observation?.observed === undefined) {
    return "Pregnant";
  }
  return observation.observed ? "Pregnant" : "Not pregnant";
}

function breastFeedingText(observation: { observed?: boolean } | undefined) {
  if (observation?.observed === undefined) {
    return "Breastfeeding";
  }
  return observation.observed ? "Breastfeeding" : "Not breastfeeding";
}

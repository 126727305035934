import { observer } from "mobx-react-lite";
import { useContext, useMemo } from "react";

import { NoDataTile, Pivot, PivotItem, Spinner, Stack } from "@bps/fluent-ui";
import { InboxScreenContext } from "@modules/inbox/screens/context/InboxScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ScrollListener } from "@ui-components/ScrollListener.tsx";

import { InboxGroupedList } from "../../shared-components/InboxGroupedList.tsx";
import { useInboxSearch } from "./useInboxResults.tsx";

export const NoFileDataTile = () => (
  <NoDataTile
    styles={{ root: { marginTop: 30 } }}
    textProps={{ text: "No files" }}
    linkProps={{ hidden: true }}
  />
);

export interface UserInboxScreenLeftSideListProps {
  onTabItemSelected?: (selectedHeaderText: string) => void;
}

export const UserInboxScreenLeftSideList: React.FC<UserInboxScreenLeftSideListProps> =
  observer(({ onTabItemSelected }) => {
    const { inbox } = useStores();
    const { searchResults, isLoading, isLoadingMore, handleScrolledToBottom } =
      useInboxSearch({ refreshKey: inbox.ui.documentRefreshKey });

    const { getPatientGroups, mapToGroupedListItem } =
      useContext(InboxScreenContext);

    const groups = useMemo(
      () => getPatientGroups(searchResults.value?.results ?? []),
      [searchResults.value?.results, getPatientGroups]
    );

    const files = useMemo(
      () => searchResults.value?.results?.map(mapToGroupedListItem) ?? [],
      [searchResults.value?.results, mapToGroupedListItem]
    );

    return (
      <Stack
        styles={{
          root: {
            overflowY: "auto"
          }
        }}
      >
        <Pivot
          styles={{
            root: { paddingLeft: 40 },
            linkIsSelected: { paddingLeft: 10 }
          }}
          defaultSelectedKey="files"
          onLinkClick={(item?: PivotItem) =>
            onTabItemSelected && onTabItemSelected(item?.props.headerText ?? "")
          }
        >
          <PivotItem
            key="files"
            headerText="Files"
            itemCount={searchResults.value?.total ?? undefined}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <InboxGroupedList groups={groups} files={files} />
                <ScrollListener
                  searchResults={searchResults}
                  onScrolledToBottom={handleScrolledToBottom}
                />
                {!searchResults.value?.results.length &&
                  !isLoading &&
                  !isLoadingMore && <NoFileDataTile />}
              </>
            )}
          </PivotItem>
        </Pivot>
      </Stack>
    );
  });

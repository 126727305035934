import { FormApi } from "final-form";

import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  SleepConfirmedClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import {
  toBoolObservationFromString,
  toStringFromBoolObservation
} from "../family-social-history.utils.ts";
import { SleepFormValidator } from "../validators/SleepFormValidator.tsx";
import { SleepFormFields } from "./SleepFormFields.tsx";
import { SleepFormValues } from "./SleepFormValues.ts";

interface SleepFormProps {
  clinicalRecord: ClinicalRecord;
}

const validator = new SleepFormValidator();

const sleepFormConfirmed = (
  openEncounter: Encounter | undefined,
  sleepConfirmed: SleepConfirmedClinicalDataDto | undefined
) => {
  return (
    openEncounter &&
    sleepConfirmed?.createLog?.createdEncounterId === openEncounter?.id
  );
};

const SleepFormComponent: React.FunctionComponent<SleepFormProps> = ({
  clinicalRecord
}) => {
  const { stashedClinicalData, openEncounter, id, saveClinicalData } =
    clinicalRecord;

  const stashedSleep = stashedClinicalData?.sleep;
  const sleepConfirmed = clinicalRecord.clinicalData?.sleepConfirmed;
  const { clinical, notification } = useStores();

  const { isViewOnly } = usePatientRecordScreenContext();

  const onCancel = () => {
    clinical.ui.closePatientRecordContentForm(id, ClinicalDataType.Sleep);

    clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["sleep"]);
  };

  const initialValues: SleepFormValues = {
    sleepQuality: stashedSleep?.sleepQuality,
    sleepPosition: stashedSleep?.sleepPosition,
    numOfHours: stashedSleep?.numOfHours,
    sleepTime: stashedSleep?.sleepTime,
    wakeSameTime: toStringFromBoolObservation(
      stashedSleep?.wakeSameTime?.observed
    ),
    wakeTime: stashedSleep?.wakeTime,
    weekendDiffer: toStringFromBoolObservation(
      stashedSleep?.weekendDiffer?.observed
    ),
    weekendDifferComment: stashedSleep?.weekendDifferComment,
    snore: stashedSleep?.snore,
    electronicDevices: toStringFromBoolObservation(
      stashedSleep?.electronicDevices?.observed
    ),
    environment: stashedSleep?.environment,
    disrupted: toStringFromBoolObservation(stashedSleep?.disrupted?.observed),
    comfortable: toStringFromBoolObservation(
      stashedSleep?.comfortable?.observed
    ),
    comfortableComment: stashedSleep?.comfortableComment,
    troubleFallingAsleep: toStringFromBoolObservation(
      stashedSleep?.troubleFallingAsleep?.observed
    ),
    stopsSleep: stashedSleep?.stopsSleep,
    whatHelpsSleep: stashedSleep?.whatHelpsSleep,
    sleepDisturbances: toStringFromBoolObservation(
      stashedSleep?.sleepDisturbances?.observed
    ),
    kindOfDisturbances: stashedSleep?.kindOfDisturbances,
    wakeDuringNight: toStringFromBoolObservation(
      stashedSleep?.wakeDuringNight?.observed
    ),
    timesWakeDuringNight: stashedSleep?.timesWakeDuringNight,
    troubleGoingBackToSleep: toStringFromBoolObservation(
      stashedSleep?.troubleGoingBackToSleep?.observed
    ),
    wakeEarly: toStringFromBoolObservation(stashedSleep?.wakeEarly?.observed),
    wakeEarlyAtSameTime: toStringFromBoolObservation(
      stashedSleep?.wakeEarlyAtSameTime?.observed
    ),
    wakeEarlyTime: stashedSleep?.wakeEarlyTime,
    restedWhenAwake: toStringFromBoolObservation(
      stashedSleep?.restedWhenAwake?.observed
    ),
    daytimeSleepiness: toStringFromBoolObservation(
      stashedSleep?.daytimeSleepiness?.observed
    ),
    concentrationAndMemory: toStringFromBoolObservation(
      stashedSleep?.concentrationAndMemory?.observed
    ),
    confirmed: sleepFormConfirmed(openEncounter, sleepConfirmed)
  };

  const getClinicalData = (values: SleepFormValues) => {
    const sleepETag = stashedClinicalData?.originalDto?.sleep?.eTag;
    const confirmedETag =
      stashedClinicalData?.originalDto?.sleepConfirmed?.eTag;

    const clinicalData: EncounterClinicalDataDto = {};

    clinicalData.sleep = {
      eTag: sleepETag,
      sleepQuality: values.sleepQuality,
      sleepPosition: values.sleepPosition,
      numOfHours: values.numOfHours,
      sleepTime: values.sleepTime,
      wakeSameTime: toBoolObservationFromString(values.wakeSameTime),
      wakeTime: values.wakeTime,
      weekendDiffer: toBoolObservationFromString(values.weekendDiffer),
      weekendDifferComment: values.weekendDifferComment,
      snore: values.snore,
      electronicDevices: toBoolObservationFromString(values.electronicDevices),
      environment: values.environment,
      disrupted: toBoolObservationFromString(values.disrupted),
      comfortable: toBoolObservationFromString(values.comfortable),
      comfortableComment: values.comfortableComment,
      troubleFallingAsleep: toBoolObservationFromString(
        values.troubleFallingAsleep
      ),
      stopsSleep: values.stopsSleep,
      whatHelpsSleep: values.whatHelpsSleep,
      sleepDisturbances: toBoolObservationFromString(values.sleepDisturbances),
      kindOfDisturbances: values.kindOfDisturbances,
      wakeDuringNight: toBoolObservationFromString(values.wakeDuringNight),
      timesWakeDuringNight: values.timesWakeDuringNight,
      troubleGoingBackToSleep: toBoolObservationFromString(
        values.troubleGoingBackToSleep
      ),
      wakeEarly: toBoolObservationFromString(values.wakeEarly),
      wakeEarlyAtSameTime: toBoolObservationFromString(
        values.wakeEarlyAtSameTime
      ),
      wakeEarlyTime: values.wakeEarlyTime,
      restedWhenAwake: toBoolObservationFromString(values.restedWhenAwake),
      daytimeSleepiness: toBoolObservationFromString(values.daytimeSleepiness),
      concentrationAndMemory: toBoolObservationFromString(
        values.concentrationAndMemory
      )
    };

    clinicalData.sleepConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };
    return clinicalData;
  };

  const submitData = async (values: SleepFormValues) => {
    const clinicalData = getClinicalData(values);
    await saveClinicalData(clinicalData);
  };

  const onSubmitSucceeded = (
    values: SleepFormValues,
    form: FormApi<SleepFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.Sleep
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onCancel();
    }
  };

  const getValues = (stashedData?: StashedEncounterClinicalData) => {
    const dirty = stashedData?.dirtyAreas
      ? !!stashedData?.dirtyAreas.get("sleep")
      : false;

    const isNewSleepForm = !stashedSleep?.createLog;

    const sleepFormAlreadyConfirmed =
      sleepFormConfirmed(
        openEncounter,
        stashedData?.originalDto?.sleepConfirmed
      ) ?? false;

    return { dirty, sleepFormAlreadyConfirmed, isNewSleepForm };
  };

  const getButtonText = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, sleepFormAlreadyConfirmed, isNewSleepForm } =
      getValues(stashedData);

    const submissionButtonText =
      isNewSleepForm ||
      sleepFormAlreadyConfirmed ||
      (!sleepFormAlreadyConfirmed && dirty)
        ? "Save"
        : "Confirm";

    return submissionButtonText;
  };

  const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, sleepFormAlreadyConfirmed, isNewSleepForm } =
      getValues(stashedData);

    return (isNewSleepForm || sleepFormAlreadyConfirmed) && !dirty;
  };

  return (
    <ClinicalSubmissionForm<SleepFormValues>
      formName="sleep"
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues}
      readOnly={isViewOnly}
      validate={validator.validate}
      disableRoutePrompt
      heading="Sleep"
      onCancel={onCancel}
      extraPromptConditionOnCancel={() =>
        clinicalRecord.stashedClinicalData?.dirtyAreas
          ? !!clinicalRecord.stashedClinicalData?.dirtyAreas.get("sleep")
          : false
      }
      disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
      submitButtonTextCondition={stashedData => getButtonText(stashedData)}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.sleepConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.sleepConfirmed
      )}
      hideSubmit={isViewOnly}
    >
      <>
        <StashedClinicalDataFormSpy<SleepFormValues>
          clinicalRecord={clinicalRecord}
          getData={getClinicalData}
          areasToObserve={{ sleep: [ClinicalDataType.Sleep] }}
        />
        <SleepFormFields />
      </>
    </ClinicalSubmissionForm>
  );
};

export const SleepForm = withFetch(
  x => [
    x.clinical.ref.sleepQualities.load(),
    x.clinical.ref.sleepPositions.load(),
    x.clinical.ref.sleepTimes.load(),
    x.clinical.ref.snoreOptions.load()
  ],
  SleepFormComponent
);

import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import {
  TransactionFilter,
  transactionFilterNameOf
} from "./TransactionFilter.types.ts";
import {
  TransactionFilterBase,
  transactionFilterBaseNameOf
} from "./TransactionFilterBase.types.ts";

export const searchFilter = (): Item<TransactionFilterBase> => ({
  type: "searchBox",
  name: transactionFilterBaseNameOf("search"),
  stickItem: true,
  props: {
    id: "number-search",
    placeholder: "Search",
    styles: { root: { minWidth: 280 } },
    ...dataAttribute(DataAttributes.Element, "number-search")
  }
});

export const numberSearchFilter = (): Item<TransactionFilterBase> => ({
  type: "searchBox",
  name: transactionFilterBaseNameOf("numberSearch"),
  stickItem: true,
  props: {
    id: "transaction-number-search",
    placeholder: "Search for transaction",
    styles: { root: { minWidth: 250 } },
    ...dataAttribute(DataAttributes.Element, "transaction-number-search")
  }
});
export const patientFilter = (): Item<TransactionFilterBase> => ({
  type: "contactPicker",
  name: transactionFilterBaseNameOf("patientId"),
  stickItem: true,
  props: {
    inputProps: {
      placeholder: "Search for patient",
      name: "transaction-patient-filter"
    },
    styles: { root: { minWidth: 310 } },
    iconName: "Search",
    pickerCalloutProps: {
      calloutWidth: 300
    }
  },
  ...dataAttribute(DataAttributes.Element, "transaction-patient-filter")
});

export const claimStatusesFilter = (
  claimStatuses: KeyTextValue[]
): Item<TransactionFilterBase> => ({
  type: "optionsSelect",
  name: transactionFilterBaseNameOf("claimStatuses"),
  props: {
    id: "acc-invoices-filter-claim-statuses",
    multiSelect: true,
    placeholder: "Claim status",
    hideSearchOption: true,
    calloutWidth: 170,
    options: claimStatuses,
    ...dataAttribute(DataAttributes.Id, "transaction-claim-status-select")
  }
});

export const usersSelectFilter = (): Item<TransactionFilterBase> => ({
  type: "usersSelect",
  name: transactionFilterBaseNameOf("userIds"),
  props: {
    id: "accredited-billing-filter-user",
    placeholder: "Providers",
    calloutWidth: 200,
    multiSelect: true,
    ...dataAttribute(DataAttributes.Id, "transaction-user-select")
  }
});

export const billToSelectFilter = (): Item<TransactionFilterBase> => ({
  type: "contactsSelect",
  name: transactionFilterBaseNameOf("accountIds"),
  props: {
    id: "transaction-bill-to-select",
    multiSelect: true,
    placeholder: "Billed to",
    styles: { root: { maxWidth: 250, minWidth: 180 } },
    calloutWidth: 250,
    ...dataAttribute(DataAttributes.Id, "transaction-bill-to-select")
  }
});

export const datesRangePickerFilter = (): Item<TransactionFilterBase> => ({
  type: "datesRangePicker",
  name: "datesRangePicker",
  props: {
    startDateName: transactionFilterBaseNameOf("startTime"),
    endDateName: transactionFilterBaseNameOf("endTime"),
    placeholder: "Date(s)",
    ...dataAttribute(DataAttributes.Id, "transaction-date-select")
  }
});

export const itemTypeFilters = (
  itemTypes: KeyTextValue[]
): Item<TransactionFilter> => ({
  type: "optionsSelect",
  name: transactionFilterNameOf("itemTypes"),
  props: {
    id: "item-type",
    multiSelect: true,
    hideSearchOption: true,
    placeholder: "Type",
    calloutWidth: 170,
    options: itemTypes,
    ...dataAttribute(DataAttributes.Id, "transaction-type-select")
  }
});
